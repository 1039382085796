import React from 'react';
import PropTypes from 'prop-types';
import { StatelessCheckbox } from 'spheracloud-common';
import './ChooseList.css';

const wrapStartInSpanToHighlight = (str) => {
  const startColonTextArr = str.split(new RegExp('([^:]*)$'));
  if(!startColonTextArr[0]) {
    return str;
  }
  return [<span className="ChooseListItemBlurry">
    {startColonTextArr[0].replace(/:/g, " > ")}
  </span>, startColonTextArr[1]];
};

const ChooseList = ({ items, noResultsText, onItemSelect, maxHeight, fieldId }) => (
  <div className="ChooseListItemContainer" style={ maxHeight ? {maxHeight:maxHeight, overflowY:"auto"} : {}}>
    {items.map(i => (
      <div
        key={`${fieldId}-${i.value}`}
        className="ChooseListItem"
        onClick={() => onItemSelect(i.value)}
        onKeyPress={() => onItemSelect(i.value)}
        role="switch"
        aria-checked={i.selected}
        tabIndex={0}
      >
        <StatelessCheckbox
          isChecked={i.selected}
          isKendoGridCell={false}
        />
        <span>{wrapStartInSpanToHighlight(i.text)}</span>
      </div>
    ))}
    {items.length === 0 && (
      <div className="NoResultsItem">
        <span>{noResultsText}</span>
      </div>
    )}
  </div>
);

ChooseList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    selected: PropTypes.bool,
  })),
  noResultsText: PropTypes.string,
  onItemSelect: PropTypes.func,
  maxHeight: PropTypes.string,
  fieldId: PropTypes.string
};

ChooseList.defaultProps = {
  items: [],
  noResultsText: 'No results found.',
  onItemSelect: () => {},
  maxHeight: undefined,
  fieldId: undefined
}

export default ChooseList;
