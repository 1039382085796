import React from 'react';
import PropTypes from 'prop-types';
import { setSummaryModalState } from '../../actions/appActions';
import ReviewPageData from '../ReviewPage/ReviewPageData/ReviewPageData';
import { Modal, Spinner } from 'spheracloud-common';
import { connect } from 'react-redux';
import {  Account, IncognitoHeader, } from '../../icons';
import {  getSelectedFormWizardPages, getStartPage, getPageAnswersCount, getPageQuestionsCount, getElementVisibility } from '../../selectors/wizardSelectors';
import {  getSelectedForm, getString } from '../../selectors/appSelectors';
import { resolveLabelBegin } from '../../actions/wizardActions';
import Strings from '../../constants/Strings';

import './SummaryModal.scss';

const SummaryModal = (props) => {
    const onCancel = () => {
        props.hideModal();
    };

    const getAboutUserPageSummary = () => {
        const userName = props.recordData.userInfo.NAME;
        const userEmail = props.recordData.userInfo.EMAIL;
        if(userName || userEmail) {
            return <div className='ReviewPageAccountContainer'>
                <img src={Account} alt="user-icon" />
                <span>{userName ||userEmail}</span>
            </div>;
        }
        
        return <div className='ReviewPageAccountContainer'>
            <img src={IncognitoHeader} alt="user-icon" />
            <span>{props.anonymousData}</span>
        </div>;
    };

    const renderModal = () => {
        return <Modal title={props.modalTitle} className='SummaryModal' onCancel={onCancel} apply={""} >
            {props.recordData 
                ? <ReviewPageData 
                    key='summaryModal'
                    getAboutUserPageSummary={getAboutUserPageSummary}
                    answers={props.recordData}
                    pages={props.pages}
                    startPage={props.startPage}
                    selectedLanguage ={props.selectedLanguage}
                    pageAnswerCount={props.pageAnswerCount}
                    pageQuestionCount={props.pageQuestionCount}
                    elementIsVisible={props.elementIsVisible}
                    startPageDescription={props.startPageDescription}
                    showAllMultilingualFields={true}
                    resolveLabelBegin={props.resolveLabelBegin}
                    liveSearchOptions={props.liveSearchOptions}
                    objectPicklistValues={props.objectPicklistValues}
                    resolvedLabels={props.resolvedLabels}
                    isHierarchy={props.isHierarchy}
                    /> 
                : <Spinner/>}
        </Modal>
    };

    return props.isModalEnabled ? renderModal()
    : <> </>;
};

SummaryModal.propTypes = {
    recordData: PropTypes.object,
    isModalEnabled: PropTypes.bool,
    hideModal: PropTypes.func,
    pages: PropTypes.array,
    selectedLanguage: PropTypes.string,
    pageAnswerCount: PropTypes.func,
    pageQuestionCount: PropTypes.func,
    anonymousData: PropTypes.string,
    elementIsVisible: PropTypes.func,
    startPageDescription: PropTypes.string,
    modalTitle: PropTypes.string,
    resolveLabelBegin: PropTypes.func,
    resolvedLabels: PropTypes.object,
    objectPicklistValues: PropTypes.object,
    liveSearchOptions: PropTypes.object,
    isHierarchy: PropTypes.bool,
};

const getTitle = (language, fields) => {
    const AccidentDescription = 'Accidents.Description';
    const getLanguageAttribute = (lang) =>`${AccidentDescription}.${lang}`; 
    if (fields[getLanguageAttribute(language)]){
        return `${fields[getLanguageAttribute(language)]} summary`;
    }
    else {
        const key = Object.keys(fields).find(key => key.indexOf(AccidentDescription) > -1);
        return `${fields[key]} summary`;
    }
};

const mapStateToProps = (state) => {
    const isModalEnabled = state.appReducer.similarRecordsModal.isEnabled;
    let recordData = undefined;
    if (isModalEnabled && state.appReducer.similarRecordsModal.currentRecordId > 0)
        recordData = state.appReducer.similarRecordSummaries[state.appReducer.similarRecordsModal.currentRecordId];

    return {
        pageAnswerCount: (page) => { return getPageAnswersCount(0, page, state, recordData) },
        pageQuestionCount: (page) => { return getPageQuestionsCount(0, page, state) },
        selectedLanguage: state.appReducer.userSettings.language,
        startPage: getStartPage(state),
        pages: getSelectedFormWizardPages(state),
        isModalEnabled,
        recordData,
        anonymousData: getString(Strings.anonymous, state),
        elementIsVisible: (elementGuid) => { return getElementVisibility(state, elementGuid) },
        startPageDescription: getSelectedForm(state).ShortDescription,
        modalTitle: recordData ? getTitle(state.appReducer.userSettings.language, recordData.fields) : '',
        liveSearchOptions: state.wizardReducer.liveSearchOptions,
        resolvedLabels: state.wizardReducer.answers.resolvedLabels,
        objectPicklistValues: state.wizardReducer.objectPicklistValues,
        isHierarchy: state.appReducer.isHierarchy,
    };
};

const mapDispatchToProps = (dispatch) => ({
    hideModal: () => {dispatch(setSummaryModalState({isEnabled: false, currentRecordId: 0 }))},
    resolveLabelBegin: (paramObj) => {
        dispatch(resolveLabelBegin(paramObj));
    }
});

export default connect(mapStateToProps, mapDispatchToProps) (SummaryModal);