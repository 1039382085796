export const ATTRIBUTE_TYPES = {
    PICKLIST: 1,
    NUMERIC: 2,
    TEXT: 3,
    BOOL: 4,
    CALENDAR_DATE: 5,
    TIME: 6,
    RELATED_CLASS: 7,
    DERIVED: 8,
    FLOAT: 9
}