import _ from 'lodash';
import { CODED_ELEMENT_TYPES } from "../constants/ElementTypes";
import { LOCATION_ID_ATTRIBUTE, SITEMAP_HOTSPOT } from "../constants/KnownAttributes";

export const convertElementAnswerToAttributes = (element, answer, isHierarchy, elementVisible) => {
    if (!element || !answer) {
        return {};
    }

    switch (element.Name) {
        case CODED_ELEMENT_TYPES.MAP_COORDS:
            return convertMapCoordsAnswerToAttributes(element, answer, elementVisible);
        case CODED_ELEMENT_TYPES.ACCIDENTS_MAPS:
            return convertAccidentMapCoordsAnswerToAttributes(element, answer, isHierarchy, elementVisible);
        case CODED_ELEMENT_TYPES.LOCATION_SELECTOR:
        case CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR:
            if (elementVisible) {
                return { [LOCATION_ID_ATTRIBUTE]: [isHierarchy ? answer.nodeExternalId : answer] }
            } else {
                return { [LOCATION_ID_ATTRIBUTE]:-1 }
            }



        default:
            return {};
    }
}

export const convertAttributesToElementAnswers = (element, attributes, isHierarchy) => {
    if (!element || !attributes) {
        return {};
    }

    let answers;
    switch (element.Name) {
        case CODED_ELEMENT_TYPES.MAP_COORDS:
            answers = convertMapCoordsAttributesToAnswers(element, attributes);
            break;
        case CODED_ELEMENT_TYPES.ACCIDENTS_MAPS:
            answers = convertAccidentMapCoordsAttributesToAnswer(element, attributes, isHierarchy);
            break;
        case CODED_ELEMENT_TYPES.LOCATION_SELECTOR:
        case CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR:
            if (isHierarchy) {
                let answer = attributes[LOCATION_ID_ATTRIBUTE];
                answers = _.isArray(answer) && answer.length > 0 ? { nodeExternalId: answer[0] } : undefined;
            } else {
                let answer = attributes[LOCATION_ID_ATTRIBUTE];
                answers = _.isArray(answer) && answer.length > 0 ? answer[0] : undefined;
            }
            break;

        default:
            answers = {};
            break;
    }

    return answers ? answers : {};
}

const convertAccidentMapCoordsAnswerToAttributes = (element, answer, isHierarchy, elementVisible) => {

    const attributes = {};
    const latitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Latitude'));
    const longitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Longitude'));
    if (elementVisible) {
        if (latitudeField && answer.latitude) {
            attributes[latitudeField.TemplateAttribute.Name] = answer.latitude;
        }
        if (longitudeField && answer.longitude) {
            attributes[longitudeField.TemplateAttribute.Name] = answer.longitude;
        }
        if (isHierarchy && answer.hierarchyLocation && answer.hierarchyLocation.nodeExternalId) {
            attributes[LOCATION_ID_ATTRIBUTE] = [answer.hierarchyLocation.nodeExternalId];
        } else if (answer.locationId) {
            attributes[LOCATION_ID_ATTRIBUTE] = [answer.locationId];
        }
        if (answer.hotspotX) {
            attributes[SITEMAP_HOTSPOT.HOTSPOT_X] = answer.hotspotX;
        }
        if (answer.hotspotY) {
            attributes[SITEMAP_HOTSPOT.HOTSPOT_Y] = answer.hotspotY;
        }
        if (answer.documentID) {
            attributes[SITEMAP_HOTSPOT.HOTSPOT_DOCUMENT_ID] = answer.documentID;
        }
    } else {
        attributes[latitudeField.TemplateAttribute.Name] = 0;
        attributes[longitudeField.TemplateAttribute.Name] = 0;
        attributes[LOCATION_ID_ATTRIBUTE] = [-1];
        attributes[SITEMAP_HOTSPOT.HOTSPOT_X] = 0;
        attributes[SITEMAP_HOTSPOT.HOTSPOT_Y] = 0;
        attributes[SITEMAP_HOTSPOT.HOTSPOT_DOCUMENT_ID] = -1;
    }
    return attributes;
}


const convertMapCoordsAnswerToAttributes = (element, answer, elementVisible) => {
    const attributes = {};

    const latitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Latitude'));
    const longitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Longitude'));

    if (latitudeField && answer.latitude && elementVisible) {
        attributes[latitudeField.TemplateAttribute.Name] = answer.latitude;
    } else {
        attributes[latitudeField.TemplateAttribute.Name] = 0;
    }

    if (longitudeField && answer.longitude && elementVisible) {
        attributes[longitudeField.TemplateAttribute.Name] = answer.longitude;
    } else {
        attributes[longitudeField.TemplateAttribute.Name] = 0;
    }

    return attributes;
}

const convertMapCoordsAttributesToAnswers = (element, attributes) => {
    const latitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Latitude'));
    const longitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Longitude'));

    return (latitudeField && attributes[latitudeField.TemplateAttribute.Name]
        && longitudeField && attributes[longitudeField.TemplateAttribute.Name])
        ? {
            latitude: attributes[latitudeField.TemplateAttribute.Name],
            longitude: attributes[longitudeField.TemplateAttribute.Name]
        }
        : undefined;
}

const convertAccidentMapCoordsAttributesToAnswer = (element, attributes, isHierarchy) => {

    const latitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Latitude'));
    const longitudeField = element.Fields.find(f => f.TemplateAttribute.Name.includes('Longitude'));

    const latitude = latitudeField && attributes[latitudeField.TemplateAttribute.Name]
    const longitude = longitudeField && attributes[longitudeField.TemplateAttribute.Name]

    const location = attributes[LOCATION_ID_ATTRIBUTE] && attributes[LOCATION_ID_ATTRIBUTE][0];
    let locationId, hierarchyLocation;

    if (isHierarchy) {
        hierarchyLocation = {
            nodeExternalId: location
        }
    } else {
        locationId = location
    }

    return {
        locationId,
        hierarchyLocation,
        latitude,
        longitude,
        hotspotX: attributes[SITEMAP_HOTSPOT.HOTSPOT_X],
        hotspotY: attributes[SITEMAP_HOTSPOT.HOTSPOT_Y],
        documentID: attributes[SITEMAP_HOTSPOT.HOTSPOT_DOCUMENT_ID]
    }
}
