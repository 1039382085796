import * as AppActions from '../actions/appActions';

const getCurrentLocation = async (store, next) => {
    next(AppActions.getCurrentLocationBegin());
    try {
        window.navigator.geolocation.getCurrentPosition(position => {
            next(AppActions.getCurrentLocationSuccess({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            }));
        });
    } catch (error) {
        next(AppActions.getCurrentLocationFailure(error.response));
    }
}

const LocationService = store => next => (action) => {
    next(action);
    switch (action.type) {
        case AppActions.GET_CURRENT_LOCATION:
            getCurrentLocation(store, next);
            break;
        default:
            break;
    }
};

export default LocationService;
