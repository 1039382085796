import { SpeechConfig, AudioConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import * as WizardActions from '../actions/wizardActions';
import * as AppActions from '../actions/appActions';
import HttpClient from '../utils/httpClient';
import endpoints from '../constants/Endpoints';
import { getAppSettings, isAuthenticated, getSpeechToTextTokenValue } from '../selectors/appSelectors';
import moment from 'moment/min/moment-with-locales';

const speechToText = async (next, store, fieldId) => {

  let state = store.getState();
  const httpClient = new HttpClient(state);
  const appSettings = getAppSettings(store);
  const isUserAuthenticated = isAuthenticated(state);

  let tokenSettings = getSpeechToTextTokenValue(state);

  let isValidToken = tokenSettings.token && validateToken(tokenSettings)

  if (!isValidToken) {

    const speechToTextTokenResponse = await httpClient.get(endpoints.getSpeechToTextToken(appSettings, isUserAuthenticated));
    const speechToTextToken = speechToTextTokenResponse.data;

    if (speechToTextToken) {
        next(AppActions.setSpeechToTextTokenSettings(speechToTextToken.token));
    }

    state = store.getState();
    tokenSettings = getSpeechToTextTokenValue(state);
  }

  let token = tokenSettings.token;

  if (token) {
    next(WizardActions.setFieldMicInUse(fieldId, true));

    let serviceRegion = tokenSettings.region;
    let micValue = '';

    const speechConfig = SpeechConfig.fromAuthorizationToken(token, serviceRegion);

    speechConfig.speechRecognitionLanguage = state.appReducer.userSettings.language;

    let audioConfig = AudioConfig.fromDefaultMicrophoneInput();
    let recognizer = new SpeechRecognizer(speechConfig, audioConfig);

    const updateField = (fieldId, micValue) => {
      next(WizardActions.speechToTextSuccess(fieldId, micValue))
    }

    recognizer.recognizeOnceAsync(
      function (result) {
        micValue += result.text;
        recognizer.close();
        next(WizardActions.setFieldMicInUse(fieldId, false));
        updateField(fieldId, micValue === 'undefined' ? "" : micValue)
        recognizer = undefined;
      },
      function (err) {
        next(WizardActions.setFieldMicInUse(fieldId, false));
        window.console.log(err);
        recognizer.close();
        recognizer = undefined;
        return false;
      });

  } else {

    next(WizardActions.setFieldMicInUse(fieldId, false));
    next(WizardActions.speechToTextSuccess(fieldId, ''))

  }
}

const validateToken = (tokenSettings) => {

  const tokenTime = moment(tokenSettings.timeStamp).add('9','minutes');

  const nowTime = moment();

  return nowTime.isBefore(tokenTime);

}

const detectNameEntity = async (next, store, answers, page) => {


  try {

    next(WizardActions.setWizardPageLoading(true))

    let state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const isUserAuthenticated = isAuthenticated(state);

    const nerResponse = await httpClient.post(endpoints.azureNER(appSettings, isUserAuthenticated), answers.map(answer => { return answer.answer }))
    const nerData = nerResponse.data;

    let namesDetected = nerData && nerData.documents
      .map((document, i) => { return { name: answers[i].name, entities: document.entities.filter(e => { return e.category === "Person" }) } })
      .filter((d) => { return d.entities.length > 0 })

    if (namesDetected && namesDetected.length > 0) {
      next(WizardActions.setNamesFound(namesDetected));
    } else {
      store.dispatch(WizardActions.moveToPage(page))
    }

    next(WizardActions.setWizardPageLoading(false))

  } catch (ex) {

    store.dispatch(WizardActions.moveToPage(page))
    next(WizardActions.setWizardPageLoading(false))
    console.log(ex)

  }

}

const AzureServices = store => next => (action) => {
    next(action);

    switch (action.type) {
        case WizardActions.SPEECH_TO_TEXT_BEGIN:
            speechToText(next, store, action.fieldId);
            break;
        case WizardActions.CHECK_NAME_ENTITY:
            detectNameEntity(next, store, action.answers, action.page)
            break;
        default:
            break;
    }
};

export default AzureServices;
