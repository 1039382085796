import React from 'react';
import PropTypes from 'prop-types';
import { Mic, MicNone } from '../../../icons/index';
import style from './TextArea.module.scss';
import { Translate } from '../../../icons';
import PopupMenu from '../../PopupMenu/PopupMenu';
import FieldSpinner from "../../FieldSpinner/FieldSpinner";
const classNames = require('classnames');

class TextArea extends React.Component {
    constructor(props) {
        super(props);

        const { initialText } = this.props;

        this.state = {
            text: initialText,
            isLanguagePopupOpen: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.captureSpeech = this.captureSpeech.bind(this);
        this.handleMicInput = this.handleMicInput.bind(this);
    }

    captureSpeech() {
        const { onMicInputClick } = this.props;

        onMicInputClick();
    };


    componentDidUpdate(prevProps) {
        const { speechToTextValue, } = this.props;

        if (prevProps.speechToTextValue !== speechToTextValue) {
            this.handleMicInput(speechToTextValue);
        }

        if (this.props.initialText !== prevProps.initialText) {
            this.setState({
                text: this.props.initialText
            });
        }
    }

    handleTranslateIconClick = () => {
        this.setState({ isLanguagePopupOpen: true });
    }

    handleChange(e) {
        const { onTextChanged } = this.props;

        const text = e.target.value;

        this.setState({ text }, onTextChanged && onTextChanged(text));
    }

    handleMicInput(x) {
        const { onTextChanged } = this.props;

        let text = this.state.text

        if (text) {
            text = text + ' ' + x;
        } else if (x) {
            text = x
        }

        this.setState({ text }, onTextChanged && onTextChanged(text));
    }

    render() {
        const {
            name, id, placeholder, disabled, onKeyPress, maxLength, hasError,
            speechToTextSettings, isLanguageSupported, micInUse, fieldId,
            languageOptions, onLanguageChanged, selectedLanguageIsoCode, translationIsBeingProcessed, shouldShowSpinner
        } = this.props;

        const {
            text,
            isLanguagePopupOpen
        } = this.state;

        let classes = [style['Text-Area']];

        if (hasError) {
            classes.push(style['Text-Area-Error']);
        }

        return (
            <div className={style["Text-Area-Container"]}>
                <textarea
                    className={classNames(classes)}
                    type="text"
                    name={name}
                    value={text}
                    id={id}
                    placeholder={!disabled && placeholder}
                    onChange={this.handleChange}
                    disabled={disabled}
                    onKeyPress={onKeyPress}
                    maxLength={maxLength}
                >
                </textarea>
                <PopupMenu
                    open={isLanguagePopupOpen}
                    options={languageOptions}
                    onChange={(value) => {
                        this.setState({ isLanguagePopupOpen: false });
                        if (selectedLanguageIsoCode === value.iso) {
                            return;
                        }
                        if (value.translateOpt) {
                            this.props.onTranslateClick(this.state.text, selectedLanguageIsoCode, value.iso);
                        } else {
                            onLanguageChanged(value);
                        }
                    }}
                    onClose={() => this.setState({ isLanguagePopupOpen: false })}
                />
                <div className={style["Text-Area-Icon-Container"]}>
                    {
                        !disabled &&
                        <>
                            {speechToTextSettings && speechToTextSettings.isActive && isLanguageSupported &&
                                <img onClick={this.captureSpeech} alt='record' className={micInUse ? style['Text-Area-Mic-InUse'] : style['Text-Area-Mic']} src={micInUse ? MicNone : Mic}></img>
                            }
                            {languageOptions.length > 0 && <img className={style["Text-Area-Translate-Icon"]} src={Translate} alt="Translate" onClick={this.handleTranslateIconClick} />}
                            {((translationIsBeingProcessed && translationIsBeingProcessed.fieldId === fieldId) || shouldShowSpinner) && <FieldSpinner />}
                        </>
                    }
                    </div>
            </div>
        );
    }
}

TextArea.propTypes = {
    id: PropTypes.string,
    initialText: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onTextChanged: PropTypes.func,
    onKeyPress: PropTypes.func,
    maxLength: PropTypes.number,
    hasError: PropTypes.bool,
    translationIsBeingProcessed: PropTypes.bool.isRequired,
    fieldId: PropTypes.string.isRequired,
    shouldShowSpinner: PropTypes.bool
};

TextArea.defaultProps = {
    id: undefined,
    initialText: '',
    name: undefined,
    placeholder: undefined,
    disabled: false,
    onTextChanged: undefined,
    onKeyPress: undefined,
    maxLength: undefined,
    hasError: false,
    shouldShowSpinner: false
};

export default TextArea;
