import React, { Component } from 'react';
import './CompanyLogo.scss';
import { connect } from 'react-redux';
import { SpheraLogo, SpheraLogoMobile } from '../../icons';

class CompanyLogo extends Component {
    render() {
        const { companyLogo } = this.props;

        return (
            <div className="CompanyLogoContainer">
                <img src={SpheraLogo} alt="Sphera" className="CompanyLogoSpheraLogo" />
                <img src={SpheraLogoMobile} alt="Sphera" className="CompanyLogoSpheraLogoMobile" />
                {
                    companyLogo && <>
                        <div className="CompanyLogoSeparator"></div>
                        <img src={companyLogo} alt="" className="CompanyLogo" />
                    </>
                }
            </div>

        );
    }
}

const mapStateToProps = state => {
    const { appReducer } = state;

    return {
        companyLogo: appReducer.companyLogo,
    };
};

export default connect(mapStateToProps)(CompanyLogo);