import {
    requestLiveSearch,
    requestResolveLabel,
    requestTranslationLanguages,
    translate
} from "../services/ConfigService";
import {getMultiName} from "../selectors/wizardSelectors";

export const GO_TO_NEXT_PAGE_BEGIN = 'GO_TO_NEXT_PAGE_BEGIN';
export const GO_TO_NEXT_PAGE = 'GO_TO_NEXT_PAGE';
export const GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE';
export const GO_TO_PAGE = 'GO_TO_PAGE';
export const GO_TO_PAGE_BEGIN = 'GO_TO_PAGE_BEGIN';
export const ADD_SUB_PAGE_OBJECT = 'ADD_SUB_PAGE_OBJECT';
export const UPDATE_SUB_PAGE_OBJECT = 'UPDATE_SUB_PAGE_OBJECT';
export const SELECT_SUB_FORM = 'SELECT_SUB_FORM';
export const GO_TO_NEXT_SUB_PAGE_BEGIN = 'GO_TO_NEXT_SUB_PAGE_BEGIN';
export const GO_TO_NEXT_SUB_PAGE = 'GO_TO_NEXT_SUB_PAGE';
export const GO_TO_PREVIOUS_SUB_PAGE = 'GO_TO_PREVIOUS_SUB_PAGE';
export const GO_TO_SUB_PAGE = 'GO_TO_SUB_PAGE';
export const CLEAR_SUB_PAGE = 'CLEAR_SUB_PAGE';
export const SELECT_SUB_PAGE_OBJECT = 'SELECT_SUB_PAGE_OBJECT';
export const REMOVE_SUB_PAGE_OBJECT = 'REMOVE_SUB_PAGE_OBJECT';
export const UPDATE_ELEMENT_ANSWER = 'UPDATE_ELEMENT_ANSWER';
export const UPDATE_FIELD_ANSWER = 'UPDATE_FIELD_ANSWER';
export const UPDATE_FIELD_SETTING = 'UPDATE_FIELD_SETTING';
export const CLEAR_WIZARD = 'CLEAR_WIZARD';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const SET_INVALID = 'SET_INVALID';
export const CLEAR_SCROLL_TO_FIRST = 'CLEAR_SCROLL_TO_FIRST';
export const SET_PAGE_REACHED = 'SET_PAGE_REACHED';
export const UPDATE_ADDITIONAL_RECIPIENTS = 'UPDATE_ADDITIONAL_RECIPIENTS';
export const GET_SITEMAP_IMAGE_LIST = 'GET_SITEMAP_IMAGE_LIST';
export const GET_SITEMAP_IMAGE_LIST_BEGIN = 'GET_SITEMAP_IMAGE_LIST_BEGIN';
export const GET_SITEMAP_IMAGE_LIST_FAILURE = 'GET_SITEMAP_IMAGE_LIST_FAILURE';
export const GET_SITEMAP_IMAGE_LIST_SUCCESS = 'GET_SITEMAP_IMAGE_LIST_SUCCESS';
export const GET_SITEMAP_IMAGE = 'GET_SITEMAP_IMAGE';
export const GET_SITEMAP_IMAGE_BEGIN = 'GET_SITEMAP_IMAGE_BEGIN';
export const GET_SITEMAP_IMAGE_FAILURE = 'GET_SITEMAP_IMAGE_FAILURE';
export const GET_SITEMAP_IMAGE_SUCCESS = 'GET_SITEMAP_IMAGE_SUCCESS';
export const RELOAD_OBJECT_PICKLIST_VALUES = 'RELOAD_OBJECT_PICKLIST_VALUES';
export const RELOAD_OBJECT_PICKLIST_VALUES_BEGIN = 'RELOAD_OBJECT_PICKLIST_VALUES_BEGIN';
export const RELOAD_OBJECT_PICKLIST_VALUES_FAILURE = 'RELOAD_OBJECT_PICKLIST_VALUES_FAILURE';
export const RELOAD_OBJECT_PICKLIST_VALUES_SUCCESS = 'RELOAD_OBJECT_PICKLIST_VALUES_SUCCESS';
export const LOAD_LOCATION_ATTRIBUTES = 'LOAD_LOCATION_ATTRIBUTES';
export const LOAD_LOCATION_ATTRIBUTES_FAILURE = 'LOAD_LOCATION_ATTRIBUTES_FAILURE';
export const LOAD_FIELD_DEPENDENT_DEFAULT_VALUES = 'LOAD_FIELD_DEPENDENT_DEFAULT_VALUES';
export const LOAD_FIELD_DEPENDENT_DEFAULT_VALUES_FAILURE = 'LOAD_FIELD_DEPENDENT_DEFAULT_VALUES_FAILURE';
export const UPLOAD_RELATED_DOCUMENTS = 'UPLOAD_RELATED_DOCUMENTS';
export const UPLOAD_RELATED_DOCUMENT_SUCCESS = 'UPLOAD_RELATED_DOCUMENT_SUCCESS';
export const UPLOAD_RELATED_DOCUMENT_BEGIN = 'UPLOAD_RELATED_DOCUMENT_BEGIN';
export const UPLOAD_RELATED_DOCUMENT_FAILURE = 'UPLOAD_RELATED_DOCUMENT_FAILURE';
export const LOAD_RELATED_DOCUMENT = 'LOAD_RELATED_DOCUMENT';
export const LOAD_RELATED_DOCUMENT_SUCCESS = 'LOAD_RELATED_DOCUMENT_SUCCESS';
export const LOAD_HIERARCHY_SITEMAP_ATTRIBUTES = 'LOAD_HIERARCHY_SITEMAP_ATTRIBUTES';
export const LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_BEGIN = 'LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_BEGIN';
export const LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_SUCCESS = 'LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_SUCCESS';
export const LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_FAILURE = 'LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_FAILURE';
export const CLEAR_SCROLL_TO_TOP = 'CLEAR_SCROLL_TO_TOP';
export const SPEECH_TO_TEXT_BEGIN = 'SPEECH_TO_TEXT_BEGIN';
export const SPEECH_TO_TEXT_SUCCESS = 'SPEECH_TO_TEXT_SUCCESS';
export const SET_FIELD_MIC_IN_USE = 'SET_FIELD_MIC_IN_USE';

export const TRANSLATION_BEGIN = 'TRANSLATION_BEGIN';
export const TRANSLATION_REQUEST_START = 'TRANSLATION_REQUEST_START';
export const TRANSLATION_SUCCESS = 'TRANSLATION_SUCCESS';
export const TRANSLATION_FAILURE = 'TRANSLATION_FAILURE';

export const TRANSLATION_LANGUAGES_LIST_REQUEST_START = 'TRANSLATION_LANGUAGES_LIST_REQUEST_START';
export const TRANSLATION_LANGUAGES_LIST_SUCCESS = 'TRANSLATION_LANGUAGES_LIST_SUCCESS';
export const TRANSLATION_LANGUAGES_LIST_FAILURE = 'TRANSLATION_LANGUAGES_LIST_FAILURE';

export const LIVE_SEARCH_LIST_REQUEST_START = 'LIVE_SEARCH_REQUEST_START';
export const LIVE_SEARCH_LIST_REQUEST_CANCEL = 'LIVE_SEARCH_LIST_REQUEST_CANCEL';
export const LIVE_SEARCH_LIST_SUCCESS = 'LIVE_SEARCH_LIST_SUCCESS';
export const LIVE_SEARCH_LIST_FAILURE = 'LIVE_SEARCH_LIST_FAILURE';

export const RESOLVE_LABEL_START = 'RESOLVE_LABEL_START';
export const RESOLVE_LABEL_SUCCESS = 'RESOLVE_LABEL_SUCCESS';
export const RESOLVE_LABEL_FAILURE = 'RESOLVE_LABEL_FAILURE';

export const DELETE_DRAFT_RECORD = 'DELETE_DRAFT_RECORD';
export const CHECK_NAME_ENTITY = 'CHECK_NAME_ENTITY';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const SET_NAMES_FOUND = 'SET_NAMES_FOUND';
export const MOVE_TO_PAGE = 'MOVE_TO_PAGE';
export const CLEAR_NER_WARNING = 'CLEAR_NER_WARNING';

export const goToNextPageBegin = () => ({
    type: GO_TO_NEXT_PAGE_BEGIN
});

export const goToNextPage = () => ({
    type: GO_TO_NEXT_PAGE
});

export const goToPreviousPage = () => ({
    type: GO_TO_PREVIOUS_PAGE
});

export const goToPageBegin = page => ({
    type: GO_TO_PAGE_BEGIN,
    page
});

export const goToPage = page => ({
    type: GO_TO_PAGE,
    page
});

export const addSubPageObject = elementId => ({
    type: ADD_SUB_PAGE_OBJECT,
    elementId
});

export const updateSubPageObject = (elementId, index) => ({
    type: UPDATE_SUB_PAGE_OBJECT,
    elementId,
    index
});

export const goToNextSubPageBegin = () => ({
    type: GO_TO_NEXT_SUB_PAGE_BEGIN
});

export const goToNextSubPage = () => ({
    type: GO_TO_NEXT_SUB_PAGE
});

export const goToPreviousSubPage = () => ({
    type: GO_TO_PREVIOUS_SUB_PAGE
});

export const selectSubForm = formId => ({
    type: SELECT_SUB_FORM,
    formId
})
export const goToSubPage = subPage => ({
    type: GO_TO_SUB_PAGE,
    subPage
});

export const clearSubPage = () => ({
    type: CLEAR_SUB_PAGE
});

export const selectSubPageObject = (elementId, index) => ({
    type: SELECT_SUB_PAGE_OBJECT,
    elementId,
    index
});

export const removeSubPageObject = (elementId, index) => ({
    type: REMOVE_SUB_PAGE_OBJECT,
    elementId,
    index
});

export const updateElementAnswer = (elementId, value) => ({
    type: UPDATE_ELEMENT_ANSWER,
    elementId,
    value
});

export const updateFieldAnswer = (fieldId, value) => ({
    type: UPDATE_FIELD_ANSWER,
    fieldId,
    value
});

export const updateFieldSetting = (fieldId, settingName, settingValue) => ({
    type: UPDATE_FIELD_SETTING,
    fieldId,
    settingName,
    settingValue
});

export const clearWizard = () => ({
    type: CLEAR_WIZARD
});

export const setUserType = (userTypeSelected) => ({
    type: SET_USER_TYPE,
    userTypeSelected
});

export const toggleSideMenu = () => ({
    type: TOGGLE_SIDE_MENU
});

export const setInvalid = (invalid, isSubPage) => ({
    type: SET_INVALID,
    invalid,
    isSubPage,
});

export const clearScrollToFirst = () => ({
    type: CLEAR_SCROLL_TO_FIRST
});

export const setPageReached = (pageReached) => ({
    type: SET_PAGE_REACHED,
    pageReached
});

export const updateAdditionalRecipients = additionalRecipients => ({
    type: UPDATE_ADDITIONAL_RECIPIENTS,
    additionalRecipients,
});

export const getSiteMapImageList = (locationId, elementGuid) => ({
    type: GET_SITEMAP_IMAGE_LIST,
    locationId,
    elementGuid,
});

export const getSiteMapImageListBegin = (elementGuid) => ({
    type: GET_SITEMAP_IMAGE_LIST_BEGIN,
    elementGuid,
});

export const getSiteMapImageListSuccess = (imageList, elementGuid) => ({
    type: GET_SITEMAP_IMAGE_LIST_SUCCESS,
    imageList,
    elementGuid,
});

export const getSiteMapImageListFailure = (error, elementGuid) => ({
    type: GET_SITEMAP_IMAGE_LIST_FAILURE,
    elementGuid,
    error,
});

export const getSiteMapImage = (imageId, elementGuid) => ({
    type: GET_SITEMAP_IMAGE,
    imageId,
    elementGuid,
});

export const getSiteMapImageBegin = (elementGuid) => ({
    type: GET_SITEMAP_IMAGE_BEGIN,
    elementGuid,
});

export const getSiteMapImageSuccess = (image, elementGuid) => ({
    type: GET_SITEMAP_IMAGE_SUCCESS,
    elementGuid,
    image,
});

export const getSiteMapImageFailure = (error, elementGuid) => ({
    type: GET_SITEMAP_IMAGE_FAILURE,
    elementGuid,
    error,
});

export const reloadObjectPicklistValues = (locationId, formId) => ({
    type: RELOAD_OBJECT_PICKLIST_VALUES,
    locationId,
    formId
});

export const reloadObjectPicklistValuesBegin = (attributes) => ({
    type: RELOAD_OBJECT_PICKLIST_VALUES_BEGIN,
    attributes,
});

export const reloadObjectPicklistValuesSuccess = (values) => ({
    type: RELOAD_OBJECT_PICKLIST_VALUES_SUCCESS,
    values,
});

export const reloadObjectPicklistValuesFailure = (error, attributes) => ({
    type: RELOAD_OBJECT_PICKLIST_VALUES_FAILURE,
    attributes,
    error,
});

export const loadLocationAttributes = (locationId, setIfEmptyOnly) => ({
    type: LOAD_LOCATION_ATTRIBUTES,
    locationId,
    setIfEmptyOnly
});

export const loadLocationAttributesFailure = (error) => ({
    type: LOAD_LOCATION_ATTRIBUTES_FAILURE,
    error
});

export const loadFieldDependentDefaultValues = (field, value) => ({
    type: LOAD_FIELD_DEPENDENT_DEFAULT_VALUES,
    field,
    value
});

export const loadFieldDependentDefaultValuesFailure = (error) => ({
    type: LOAD_FIELD_DEPENDENT_DEFAULT_VALUES_FAILURE,
    error
});

export const uploadRelatedDocuments = (elementGuid, documents) => ({
    type: UPLOAD_RELATED_DOCUMENTS,
    elementGuid,
    documents
});

export const uploadRelatedDocumentBegin = (elementGuid, index) => ({
    type: UPLOAD_RELATED_DOCUMENT_BEGIN,
    elementGuid,
    index
});

export const uploadRelatedDocumentSuccess = (elementGuid, index) => ({
    type: UPLOAD_RELATED_DOCUMENT_SUCCESS,
    elementGuid,
    index
});

export const uploadRelatedDocumentFailure = (elementGuid, error, index) => ({
    type: UPLOAD_RELATED_DOCUMENT_FAILURE,
    elementGuid,
    error,
    index
});

export const loadRelatedDocument = (elementGuid, relatedDocumentId, fileName) => ({
    type: LOAD_RELATED_DOCUMENT,
    elementGuid,
    relatedDocumentId,
    fileName
});

export const loadRelatedDocumentSuccess = (elementGuid, document) => ({
    type: LOAD_RELATED_DOCUMENT_SUCCESS,
    elementGuid,
    document
});

export const loadHierarchySiteMapAttributes = (locationId, elementGuid) => ({
    type: LOAD_HIERARCHY_SITEMAP_ATTRIBUTES,
    locationId,
    elementGuid
});

export const loadHierarchySiteMapAttributesBegin = (elementGuid) => ({
    type: LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_BEGIN,
    elementGuid
});

export const loadHierarchySiteMapAttributesSuccess = (data, elementGuid) => ({
    type: LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_SUCCESS,
    data,
    elementGuid
});

export const loadHierarchySiteMapAttributesFailure = (elementGuid) => ({
    type: LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_FAILURE,
    elementGuid
});

export const clearScrollToTop = () => ({
    type: CLEAR_SCROLL_TO_TOP,
});

export const speechToTextBegin = (fieldId) => ({
    type: SPEECH_TO_TEXT_BEGIN,
    fieldId,
});

export const speechToTextSuccess = (fieldId, value) => ({
    type: SPEECH_TO_TEXT_SUCCESS,
    fieldId,
    value,
});

export const setFieldMicInUse = (fieldId, inUse) => ({
    type: SET_FIELD_MIC_IN_USE,
    fieldId,
    inUse,
});

export const translationRequestStart = (fieldId) => ({
    type: TRANSLATION_REQUEST_START,
    payload: { fieldId }
});

export const translationSuccess = (text) => ({
    type: TRANSLATION_SUCCESS,
    payload: text
});

export const translationFailure = (translationError) => ({
    type: TRANSLATION_FAILURE,
    payload: translationError
});

export const translationBegin =
    (fieldId, settingValue, fromLanguage, toLanguage) => (dispatch, getState) => {
        dispatch(translationRequestStart(fieldId));
        translate({ getState }, settingValue, fromLanguage, toLanguage).then(
            (result) => {
                const translated = result.data || "";
                dispatch(translationSuccess(translated));
                dispatch(updateFieldAnswer(getMultiName(fieldId, toLanguage), translated));
            },
            (ex) => {
                dispatch(translationFailure(ex));
            }
        );
    };

export const translationLanguagesListRequestStart = () => ({
    type: TRANSLATION_LANGUAGES_LIST_REQUEST_START
});

export const translationLanguagesListSuccess = (list) => ({
    type: TRANSLATION_LANGUAGES_LIST_SUCCESS,
    payload: list
});

export const translationLanguagesListFailure = (translationError) => ({
    type: TRANSLATION_LANGUAGES_LIST_FAILURE,
    payload: translationError
});

export const translationLanguagesListBegin = () => (dispatch, getState) => {
    dispatch(translationLanguagesListRequestStart());
    requestTranslationLanguages({ getState }).then(
        (result) => {
            const translationLanguagesList = result.data || "";
            dispatch(translationLanguagesListSuccess(translationLanguagesList));
        },
        (ex) => {
            dispatch(translationLanguagesListFailure(ex));
        }
    );
};

export const resolveLabelStart = () => ({
    type: RESOLVE_LABEL_START
});

export const resolveLabelSuccess = (value) => ({
    type: RESOLVE_LABEL_SUCCESS,
    payload: value
});

export const resolveLabelFailure = (error) => ({
    type: RESOLVE_LABEL_FAILURE,
    payload: error
});

export const resolveLabelBegin = (paramObj) => (dispatch, getState) => {
    dispatch(resolveLabelStart());
    requestResolveLabel({ getState }, paramObj).then(
        (result) => {
            const resolvedLabels = result.data || {};
            dispatch(resolveLabelSuccess(resolvedLabels));
        },
        (ex) => {
            dispatch(resolveLabelFailure(ex));
        }
    );
};

export const liveSearchRequestStart = (fieldId, cancelTokenSource) => ({
    type: LIVE_SEARCH_LIST_REQUEST_START,
    payload: {fieldId, cancelTokenSource}
});

export const liveSearchRequestCancel = (fieldId) => ({
    type: LIVE_SEARCH_LIST_REQUEST_CANCEL,
    payload: fieldId
});

export const liveSearchSuccess = (data, fieldId) => ({
    type: LIVE_SEARCH_LIST_SUCCESS,
    payload: {data, fieldId}
});

export const liveSearchFailure = (liveSearchError) => ({
    type: LIVE_SEARCH_LIST_FAILURE,
    payload: liveSearchError
});

export const liveSearchBegin =
    (SearchQuery, AttributeName, Page = '', Language = 'en-gb', ElementGuid = '', LocationId = '', AdditionalColumns = '') => (dispatch, getState) => {
        const state = getState();
        if (state.wizardReducer.liveSearchIsBeingProcessed) {
            state.wizardReducer.liveSearchIsBeingProcessed.cancelTokenSource.cancel("operation canceled due to new request");
            dispatch(liveSearchRequestCancel(AttributeName));
        }
        const {req, cancelTokenSource} = requestLiveSearch({getState}, SearchQuery, AttributeName, Page, Language, ElementGuid, LocationId, AdditionalColumns);
        dispatch(liveSearchRequestStart(AttributeName, cancelTokenSource));
        req.then(
            (result) => {
                const translationLanguagesData = result.data || {};
                dispatch(liveSearchSuccess(translationLanguagesData, AttributeName));
            },
            (ex) => {
                dispatch(liveSearchFailure(ex));
            }
        );
    };

export const deleteDraftRecord = (shortcode) => ({
    type: DELETE_DRAFT_RECORD,
    shortcode
})

export const checkForNameEntity = (answers, page) => ({
    type: CHECK_NAME_ENTITY,
    answers,
    page
})

export const setWizardPageLoading = (bool) => ({
    type: SET_PAGE_LOADING,
    bool
})

export const setNamesFound = (names) => ({
    type: SET_NAMES_FOUND,
    names
})

export const moveToPage = (page) => ({
    type: MOVE_TO_PAGE,
    page
})

export const clearNERWarning = () => ({
    type: CLEAR_NER_WARNING
})