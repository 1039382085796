const bodyPartInjuryTypes = [
    {
        id: 0,
        name: "Abrasion"
    },
    {
        id: 1,
        name: "Allergic Reaction"
    },
    {
        id: 2,
        name: "Amputation"
    },
    {
        id: 3,
        name: "Bruising"
    },
    {
        id: 4,
        name: "Burns Chemical"
    },
    {
        id: 5,
        name: "Cramping"
    },
    {
        id: 6,
        name: "Dislocation"
    }
];

export default bodyPartInjuryTypes;