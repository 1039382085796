export const LOGIN_USER = 'LOGIN_USER';
export const VERIFY_USER_AND_LOAD_DATA = 'VERIFY_USER_AND_LOAD_DATA';
export const USER_SUCCESS_LOGIN = 'USER_SUCCESS_LOGIN';
export const USER_FAILED_LOGIN = 'USER_FAILED_LOGIN';
export const LOGOUT_USER = 'SHOW_FORM';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SET_AUTH_TYPE = "SET_AUTH_TYPE";
export const LOGIN_USER_WITH_TOKEN = 'LOGIN_USER_WITH_TOKEN';
export const LOGIN_USER_BASIC_AUTH = 'LOGIN_USER_BASIC_AUTH';

export const loginUserWithToken = (token) => ({
    type: LOGIN_USER_WITH_TOKEN,
    token
});

export const verifyUserAndLoadData = (history) => ({
    type: VERIFY_USER_AND_LOAD_DATA,
    history
});

export const userSuccessLogin = (history) => ({
    type: USER_SUCCESS_LOGIN,
    history
})

export const userFailedLogin = (history, error) => ({
    type: USER_FAILED_LOGIN,
    history,
    error
})

export const logoutUser = () => ({
    type: LOGOUT_USER
});

export const userLoggedOut = (history) => ({
    type: USER_LOGGED_OUT,
    history
});

export const setAuthType = (userType, history) => ({
    type: SET_AUTH_TYPE,
    userType,
    history
});

export const loginUserBasicAuth = (basicAuth) => ({
    type: LOGIN_USER_BASIC_AUTH,
    basicAuth
});