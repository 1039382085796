import React, { Component } from 'react';
import style from './SubPageSummary.module.scss';
import { connect } from 'react-redux';
import { SecondaryButton } from 'spheracloud-common';
import { CloseGrey } from '../../icons';
import { getSubPageObjectAnswer } from '../../utils/formatAnswer';
import { getCurrentParentPage } from '../../selectors/wizardSelectors';
import Strings from '../../constants/Strings';
import Translation from '../Translation/Translation';
import { THIRD_PARTIES_NAME_ATTRIBUTE } from '../../constants/KnownAttributes';
import { v4 } from 'uuid';

class SubPageSummary extends Component {

    render() {
        const { subForms, subFormSummaryConfig, subPageObject, onReviewClick, onRemoveClick } = this.props;
        return (
            !subPageObject.deleted ?
            (
                <div className={style.SubPageSummary} >
                    <div className={style.SubPageSummaryLeft}>
                        <div className={style.SubPageSummaryTitle}>{getSubPageObjectAnswer(subForms, subPageObject, THIRD_PARTIES_NAME_ATTRIBUTE)}</div>
                        {
                            subFormSummaryConfig.filter(attributeName => {
                                return attributeName !== THIRD_PARTIES_NAME_ATTRIBUTE;
                            }).map(attributeName => {
                                const answer = getSubPageObjectAnswer(subForms, subPageObject, attributeName);
                                return answer !== '' ?
                                    <div key={v4()} className={style.SubPageSummaryItem}>{answer}</div>
                                    :
                                    ''
                            })
                        }
                    </div>
                    <div className={style.SubPageSummaryRight}>
                        <SecondaryButton className={style.SubPageSummaryReviewButton} onClick={onReviewClick}>
                            <Translation>{Strings.review}</Translation>
                        </SecondaryButton>
                        <div className={style.SubPageSummaryOptions} onClick={onRemoveClick}>
                            <img src={CloseGrey} alt="Options"/>
                        </div>
                    </div>
                </div>
            ) : ''
        );
    }
}

const mapStateToProps = state => {
    return {
        subForms: getCurrentParentPage(state).WizardSubForms,
        subFormSummaryConfig: getCurrentParentPage(state).WizardSubFormSummaryConfig
    };
};

export default connect(mapStateToProps)(SubPageSummary);
