import * as AppActions from '../actions/appActions';
import localforage from 'localforage';
import { getAuthInfo } from '../selectors/appSelectors';

const saveLanguage = async (store, next, language) => {
    try {
        const state = store.getState();
        const authInfo = getAuthInfo(state);
        const userId = (authInfo && authInfo.userId) || 'anonymous';
        const settings = localforage.createInstance({
            name: `usersettings${userId || ''}`
        });
        await settings.setItem("language", language);
        next(AppActions.loadData());
    } catch (error) {
    }
}

const StorageService = store => next => (action) => {
    next(action);

    switch (action.type) {
        case AppActions.UPDATE_LANGUAGE:
            saveLanguage(store, next, action.language);
            break;
        default:
            break;
    }
};

export default StorageService;