import React from 'react';
import './PopupMenu.scss';

class PopupMenu extends React.Component {

    constructor(props) {
        super(props);

        this.popupContainer = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.setState({
                open: this.props.open
            });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.closeIfNeeded);
        document.addEventListener('focusin', this.closeIfNeeded);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeIfNeeded);
        document.removeEventListener('focusin', this.closeIfNeeded);
    }

    closeIfNeeded = (e) => {
        const { open, onClose } = this.props;

        if (open && this.popupContainer && this.popupContainer.current && !this.popupContainer.current.contains(e.target)) {
           onClose();
        }
    }

    render() {
        const {
            open, options, onChange
        } = this.props;

        return (open &&
            <div className="Dropdown" ref={this.popupContainer} >
                {options.map((item, index) => {
                    return (
                        <div key={index} className="DropdownItem"
                            onClick={() => {
                                onChange(item.value)
                            }}>
                            {item.label}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default PopupMenu;