export const LOAD_APP_SETTINGS = 'LOAD_APP_SETTINGS';
export const SET_FORM_GROUP = 'SET_FORM_GROUP';
export const SET_ANON_GUID = 'SET_ANON_GUID';
export const SET_AUTH_INFO = 'SET_AUTH_INFO';
export const ALLOW_ANON_REPORTING = 'ALLOW_ANON_REPORTING';
export const LOAD_DATA = 'LOAD_DATA';
export const SHOW_AUTH_ERROR = 'SHOW_AUTH_ERROR';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const FETCH_COMPANY_DATA_BEGIN = 'FETCH_COMPANY_DATA_BEGIN';
export const FETCH_COMPANY_DATA_SUCCESS = 'FETCH_COMPANY_DATA_SUCCESS';
export const FETCH_COMPANY_DATA_FAILURE = 'FETCH_COMPANY_DATA_FAILURE';
export const SET_FORM_TYPE = 'SET_FORM_TYPE';
export const SET_ACCIDENT_GUID = 'SET_ACCIDENT_GUID';
export const CLEAR_FORM_TYPE = 'CLEAR_FORM_TYPE';
export const SHOW_CANCEL_FORM = 'SHOW_CANCEL_FORM';
export const HIDE_CANCEL_FORM = 'HIDE_CANCEL_FORM';
export const HIDE_LOGGED_OUT_FORM = 'HIDE_LOGGED_OUT_FORM';
export const SHOW_THIS_LANGUAGE_VALUE_FORM = 'SHOW_THIS_LANGUAGE_VALUE_FORM';
export const HIDE_THIS_LANGUAGE_VALUE_FORM = 'HIDE_THIS_LANGUAGE_VALUE_FORM';
export const CANCEL_FORM = 'CANCEL_FORM';
export const LOAD_COMPANY_LOGO = 'LOAD_COMPANY_LOGO';
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const SHOW_CONTINUE_DRAFT = 'SHOW_CONTINUE_DRAFT';
export const HIDE_CONTINUE_DRAFT = 'HIDE_CONTINUE_DRAFT';
export const CONTINUE_DRAFT = 'CONTINUE_DRAFT';
export const CONTINUE_DRAFT_BEGIN = 'CONTINUE_DRAFT_BEGIN';
export const CONTINUE_DRAFT_CANCEL = 'CONTINUE_DRAFT_CANCEL';
export const CONTINUE_DRAFT_SUCCESS = 'CONTINUE_DRAFT_SUCCESS';
export const CONTINUE_DRAFT_FAILURE = 'CONTINUE_DRAFT_FAILURE';
export const CONTINUE_DRAFT_LOAD = 'CONTINUE_DRAFT_LOAD';
export const CLEAR_DRAFT_FAILURE = 'CLEAR_DRAFT_FAILURE';
export const SUBMIT_DRAFT = 'SUBMIT_DRAFT';
export const SUBMIT_DRAFT_BEGIN = 'SUBMIT_DRAFT_BEGIN';
export const SUBMIT_DRAFT_SUCCESS = 'SUBMIT_DRAFT_SUCCESS';
export const SUBMIT_DRAFT_FAILURE = 'SUBMIT_DRAFT_FAILURE';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SUBMIT_FORM_BEGIN = 'SUBMIT_FORM_BEGIN';
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'SUBMIT_FORM_FAILURE';
export const CLEAR_FORM_FAILURE = 'CLEAR_FORM_FAILURE';
export const SET_MANUAL_USER = 'SET_MANUAL_USER';
export const SET_ANONYMOUS_USER = 'SET_ANONYMOUS_USER';
export const CLEAR_FORM = 'CLEAR_FORM';
export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION';
export const GET_CURRENT_LOCATION_BEGIN = 'GET_CURRENT_LOCATION_BEGIN';
export const GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION_SUCCESS';
export const GET_CURRENT_LOCATION_FAILURE = 'GET_CURRENT_LOCATION_FAILURE';
export const CONTINUE_DRAFT_SET_DATA = 'CONTINUE_DRAFT_SET_DATA';
export const CONTINUE_DRAFT_REMOVE_DATA = 'CONTINUE_DRAFT_REMOVE_DATA';
export const LOAD_USER_DEPENDENT_DEFAULT_VALUES = 'LOAD_USER_DEPENDENT_DEFAULT_VALUES';
export const LOAD_USER_DEPENDENT_DEFAULT_VALUES_SUCCESS = 'LOAD_USER_DEPENDENT_DEFAULT_VALUES_SUCCESS';
export const LOAD_USER_DEPENDENT_DEFAULT_VALUES_FAILURE = 'LOAD_USER_DEPENDENT_DEFAULT_VALUES_FAILURE';
export const CLEAR_FORM_TYPE_LOGOUT = 'CLEAR_FORM_TYPE_LOGOUT';
export const CLEAR_FORM_TYPE_STAY_LOGGED_IN = 'CLEAR_FORM_TYPE_STAY_LOGGED_IN';
export const SET_LOGGING_OFF = 'SET_LOGGING_OFF';
export const SET_SSO_SETTINGS = 'SET_SSO_SETTINGS';
export const SET_FROM_SSO = 'SET_FROM_SSO';
export const RELOAD_FORM_PERMISSIONS = 'RELOAD_FORM_PERMISSIONS';
export const UPDATE_FORM_TYPE = 'UPDATE_FORM_TYPE';
export const BASIC_AUTH_LOGIN_BEGIN = 'BASIC_AUTH_LOGIN_BEGIN';
export const BASIC_AUTH_LOGIN_SUCCESS = 'BASIC_AUTH_LOGIN_SUCCESS';
export const BASIC_AUTH_LOGIN_FAILURE = 'BASIC_AUTH_LOGIN_FAILURE';
export const CLEAR_BASIC_AUTH_LOGIN_ERROR = 'CLEAR_BASIC_AUTH_LOGIN_ERROR';
export const SET_CONTENT_DIMENSIONS = 'SET_CONTENT_DIMENSIONS';
export const SET_SPEECH_TO_TEXT_TOKEN_SETTINGS = 'SET_SPEECH_TO_TEXT_TOKEN_SETTINGS';
export const SET_MIC_INUSE = 'SET_MIC_INUSE';
export const SET_SAVED_ACCIDENTS = 'SET_SAVED_ACCIDENTS';
export const DELETE_DRAFT_START = 'DELETE_DRAFT_START';
export const DELETE_DRAFT_END = 'DELETE_DRAFT_END';
export const SIMILAR_RECORDS_SEARCH = 'SIMILAR_RECORDS_SEARCH';
export const SET_SIMILAR_RECORDS_DATA = 'SET_SIMILAR_RECORDS_DATA';
export const SHOW_SIMILAR_RECORD_SUMMARY = 'SHOW_SIMILAR_RECORD_SUMMARY';
export const SET_SIMILAR_RECORD_SUMMARY = 'SET_SIMILAR_RECORD_SUMMARY';
export const SET_SUMMARY_MODAL_VALUE = 'SET_SUMMARY_MODAL_VALUE';
export const SET_SIMILAR_RECORDS_SPINNER_VALUE = 'SET_SIMILAR_RECORDS_SPINNER_VALUE';
export const LOAD_SSO_SETTINGS = 'LOAD_SSO_SETTINGS';
export const SET_USE_FORM_LOCATION_RESTRICTIONS = 'SET_USE_FORM_LOCATION_RESTRICTIONS';
export const SET_ACTIVE_LOCATION = 'SET_ACTIVE_LOCATION';
export const SET_ACTIVE_LOCATION_ID = 'SET_ACTIVE_LOCATION_ID';
export const CLEAR_ACTIVE_LOCATION = 'CLEAR_ACTIVE_LOCATION';
export const LOAD_FORM = "LOAD_FORM";
export const RELOAD_FORMS = 'RELOAD_FORMS';
export const UPDATE_FORM_TYPES = 'UPDATE_FORM_TYPES';
export const SHOW_LOCATION_RESTRICTION_USER_ERROR = 'SHOW_LOCATION_RESTRICTION_USER_ERROR';
export const CLEAR_LOCATION_RESTRICTION_USER_ERROR = 'CLEAR_LOCATION_RESTRICTION_USER_ERROR';

export const loadAppSettings = settings => ({
    type: LOAD_APP_SETTINGS,
    settings
});

export const setFormGroup = (formGroup) => ({
    type: SET_FORM_GROUP,
    formGroup
});

export const setAnonGuid = (guid) => ({
    type: SET_ANON_GUID,
    guid
});

export const allowAnonReporting = (allowAnonReporting) => ({
    type: ALLOW_ANON_REPORTING,
    allowAnonReporting
});

export const setAuthInfo = (authInfo) => ({
    type: SET_AUTH_INFO,
    authInfo
})

export const loadData = () => ({
    type: LOAD_DATA
});

export const reloadFormPermissions = (locationId, formId) => ({
    type: RELOAD_FORM_PERMISSIONS,
    locationId,
    formId
});

export const showAuthError = (history, error) => ({
    type: SHOW_AUTH_ERROR,
    history,
    error
});

export const clearAuthError = () => ({
    type: CLEAR_AUTH_ERROR
})

export const fetchCompanyDataBegin = () => ({
    type: FETCH_COMPANY_DATA_BEGIN
});

export const fetchCompanyDataSuccess = companyData => ({
    type: FETCH_COMPANY_DATA_SUCCESS,
    companyData
});

export const fetchCompanyDataFailure = error => ({
    type: FETCH_COMPANY_DATA_FAILURE,
    error
});

export const setFormType = formType => ({
    type: SET_FORM_TYPE,
    formType
});

export const setAccidentGuid = accidentGuid => ({
    type: SET_ACCIDENT_GUID,
    accidentGuid
});

export const clearFormType = () => ({
    type: CLEAR_FORM_TYPE
});

export const clearFormTypeAndLogout = () => ({
    type: CLEAR_FORM_TYPE_LOGOUT
});

export const clearFormTypeAndStayLoggedIn = () => ({
    type: CLEAR_FORM_TYPE_STAY_LOGGED_IN
});

export const showCancelForm = () => ({
    type: SHOW_CANCEL_FORM
});

export const hideThisLanguageValueForm = () => ({
    type: HIDE_THIS_LANGUAGE_VALUE_FORM
});

export const showThisLanguageValueForm = (name, text, fromLanguage, toLanguage) => ({
    type: SHOW_THIS_LANGUAGE_VALUE_FORM,
    payload: {name, text, fromLanguage, toLanguage}
});

export const hideCancelForm = () => ({
    type: HIDE_CANCEL_FORM
});

export const hideLoggedOutForm = () => ({
    type: HIDE_LOGGED_OUT_FORM
});

export const cancelForm = () => ({
    type: CANCEL_FORM
});

export const loadCompanyLogo = () => ({
    type: LOAD_COMPANY_LOGO
});

export const setCompanyLogo = (companyLogo) => ({
    type: SET_COMPANY_LOGO,
    companyLogo
});

export const setLanguage = language => ({
    type: SET_LANGUAGE,
    language
});

export const updateLanguage = language => ({
    type: UPDATE_LANGUAGE,
    language
});

export const showContinueDraft = () => ({
    type: SHOW_CONTINUE_DRAFT,
});

export const hideContinueDraft = () => ({
    type: HIDE_CONTINUE_DRAFT,
});

export const continueDraft = (code, history) => ({
    type: CONTINUE_DRAFT,
    code,
    history,
});

export const continueDraftLoad = (shortCode, accidentId, formId) => ({
    type: CONTINUE_DRAFT_LOAD,
    shortCode,
    accidentId,
    formId,
});

export const continueDraftBegin = () => ({
    type: CONTINUE_DRAFT_BEGIN,
});

export const continueDraftCancel = () => ({
    type: CONTINUE_DRAFT_CANCEL,
});

export const continueDraftSuccess = (accidentId, shortCode, answers) => ({
    type: CONTINUE_DRAFT_SUCCESS,
    accidentId,
    shortCode,
    answers,
});

export const continueDraftFailure = error => ({
    type: CONTINUE_DRAFT_FAILURE,
    error,
});

export const clearDraftFailure = () => ({
    type: CLEAR_DRAFT_FAILURE,
});

export const submitDraft = data => ({
    type: SUBMIT_DRAFT,
    data,
});

export const submitDraftBegin = () => ({
    type: SUBMIT_DRAFT_BEGIN
});

export const submitDraftSuccess = draftId => ({
    type: SUBMIT_DRAFT_SUCCESS,
    draftId
});

export const submitDraftFailure = error => ({
    type: SUBMIT_DRAFT_FAILURE,
    error
});

export const submitForm = data => ({
    type: SUBMIT_FORM,
    data,
});

export const submitFormBegin = () => ({
    type: SUBMIT_FORM_BEGIN
});

export const submitFormSuccess = formId => ({
    type: SUBMIT_FORM_SUCCESS,
    formId
});

export const submitFormFailure = error => ({
    type: SUBMIT_FORM_FAILURE,
    error
});

export const clearFormFailure = () => ({
    type: CLEAR_FORM_FAILURE,
});

export const setManualUser = (userInfo) => ({
    type: SET_MANUAL_USER,
    userInfo
});

export const setAnonymousUser = () => ({
    type: SET_ANONYMOUS_USER
});

export const clearForm = () => ({
    type: CLEAR_FORM,
});

export const getCurrentLocation = () => ({
    type: GET_CURRENT_LOCATION
});

export const getCurrentLocationBegin = () => ({
    type: GET_CURRENT_LOCATION_BEGIN
});

export const getCurrentLocationSuccess = currentLocation => ({
    type: GET_CURRENT_LOCATION_SUCCESS,
    currentLocation,
});

export const getCurrentLocationFailure = error => ({
    type: GET_CURRENT_LOCATION_FAILURE,
    error
});

export const continueDraftSetData = (shortCode, accidentId, formId) => ({
    type: CONTINUE_DRAFT_SET_DATA,
    shortCode,
    accidentId,
    formId,
});

export const continueDraftRemoveData = () => ({
    type: CONTINUE_DRAFT_REMOVE_DATA,
});

export const loadReportingUserDependentDefaultValues = () => ({
    type: LOAD_USER_DEPENDENT_DEFAULT_VALUES,
})

export const loadReportingUserDependentDefaultValuesSuccess = (defaultValues) => ({
    type: LOAD_USER_DEPENDENT_DEFAULT_VALUES_SUCCESS,
    defaultValues,
})

export const loadReportingUserDependentDefaultValuesFailure = (error) => ({
    type: LOAD_USER_DEPENDENT_DEFAULT_VALUES_FAILURE,
    error
})

export const setLoggingOffUser = (isLoggingOff) => ({
    type: SET_LOGGING_OFF,
    isLoggingOff
})

export const setSSOSettings = (link, isSSOOnly, domainHint) => ({
    type: SET_SSO_SETTINGS,
    link,
    isSSOOnly,
    domainHint,
})

export const setSpeechToTextTokenSettings = (token) => ({
    type: SET_SPEECH_TO_TEXT_TOKEN_SETTINGS,
    token
})

export const setFromSSO = (fromSSO) => ({
    type: SET_FROM_SSO,
    fromSSO
})

export const updateFormType = (formType, formId) => ({
    type: UPDATE_FORM_TYPE,
    formType,
    formId
})

export const basicAuthLoginBegin = () => ({
    type: BASIC_AUTH_LOGIN_BEGIN
})

export const basicAuthLoginSuccess = () => ({
    type: BASIC_AUTH_LOGIN_BEGIN
})

export const basicAuthLoginFailure = (basicAuthLoginError) => ({
    type: BASIC_AUTH_LOGIN_FAILURE,
    basicAuthLoginError
})

export const clearBasicAuthLoginError = () => ({
    type: CLEAR_BASIC_AUTH_LOGIN_ERROR
})

export const setContentDimensions = (height, width) => ({
    type: SET_CONTENT_DIMENSIONS,
    height,
    width
})

export const setMicInUse = (inUse) => ({
    type : SET_MIC_INUSE,
    inUse
})
export const setSavedAccidents = (list) => ({
    type: SET_SAVED_ACCIDENTS,
    list
})

export const deleteDraftRecordStart = () => ({
    type: DELETE_DRAFT_START,
})

export const deleteDraftRecordEnd = () => ({
    type: DELETE_DRAFT_END,
})

export const searchSimilarRecord = (templateAttributeName, viewId, searchValue, isSearchResctictedToUserLocation) => ({
    type: SIMILAR_RECORDS_SEARCH,
    templateAttributeName,
    viewId,
    searchValue,
    isSearchResctictedToUserLocation
});

export const setSimilarRecordsData = (recordsData, templateAttributeName, searchValue) => ({
    type: SET_SIMILAR_RECORDS_DATA,
    recordsData,
    templateAttributeName,
    searchValue
});

export const showSimilarRecordSummary = (accidentId) => ({
    type: SHOW_SIMILAR_RECORD_SUMMARY,
    accidentId
});

export const setSimilarRecordSummary = (accidentId, recordSummary) => ({
    type: SET_SIMILAR_RECORD_SUMMARY,
    accidentId,
    recordSummary
});

export const setSummaryModalState = (value) => ({
    type: SET_SUMMARY_MODAL_VALUE,
    value
});

export const setSimilarRecordFieldSpinner = (value, fieldName) => ({
    type: SET_SIMILAR_RECORDS_SPINNER_VALUE,
    fieldName,
    value
});

export const loadSSOSettings = (redirectToB2C) => ({
    type: LOAD_SSO_SETTINGS,
    redirectToB2C
})

export const setUseFormLocationRestrictions = (useFormLocationRestrictions) => ({
    type: SET_USE_FORM_LOCATION_RESTRICTIONS,
    useFormLocationRestrictions
});

export const setActiveLocation = (location) => ({
    type: SET_ACTIVE_LOCATION,
    location
});

export const setActiveLocationId = (locationId) => ({
    type: SET_ACTIVE_LOCATION_ID,
    locationId
});

export const clearActiveLocation = () => ({
    type: CLEAR_ACTIVE_LOCATION
});

export const loadForm = (formId) => ({
    type: LOAD_FORM,
    formId
});

export const reloadForms = (locationId) => ({
    type: RELOAD_FORMS,
    locationId
});

export const updateFormTypes = (summaryFormTypes) => ({
    type: UPDATE_FORM_TYPES,
    summaryFormTypes
});

export const showLocationRestrictionUserError = () => ({
    type: SHOW_LOCATION_RESTRICTION_USER_ERROR
})

export const clearLocationRestrictionUserError = () => ({
    type: CLEAR_LOCATION_RESTRICTION_USER_ERROR
})
