import React, { Component } from 'react';
import style from './SideMenu.module.scss';
import { connect } from 'react-redux';
import SideMenuItem from './SideMenuItem/SideMenuItem';
import { goToPageBegin, toggleSideMenu } from '../../actions/wizardActions';
import { showCancelForm } from '../../actions/appActions';
import { getActiveLocationPath, getSelectedForm, getString } from '../../selectors/appSelectors';
import { getSelectedFormWizardPages, getFormProgress } from '../../selectors/wizardSelectors';
import { ArrowBack, LocationSmall } from '../../icons';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import Strings from '../../constants/Strings';
import HeaderReportingUser from './../../containers/Header/HeaderReportingUser/HeaderReportingUser'
import classNames from 'classnames';
import { Modal } from 'spheracloud-common';

class SideMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locationModalOpen: false,
        };

        this.toggleLocationModal = this.toggleLocationModal.bind(this);
    }

    toggleLocationModal(open) {
        this.setState({
            locationModalOpen: open
        });
    }

    render() {
        const { 
            selectedForm, 
            items, 
            selectedPage, 
            selectPage, 
            showCancelForm, 
            sideMenuOpen,
            toggleSideMenu, 
            formProgress, 
            draftShortCode, 
            startPageMenuLabel, 
            pageReached, 
            isPageLoading,
            activeLocationPath 
        } = this.props;

        const { locationModalOpen } = this.state;

        let sideMenuClasses = [style.SideMenu];
        if (sideMenuOpen) {
            sideMenuClasses.push(style.SideMenuOpen)
        }
        
        return (
            <div className={classNames(sideMenuClasses)}>
                <div className={style.SideMenuTopSection}>
                    <div className={style.SideMenuHeader}>
                        <div className={style.SideMenuMobileToggleButton} onClick={toggleSideMenu}>
                            <img src={ArrowBack} alt="Close"></img>
                        </div>
                        <div className={style.SideMenuHeaderTitleContainer}>
                            <div className={style.SideMenuHeaderTitle}>
                                {selectedForm.ShortDescription}
                            </div>
                            {
                                draftShortCode &&
                                    <div className={style.SideMenuHeaderDraftShortCode}>
                                        {draftShortCode}
                                    </div>
                            }
                            <div className={`${style.SideMenuIconContainer} SideMenuIconContainer`}>
                                {
                                    activeLocationPath && <>
                                        <div className={style.SideMenuLocationIcon} onClick={() => {this.toggleLocationModal(true)}}>
                                            <img src={LocationSmall} alt="Location" />
                                        </div>
                                        {
                                        locationModalOpen &&
                                        <Modal
                                            apply={""}
                                            cancel={""}
                                            onApply={() => {}}
                                            onCancel={() => {this.toggleLocationModal(false)} }
                                            className="HelpTextModal"                                            
                                        >
                                            {activeLocationPath}
                                        </Modal>
                                    }
                                    </>
                                }
                                <div className="SideMenuReportingUser">
                                    <HeaderReportingUser sideMenu={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.SideMenuProgressBar}>
                        <div className={style.SideMenuProgress} style={{width: `${formProgress}%`}}></div>
                    </div>
                    <div className={style.SideMenuContent}>
                        <SideMenuItem
                            onClick={() => showCancelForm()}
                            title={startPageMenuLabel}
                            pageGuid="START_PAGE"
                        />
                        {
                            items.map((item, index) => {
                                const disabled = pageReached < index || isPageLoading;
                                return !!item.visible && <SideMenuItem
                                    key={index}
                                    onClick={() => !disabled ? selectPage(index) : undefined}
                                    title={item.MenuLabel}
                                    selected={selectedPage === index}
                                    pageGuid={item.Guid}
                                    disabled={disabled}
                                />
                            })
                        }
                    </div>
                </div>
                <div className={style.SideMenuBottomSection}>
                    <LanguagePicker lightBackground={true}/>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedForm: getSelectedForm(state),
        items: getSelectedFormWizardPages(state),
        selectedPage: state.wizardReducer.selectedPage,
        pageReached: state.wizardReducer.pageReached,
        answers: state.wizardReducer.answers,
        sideMenuOpen: state.wizardReducer.sideMenuOpen,
        formProgress: getFormProgress(state),
        draftShortCode: state.appReducer.draftShortCode,
        startPageMenuLabel: getString(Strings.startPageMenuLabel, state),
        isPageLoading: state.wizardReducer.isPageLoading,
        activeLocationPath: getActiveLocationPath(state),
    };
};

const mapDispatchToProps = dispatch => ({
    selectPage: page => {
        dispatch(goToPageBegin(page));
    },
    showCancelForm: () => {
        dispatch(showCancelForm());
    },
    toggleSideMenu: () => {
        dispatch(toggleSideMenu());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
