import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'spheracloud-common';
import Strings from '../../constants/Strings';
import './SimilarRecordsGrid.scss';
import moment from 'moment/min/moment-with-locales';

const SimilarRecordsGrid = (props) => {
    
    const renderTableHead = () => {
        const elements = props.columns.map(c => <th key={c.TemplateAttributeID}>{c.ColumnTitle}</th>);
        return <thead><tr><th key='summary'/>{elements}</tr></thead>;
    };

    const renderTableContent = () => {
        const content = props.result.map(c => <tr key={c.AccidentID}>
                <td key='summary'><PrimaryButton className='Summary-Button' onClick={() => {props.onRecordSelected(c.AccidentID);}}>{Strings.viewSummary}</PrimaryButton></td>
                {c.ColumnsValues.map(v => <td key={v.TemplateAttributeId}>{v.AttributeName !== "Accidents.DateTime" ? v.Value : moment.utc(v.Value, 'DD/MM/YYYY HH:mm:ss').locale(props.selectedLanguage).fromNow()}</td>)}
            </tr>);
        return <tbody>{content}</tbody>;
    };

    if (props.columns && props.result.length > 0) {
        return <div className='GridContainer'>
                <h4 className='TableTitle'>{Strings.similarIncidentTitle}</h4>
                <table className='GridTable'>
                    {renderTableHead()} 
                    {renderTableContent()}
                </table>
            </div>;
    }

    return <></>;
};

SimilarRecordsGrid.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({TemplateAttributeID: PropTypes.number, ColumnTitle: PropTypes.string })),
    result:  PropTypes.arrayOf(PropTypes.shape({ AccidentID: PropTypes.number, 
        ColumnsValues: PropTypes.arrayOf(PropTypes.shape({ TemplateAttributeId: PropTypes.number, Value: PropTypes.string }))
    })),
    onRecordSelected: PropTypes.func
};

export default SimilarRecordsGrid;