import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Spinner } from 'spheracloud-common';
import './Logout.scss';
import { userLoggedOut } from '../../../actions/authActions';
import Cookies from 'universal-cookie';

export class Logout extends React.Component {
    componentDidMount() {
        const { onLogout, appSettings } = this.props;

        const cookies = new Cookies();
        var originator = cookies.get('fr-originator');
    
        if (originator) {
            cookies.remove('fr-originator', { path: "/", domain: `.${appSettings.domain}` });
            window.location.hostname = originator;
        }
        else {
            onLogout();
        }        
    }

    render() {
        return (
            <div className="LogoutLoading">
                <Spinner />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appSettings: state.appReducer.appSettings,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLogout: () => { dispatch(userLoggedOut(ownProps.history)); }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
