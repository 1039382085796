import React, { Component } from 'react';
import style from './Header.module.scss';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { HelpCircle, Menu, Close, Location } from '../../icons';
import { getCurrentParentPage, getFormProgress, getLocationSelected } from '../../selectors/wizardSelectors';
import { SecondaryButton, Modal } from 'spheracloud-common';
import { submitDraft, showCancelForm, clearFormType } from '../../actions/appActions';
import { toggleSideMenu } from '../../actions/wizardActions';
import Strings from '../../constants/Strings';
import Translation from '../../containers/Translation/Translation';
import HeaderReportingUser from './HeaderReportingUser/HeaderReportingUser';
import { getNodeFromString } from '../../utils/utils';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import classNames from 'classnames';
import { getActiveLocationPath, getProfileValue } from '../../selectors/appSelectors';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locationModalOpen: false,
        };

        this.toggleLocationModal = this.toggleLocationModal.bind(this);
    }

    toggleLocationModal(open) {
        this.setState({
            locationModalOpen: open
        });
    }

    render() {
        const { pageTitle, toggleSideMenu, formProgress, submitDraft, showCancelForm, clearFormType, companySupportDetails, locationSelected, activeLocationPath } = this.props;
        const { locationModalOpen } = this.state;        
        const tooltipName = 'company-support-details';
        return (
            <div className={style.HeaderContainer}>
                <div className={'HeaderContent'}>
                    <CompanyLogo />
                    <div className={style.HeaderRightSection}>
                        <div className={style.HeaderLocationText} onClick={() => {this.toggleLocationModal(true)}} >{activeLocationPath}</div>
                        {
                            activeLocationPath && <div className={style.HeaderLocationIcon} onClick={() => {this.toggleLocationModal(true)}} >
                                <img src={Location} alt="Location" />
                            </div>
                        }
                        {
                            activeLocationPath && locationModalOpen &&
                            <Modal
                                apply={""}
                                cancel={""}
                                onApply={() => {}}
                                onCancel={() => {this.toggleLocationModal(false)} }
                                className="HelpTextModal"                                            
                            >
                                {activeLocationPath}
                            </Modal>
                        }
                        <HeaderReportingUser />
                        {
                            companySupportDetails &&
                            <>
                                <div className={style.HeaderHelpContainer} data-tip data-for={tooltipName} data-event="click mouseenter" data-event-off="mouseleave">
                                    <img src={HelpCircle} alt="Help" />
                                </div>
                                <ReactTooltip id={tooltipName} effect="solid" place="bottom" type="light" globalEventOff="click" delayShow={100} delayHide={100} clickable={true}>
                                    {getNodeFromString(companySupportDetails)}
                                </ReactTooltip>
                            </>
                        }
                        <SecondaryButton className={classNames([style.HeaderActionButton, style.CancelButton])} onClick={() => showCancelForm()}>
                            <img src={Close} alt="Cancel" />
                            <Translation>{Strings.cancel}</Translation>
                        </SecondaryButton>
                        {
                            locationSelected && <SecondaryButton className={classNames([style.HeaderActionButton, style.SaveExitButton])} onClick={() => submitDraft()}>
                                <img src={Close} alt="Save and Exit" />
                                <Translation>{Strings.saveAndExit}</Translation>
                            </SecondaryButton>
                        }
                    </div>
                </div>
                <div className={style.HeaderMobile}>
                    <div className={style.HeaderMobileMenuButton} onClick={toggleSideMenu}>
                        <img src={Menu} alt="Menu"/>
                    </div>
                    <div className={style.HeaderMobileTitle}>{pageTitle}</div>
                    <div className={style.HeaderMobileRight}>
                        <SecondaryButton className={style.HeaderMobileCancelButton} onClick={() => formProgress > 0 ? showCancelForm() : clearFormType()}>
                            <Translation>{Strings.cancel}</Translation>
                        </SecondaryButton>
                        {
                            locationSelected && <SecondaryButton className={style.HeaderMobileSaveExitButton} onClick={() => submitDraft()}>
                                <Translation>{Strings.save}</Translation>
                            </SecondaryButton>
                        }
                    </div>
                </div>
                <div className={style.HeaderProgressBar}>
                    <div className={style.HeaderProgress} style={{width: `${formProgress}%`}}></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        answers: state.wizardReducer.answers,
        pageTitle: getCurrentParentPage(state).MenuLabel,
        formProgress: getFormProgress(state),
        companySupportDetails: getProfileValue("Company.Support.Details", state),
        locationSelected: getLocationSelected(state),
        activeLocationPath: getActiveLocationPath(state),
    };
};

const mapDispatchToProps = dispatch => ({
    toggleSideMenu: () => {
        dispatch(toggleSideMenu());
    },
    submitDraft: (data) => {
        dispatch(submitDraft(data));
    },
    showCancelForm: () => {
        dispatch(showCancelForm());
    },
    clearFormType: () => {
        dispatch(clearFormType());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
