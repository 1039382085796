import appReducer from '../reducers/appReducer';
import wizardReducer from '../reducers/wizardReducer';

const storageKey = 'reducerstate';
const localStorageKey = 'localreducerstate'

export const persistReducerState = (state) => {
    const persistedState = {
        appReducer: appReducer.getPersistedState(state.appReducer),
        wizardReducer: wizardReducer.getPersistedState(state.wizardReducer)
    }
    const persistedUserInfo = {
        appReducer: appReducer.getLocalPersistedState(state.appReducer),
        wizardReducer: wizardReducer.getLocalPersistedState(state.wizardReducer)
    }

    localStorage.setItem(localStorageKey, JSON.stringify(persistedUserInfo));
    sessionStorage.setItem(storageKey, JSON.stringify(persistedState));

    return {
        ...state
    }
}

export const recoverReducerState = () => {
    const persistedState = JSON.parse(sessionStorage.getItem(storageKey))
        || { appReducer: {}, wizardReducer: {} };

    sessionStorage.removeItem(storageKey);

    const localPersistedState = JSON.parse(localStorage.getItem(localStorageKey))
        || { appReducer: {}, wizardReducer: {} };

    localStorage.removeItem(localStorageKey);

    return {
        appReducer: {
            ...appReducer.initialState,
            ...persistedState.appReducer,
            ...localPersistedState.appReducer,
        },
        wizardReducer: {
            ...wizardReducer.initialState,
            ...persistedState.wizardReducer,
            ...localPersistedState.wizardReducer
        }
    };
}

export const clearReducerState = () => {
    localStorage.removeItem(storageKey);
}
