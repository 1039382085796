import { FIELD_TYPES } from "../constants/FieldTypes";
import moment from "moment";
import _ from "lodash";
import {checkIsLive} from "./utils";
import { getPossibleValues } from "../selectors/wizardSelectors";

const DATEPICKER_FORMAT = 'DD/MM/YYYY';
const TIMEPICKER_FORMAT = 'HH:mm';

export const convertToDbValue = (answer, field, state) => {
    if (!field) {
        return answer;
    }

    switch (field.RenderingType) {
        case FIELD_TYPES.DATE:
            return convertToDbDate(answer);
        case FIELD_TYPES.TIME:
            return convertToDbTime(answer);
        case FIELD_TYPES.DROPDOWN:
        case FIELD_TYPES.RADIO_BUTTONS:
        case FIELD_TYPES.SINGLE_CHOOSER:
            return convertToDbPicklist(answer, field);
        case FIELD_TYPES.DROPDOWN_MULTI_LEVEL:
            const isLiveSearch = checkIsLive(field);
            if (isLiveSearch) {
                return answer ? [answer] : [];
            }
            return answer && convertToDbMultiLevel(answer, field, state);
        case FIELD_TYPES.CHECKLIST_MULTI_LEVEL:
            return answer && convertToDbMultiLevel(answer, field, state);
        default:
            return answer;
    }
}

export const convertFromDbValue = (answer, field, state) => {
    if (!field) {
        return answer;
    }

    switch (field.RenderingType) {
        case FIELD_TYPES.DATE:
            return convertFromDbDate(answer);
        case FIELD_TYPES.TIME:
            return convertFromDbTime(answer);
        case FIELD_TYPES.DROPDOWN:
        case FIELD_TYPES.RADIO_BUTTONS:
        case FIELD_TYPES.SINGLE_CHOOSER:
            return _.isArray(answer) && answer.length > 0 ? answer[0] : undefined;
        case FIELD_TYPES.DROPDOWN_MULTI_LEVEL:
        case FIELD_TYPES.CHECKLIST_MULTI_LEVEL:
            return _.isArray(answer) && answer.length > 0 ?
                (checkIsLive(field) && answer.length === 1 ? answer[0] : convertFromDbMultiLevel(answer, field, state)) :
                undefined;
        default:
            return answer;
    }
}

const convertToDbDate = (dateString) => {
    const date = moment.utc(dateString, DATEPICKER_FORMAT);

    if (!date.isValid()) {
        return null;
    }

    return date.toISOString();
}

export const convertFromDbDate = (dateString) => {
    const date = moment(dateString);

    if (!date.isValid()) {
        return undefined;
    }

    return date.format(DATEPICKER_FORMAT);
}

const convertToDbTime = (timeString) => {
    const time = moment.utc(timeString, TIMEPICKER_FORMAT);

    if (!time.isValid()) {
        return null;
    }

    return time.toISOString();
}

export const convertFromDbTime = (timeString) => {

    let time

    if (moment(timeString).isValid()) {
        time = moment(timeString)
    } else if (moment(timeString, TIMEPICKER_FORMAT).isValid()) {
        time = moment(timeString, TIMEPICKER_FORMAT)
    } else {
        return undefined;
    }

    return time.format(TIMEPICKER_FORMAT);
}

export const convertToDbPicklist = (answer, field) => {
    const selectedAttribute = field.TemplateAttribute.PossibleValues.find(v => v.Identifier === answer || v.DataGuid === answer)
    if (!selectedAttribute) {
        return answer ? [answer] : [];
    }
    
    return [selectedAttribute.Identifier];
}

export const isValidAnswer = (answer) => {
    return !_.isEmpty(answer) || _.isNumber(answer) || _.isBoolean(answer);
}

export const getMultiLevelStructure = (values) => {
    const insert = (children = [], item, path = []) => {
        const [head, ...tail] = item.label;
        let child = children.find(child => child.label === head);
        if (!child) {
            child = {
                ...item,
                value: tail.length === 0 ? item.value : undefined,
                label: head,
                path: [...path, head].join(':'),
                children: [],
            }
            children.push(child);
        }
        if (tail.length > 0) {
            insert(child.children, {
                ...item,
                label: tail
            }, [...path, head]);
        }
        return children;
    }

    return values
        .map(value => {
            return {
                ...value,
                label: value.label.split(':').map(item => item.trim()),
            }
        })
        .sort((a,b) => a.label.length - b.label.length)
        .reduce((children, value) => insert(children, value), []);
}

export const isInvalidMultiLevelAnswer = (answer, field, possibleValues) => {
    const isLiveSearch = checkIsLive(field);
    if (isLiveSearch) {
        return !answer;
    }

    const options = getMultiLevelStructure(possibleValues.map(v => ({
        value: v.Identifier,
        label: v.Label
    })));

    const invalid = (options) => {
        const selected = options.filter(o => answer.includes(o.path))
        return selected.length === 0 || selected.some(s => {
            return s.children.length > 0 && invalid(s.children);
        });
    }

    return !answer || invalid(options);
}

const convertToDbMultiLevel = (answer, field, state) => {
    return getPossibleValues(state, field).filter(v => {
        return answer.indexOf(v.Label) > -1;
    }).map(v => {
        return v.Identifier;
    });
}

const convertFromDbMultiLevel = (answer, field, state) => {
    const selected = getPossibleValues(state, field).filter(o => answer.includes(o.Identifier));
    const z = selected.reduce((arr, s) => {
        const split = s.Label.split(':');
        let x = [];
        split.forEach((spl, i) => {
            x = x.concat(split.slice(0, i + 1).join(':'));
        })
        return arr.concat(x);
    }, []);
    return z;
}

export const getEmptyAnswer = (renderingType) => {

    switch (renderingType) {
        case FIELD_TYPES.TEXT_INPUT:
        case FIELD_TYPES.NUMERIC:
        case FIELD_TYPES.FLOAT:
        case FIELD_TYPES.TEXT_AREA:
        case FIELD_TYPES.DATE:
        case FIELD_TYPES.TIME:
            return null;
        case FIELD_TYPES.LOCATION:
        case FIELD_TYPES.CHECKLIST:
        case FIELD_TYPES.CHECKLIST_MULTI_LEVEL:
        case FIELD_TYPES.MULTI_CHOOSER:
            return [];
        case FIELD_TYPES.RADIO_BUTTONS:
        case FIELD_TYPES.SINGLE_CHOOSER:
        case FIELD_TYPES.DROPDOWN:
        case FIELD_TYPES.DROPDOWN_MULTI_LEVEL:
        case FIELD_TYPES.SLIDER:
            return undefined;
        case FIELD_TYPES.TOGGLE:
            return false;
        default:
            return "";
    }
}
