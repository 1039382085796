import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SideMenuItem.scss';
import { CheckGreen } from '../../../icons';
import { getPageStatus } from '../../../selectors/wizardSelectors';
import { PAGE_STATUS_TYPES } from '../../../constants/PageStatusTypes';
import Translation from '../../Translation/Translation';
import Strings from '../../../constants/Strings';

class SideMenuItem extends Component {
    
    getStatusIcon(status) {
        switch (status) {
            case PAGE_STATUS_TYPES.COMPLETE:
                return <img src={CheckGreen} alt="Completed" />
            default:
                return '';
        }
    }

    render() {
        const { onClick, title, selected, status, disabled } = this.props;
        const selectedClass = selected ? ' SideMenuItemSelected' : '';
        const disabledClass = disabled ? ' SideMenuItemDisabled' : '';

        return (
            <div className={"SideMenuItem" + selectedClass + disabledClass} onClick={onClick}>
                <div className="SideMenuItemContent">
                    <div className="SideMenuItemIcon"></div>
                    <div className="SideMenuItemTitle">{title ? title : <Translation>{Strings.untitledPage}</Translation>}</div>
                </div>
                <div className="SideMenuItemStatus">
                    {this.getStatusIcon(status)}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        status: getPageStatus(state, ownProps.pageGuid),
    };
};

export default connect(mapStateToProps)(SideMenuItem);