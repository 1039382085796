export const REPORTING_USER = {
    TYPE: 'Accidents.PersonReporting.ReportingMethod',
    USER_ID: 'Accidents.PersonReportingUserID',
    NAME: 'Accidents.PersonReporting.Name',
    PHONE: 'Accidents.PersonReporting.TelNumber',
    EMAIL: 'Accidents.PersonReporting.EmailAddress',
};

export const SITEMAP_HOTSPOT = {
    HOTSPOT_X: 'Accidents.HotSpotMapX',
    HOTSPOT_Y:'Accidents.HotSpotMapY',
    HOTSPOT_DOCUMENT_ID: 'Accidents.HotSpotDocumentID'
};

export const ACCIDENT_ID_ATTRIBUTE = 'Accidents.AccidentID';

export const FORM_ID_ATTRIBUTE = 'Accidents.FormId';

export const IS_DRAFT_ATTRIBUTE = 'Accidents.IsDraft';

export const ACCIDENT_GUID_ATTRIBUTE = 'Accidents.Guid';

export const THIRD_PARTIES_NAME_ATTRIBUTE = 'ThirdParty.EmployeeName';
export const THIRD_PARTIES_ACCIDENT_ID_ATTRIBUTE = 'ThirdParties.AccidentID';

export const SHORTCODE_ATTRIBUTE = 'Accidents.ShortCode';

export const FIRSTREPORT_ATTRIBUTE = 'Accidents.FromFirstReport';

export const LOCATION_ID_ATTRIBUTE = 'Accidents.LocationID';

export const PAGE_REACHED_ATTRIBUTE = 'Accidents.FirstReportPageReached';

export const EMAIL_LIST_ATTRIBUTE = 'Accidents.FirstReportEmailList';