import React from 'react';
import PropTypes from 'prop-types';
import { Mic, MicNone } from '../../../icons/index';
import styles from './TextInput.module.css';
import { Translate } from '../../../icons';
import PopupMenu from '../../PopupMenu/PopupMenu';
import FieldSpinner from "../../FieldSpinner/FieldSpinner";
const classNames = require('classnames');

class TextInput extends React.Component {
    constructor(props) {
        super(props);

        const { initialText, isTextValid } = this.props;

        this.state = {
            text: isTextValid(initialText) ? initialText : '',
            isLanguagePopupOpen: false
        };

        this.input = React.createRef();

        this.focus = this.focus.bind(this);
        this.blur = this.blur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.captureSpeech = this.captureSpeech.bind(this);
        this.handleMicInput = this.handleMicInput.bind(this);
    }

    captureSpeech() {
        const { onMicInputClick } = this.props;

        onMicInputClick();
    };

    handleMicInput(x) {
        const { onTextChanged } = this.props;

        let text = this.state.text

        if (text) {
            text = text + ' ' + x;
        } else if (x) {
            text = x
        }

        this.setState({ text }, onTextChanged && onTextChanged(text));
    }

    componentDidUpdate(prevProps) {
        const { speechToTextValue, } = this.props;

        if (prevProps.speechToTextValue !== speechToTextValue) {
            this.handleMicInput(speechToTextValue);
        }

        if (this.props.initialText !== prevProps.initialText) {
            this.setState({
                text: this.props.initialText
            });
        }
    }

    focus() {
        this.input.current.focus();
    }

    blur() {
        const { onBlur, onTextChanged, isTextValid } = this.props;
        const text = this.input.current.value;

        if (!isTextValid(text)) {
            this.setState({ text: '' });

            if (onTextChanged) {
                onTextChanged('');
            }
        }

        if (onBlur) {
            onBlur();
        }
    }

    handleChange(e) {
        const { onTextChanged, isTextEntryValid, isTextValid } = this.props;

        const text = e.target.value;

        if (isTextEntryValid(text)) {
            this.setState({ text }, () => {
                if (isTextValid(text) && onTextChanged) {
                    onTextChanged(text);
                }
            });
        }
    }

    handleTranslateIconClick = () => {
        this.setState({ isLanguagePopupOpen: true });
    }

    render() {
        const {
            disabled,
            hasError,
            id,
            leftIcon,
            maxLength,
            name,
            onFocus,
            onKeyPress,
            placeholder,
            rightIcon,
            type,
            speechToTextSettings,
            isLanguageSupported,
            micInUse,
            languageOptions,
            onLanguageChanged,
            translationIsBeingProcessed,
            fieldId,
            shouldShowSpinner
        } = this.props;

        const {
            text,
            isLanguagePopupOpen
        } = this.state;

        let classes = [styles['Text-Input']];

        let leftIconComponent = null;
        if (leftIcon) {
            leftIconComponent = <span className={styles['Text-Input-Left-Icon']}>{leftIcon}</span>;
            classes.push(styles['Text-Input-Extra-Padding-Left']);
        }

        let rightIconComponent = null;
        if (rightIcon) {
            rightIconComponent = <span className={styles['Text-Input-Right-Icon']}>{rightIcon}</span>;
            classes.push(styles['Text-Input-Extra-Padding-Right']);
        }

        if (hasError) {
            classes.push(styles['Text-Input-Error']);
        }

        return (
            <span className={styles["Text-Input-Container"]}>
                {leftIconComponent}
                <input
                    className={classNames(classes)}
                    type={type}
                    name={name}
                    id={id}
                    value={text}
                    placeholder={!disabled && placeholder}
                    onChange={this.handleChange}
                    disabled={disabled}
                    onKeyPress={onKeyPress}
                    maxLength={maxLength}
                    ref={this.input}
                    onBlur={this.blur}
                    onFocus={onFocus}
                />
                {rightIconComponent}
                <PopupMenu
                    open={isLanguagePopupOpen}
                    options={languageOptions}
                    onChange={(value) => {
                        this.setState({ isLanguagePopupOpen: false });
                        if (this.props.selectedLanguageIsoCode === value.iso) {
                            return;
                        }
                        if (value.translateOpt) {
                            this.props.onTranslateClick(this.state.text, this.props.selectedLanguageIsoCode, value.iso);
                        } else {
                            onLanguageChanged(value);
                        }
                    }}
                    onClose={() => this.setState({ isLanguagePopupOpen: false })}
                />
                <div className={styles["Text-Input-Icon-Container"]}>
                    {
                        !disabled &&
                        <>
                            {((translationIsBeingProcessed && translationIsBeingProcessed.fieldId === fieldId) || shouldShowSpinner) && <FieldSpinner />}
                            {speechToTextSettings && speechToTextSettings.isActive && isLanguageSupported &&
                                <img onClick={this.captureSpeech}
                                     alt='record'
                                     className={micInUse ? styles['Text-Input-Mic-InUse'] : styles['Text-Input-Mic']}
                                     src={micInUse ? MicNone : Mic}></img>
                            }
                            {languageOptions.length > 0 && <img className={styles["Text-Input-Translate-Icon"]} src={Translate}
                                                                alt="Translate" onClick={this.handleTranslateIconClick} />}
                        </>
                    }
                </div>
            </span>
        );
    }
}

export const propTypes = {
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    id: PropTypes.string,
    initialText: PropTypes.string,
    leftIcon: PropTypes.node,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    isTextEntryValid: PropTypes.func,
    isTextValid: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onTextChanged: PropTypes.func,
    placeholder: PropTypes.string,
    rightIcon: PropTypes.node,
    type: PropTypes.string,
    translationIsBeingProcessed: PropTypes.bool.isRequired,
    shouldShowSpinner:  PropTypes.bool,
    fieldId: PropTypes.string.isRequired
};

export const defaultProps = {
    disabled: false,
    hasError: false,
    id: undefined,
    initialText: '',
    leftIcon: undefined,
    maxLength: undefined,
    name: undefined,
    isTextEntryValid: () => true,
    isTextValid: () => true,
    onBlur: undefined,
    onFocus: undefined,
    onKeyPress: undefined,
    onTextChanged: undefined,
    placeholder: undefined,
    rightIcon: undefined,
    shouldShowSpinner: false,
    type: 'text',
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
