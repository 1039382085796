export const FIELD_OPERATOR = {
    EQUALS: 1,
    NOT_EQUALS: 2,
    GREATER_THAN: 3,
    LESS_THAN: 4,
    EQUALS_TO_OR_GREATER_THAN: 5,
    EQUALS_TO_OR_LESS_THAN: 6,
    CONTAINS: 7,
    DOES_NOT_CONTAIN: 8,
    IS_DEFINED: 9,
    IS_UNDEFINED: 10,
    IS_BLANK: 11,
    IS_NOT_BLANK: 12
}
