const strings = {
    back: "Back",
    next: "Next",
    done: "Done",
    submit: "Submit",
    exit: "Exit",
    edit: "Edit",
    ok: "Ok",
    error: "Error",
    addPerson: "Add Person",
    review: "Review",
    saving: "Saving...",
    submitting: "Submitting...",
    failedToSubmit: "Failed to submit",
    startFullReport: "Start Full Report",
    applicationFailedToLoad: "Application failed to load",
    noFormTypesConfigured: "No form types configured",
    orContinueAnExistingEvent: "or Continue an Existing [Accident]",
    continueExisting: "Continue Existing",
    submitInitialReport: "Submit Initial Report",
    addTask: "Add Task",
    saveAndExit: "Save & Exit",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    youLoggedOut: "Your session has expired, please login again",
    apply: "Apply",
    cancelConfirmation: "Any unsaved data will be lost, are you sure?",
    noResults: "No results found.",
    yes: "Yes",
    no: "No",
    noAccessToCompany: "This account does not have access to this company. Please provide your information manually.",
    submitPageTitle: "[Accident] with ID: <FormId> Submitted",
    draftCodePlaceholder: "Code",
    continueDraftTitle: "Continue [Accident]",
    continueDraftMessage: "Please enter the existing [accident] code to continue.",
    genericDraftError: "Could not continue existing [accident], please try again.",
    accidentNotInDraftError: "[Accident] has already been submitted.",
    draftNotFoundError: "[Accident] not found, please confirm that you entered the correct code.",
    draftCodeError: "Invalid draft code format.",
    formNotFoundError: "Form not found, please confirm that you entered the correct code.",
    submittedDraftTitle: "Saved",
    submittedDraftMessage: "Use the code <strong><FormId></strong> to continue working on this [accident] later.",
    genericAuthError: "There was an error authenticating your account. Please try again.",
    genericSubmitError: "There was an error when submitting the report. Please try again.",
    genericSaveError: "There was an error when saving the report. Please try again.",
    reportingAs: "Reporting As",
    reportingAnonymously: "Reporting Anonymously",
    reportAnonymously: "Report Anonymously",
    enterInfoManually: "Enter Info Manually",
    useAccountLogin: "Use Account (Login)",
    useSSOLogin: "Use SSO (Login)",
    loginAgain: "Login again",
    anonymous: "Anonymous",
    loggedInAs: "Logged in as",
    startPageMenuLabel: "[Accident] Type",
    startPageScreenTitle: "What type of [accident] is this?",
    submitPageHelpText: "The initial report was submitted. You can continue adding more details in the full report.",
    reviewPageScreenTitle: "Review & Submit",
    reviewPagePrintScreenTitle: "[Accident] Summary",
    reviewPageHelpText: "You have finished the initial report details.",
    userPageMenuLabel: "About You",
    userPageScreenTitle: "How would you like to report?",
    userPageHelpText: "Provide some basic information.",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    requiredField: "Required field",
    invalidValue: "Invalid value",
    minimumLength: "Minimum length",
    maximumLength: "Maximum length",
    indicatesRequiredField: "Indicates a required field.",
    today: "Today",
    yesterday: "Yesterday",
    other: "Other",
    earlier: "Earlier",
    selectDate: "Select Date",
    globalMap: "Global Map",
    facilityMap: "Facility Map",
    addLinkDialogTitle: "Add/edit link",
    upload: "Upload",
    link: "Link",
    emailModalTitle: "Add Recipients",
    update: "Update",
    emailModalHelpText: "Add additional recipients of the confirmation email",
    emailModalPlaceholderText: "Enter email",
    addEmailButtonLabel: "Add Recipients",
    printReviewPageButtonLabel: "Print",
    nameField: "TITLE",
    linkField: "LINK",
    fileName: "File name",
    editFileDialogTitle: "Edit file details",
    logout: "Logout",
    startANewReport: "Start a new report",
    untitledPage: "Untitled page",
    additionalRecipients: "Additional recipients",
    selectLocation: "Select Location",
    selected: "Selected",
    showMore: "Show More",
    showAllSelected: "Show All Selected",
    showLess: "Show Less",
    search: "Search ...",
    siteMapLoadingError: "No facility map was found for this location",
    noLocationSelectedError: "Please select a location",
    siteMapHelpText: "Click to place a pin on global and facility maps",
    invalidEmail: "Invalid email address",
    invalidPhone: "Invalid phone number",
    signIn: "Sign In",
    username: "Username",
    enterUsername: "Enter Username",
    password: "Password",
    enterPassword: "Enter Password",
    incorrectEmailOrPassword: "Incorrect username or password",
    chooserTitle: "Title",
    allQuestions: "All Questions",
    completeQuestions: "Complete Questions",
    incompleteQuestions: "Incomplete Questions",
    expandAll: "Expand all",
    collapseAll: "Collapse all",
    thisLanguageValueExists: "Text already entered for this language. Overwrite?",
    thisLanguageValue: "Overwrite",
    deleteDraftDescription: "Are you sure you want to delete draft",
    deleteDraftTitle: "Delete Draft",
    warning: "Warning",
    namesDetected: "Your response may contain sensitive information about yourself or others. Possible names have been detected in these fields",
    maximum: "Maximum",
    minimum: "Minimum",
    continue: "Continue",
    viewSummary: "View Summary",
    similarIncidentTitle: "Similar incidents were reported recently",
    startPageLocationSelection: "Select your location",
    locationRestrictionUserError: "You do not have access to the locations this form is restricted to",
    continueAsAnonymous: "Continue anonymously",
    goBack: "Go back"
};

export default strings;
