import React, { Component } from 'react';
import style from './HeaderReportingUser.module.scss';
import { connect } from 'react-redux';
import { Account, IncognitoHeader, Logout, IncognitoHeaderSmall, AccountSmall, SsoSmall, SsoSmallBlue, DetailsSmall, DetailsSmallBlue } from '../../../icons';
import Strings from '../../../constants/Strings';
import Translation from '../../../containers/Translation/Translation';
import { getFormUser, getSelectedForm, getAppSettingsFromState, getProfileValue } from '../../../selectors/appSelectors';
import { goToPage } from '../../../actions/wizardActions';
import * as AuthActions from '../../../actions/authActions';
import { USER_TYPES } from '../../../constants/UserTypes';
import { withRouter } from 'react-router-dom';

class HeaderReportingUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.closeIfNeeded = this.closeIfNeeded.bind(this);
        this.getHeaderIcon = this.getHeaderIcon.bind(this);
        this.container = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.closeIfNeeded);
        document.addEventListener('focusin', this.closeIfNeeded);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeIfNeeded);
        document.removeEventListener('focusin', this.closeIfNeeded);
    }

    closeIfNeeded(e) {

        const { isOpen } = this.state;

        if (isOpen && this.container && this.container.current && !this.container.current.contains(e.target)) {
            this.setState({ isOpen: false });
        }
    }

    toggleOpen() {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });
    }

    getHeaderIcon() {
        const { sideMenu, userTypeSelected } = this.props;
        let img;
        if (userTypeSelected === USER_TYPES.AUTHENTICATED || userTypeSelected === USER_TYPES.B2CAUTHENTICATED) {
            if (sideMenu) {
                img = AccountSmall
            } else {
                img = Account
            }
        } else if (userTypeSelected === USER_TYPES.SSOAUTHENTICATED || userTypeSelected === USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN) {
            if (sideMenu) {
                img = SsoSmallBlue
            } else {
                img = SsoSmall
            }
        } else if (userTypeSelected === USER_TYPES.MANUAL) {
            if (sideMenu) {
                img = DetailsSmallBlue
            } else {
                img = DetailsSmall
            }
        }
        else {
            if (sideMenu) {
                img = IncognitoHeaderSmall
            } else {
                img = IncognitoHeader
            }
        }

        return img
    }

    render() {
        const { user, goToUserPage, selectedForm, selectedFormTypeId, logoutUser, sideMenu, userTypeSelected, setAuthType, history, isSSOOnly, ssoLink, domainHint, appSettings, loginButtonLabel, ssoLoginButtonLabel } = this.props;
        const { isOpen } = this.state;
        const hideReportingUser = selectedForm && (userTypeSelected === -1 || (!selectedForm.AllowAnonReporting && (userTypeSelected === USER_TYPES.ANONYMOUS || userTypeSelected === USER_TYPES.MANUAL)));
        const isB2C = appSettings.AUTHENTICATED_USER_SETTINGS.ISB2C === 'true';
        return (
            <>
                {
                    !hideReportingUser &&
                    <div className={sideMenu ? 'SideMenuReportingUser' : style.HeaderReportingUser} onClick={() => selectedFormTypeId === -1 ? this.toggleOpen() : goToUserPage()}>
                        <div className={sideMenu ? style.SideMenuReportingUserText : style.HeaderReportingUserText}>
                            {
                                user.name ?
                                    <>
                                        <Translation>{Strings.reportingAs}</Translation>
                                        <span> {user.name}</span>
                                    </>
                                    :
                                    <Translation>{Strings.reportingAnonymously}</Translation>
                            }

                        </div>
                        <div className={sideMenu ? style.SideMenuReportingUserIcon : style.HeaderReportingUserIcon}>
                            <img src={this.getHeaderIcon()} alt="Reporting as" />
                        </div>
                        {
                            isOpen && user.name && (
                                <div className={style.HeaderReportingUserDropdown} ref={this.container} >
                                    {
                                        <>
                                            <div className={style.HeaderReportingUserLogoutText}>
                                                {user.name}
                                            </div>
                                            <div className={style.HeaderReportingUserDropdownBoxButton} onClick={() => logoutUser()}>
                                                <div className={style.HeaderReportingUserDropdownBoxImg}>
                                                    <img src={Logout} alt="Logout" />
                                                </div>
                                                <div className={style.HeaderReportingUserDropdownBoxText}>
                                                    <Translation>{Strings.logout}</Translation>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        }
                        {
                            isOpen && !user.name && (
                                <div className={style.HeaderReportingUserDropdown} ref={this.container} >
                                    {
                                        <>
                                            {
                                                !isSSOOnly && !isB2C &&
                                                <div className={style.HeaderReportingUserDropdownBoxButton} onClick={() => setAuthType(USER_TYPES.AUTHENTICATED, history)}>
                                                    <div className={style.HeaderReportingUserDropdownBoxImg}>
                                                        <img src={AccountSmall} alt="Logout" />
                                                    </div>
                                                    <div className={style.HeaderReportingUserDropdownBoxText}>
                                                        <span>{loginButtonLabel}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                ssoLink && !isB2C &&
                                                <div className={style.HeaderReportingUserDropdownBoxButton} onClick={() => setAuthType(USER_TYPES.SSOAUTHENTICATED, history)}>
                                                    <div className={style.HeaderReportingUserDropdownBoxImg}>
                                                        <img src={SsoSmallBlue} alt="Logout" />
                                                    </div>
                                                    <div className={style.HeaderReportingUserDropdownBoxText}>
                                                        <span>{ssoLoginButtonLabel}</span>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                isB2C &&
                                                <div className={style.HeaderReportingUserDropdownBoxButton} onClick={() => setAuthType(USER_TYPES.B2CAUTHENTICATED, history)}>
                                                    <div className={style.HeaderReportingUserDropdownBoxImg}>
                                                        <img src={AccountSmall} alt="Logout" />
                                                    </div>
                                                    <div className={style.HeaderReportingUserDropdownBoxText}>
                                                        <span>{loginButtonLabel}</span>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                isB2C && domainHint &&
                                                <div className={style.HeaderReportingUserDropdownBoxButton} onClick={() => setAuthType(USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN, history)}>
                                                    <div className={style.HeaderReportingUserDropdownBoxImg}>
                                                        <img src={AccountSmall} alt="Logout" />
                                                    </div>
                                                    <div className={style.HeaderReportingUserDropdownBoxText}>
                                                        <span>{ssoLoginButtonLabel}</span>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            )
                        }
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getFormUser(state),
        selectedForm: getSelectedForm(state),
        selectedFormTypeId: state.appReducer.selectedFormTypeId,
        userTypeSelected: state.wizardReducer.userTypeSelected,
        ssoLink: state.appReducer.linkForSSO,
        isSSOOnly: state.appReducer.isSSOOnly,
        domainHint: state.appReducer.domainHint,
        appSettings: getAppSettingsFromState(state),
        loginButtonLabel: getProfileValue('Company.Portal.LoginButtonLabel', state),
        ssoLoginButtonLabel: getProfileValue('Company.Portal.SSOLoginButtonLabel', state),
    };
};

const mapDispatchToProps = dispatch => ({
    goToUserPage: () => {
        dispatch(goToPage(0));
    },
    logoutUser: () => {
        dispatch(AuthActions.setAuthType(0));
    },
    setAuthType: (type, history) => { dispatch(AuthActions.setAuthType(type, history)); },

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderReportingUser));
