import React from 'react';
import PropTypes from 'prop-types';

import { StatelessCheckbox } from 'spheracloud-common';
import './ChooseListTableView.css';


const ChooseListTableView = ({ fieldId, items, noResultsText, onItemSelect, maxHeight, columnNames, chooserTitle }) => (
  <div className="ChooseListViewItemContainer" style={maxHeight ? { maxHeight: maxHeight, overflowY: "auto"} : {}}>

    <table className="ChooseListViewTable">
      <thead>
        <tr>
          <th></th>
          <th>{chooserTitle}</th>
          {
            columnNames.map((i, idx) => {
              return <th key={fieldId + '.th.' + idx}>{i.Value}</th>
            })
          }
        </tr>
      </thead>
      <tbody>
        {items.map(i => (
          <tr
            key={fieldId + '.tr.' + i.value}
            className="ChooseListViewItem"
            onClick={() => onItemSelect(i.value)}
            onKeyPress={() => onItemSelect(i.value)}
            role="switch"
            aria-checked={i.selected}
            tabIndex={0}
          >
            <td className="ChooseListViewCheckbox">
                <StatelessCheckbox
                  key={fieldId + '.sc.' + i.value}
                  isChecked={i.selected}
                  isKendoGridCell={false}
                />
            </td>

            <td className="ChooseListAdditionalItems ChooseListTitle">
              {i.text}
            </td>
            {i.additionalFields && i.additionalFields.map((x, i) => {
              return (<td  key={fieldId + '.td.' + i.value + '.add.' + x} className="ChooseListAdditionalItems">{x}</td>)
            })}
          </tr>
        ))}
        {items.length === 0 && (
          <tr key={fieldId + ".notFound"}>
            <td colSpan={2 + (columnNames?.length ?? 0)}>
              <div className="NoResultsItem">
                <span>{noResultsText}</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

ChooseListTableView.propTypes = {
  fieldId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    selected: PropTypes.bool,
  })),
  noResultsText: PropTypes.string,
  onItemSelect: PropTypes.func,
  maxHeight: PropTypes.string,
  columnNames: PropTypes.array,
  chooserTitle: PropTypes.string,
};

ChooseListTableView.defaultProps = {
  fieldId: 'unset',
  items: [],
  noResultsText: 'No results found.',
  onItemSelect: () => { },
  maxHeight: undefined,
  columnNames: {},
  chooserTitle: '',
}

export default ChooseListTableView;
