import React, { Component } from 'react';
import './Element.scss';
import { connect } from 'react-redux';
import { ELEMENT_TYPES, CODED_ELEMENT_TYPES } from '../../constants/ElementTypes';
import { SingleChooser } from 'spheracloud-common';
import BodyMap from './BodyMap/BodyMap';
import Field from '../Field/Field';
import { updateElementAnswer, reloadObjectPicklistValues, loadLocationAttributes, clearScrollToFirst } from '../../actions/wizardActions';
import { reloadFormPermissions } from '../../actions/appActions';
import { getElementAnswer, getElementVisibility, getCurrentPageElementIsInvalid, getCurrentPageElementIsFirstInvalid, getCurrentPageElementIsMandatory, getDefaultValue, getElementIsUpdated } from '../../selectors/wizardSelectors';
import LocationMap from './LocationMap/LocationMap';
import LocationSelectorWithSiteMap from './LocationSelectorWithSiteMap/LocationSelectorWithSiteMap';
import Strings from '../../constants/Strings';
import Translation from '../../containers/Translation/Translation';
import { LOCATION_ID_ATTRIBUTE } from '../../constants/KnownAttributes';
import HierarchyLocationSelector from './HierarchyLocationSelector/HierarchyLocationSelector';
import { PERMISSION_TYPES } from '../../constants/PermissionTypes';
import RelatedDocuments from './RelatedDocuments/RelatedDocuments';
import { VISIBILITY_TYPES } from '../../constants/VisibilityTypes';
import { getUseFormLocationRestrictions, getActiveLocation } from '../../selectors/appSelectors';

class Element extends Component {

    constructor(props) {
        super(props);

        const { defaultValue, isUpdated } = props;

        let value = props.value;
        if (value === undefined && !isUpdated) {
            value = this.getActiveLocationDefaultValue() || defaultValue;

            if (value !== undefined) {
                this.handleElementChange(value);
            }
        }

        this.state = {
            value
        };

        this.scrollIntoView = this.scrollIntoView.bind(this);
        this.handleElementChange = this.handleElementChange.bind(this);
        this.container = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { value, isFirstInvalid, clearScrollToFirst, defaultValue } = this.props;

        if (value !== prevProps.value) {
            this.setState({
                value
            });
        }

        if (prevProps.isFirstInvalid === false && isFirstInvalid === true) {
            this.container.current.scrollIntoView({behavior: 'smooth'});
            clearScrollToFirst();
        }

        if (defaultValue !== prevProps.defaultValue) {
            const { defaultValue, isUpdated } = this.props;
            let value = this.props.value;
            if (value === undefined && !isUpdated) {
                value = this.getActiveLocationDefaultValue() || defaultValue;

                if (value !== undefined) {
                    this.handleElementChange(value);
                }
            }
        }
    }

    getActiveLocationDefaultValue() {
        const { element, useLocationRestrictions, activeLocation } = this.props;
        let activeLocationValue;

        if (useLocationRestrictions && activeLocation && element.Type === ELEMENT_TYPES.CODED) {
            if (element.Name === CODED_ELEMENT_TYPES.LOCATION_SELECTOR || element.Name === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR) {
                if (this.isActiveLocationAllowedOnElement()) {
                    activeLocationValue = activeLocation;
                }
            }
            else if (element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS) {
                activeLocationValue = { hierarchyLocation: activeLocation };
            }
        }

        return activeLocationValue
    }

    isActiveLocationAllowedOnElement() {
        const { element, activeLocation } = this.props;

        let isTypeAllowed = true;
        if (element.ConfigData && element.ConfigData.LocationHierarchyNodeType) {
            const locationHierarchyNodeType = element.ConfigData.LocationHierarchyNodeType.replaceAll('+', ' ');
            let selectableTypes = locationHierarchyNodeType.split(",").map(nt => decodeURIComponent(nt));
            isTypeAllowed = !selectableTypes || selectableTypes.indexOf(activeLocation.nodeType) !== -1;
        }

        let isNodeInAllowedPath = true;
        if (element.ConfigData.LocationHierarchyLimitToGroupNodeId) {
            const limitToNodeId = parseInt(element.ConfigData.LocationHierarchyLimitToGroupNodeId);
            isNodeInAllowedPath = activeLocation.path.findIndex(item => item.nodeId === limitToNodeId) !== -1 || activeLocation.nodeId === limitToNodeId;
        }

        return (isTypeAllowed && isNodeInAllowedPath);
    }

    scrollIntoView() {
        this.container.current && this.container.current.scrollIntoView({ block: 'nearest' });
    }

    handleElementChange(value) {
        const { updateElementAnswer, element, reloadObjectPicklistValues, isHierarchy, loadLocationAttributes, reloadFormPermissions, selectedFormTypeId } = this.props;
        const prevValue = this.state && this.state.value;
        this.setState({
            value: value
        });
        updateElementAnswer(element.Guid, value);

        if (element.Type === ELEMENT_TYPES.CODED &&
            // we need to perform these actions only for the main Location attribute (LocationID), not for the case when there is a custom Location attribute specified
            !element.ConfigData.LocationAttribute &&
            (element.Name === CODED_ELEMENT_TYPES.LOCATION_SELECTOR ||
                element.Name === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR ||
                element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS)) {

            let prevLocationId, locationId;
            if (element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS) {
                prevLocationId = isHierarchy ? prevValue && prevValue.hierarchyLocation && prevValue.hierarchyLocation.nodeExternalId : prevValue && prevValue.locationId;
                locationId = isHierarchy ? value && value.hierarchyLocation && value.hierarchyLocation.nodeExternalId : value && value.locationId;
            } else {
                prevLocationId = isHierarchy ? prevValue && prevValue.nodeExternalId : prevValue;
                locationId = isHierarchy ? value && value.nodeExternalId : value;
            }

            if (locationId !== prevLocationId) {
                reloadFormPermissions(locationId ? locationId : -1, selectedFormTypeId ? selectedFormTypeId : -1);
                reloadObjectPicklistValues(locationId ? locationId : -1);
                loadLocationAttributes(locationId);
            }
        }
    }

    getCodedElement(element) {
        const { noResultsText, isInvalid, isHierarchy, disabled, contentDimensions } = this.props;
        const fields = element.Fields;
        const configData = element.ConfigData;
        const isDisabled = disabled || element.Permission === PERMISSION_TYPES.READ_ONLY;
        const maxDropdownHeight = contentDimensions && contentDimensions.height ? Math.min(contentDimensions.height - 60, 400): 400;

        switch (element.Name) {
            case CODED_ELEMENT_TYPES.RELATED_DOCUMENTS:
                return <RelatedDocuments value={this.state.value} elementGuid={element.Guid}  configData={element.ConfigData} disabled={isDisabled}/>

            case CODED_ELEMENT_TYPES.BODY_MAP:
                return <BodyMap initialSelection={this.state.value} onChange={this.handleElementChange} disabled={isDisabled}/>

            case CODED_ELEMENT_TYPES.ACCIDENTS_MAPS:
                const locationAttributeForSiteMap = fields.find(field => {
                    return field.TemplateAttribute.Name === LOCATION_ID_ATTRIBUTE
                }).TemplateAttribute

                const singleChooserItemsForSiteMap = locationAttributeForSiteMap && locationAttributeForSiteMap.PossibleValues ? locationAttributeForSiteMap.PossibleValues.map(item => ({
                    value: item.Identifier,
                    text: item.Label,
                    selected: this.state.value === item.Identifier,
                    imageId: item.ImageId,
                    latitude: item.Latitude,
                    longitude: item.Longitude
                })) : [];
                return <LocationSelectorWithSiteMap initialLocation={this.state.value} onChange={this.handleElementChange} placeholder={configData.LocationText} items={singleChooserItemsForSiteMap}
                    onSelectionChanged={this.handleElementChange} hasError={isInvalid} elementGuid={element.Guid} disabled={isDisabled} isHierarchy={isHierarchy}
                />

            case CODED_ELEMENT_TYPES.MAP_COORDS:
                return <LocationMap initialLocation={this.state.value} onChange={this.handleElementChange} disabled={isDisabled}/>

            case CODED_ELEMENT_TYPES.LOCATION_SELECTOR:
            case CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR:
                if (isHierarchy) {
                    let selectableTypes;
                    if(element.ConfigData && element.ConfigData.LocationHierarchyNodeType) {
                        const locationHierarchyNodeType = element.ConfigData.LocationHierarchyNodeType.replaceAll('+', ' ');
                        selectableTypes = locationHierarchyNodeType.split(",").map(nt => decodeURIComponent(nt));// this is how the config values are separated
                    }

                    return <HierarchyLocationSelector
                        hasError={isInvalid}
                        initialLocation={this.state.value}
                        onChange={this.handleElementChange}
                        onHeightChange={this.scrollIntoView}
                        disabled={isDisabled}
                        hierarchyId={element.ConfigData.LocationHierarchy}
                        forAdmin={element.ConfigData.AllowAccessToLocationsUserDoesNotHaveAccessTo}
                        selectableTypes={selectableTypes}
                        startNodeId={element.ConfigData.LocationHierarchyLimitToGroupNodeId}/>
                } else {
                    const locationAttribute = fields && fields[0] && fields[0].TemplateAttribute;

                    const singleChooserItems = locationAttribute && locationAttribute.PossibleValues ? locationAttribute.PossibleValues.map(item => ({
                        value: item.Identifier,
                        text: item.Label,
                        selected: this.state.value === item.Identifier,
                    })) : [];
                    return <SingleChooser
                        placeholder={configData.LocationText}
                        items={singleChooserItems}
                        noResultsText={noResultsText}
                        onSelectionChanged={this.handleElementChange}
                        onOpen={this.scrollIntoView}
                        hasError={isInvalid}
                        showDropdownOnClick={true}
                        maxHeight={`${maxDropdownHeight}px`}
                        disabled={isDisabled}
                    />
                }

            default:
                return
        }
    }

    render() {
        const { visible, element, isMandatory, isInvalid, disabled } = this.props;

        return !!visible && (element.Type === ELEMENT_TYPES.DESIGNER ?
            element.Fields.map((field) => {
                return <Field key={field.TemplateAttribute.Name} elementGuid={element.Guid} field={field} disabled={disabled || element.Permission === PERMISSION_TYPES.READ_ONLY || visible === VISIBILITY_TYPES.READ_ONLY}/>
            })
            :
            <div ref={this.container}>
                {element.Title &&
                    <div className="ElementLabel">
                        {element.Title}
                        {isMandatory &&
                            <span className="ElementMandatoryIndicator">
                                {' * '}
                                {isInvalid && <Translation>{Strings.requiredField}</Translation>}
                            </span>
                        }
                    </div>}

                <div className="ElementContainer">
                    {this.getCodedElement(element)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        value: getElementAnswer(state, ownProps.element.Guid),
        visible: getElementVisibility(state, ownProps.element.Guid),
        noResultsText: state.appReducer.appStrings[Strings.noResults],
        isMandatory: getCurrentPageElementIsMandatory(ownProps.element.Name, state),
        isInvalid: getCurrentPageElementIsInvalid(ownProps.element.Guid, state),
        isFirstInvalid: getCurrentPageElementIsFirstInvalid(ownProps.element.Guid, state),
        defaultValue: getDefaultValue(state, { field: undefined, elementGuid: undefined, element: ownProps.element }),
        isUpdated: getElementIsUpdated(state, ownProps.element.Guid),
        isHierarchy: state.appReducer.isHierarchy,
        selectedFormTypeId: state.appReducer.selectedFormTypeId,
        contentDimensions: state.appReducer.contentDimensions,
        useLocationRestrictions: getUseFormLocationRestrictions(state),
        activeLocation: getActiveLocation(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateElementAnswer: (elementId, value) => {
        dispatch(updateElementAnswer(elementId, value));
    },
    reloadObjectPicklistValues: (locationId) => {
        dispatch(reloadObjectPicklistValues(locationId));
    },
    loadLocationAttributes: (locationId) => {
        dispatch(loadLocationAttributes(locationId));
    },
    reloadFormPermissions: (locationId, formId) => {
        dispatch(reloadFormPermissions(locationId, formId));
    },
    clearScrollToFirst: () => {
        dispatch(clearScrollToFirst());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Element);