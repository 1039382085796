export const profiles = [
    'Company.Support.Details',
    'Company.FirstReport.ReviewPageWatermark',
    'Company.FirstReport.AzureNameDetection',
    'Company.FirstReport.AllowMultipleSubmitSSO',
    'Company.FirstReport.LimitSimilarRecordSearchToUserToCurrentLocation',
    'Company.FirstReport.SimilarRecordsView',
    'Company.FirstReport.SimilarRecordSearch',
    'Company.Interface.FirstReportCustomLogoutPage',
    'Company.Interface.AutoTranslation',
    'Company.Interface.AutoTranslatePrimaryLanguage',
    'Company.Interface.CompanyPrimaryLanguage',
    'Company.Portal.LoginButtonLabel',
    'Company.Portal.SSOLoginButtonLabel'
]

export default {
    profiles
};