import React from 'react';
import './TabButtonBar.scss';

class TabButtonBar extends React.Component {
    constructor(props) {
        super(props);

        const { initialSelected } = this.props;

        this.state = {
            selected: initialSelected || 0
        };

        this.selectTab = this.selectTab.bind(this);
    }

    selectTab(selected) {
        const { onChange } = this.props;

        this.setState({ selected }, onChange && onChange(selected));
    }

    render() {
        const { buttons } = this.props;
        const { selected } = this.state;


        return (
            <div className="TabButtonBar">
                {
                    (buttons) &&
                    buttons.map((button, index) => {
                        const selectedClass = index === selected ? " TabButtonSelected" : "";
                        return <div key={index} className={"TabButton" + selectedClass} onClick={() => {this.selectTab(index)}}>{button.text}</div>
                    })
                }
            </div>
        );
    }
}

export default TabButtonBar;