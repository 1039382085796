import React from 'react';
import PropTypes from 'prop-types';
import style from './Chooser.module.css';
import SearchBar from './SearchBar/SearchBar';
import ChooseList from './ChooseList/ChooseList';
import ChosenItem from './ChosenItem/ChosenItem';
import _ from 'lodash';
import ChooseListTableView from '../ChooserListView/ChooseListTableView/ChooseListTableView';

class Chooser extends React.Component {
  static applySearch(items, searchText) {
    if (!searchText) {
      return [];
    }

    const searchLower = searchText.toLowerCase();
    return items
      .filter(i => i && i.text)
      .filter(i => i.text
        .toLowerCase()
        .includes(searchLower));
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedValues: [],
      searchText: '',
      showDropdown: false
    };

    this.container = React.createRef();
    this.searchInput = React.createRef();

    this.onSearchTextChange = this.onSearchTextChange.bind(this);
    this.onItemSelect = this.onItemSelect.bind(this);
    this.onItemRemove = this.onItemRemove.bind(this);
    this.notifySelection = this.notifySelection.bind(this);
    this.closeIfNeeded = this.closeIfNeeded.bind(this);
    this.focusSearchInput = this.focusSearchInput.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    const { liveSearchFn } = props;
    this.liveSearchDebounced = liveSearchFn ?
      _.debounce((text, Page = '') => liveSearchFn(text, Page), 1000) : null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      const selectedValues = this.props.items
          .filter(i => i.selected)
          .map(i => i.value);
      this.setState({selectedValues});
    }

    const { liveSearchFn } = this.props;
    if (liveSearchFn !== prevProps.liveSearchFn) {
      this.liveSearchDebounced = liveSearchFn ? 
        _.debounce((text, Page = '') => liveSearchFn(text, Page), 1000) : null;
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closeIfNeeded, false);
    document.addEventListener('focusin', this.closeIfNeeded, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeIfNeeded);
    document.addEventListener('focusin', this.closeIfNeeded);
  }

  onSearchTextChange(searchText) {
    const { onOpen } = this.props;
    this.setState({ searchText }, () => { onOpen && onOpen(); });

    if (searchText === '') {
      this.focusSearchInput();
    }
    this.liveSearchDebounced && this.liveSearchDebounced(searchText);
  }

  onInputClick(){
    const { onOpen } = this.props;
    this.setState({ showDropdown: true }, () => { onOpen && onOpen(); });
  }

  onItemSelect(selectedValue) {
    const { isSingleSelect } = this.props;
    const { searchText, selectedValues } = this.state;

    let updatedText = searchText.slice();
    if (isSingleSelect) {
      updatedText = '';
    }

    let updatedValues = [];
    if (selectedValues.includes(selectedValue)) {
      updatedValues = selectedValues.filter(v => v !== selectedValue);
    } else {
      updatedValues = selectedValues.concat([selectedValue]);
    }

    const dropdownState = isSingleSelect ? {showDropdown:false} : {}

    this.setState({ searchText: updatedText, selectedValues: updatedValues, ...dropdownState });

    this.notifySelection(updatedValues);
  }

  onItemRemove(removedValue) {
    const { selectedValues } = this.state;

    const updatedValues = selectedValues.filter(v => v !== removedValue);

    this.setState({ selectedValues: updatedValues }, () => {
      if (updatedValues.length === 0) {
        this.focusSearchInput();
      }
    });

    this.notifySelection(updatedValues);
  }

  notifySelection(values) {
    const { isSingleSelect, onSelectionChanged } = this.props;

    if (isSingleSelect) {
      onSelectionChanged(values.length > 0 ? values[0] : undefined);
    } else {
      onSelectionChanged(values);
    }
  }

  closeIfNeeded(e) {
    if (this.container.current &&
        !this.container.current.contains(e.target)) {
      this.setState ({ showDropdown:false});
    }
  }

  focusSearchInput() {
    if (this.searchInput.current) {
      this.searchInput.current.focus();
    }
  }

  render() {
    const {
      items, noResultsText, isSingleSelect, placeholder, hasError, showDropdownOnClick, maxHeight, disabled,
      liveSearchFn, fullLiveSearchData, liveSearchIsBeingProcessed, fieldId, foundValues, columnNames, chooserTitle
    } = this.props; 
    const { selectedValues, searchText, showDropdown } = this.state;

    const listItems = items
      .map(i => ({
        ...i,
        selected: selectedValues.includes(i.value),
      }))
      .sort((a, b) => a.text >= b.text);

    let selectedItems = listItems
      .filter(i => i.selected);

    if (isSingleSelect) {
      selectedItems = selectedItems.slice(0, 1);
    }

    let foundItems = [];

    if (showDropdownOnClick && showDropdown && searchText === "" || liveSearchFn) {
      foundItems = isSingleSelect ? listItems : listItems.filter(i => i.selected || foundValues.some(v => v.value === i.value));
    } else {
      foundItems = Chooser
      .applySearch(listItems, searchText);
    }

    return (
      <div
        className="ChooserContainer"
        onClick={this.focusSearchInput}
        role="presentation"
        ref={this.container}
      >
        {(isSingleSelect && selectedItems.length === 1) ? (
          <ChosenItem
            key={fieldId}
            label={selectedItems[0].text}
            onRemove={() => this.onItemRemove(selectedItems[0].value)}
            className={style.SingleSelectItem}
            disabled={disabled}
          />
        ) : (
          <SearchBar
            searchText={searchText}
            selectedItems={selectedItems}
            placeholder={placeholder}
            onSearchChange={this.onSearchTextChange}
            onInputClick={this.onInputClick}
            onItemRemove={this.onItemRemove}
            inputRef={this.searchInput}
            hasError={hasError}
            disabled={disabled}
            showLoadingSpinner={liveSearchIsBeingProcessed && (liveSearchIsBeingProcessed.fieldId === fieldId)}
          />
        )}
        {!(isSingleSelect && selectedItems.length) &&
        this.state.showDropdown &&
        (
          [
            columnNames && columnNames.length > 0 ?
            <ChooseListTableView
              fieldId={fieldId}
              key={fieldId + 'ChooseListTableView'}
              items={foundItems}
              noResultsText={noResultsText}
              onItemSelect={this.onItemSelect}
              maxHeight={maxHeight}
              columnNames={columnNames}
              chooserTitle={chooserTitle}
            />
:
          <ChooseList
            items={foundItems}
            noResultsText={noResultsText}
            onItemSelect={this.onItemSelect}
            maxHeight={maxHeight}
            fieldId={fieldId}
          />,
        (fullLiveSearchData &&
          fullLiveSearchData.CurrentPage &&
          (fullLiveSearchData.CurrentPage < fullLiveSearchData.TotalPages))
          && (
          <div className={style.loadMoreContainer}>
          <button className={style.linkButton}
          onClick={() => {this.liveSearchDebounced(searchText, fullLiveSearchData.CurrentPage + 1)}}>
          Load more
          </button>
          </div>
          )]
        )}

      </div>
    );
  }
}

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    selected: PropTypes.bool,
  })),
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  onOpen: PropTypes.func,
  hasError: PropTypes.bool,
  showDropdownOnClick: PropTypes.bool,
  maxHeight: PropTypes.string,
  disabled: PropTypes.bool,
  liveSearchFn: PropTypes.func,
  fullLiveSearchData: PropTypes.object,
  liveSearchIsBeingProcessed: PropTypes.bool,
  fieldId: PropTypes.string,
  foundValues: PropTypes.array
};

const defaultProps = {
  items: [],
  placeholder: '',
  noResultsText: undefined,
  onSelectionChanged: () => {},
  onOpen: () => {},
  hasError: false,
  showDropdownOnClick: false,
  maxHeight: undefined,
  disabled: false,
  fullLiveSearchData: null,
  liveSearchFn: null,
  liveSearchIsBeingProcessed: false,
  fieldId: false,
  foundValues: []
};

Chooser.propTypes = {
  ...propTypes,
  isSingleSelect: PropTypes.bool.isRequired,
};
Chooser.defaultProps = {
  ...defaultProps,
};

const SingleChooser = ({
  items, placeholder, noResultsText, onSelectionChanged, hasError, showDropdownOnClick, maxHeight, disabled, onOpen,
  liveSearchFn, fullLiveSearchData, liveSearchIsBeingProcessed, fieldId, columnNames, chooserTitle
}) => (
  <Chooser
    key={fieldId}
    items={items}
    placeholder={placeholder}
    noResultsText={noResultsText}
    onSelectionChanged={onSelectionChanged}
    onOpen={onOpen}
    isSingleSelect
    hasError={hasError}
    showDropdownOnClick={showDropdownOnClick}
    maxHeight={maxHeight}
    disabled={disabled}
    liveSearchFn={liveSearchFn}
    liveSearchIsBeingProcessed={liveSearchIsBeingProcessed}
    fullLiveSearchData={fullLiveSearchData}
    fieldId={fieldId}
    columnNames={columnNames}
    chooserTitle={chooserTitle}
  />
);
SingleChooser.propTypes = { ...propTypes };
SingleChooser.defaultProps = { ...defaultProps };

const MultiChooser = ({
  items, placeholder, noResultsText, onSelectionChanged, hasError, showDropdownOnClick, maxHeight, disabled, onOpen,
  liveSearchFn, fullLiveSearchData, fieldId, liveSearchIsBeingProcessed, foundValues, columnNames, chooserTitle
}) => (
  <Chooser
    items={items}
    placeholder={placeholder}
    noResultsText={noResultsText}
    onSelectionChanged={onSelectionChanged}
    onOpen={onOpen}
    isSingleSelect={false}
    hasError={hasError}
    showDropdownOnClick={showDropdownOnClick}
    maxHeight={maxHeight}
    disabled={disabled}
    liveSearchFn={liveSearchFn}
    fullLiveSearchData={fullLiveSearchData}
    liveSearchIsBeingProcessed={liveSearchIsBeingProcessed}
    fieldId={fieldId}
    foundValues={foundValues}
    columnNames={columnNames}
    chooserTitle={chooserTitle}
  />
);
MultiChooser.propTypes = { ...propTypes };
MultiChooser.defaultProps = { ...defaultProps };

export { SingleChooser, MultiChooser };
