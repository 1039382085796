import React from 'react';
import style from './ReviewPageItem.module.scss';
import Strings from '../../../constants/Strings';
import Translation from '../../Translation/Translation';
import { ArrowDropdownDark } from '../../../icons'
import classNames from 'classnames';

const ReviewPageItem = ({ title, onEditClick, summary, collapsed, onCollapse }) => {

    return (
        <div>
            <div className={style.ReviewPageItemHeader}>
                <div className={style.ReviewPageCollapseDiv} onClick={onCollapse}>
                    <span className={style.ReviewPageCollapseIconSpan}>
                        <img src={ArrowDropdownDark} alt="Back"
                             className={collapsed ? classNames([style.ReviewPageCollapseIcon, style.ReviewPageCollapseIconCollapsed])
                             : style.ReviewPageCollapseIcon}/>
                    </span>
                    <div className={style.ReviewPageItemTitle}>{title ? title : <Translation>{Strings.untitledPage}</Translation>}</div>
                </div>
                {
                    onEditClick &&
                    <div className={classNames([style.ReviewPageItemEdit, style.noPrint])} onClick={onEditClick}>
                        <Translation>{Strings.edit}</Translation>
                    </div>
                }
            </div>
            <div className={collapsed ? classNames([style.ReviewPageItemContent, style.ReviewPageItemContentCollapsed])
                : style.ReviewPageItemContent}>
                {summary}
            </div>
        </div>
    );
}

export default ReviewPageItem;
