import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import { ELEMENT_TYPES } from '../../../constants/ElementTypes';

import ReviewPageAnswerFilters from '../../../constants/ReviewPageAnswerFilters';
import Translation from '../../Translation/Translation';
import { formatFieldAnswer, formatElementAnswer, getSubPageObjectAnswer } from '../../../utils/formatAnswer';
import SliderInput from '../../../components/Field/SliderInput/SliderInput';
import { THIRD_PARTIES_NAME_ATTRIBUTE } from '../../../constants/KnownAttributes';
import ReviewPageItem from '../ReviewPageItem/ReviewPageItem';
import { FIELD_TYPES } from '../../../constants/FieldTypes';
import { v4 } from 'uuid';
import { ArrowDropdownBlue, CheckBlueSmall } from '../../../icons';
import { isValidAnswer, isInvalidMultiLevelAnswer } from '../../../utils/convertAnswer';

import Strings from '../../../constants/Strings';
import style from './ReviewPageData.module.scss';
import { getPossibleValues } from '../../../selectors/wizardSelectors';

const { useState, useRef, useEffect } = React;

const ReviewView = (props) => {
    const [selectedFilter, setSelectedFilter] = useState(ReviewPageAnswerFilters.ALL_QUESTIONS);

    const allPagesCollapsed = [{collapsed: false}, //previewPage
        ...props.pages.map(() => ({ collapsed: false })),
        {collapsed: false} // email recipients page option
    ];
    const recipientsPageIndex = allPagesCollapsed.length - 1;

    const [collapseProperties, setCollapseProperties] = useState({
        pages: allPagesCollapsed,
        isAllCollapsed: false
    });
    const [isOpenQuestionFilter, setisOpenQuestionFilter] = useState(false);
    const questionFilterDropdownContainer = useRef(null);

    const toggleOpen = () => {
        setisOpenQuestionFilter(!isOpenQuestionFilter);
    }

    useEffect(() => {
        document.addEventListener('focusin', closeIfNeeded);
        document.addEventListener('mousedown', closeIfNeeded);

        return () => {
            document.removeEventListener('focusin', closeIfNeeded);
            document.removeEventListener('mousedown', closeIfNeeded);
        };
    }, [])

    const selectFilter = (selected) => {
        setSelectedFilter(selected)
        toggleOpen();
    }

    const closeIfNeeded = (e) => {
        if (questionFilterDropdownContainer && questionFilterDropdownContainer.current && !questionFilterDropdownContainer.current.contains(e.target)) {
            setisOpenQuestionFilter(false);
        }
    }

    const getFilterText = () => {
        switch (selectedFilter) {
            case ReviewPageAnswerFilters.ALL_QUESTIONS:
                return (Strings.allQuestions);
            case ReviewPageAnswerFilters.COMPLETE_QUESTIONS:
                return (Strings.completeQuestions);
            case ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS:
                return (Strings.incompleteQuestions);
            default:
                return ("");
        }
    }

    const handleCollapse = (index) => {
        collapseProperties.pages[index].collapsed = !collapseProperties.pages[index].collapsed;

        let changeAllCollapsed = true;
        collapseProperties.pages.forEach((item) => {
            changeAllCollapsed = changeAllCollapsed && item.collapsed === collapseProperties.pages[index].collapsed;
        });

        setCollapseProperties({
            isAllCollapsed: changeAllCollapsed ? collapseProperties.pages[index].collapsed : collapseProperties.isAllCollapsed,
            pages: collapseProperties.pages
        });
    };

    const handleCollapseAll = () => {
        collapseProperties.pages.forEach((item) => {
            item.collapsed = !collapseProperties.isAllCollapsed;
        });
        setCollapseProperties({
            pages: collapseProperties.pages,
            isAllCollapsed: !collapseProperties.isAllCollapsed
        });
    };

    const getPageSummary = (page, index) => {
        if (page.Guid === 'USER_PAGE') {
            return props.getAboutUserPageSummary();
        } else if (page.WizardSubForms && page.WizardSubForms.length > 0) {
            return getObjectAddPageSummary(page, index);
        } else {
            return getElementListPageSummary(page);
        }
    };

    const getObjectAddPageSummary = (page) => {
        const { subPageObjects } = props.answers;
        const elementGuid = page.Elements && page.Elements[0] && page.Elements[0].Guid;
        const objects = subPageObjects[elementGuid];
        const subForms = page.WizardSubForms;
        const primaryField = THIRD_PARTIES_NAME_ATTRIBUTE;
        const secondaryField = page.WizardSubFormSummaryConfig[0];

        return _.isArray(objects) ?
            (
                objects.filter(o => !o.deleted).map(object => {
                    const primaryFieldAnswer = getSubPageObjectAnswer(subForms, object, primaryField);
                    const secondaryFieldAnswer = getSubPageObjectAnswer(subForms, object, secondaryField);
                    return <div className={style.ReviewPageFieldSummary} key={v4()}>
                        {
                            `${primaryFieldAnswer} ${secondaryFieldAnswer ? `(${secondaryFieldAnswer})` : ''}`
                        }
                    </div>
                })
            )
            :
            ''
    }

    const getSliderView = (field, value) => {
        const fieldProperties = _.isArray(field.Properties) ? field.Properties.reduce(function (properties, cur) {
            properties[cur.Name] = cur.Data;
            return properties;
        }, {}) : {};
        return <SliderInput
            initialValue={value}
            disabled={true}
            minValue={fieldProperties.minimum}
            maxValue={fieldProperties.maximum}
            step={fieldProperties.step}
            showArrows={false}
        />;
    }

    const getRowCount = (page) => {
        const { answers, elementIsVisible } = props;
        return page.Elements ? page.Elements.filter(element => elementIsVisible(element.Guid)).reduce((elementCount, element) => {
            if (element.Type === ELEMENT_TYPES.DESIGNER) {
                return element.Fields.reduce((fieldCount, field) => {
                    const answer = answers.fields[field.TemplateAttribute.Name];
                    return answer ? fieldCount + 1 : fieldCount;
                }, 0)
            } else {
                const answer = answers.elements[element.Guid];
                return answer ? elementCount + 1 : elementCount;
            }
        }, 0) : 0;
    }

    const getElementListPageSummary = (page) => {
        const { answers, elementIsVisible, isHierarchy, language, getPossibleValues } = props;
        /* IE doesn't allow grid row repeat(), count the rows and set style explicitly */
        const rowCount = getRowCount(page);
        const msGridRows = rowCount > 0 ? 'auto 4px '.repeat(rowCount) : 'auto';
        let rowCounter = -1;

        return (<div className={style.ReviewPageFieldSummary} style={{ msGridRows: msGridRows }}>{
            page.Elements ? page.Elements.filter(element => elementIsVisible(element.Guid)).map(element => {
                if (element.Type === ELEMENT_TYPES.DESIGNER) {
                    return element.Fields.map(field => {
                        let answer = answers.fields[field.TemplateAttribute.Name];
                        const answerResult = [];
                        if (!answer) {
                            const multiValues =
                                answers.fields
                                &&
                                Object
                                    .keys(answers.fields)
                                    .filter((key) => {
                                        return key.startsWith(field.TemplateAttribute.Name);
                                    });
                            const getFieldWithLang = (lang) => field.TemplateAttribute.Name + `.${lang}`
                            if (multiValues && multiValues.length) {
                                if (answers.fields[getFieldWithLang(language)]) {
                                    answer = answers.fields[getFieldWithLang(language)];
                                    answerResult.push({
                                        text: answer,
                                        title: field.Title
                                    });
                                }
                                if (!answer) {
                                    const languagesOfField = field.Properties.find((val) => val.Name === 'languages');
                                    const languagesArray = (
                                        languagesOfField &&
                                        languagesOfField.Data &&
                                        languagesOfField.Data.split(',')) || [];
                                    for (let lang of languagesArray) {
                                        if (answers.fields[getFieldWithLang(lang)]) {
                                            if (props.showAllMultilingualFields){
                                                answerResult.push({
                                                    text: answers.fields[getFieldWithLang(lang)],
                                                    title: `${field.Title} (${lang})`
                                                });
                                             }
                                             else {
                                                answerResult.push({
                                                    text: answers.fields[getFieldWithLang(lang)],
                                                    title: field.Title
                                                });
                                                break;
                                             }
                                        }
                                    }
                                }
                            }
                        } else {
                            answerResult.push({text: answer, title: field.Title});
                        }

                        let isFieldAnswersValid, fieldAnswers;

                        if (answerResult.length > 0) {
                            const possibleValues = getPossibleValues(field);
                            isFieldAnswersValid = field.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL || field.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL
                            ? answerResult.map((answ) => !isInvalidMultiLevelAnswer(answ.text, field, possibleValues))
                            : answerResult.map((answ) => isValidAnswer(answ.text));

                            fieldAnswers = answerResult.map((answ, index) => ({ text: isFieldAnswersValid[index]
                                                                                ? formatFieldAnswer(answ.text, field,
                                                                                    props.selectedLanguage, props.liveSearchOptions,
                                                                                    props.resolveLabelBegin,
                                                                                    props.resolvedLabels,
                                                                                    props.objectPicklistValues &&
                                                                                    props.objectPicklistValues[field.TemplateAttribute.Name],
                                                                                    possibleValues)
                                                                                : "-",
                                                                            title: answ.title}));
                        }
                        else {
                             isFieldAnswersValid = field.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL || field.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL
                            ? [!isInvalidMultiLevelAnswer(answer, field, getPossibleValues(field))]
                            : [isValidAnswer(answer)];

                            fieldAnswers = [{ text: isFieldAnswersValid[0] ? formatFieldAnswer(answer, field, props.selectedLanguage,props.liveSearchOptions,
                                                                                props.resolveLabelBegin,
                                                                                props.resolvedLabels,
                                                                                props.objectPicklistValues &&
                                                                                props.objectPicklistValues[field.TemplateAttribute.Name])
                                                                            : "-",
                                                title: field.Title}];
                        }

                        let showAnswer = true;

                       const isAnyAnswerValid = _.includes(isFieldAnswersValid, true);
                        if ((selectedFilter === ReviewPageAnswerFilters.COMPLETE_QUESTIONS && !isAnyAnswerValid) || (selectedFilter === ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS
                                && isAnyAnswerValid)) {
                            showAnswer = false;
                        } else {
                            rowCounter = rowCounter + 2
                        }

                        return showAnswer && (
                            <React.Fragment key={field.TemplateAttribute.Name}>
                                {
                                    fieldAnswers.map((answ, index) => renderAnswer(answ, field, rowCounter + index, isAnyAnswerValid))
                                }
                            </React.Fragment>
                        );
                    })
                } else {
                    const answer = answers.elements[element.Guid];
                    const isElementAnswerValid = isValidAnswer(answer)
                    const elementAnswer = isElementAnswerValid ? formatElementAnswer(answer, element, isHierarchy) : "-";

                    let showElementAnswer = true;

                    if ((selectedFilter === ReviewPageAnswerFilters.COMPLETE_QUESTIONS && !isElementAnswerValid) || (selectedFilter === ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS && isElementAnswerValid)) {
                        showElementAnswer = false;
                    } else {
                        rowCounter = rowCounter + 2;
                    }

                    return (showElementAnswer &&
                        <React.Fragment key={element.Guid}>
                            <div className={style.ReviewPageSummaryItemTitle} style={{ msGridRow: rowCounter }}>{element.Title}</div>
                            <div className={isElementAnswerValid ? style.ReviewPageSummaryItemLabel : style.ReviewPageSummaryItemLabelNoAnswer}
                                style={{ msGridRow: rowCounter }}>{elementAnswer}</div>
                        </React.Fragment>
                    );

                }
            }) : ''}
        </div>
        );
    }

    const renderAnswer = (answer, field, rowCounter, isAnyAnswerValid) => {

       return <> <div className={style.ReviewPageSummaryItemTitle} style={{ msGridRow: rowCounter }}>
            {answer.title}
            </div>
            <div className={isAnyAnswerValid ? style.ReviewPageSummaryItemLabel : style.ReviewPageSummaryItemLabelNoAnswer}
                style={{ msGridRow: rowCounter }}>{
                    field.RenderingType === FIELD_TYPES.SLIDER ? getSliderView(field, answer.text) : answer.text
                }</div>
        </>
    };

    return <><div className={style.ReviewPageOptionContainer}>
        <div className={style.noPrint}>
            <div className={style.ReviewPageQuestionFilterButton} onClick={toggleOpen}>
                <div className={style.ReviewPageQuestionFilterSelectedOption}>
                    <Translation>{getFilterText()}</Translation>
                </div>
                <div className={style.ReviewPageQuestionFilterCarat}>
                    <img src={ArrowDropdownBlue} alt="Language" />
                </div>
            </div>
        </div>
        {
            isOpenQuestionFilter && (
                <div className={classNames([style.ReviewPageQuestionFilterDropdown, style.noPrint])} ref={questionFilterDropdownContainer}>
                    <div className={style.ReviewPageQuestionFilterDropdownRow} onClick={() => selectFilter(ReviewPageAnswerFilters.ALL_QUESTIONS)}>
                        <div className={style.ReviewPageQuestionFilterDropdownOption}>
                            <div className={style.ReviewPageQuestionFilterDropdownOptionImg}>
                                {
                                    selectedFilter === ReviewPageAnswerFilters.ALL_QUESTIONS &&
                                    <img src={CheckBlueSmall} alt="All" />
                                }
                            </div>
                            <span><Translation>{Strings.allQuestions}</Translation></span>
                        </div>
                    </div>
                    <div className={style.ReviewPageQuestionFilterDropdownRow} onClick={() => selectFilter(ReviewPageAnswerFilters.COMPLETE_QUESTIONS)}>
                        <div className={style.ReviewPageQuestionFilterDropdownOption}>
                            <div className={style.ReviewPageQuestionFilterDropdownOptionImg}>
                                {
                                    selectedFilter === ReviewPageAnswerFilters.COMPLETE_QUESTIONS &&
                                    <img src={CheckBlueSmall} alt="Complete" />
                                }
                            </div>
                            <span><Translation>{Strings.completeQuestions}</Translation></span>
                        </div>
                    </div>
                    <div className={style.ReviewPageQuestionFilterDropdownRow} onClick={() => selectFilter(ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS)}>
                        <div className={style.ReviewPageQuestionFilterDropdownOption}>
                            <div className={style.ReviewPageQuestionFilterDropdownOptionImg}>
                                {
                                    selectedFilter === ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS &&
                                    <img src={CheckBlueSmall} alt="Incomplete" />
                                }
                            </div>
                            <span><Translation>{Strings.incompleteQuestions}</Translation></span>
                        </div>
                    </div>
                </div>
            )
        }

        <div className={classNames([style.ReviewPageExpandCollapseText, style.noPrint])} onClick={handleCollapseAll}>
            <Translation>{collapseProperties.isAllCollapsed ? Strings.expandAll : Strings.collapseAll}</Translation>
        </div>
    </div>
        <div className={style.ReviewPageItems}>
            {
                selectedFilter !== ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS &&
                <ReviewPageItem
                    title={props.startPage.MenuLabel}
                    collapsed={collapseProperties.pages[0].collapsed}
                    onCollapse={() => handleCollapse(0)}
                    summary={<div>{props.startPageDescription}</div>} />
            }
            {
                props.pages.filter(page => {
                    if (!page.visible || page.Guid === 'REVIEW_PAGE') {
                        return false;
                    }
                    switch (selectedFilter) {
                        case ReviewPageAnswerFilters.INCOMPLETE_QUESTIONS:
                            return page.Guid !== 'USER_PAGE' && props.pageAnswerCount(page) < props.pageQuestionCount(page);
                        case ReviewPageAnswerFilters.COMPLETE_QUESTIONS:
                            return props.pageAnswerCount(page) > 0;
                        default:
                            return true;
                    }
                }).map((page) => {
                    const index = props.pages.findIndex(p => p.Guid === page.Guid);
                    const revPageProps = {
                        key: index,
                        title: page.MenuLabel,
                        collapsed: collapseProperties.pages[index + 1].collapsed,
                        onCollapse: () => { handleCollapse(index + 1) },
                        summary: getPageSummary(page, index),
                        onEditClick: props.onEditPage ? () => { props.onEditPage(index) } : undefined
                    }
                    return index > -1 ? <ReviewPageItem {...revPageProps} /> : ''
                }
                )
            }
            {
                props.recipients && props.recipients.label && props.recipients.data &&
                    <ReviewPageItem key="recip" title={props.recipients.label}
                        summary={props.recipients.data}
                        collapsed={collapseProperties.pages[recipientsPageIndex].collapsed}
                        onCollapse={() => handleCollapse(recipientsPageIndex)} />
            }
        </div>
    </>
};

const mapStateToProps = (state) => {
    return {
        getPossibleValues: (field) => getPossibleValues(state, field)
    };
};

export default connect(mapStateToProps)(ReviewView);