import React, { Component } from 'react';
import { connect } from 'react-redux';

class Translation extends Component {
    static applyReplace(replace, string) {
        if (!replace || !string) {
            return string;
        }

        let replacement = string.slice();
        Object.keys(replace).forEach(key => {
            replacement = replacement.replace(`<${key}>`, replace[key])
        });

        return replacement;
    }

    render() {
        const { appStrings, replace, containsHtml, children } = this.props;

        const content = Translation.applyReplace(replace, (appStrings[children] || children));

        if (!content) {
            return null;
        }

        return containsHtml
            ? <span dangerouslySetInnerHTML={{__html: content}}></span>
            : content;
    }
}

const mapStateToProps = (state) => {
    return {
        appStrings: state.appReducer.appStrings
    };
};

export default connect(mapStateToProps)(Translation);