import React from 'react';
import style from './WizardPageElementList.module.scss';
import Element from '../../../components/Element/Element';
import Strings from '../../../constants/Strings';
import Translation from '../../Translation/Translation';
import { PERMISSION_TYPES } from '../../../constants/PermissionTypes';
import { VISIBILITY_TYPES } from '../../../constants/VisibilityTypes';
import { Modal } from 'spheracloud-common';

const WizardPageElementList = ({ page, includesMandatoryFields, nameDetectionActive, namesDetected, moveToPage, clearNERWarning }) => {

    return (
        <>
            <div className="WizardPageTitle">{page.ScreenTitle}</div>
            <div className="WizardPageHelpText">{page.HelpText}</div>
            {
                includesMandatoryFields && (
                    <div className={style.WizardPageRequiredFieldTextContainer}>
                        <span className={style.WizardPageRequiredFieldTextIndicator}>* </span>
                        <span className={style.WizardPageRequiredFieldText}>
                            <Translation>{Strings.indicatesRequiredField}</Translation>
                        </span>
                    </div>
                )
            }
            <div className="WizardPageElements">
                {

                    nameDetectionActive && namesDetected && namesDetected.length > 0 && <Modal
                        title={<Translation>{Strings.warning}</Translation>}
                        apply={Strings.continue}
                        cancel={Strings.edit}
                        onApply={() => { clearNERWarning(); moveToPage(undefined) }}
                        onCancel={() => { clearNERWarning() }}
                        className=""
                    >
                        <div className={'WizardPageFoundEntitiesContainer'}>

                    <div className={'WizardPageFoundEntities'}><Translation>{Strings.namesDetected}:</Translation></div><br/>
                            {namesDetected && namesDetected.length > 0 && namesDetected.map(names => {
                                return (
                                    <>
                                        <div className={'WizardPageFoundEntitesTitle'}>{names.name}</div>
                                        <div>{ names.entities.map(name => {return (<div>{name.Text}</div>)}) }</div>

                                    </>
                                )
                            })}

                        </div>
                    </Modal>
                }

                {
                    page.Elements.map(element => {
                        return <Element key={element.Guid} element={element} disabled={page.Permission === PERMISSION_TYPES.READ_ONLY || page.visible === VISIBILITY_TYPES.READ_ONLY} />
                    })
                }
            </div>
        </>
    );
}

export default WizardPageElementList;
