import React, { Component } from 'react';
import './App.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'spheracloud-common';
import {goToPage, setUserType, translationBegin, updateFieldSetting} from '../../actions/wizardActions';
import Wizard from '../Wizard/Wizard';
import StartPage from '../StartPage/StartPage';
import SubmitPage from '../SubmitPage/SubmitPage';
import {
    setFormGroup,
    setAnonGuid,
    cancelForm,
    hideCancelForm,
    hideLoggedOutForm,
    hideThisLanguageValueForm,
    clearLocationRestrictionUserError,
} from '../../actions/appActions';
import { setAuthType, verifyUserAndLoadData } from '../../actions/authActions';
import SubmitDraftPage from '../SubmitDraftPage/SubmitDraftPage';
import { getAppSettingsFromState, getString } from '../../selectors/appSelectors';
import Strings from '../../constants/Strings';
import ContinueDraft from '../ContinueDraft/ContinueDraft';
import Translation from '../Translation/Translation';
import { USER_TYPES } from "../../constants/UserTypes";

class App extends Component {

    componentDidMount() {

        const { isLoaded, verifyUserAndLoadData, setFormGroup, setAnonGuid, match } = this.props;

        if (match.params.formGroup && match.params.anonGuid) {
            setAnonGuid(match.params.anonGuid);
            setFormGroup(match.params.formGroup);
        }

        if (!isLoaded) {
            verifyUserAndLoadData();
        }
    }

    render() {
        const {
            isLoaded, selectedFormTypeId, isSubmitting, isSubmitted,
            submitError, showAuthErrorModal, authErr,
            isDraftSubmitted, isDraftSubmitting, submitDraftError,
            showContinueDraft, showCancelForm, showLoggedOutForm, hideCancelForm, cancelForm,
            okLabel, cancelLabel, closeLabel, genericAuthErrorLabel, match, hideLoggedOutForm, goToPage,
            loginAgain, logOut, user, allowAnonReporting, selectedPage, thisLanguageValue, hideThisLanguageValueForm,
            showThisLanguageValueForm, translationBegin, updateFieldSetting, appSettings, showLocationRestrictionUserError,
            clearLocationRestrictionUserError, history, setAuthType, errorLabel,
        } = this.props;


        if (!allowAnonReporting && !user) {
            history.push(`/${match.params.anonGuid}/${match.params.formGroup}/login`);
        }

        if (!match.params.formGroup || !match.params.anonGuid) {
            return <div>
                <h3>First Report</h3>
                <span>Please contact support for your application URL.</span>
            </div>
        } else {
            return <>
                {
                    showAuthErrorModal &&
                    <Modal
                        onApply={cancelForm}
                        onCancel={cancelForm}
                        apply={okLabel}
                        cancel={cancelLabel}
                    >
                        <span>{authErr || genericAuthErrorLabel}</span>
                    </Modal>
                }
                {
                    showLocationRestrictionUserError &&
                    <Modal
                        title={errorLabel}
                        onApply={() => {
                            setAuthType(USER_TYPES.ANONYMOUS, history);
                        }}
                        onCancel={() => {
                            clearLocationRestrictionUserError();
                            cancelForm();
                        }}
                        apply={Strings.logout}
                        cancel={Strings.goBack}
                    >
                        <Translation>{Strings.locationRestrictionUserError}</Translation>
                    </Modal>
                }
                {
                    showCancelForm &&
                    <Modal
                        title={cancelLabel}
                        onApply={cancelForm}
                        onCancel={hideCancelForm}
                        apply={okLabel}
                        cancel={cancelLabel}
                    >
                        <Translation>{Strings.cancelConfirmation}</Translation>
                    </Modal>
                }
                {
                    showLoggedOutForm &&
                    <Modal
                        title={logOut}
                        onApply={() => {
                            goToPage(0);
                            hideLoggedOutForm();
                        }}
                        onCancel={() => {
                            if (allowAnonReporting) {
                                setUserType(USER_TYPES.ANONYMOUS);
                                hideLoggedOutForm();
                            } else {
                                goToPage(0);
                                setUserType(appSettings.AUTHENTICATED_USER_SETTINGS.ISB2C === 'true' ? USER_TYPES.B2CAUTHENTICATED : USER_TYPES.AUTHENTICATED)
                                hideLoggedOutForm();
                            }
                        }}
                        apply={selectedPage ? loginAgain : closeLabel}
                        cancel={selectedPage ? closeLabel : allowAnonReporting ? closeLabel : null}
                    >
                        <Translation>{Strings.youLoggedOut}</Translation>
                    </Modal>
                }
                {
                    showThisLanguageValueForm &&
                    <Modal
                        title={thisLanguageValue}
                        onApply={() => {
                            const {name, text, fromLanguage, toLanguage} = showThisLanguageValueForm;
                            translationBegin(
                                name,
                                text,
                                fromLanguage,
                                toLanguage);
                            updateFieldSetting(name,
                                'languageIsoCode',
                                toLanguage);
                            hideThisLanguageValueForm();
                        }}
                        onCancel={() => hideThisLanguageValueForm()}
                        apply={okLabel}
                        cancel={closeLabel}
                    >
                        <Translation>{Strings.thisLanguageValueExists}</Translation>
                    </Modal>
                }
                {
                    showContinueDraft &&
                    <ContinueDraft />
                }
                {
                    (isDraftSubmitted || isDraftSubmitting || submitDraftError) &&
                    <SubmitDraftPage />
                }
                {
                    (isSubmitted || isSubmitting || submitError) &&
                    <SubmitPage />
                }
                {
                    isLoaded && selectedFormTypeId > -1 ? <Wizard /> : <StartPage />
                }
            </>
        }
    }
}

const mapStateToProps = (state) => {
    const { appReducer, wizardReducer } = state;
    return {
        selectedFormTypeId: appReducer.selectedFormTypeId,
        isLoaded: appReducer.isLoaded,
        isDraftSubmitting: appReducer.isDraftSubmitting,
        isDraftSubmitted: appReducer.isDraftSubmitted,
        submitDraftError: appReducer.errors.submitDraft,
        isSubmitting: appReducer.isSubmitting,
        isSubmitted: appReducer.isSubmitted,
        submitError: appReducer.errors.submitForm,
        authErr: appReducer.authErr,
        showAuthErrorModal: appReducer.showAuthErrorModal,
        showContinueDraft: appReducer.showContinueDraft,
        showCancelForm: appReducer.showCancelForm,
        showLoggedOutForm: appReducer.showLoggedOutForm,
        okLabel: getString(Strings.ok, state),
        showTranslationExistsForm: getString(Strings.showTranslationExistsForm, state),
        showThisLanguageValueForm: appReducer.showThisLanguageValueForm,
        thisLanguageValue: getString(Strings.thisLanguageValue, state),
        loginAgain: getString(Strings.loginAgain, state),
        logOut: getString(Strings.logout, state),
        cancelLabel: getString(Strings.cancel, state),
        closeLabel: getString(Strings.close, state),
        genericAuthErrorLabel: getString(Strings.genericAuthError, state),
        userTypeSelected: state.wizardReducer.userTypeSelected,
        user: state.auth.user,
        allowAnonReporting: state.appReducer.allowAnonReporting,
        selectedPage: wizardReducer.selectedPage,
        appSettings: getAppSettingsFromState(state),
        showLocationRestrictionUserError: state.appReducer.showLocationRestrictionUserError,
        errorLabel: getString(Strings.error, state),
    }
}

const mapDispatchToProps = dispatch => ({
    verifyUserAndLoadData: () => { dispatch(verifyUserAndLoadData()) },
    setFormGroup: (formGroup) => { dispatch(setFormGroup(formGroup)) },
    setAnonGuid: (guid) => { dispatch(setAnonGuid(guid)) },
    setUserType: (userType) => { dispatch(setUserType(userType)) },
    hideCancelForm: () => dispatch(hideCancelForm()),
    hideLoggedOutForm: () => dispatch(hideLoggedOutForm()),
    hideThisLanguageValueForm: () => dispatch(hideThisLanguageValueForm()),
    cancelForm: () => dispatch(cancelForm()),
    goToPage: (page) => dispatch(goToPage(page)),
    translationBegin: (name, text, from, to) => dispatch(translationBegin(name, text, from, to)),
    updateFieldSetting: (name, code, to) => dispatch(updateFieldSetting(name, code, to)),
    clearLocationRestrictionUserError: () => dispatch(clearLocationRestrictionUserError()),
    setAuthType: (type, history) => dispatch(setAuthType(type, history)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
