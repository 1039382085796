import { ELEMENT_TYPES, CODED_ELEMENT_TYPES } from '../constants/ElementTypes';
import { LOGICAL_OPERATOR } from '../constants/FormActions/LogicalOperator';
import { FIELD_OPERATOR } from '../constants/FormActions/FieldOperator';
import { FORM_ACTION_TYPE } from '../constants/FormActions/FormActionType';
import { getIsoCodes } from '../constants/LanguageFlags';
import {getString, getElements, getFormUser, getFormField, getProfileValue} from './appSelectors';
import Strings from '../constants/Strings';
import { VALIDATION_TYPES, USER_PAGE_VALIDATION_TYPES } from '../constants/ValidationTypes';
import { DEFAULT_VALUE_TYPES, DEFAULT_VALUE_STATIC_TYPES, DEFAULT_VALUE_SOURCE_TYPES} from '../constants/DefaultValueTypes';
import { USER_TYPES } from '../constants/UserTypes';
import { PAGE_STATUS_TYPES } from '../constants/PageStatusTypes';
import { convertElementAnswerToAttributes } from '../utils/convertElement';
import { getEmptyAnswer, isValidAnswer, isInvalidMultiLevelAnswer, convertFromDbDate, convertFromDbTime , convertToDbValue} from '../utils/convertAnswer';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { FIELD_TYPES } from '../constants/FieldTypes';
import { VISIBILITY_TYPES } from '../constants/VisibilityTypes';
import { createSelector } from 'reselect'

export const getSelectedFormWizardPages = createSelector(
    state => state,
    (state) => {
        const form = state.appReducer.formTypes && state.appReducer.formTypes.find(type => {
            return type.Id === state.appReducer.selectedFormTypeId;
        });
        const wizardPages = form ? form.WizardPages : [];
        return [
        ...(state.appReducer.allowAnonReporting ? [getUserPage(state)] : []),
        ...wizardPages,
        getReviewPage(state)
    ].map(page => ({
        ...page,
        visible: getPageOrElementVisibility(state, null, page.Guid)
    }));
});

export const getStartPage = state => ({
    Guid: 'START_PAGE',
    MenuLabel: getString(Strings.startPageMenuLabel, state),
})

export const getUserPage = state => ({
    Guid: 'USER_PAGE',
    MenuLabel: getString(Strings.userPageMenuLabel, state),
    ScreenTitle: getString(Strings.userPageScreenTitle, state),
    HelpText: getString(Strings.userPageHelpText, state),
})

export const getReviewPage = state => ({
    Guid: 'REVIEW_PAGE',
    MenuLabel: getString(Strings.reviewPageScreenTitle, state),
    ScreenTitle: getString(Strings.reviewPageScreenTitle, state),
    HelpText: getString(Strings.reviewPageHelpText, state),
})

export const getSelectedFormVisibleWizardPages = state => {
    const allPages = getSelectedFormWizardPages(state);

    return allPages.filter(page => {
        return page.visible;
    });
}

export const getPreviousPageIndex = state => {
    const pages = getSelectedFormWizardPages(state);
    let prevPageIndex = state.wizardReducer.selectedPage - 1;

    while (prevPageIndex > 0 && !pages[prevPageIndex].visible) {
        prevPageIndex--;
    }

    return prevPageIndex;
}

export const getPreviousPage = state => {
    const prevPageIndex = getPreviousPageIndex(state);
    const prevPage = getSelectedFormWizardPages(state)[prevPageIndex];

    return prevPage ? prevPage : getStartPage(state);
}

export const getPreviousPageTitle = state => {
    return getPreviousPage(state).MenuLabel;
}

export const getNextPageIndex = state => {
    const pages = getSelectedFormWizardPages(state);
    let nextPageIndex = state.wizardReducer.selectedPage + 1;

    while (nextPageIndex < pages.length - 1 && !pages[nextPageIndex].visible) {
        nextPageIndex++;
    }

    return nextPageIndex;
}

export const getNextPage = state => {
    const nextPageIndex = getNextPageIndex(state);
    const nextPage = getSelectedFormWizardPages(state)[nextPageIndex];

    return nextPage ? nextPage : getReviewPage(state);
}

export const getPreviousSubPageTitle = state => {
    const selectedSubPage = state.wizardReducer.selectedSubPage;
    return selectedSubPage > 0 ? getCurrentSubFormPages(state)[selectedSubPage - 1].menuTitle : '';
}

export const getIsReviewPage = state => {
    return getCurrentParentPage(state).Guid === getReviewPage(state).Guid;
}

export const getIsUserPage = state => {
    return getCurrentParentPage(state).Guid === getUserPage(state).Guid;
}

export const getIsLastSubPage = state => {
    const { selectedSubPage } = state.wizardReducer;
    return selectedSubPage > -1 && selectedSubPage === getCurrentSubFormPages(state).length - 1;
}

export const getCurrentParentPage = state => {
    return getSelectedFormWizardPages(state)[state.wizardReducer.selectedPage];
}

export const getCurrentSubFormPages = state => {
    const { selectedSubForm } = state.wizardReducer;
    const subForm = getCurrentParentPage(state).WizardSubForms.find(form => form.Id === selectedSubForm);
    return subForm && subForm.WizardPages;
}

export const getCurrentSubPage = state => {
    const { selectedSubPage } = state.wizardReducer;
    const subForm = getCurrentSubFormPages(state);
    return subForm && subForm[selectedSubPage];
}

export const getCurrentPage = state => {
    const { selectedSubPage } = state.wizardReducer;
    return selectedSubPage === -1 ? getCurrentParentPage(state) : getCurrentSubPage(state);
}

export const getSubPageElementGUID = state => {
    const page = getCurrentParentPage(state);
    return page && page.Elements && page.Elements[0] && page.Elements[0].Guid;
}

export const getSubPageAddButtonTitle = state => {
    const page = getCurrentParentPage(state);
    return page && page.Elements && page.Elements[0] && page.Elements[0].ConfigData && page.Elements[0].ConfigData.AddThirdPartyButtonText;
}

export const getCurrentPageSubPageObjects = state => {
    const guid = getSubPageElementGUID(state);
    return state.wizardReducer.answers.subPageObjects[guid] || []
}

export const getElementAnswer = (state, elementID) => {
    const { selectedSubPage, currentSubPageObject, answers } = state.wizardReducer;
    if (selectedSubPage === -1) {
        return answers.elements[elementID];
    } else {
        return currentSubPageObject.elements ? currentSubPageObject.elements[elementID] : '';
    }
}

const getFormActions = (state) => {
    const formType = state.appReducer.formTypes.find(type => {
        return type.Id === state.appReducer.selectedFormTypeId;
    });

    return formType ? formType.FormActions : [];
}

const evaluateCondition = (condition, answers, field, state) => {
    let leftValue = answers.fields[condition.LeftSourceTemplateAttribute] ? answers.fields[condition.LeftSourceTemplateAttribute] : '';
    const rightValue = condition.RightSourceTemplateAttribute ? answers.fields[condition.RightSourceTemplateAttribute] : condition.ComparisonValue;

    if(field && (field.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL || field.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL))
    {
        leftValue = convertToDbValue(leftValue, field, state)
    }

    switch (condition.FieldOperator) {
        case FIELD_OPERATOR.EQUALS:
            return (leftValue == rightValue) ||
                (Array.isArray(leftValue) && leftValue.findIndex((item) => item == rightValue) > -1); //ignore type in find
        case FIELD_OPERATOR.NOT_EQUALS:
            return (leftValue != rightValue) &&
                !(Array.isArray(leftValue) && leftValue.findIndex((item) => item == rightValue) > -1); //ignore type in find
        case FIELD_OPERATOR.GREATER_THAN:
            return Number(leftValue) > Number(rightValue);
        case FIELD_OPERATOR.LESS_THAN:
            return Number(leftValue) < Number(rightValue);
        case FIELD_OPERATOR.EQUALS_TO_OR_GREATER_THAN:
            return Number(leftValue) >= Number(rightValue);
        case FIELD_OPERATOR.EQUALS_TO_OR_LESS_THAN:
            return Number(leftValue) <= Number(rightValue);
        case FIELD_OPERATOR.CONTAINS:
            return leftValue.indexOf(rightValue) >= 0;
        case FIELD_OPERATOR.DOES_NOT_CONTAIN:
            return leftValue.indexOf(rightValue) < 0;
        case FIELD_OPERATOR.IS_DEFINED:
            return leftValue;
        case FIELD_OPERATOR.IS_UNDEFINED:
            return !leftValue;
        case FIELD_OPERATOR.IS_BLANK:
            return  leftValue === '';
        case FIELD_OPERATOR.IS_NOT_BLANK:
            return  leftValue !== '';
        default:
            return false;
    }
}

const evaluateDateOrTimeCondition = (condition, answers, field) => {
    let leftValue;
    let rightValue;
    let precision;

    if (field && field.RenderingType === FIELD_TYPES.DATE) {
        leftValue = answers.fields[condition.LeftSourceTemplateAttribute] ? moment(answers.fields[condition.LeftSourceTemplateAttribute], 'DD/MM/YYYY') : '';
        rightValue = condition.RightSourceTemplateAttribute ? moment(answers.fields[condition.RightSourceTemplateAttribute], 'DD/MM/YYYY') : moment(condition.ComparisonValue, 'x');
        precision = 'day';
    } else if (field && field.RenderingType === FIELD_TYPES.TIME) {
        leftValue = answers.fields[condition.LeftSourceTemplateAttribute] ? moment(answers.fields[condition.LeftSourceTemplateAttribute], 'HH:mm') : '';
        rightValue = condition.RightSourceTemplateAttribute ? moment(answers.fields[condition.RightSourceTemplateAttribute], 'HH:mm') : moment(condition.ComparisonValue, 'x');
        precision = 'minute';
    }

    if (!leftValue || !rightValue || !leftValue.isValid() || !rightValue.isValid()) {
        return false;
    }

    switch (condition.FieldOperator) {
        case FIELD_OPERATOR.EQUALS:
            return leftValue.isSame(rightValue, precision);
        case FIELD_OPERATOR.NOT_EQUALS:
            return !leftValue.isSame(rightValue, precision);
        case FIELD_OPERATOR.GREATER_THAN:
            return leftValue.isAfter(rightValue, precision);
        case FIELD_OPERATOR.LESS_THAN:
            return leftValue.isBefore(rightValue, precision);
        case FIELD_OPERATOR.EQUALS_TO_OR_GREATER_THAN:
            return leftValue.isSameOrAfter(rightValue, precision);
        case FIELD_OPERATOR.EQUALS_TO_OR_LESS_THAN:
            return leftValue.isSameOrBefore(rightValue, precision);
        default:
            return false;
    }
}

const calculateActions = (state, visible, actions, answers) => {
    actions.forEach(action => {
        const actionContions = action.Conditions;
        if (actionContions && actionContions.length > 0) {
            let conditionsResult;

            actionContions.forEach(condition => {
                const field = getFormField(state.appReducer.selectedFormTypeId, condition.LeftSourceTemplateAttribute, state);
                const fieldType = field && field.RenderingType;
                const evaluate = fieldType === FIELD_TYPES.DATE || fieldType === FIELD_TYPES.TIME ? evaluateDateOrTimeCondition : evaluateCondition;

                if (conditionsResult === undefined) {
                    conditionsResult = evaluate(condition, answers, field, state);
                }
                else {
                    switch (condition.LogicalOperator) {
                        case LOGICAL_OPERATOR.AND:
                            conditionsResult = conditionsResult && evaluate(condition, answers, field, state);
                            break;
                        case LOGICAL_OPERATOR.OR:
                            conditionsResult = conditionsResult || evaluate(condition, answers, field, state);
                            break;
                        default:
                            break;
                    }
                }
            });

            switch (action.ActionType) {
                case FORM_ACTION_TYPE.SHOW:
                case FORM_ACTION_TYPE.ENABLE:
                    visible = visible && conditionsResult;
                    break;
                case FORM_ACTION_TYPE.HIDE:
                case FORM_ACTION_TYPE.DISABLE:
                    visible = visible && !conditionsResult;
                    break;
                default:
                    break;
            }
        }
    });

    return visible;
}

const calculateVisibility = (state, actions) => {
    const { answers } = state.wizardReducer;
    const showHideActions = actions.filter(item => (item.ActionType === FORM_ACTION_TYPE.SHOW || item.ActionType === FORM_ACTION_TYPE.HIDE));

    let visibility = VISIBILITY_TYPES.VISIBLE;

    if (calculateActions(state, true, showHideActions, answers)) {
        const enableDisableActions = actions.filter(item => (item.ActionType === FORM_ACTION_TYPE.ENABLE || item.ActionType === FORM_ACTION_TYPE.DISABLE));

        if (!calculateActions(state, true, enableDisableActions, answers)) {
            visibility = VISIBILITY_TYPES.READ_ONLY;
        }
    } else {
        visibility = VISIBILITY_TYPES.HIDDEN;
    }

    return visibility;
}

const getPageOrElementVisibility = (state, elementGuid, pageGuid) => {
    const formActions = getFormActions(state);
    const actions = formActions ? formActions.filter(item => item.TargetPlatform === 2 && (item.TargetElement === elementGuid || item.TargetWizardPage === pageGuid)) : [];       
    return calculateVisibility(state, actions);
}

export const getPageVisibility = (state, pageGuid) => getPageOrElementVisibility(state, null, pageGuid);
export const getElementVisibility = (state, elementGuid) => getPageOrElementVisibility(state, elementGuid, null);

export const getFieldAnswer = (state, fieldID) => {
    const { selectedSubPage, currentSubPageObject, answers } = state.wizardReducer;
    if (selectedSubPage === -1) {
        return answers.fields[fieldID];
    } else {
        return currentSubPageObject.fields ? currentSubPageObject.fields[fieldID] : '';
    }
}

export const getFieldAnswerArray = (state, fieldID) => {
    let returnArray = [];
    const {selectedSubPage, currentSubPageObject, answers} = state.wizardReducer;
    if (selectedSubPage === -1) {
        for (let key in answers.fields) {
            if (key === fieldID || isFieldIdMultilingualAnswer(fieldID, key)) {
                const answer = answers.fields[key];
                if (answer) {
                    returnArray.push(answer);
                }
            }
        }
        return returnArray;
    } else if (currentSubPageObject.fields) {
        for (let key in currentSubPageObject.fields) {
            if (key === fieldID || isFieldIdMultilingualAnswer(fieldID, key)) {
                const answer = currentSubPageObject.fields[key];
                if (answer) {
                    returnArray.push(answer);
                }
            }
        }
    }
    return returnArray;
}

export const isFieldIdMultilingualAnswer = (fieldID, answerID) => {
    if (fieldID != answerID && answerID.startsWith(fieldID)) {
        const potentialIsoCode = answerID.replace(fieldID, '').replace('.', '');
        return getIsoCodes().includes(potentialIsoCode.toLowerCase());
    }
    return false;
}

export const getFieldSettings = (state, fieldID, settingName) => {
    const { settings } = state.wizardReducer;
    return settings.fields[fieldID];
}

export const getCurrentPageElements = (state) => {
    const currentPage = getCurrentPage(state);
    return currentPage.Elements ? currentPage.Elements.map(e => ({
        ...e,
        visible: getElementVisibility(state, e.Guid)
    })) : [];
}

export const getCurrentPageFields = (state) => {

    return getCurrentPageElements(state).filter(e => e.Type === ELEMENT_TYPES.DESIGNER).flatMap(e => e.Fields.map(f => ({
        ...f,
        visible: e.visible
    })));
}

export const getCurrentPageCodedElements = (state) => {
    return getCurrentPageElements(state).filter(e => e.Type === ELEMENT_TYPES.CODED);
}

export const getCurrentPageValidationRules = (state) => {
    return getCurrentPageElements(state).flatMap(e => !e.Validation ? [] : Object.values(e.Validation).filter(v => {
        if (v.FieldName) {
            const field = e.Fields.find(f => f.TemplateAttribute?.Name && `Attributes.${f.TemplateAttribute.Name}` === v.FieldName);
            return !field?.IsPermanentlyHidden && !field?.IsAlwaysDisabled;
        }

        return true;
    }));
}

export const getCurrentPageFieldValidationRules = (attributeName, state) => {
    return getCurrentPageValidationRules(state).filter(v => {
        const attributeNameWithoutDots = attributeName.replace(/\./g, '');
        return v.FieldName === `Attributes.${attributeName}` || v.FieldName === attributeName ||
            (v.Type === VALIDATION_TYPES.MANDATORY_DATE && (v.FieldName === attributeNameWithoutDots || v.FieldName === `Attributes${attributeNameWithoutDots}`)) ||
            (v.Type === VALIDATION_TYPES.MANDATORY_TIME && (v.FieldName === attributeNameWithoutDots || v.FieldName === `Attributes${attributeNameWithoutDots}`))
    });
}

export const getCurrentPageHasMandatoryFields = (state) => {
    const hasMandatoryFields = getCurrentPageValidationRules(state).filter(v => {
        return v.Type === VALIDATION_TYPES.MANDATORY || v.Type === VALIDATION_TYPES.MANDATORY_DATE || v.Type === VALIDATION_TYPES.MANDATORY_TIME;
    }).length > 0;
    const hasMandatoryCodedElements = getCurrentPageCodedElements(state).find(e => getCurrentPageElementIsMandatory(e.Name, state)) !== undefined;

    return hasMandatoryFields || hasMandatoryCodedElements;
}

export const getCurrentPageFieldIsMandatory = (attributeName, state) => {
    return !!getCurrentPageFieldValidationRules(attributeName, state).find(v => {
        return v.Type === VALIDATION_TYPES.MANDATORY ||
            v.Type === VALIDATION_TYPES.MANDATORY_DATE ||
            v.Type === VALIDATION_TYPES.MANDATORY_TIME
    })
}

export const getCurrentPageElementIsMandatory = (elementName, state) => {
    return elementName === CODED_ELEMENT_TYPES.LOCATION_SELECTOR || elementName === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR || elementName === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS;
}

export const getCurrentPageFieldIsInvalid = (attributeName, state) => {
    const { selectedSubPage, invalid } = state.wizardReducer;
    const invalidFields = selectedSubPage === -1 ? invalid.fields : invalid.currentSubPageObject.fields;
    return invalidFields.find(f => f.field === attributeName);
}

export const getCurrentPageFieldIsFirstInvalid = (attributeName, state) => {
    const { selectedSubPage, invalid } = state.wizardReducer;
    const invalidFields = selectedSubPage === -1 ? invalid.fields : invalid.currentSubPageObject.fields;
    return invalid.scrollToFirst === 'field' && invalidFields.findIndex(f => f.field === attributeName) === 0;
}

export const getCurrentPageElementIsInvalid = (elementGuid, state) => {
    const { selectedSubPage, invalid } = state.wizardReducer;
    const invalidElements = selectedSubPage === -1 ? invalid.elements : invalid.currentSubPageObject.elements;
    return invalidElements.find(e => e.element === elementGuid);
}

export const getCurrentPageElementIsFirstInvalid = (elementGuid, state) => {
    const { selectedSubPage, invalid } = state.wizardReducer;
    const invalidElements = selectedSubPage === -1 ? invalid.elements : invalid.currentSubPageObject.elements;
    return invalid.scrollToFirst === 'element' && invalidElements.findIndex(e => e.element === elementGuid) === 0;
}

export const getLocationSelected = state => {
    const visibleLocationElements = getElements(state.appReducer.selectedFormTypeId, state).filter(e => {
        return e.Type === ELEMENT_TYPES.CODED &&
            getElementVisibility(state, e.Guid) &&
            (e.Name === CODED_ELEMENT_TYPES.LOCATION_SELECTOR ||
                e.Name === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR ||
                e.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS)
    });
    const { answers } = state.wizardReducer;
    return visibleLocationElements.some(e => {
        let answer;
        if (e.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS) {
            if (state.appReducer.isHierarchy) {
                answer = answers.elements && answers.elements[e.Guid] && answers.elements[e.Guid].hierarchyLocation && answers.elements[e.Guid].hierarchyLocation.nodeExternalId;
            } else {
                answer = answers.elements && answers.elements[e.Guid] && answers.elements[e.Guid].locationId;
            }
        } else {
            answer = answers.elements && answers.elements[e.Guid];
        }
        return isValidAnswer(answer);
    });
}

export const getSelectedLocationId = state => {
    const locationElements = getLocationElements(state, state.appReducer.selectedFormTypeId);
    if (locationElements.length > 0) {
        const location = state.wizardReducer.answers.elements[locationElements[0].Guid];
        if (location) {
            return typeof location === 'object' ? location.nodeExternalId : location;
        }
    }

    return -1;
}

export const getLocationElements = (state, formid) => {
    const locationElements = getElements(formid, state).filter(e => {
        return e.Type === ELEMENT_TYPES.CODED &&
            (e.Name === CODED_ELEMENT_TYPES.LOCATION_SELECTOR ||
                e.Name === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR ||
                e.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS);
    });
    return locationElements;
}

export const getAnswerAttributes = (state) => {
    const { answers } = state.wizardReducer;

    let attributes = {};
    const pages = getSelectedFormWizardPages(state);
    // store fields in order not to have them reset 
    // when the same attribute exists on multiple pages 
    // that are conditionally visible
    const processedFields = [];
    pages.forEach((page) => {
        page.Elements && page.Elements.forEach(element => {
            const pageVisible = getPageVisibility(state, page.Guid);
            if (element.Type === ELEMENT_TYPES.DESIGNER) {
                const elementVisible = getElementVisibility(state, element.Guid) !== VISIBILITY_TYPES.HIDDEN;
                element.Fields.forEach(field => {
                    const fieldName = field.TemplateAttribute.Name;
                    const renderType = field.RenderingType;
                    if (pageVisible && elementVisible) {

                        const answer = answers.fields[fieldName];
                        if (isValidAnswer(answer)) {
                            attributes[fieldName] = answer;
                            processedFields.push(fieldName);
                        }
                        else {
                            attributes[fieldName] = getEmptyAnswer(renderType);
                        }

                        if (field.Properties) {
                            const languages = field.Properties.find((item) => item.Name === 'languages');
                            if (languages && languages.Data) {
                                languages.Data.split(",").forEach((isoCode) => {
                                    const multilingualAttributeName = getMultiName(fieldName, isoCode);

                                    if (isValidAnswer(answers.fields[multilingualAttributeName])) {
                                        attributes[multilingualAttributeName] = answers.fields[multilingualAttributeName];
                                        processedFields.push(multilingualAttributeName);
                                    }
                                });
                            }
                        }
                    } else if (processedFields.indexOf(fieldName) === -1) {
                        attributes[fieldName] = getEmptyAnswer(renderType);
                    }
                })
            } else {
                const elementVisible = getElementVisibility(state, element.Guid) !== VISIBILITY_TYPES.HIDDEN

                const elementAttributes = convertElementAnswerToAttributes(element, answers.elements[element.Guid], state.appReducer.isHierarchy, elementVisible);

                attributes = {
                    ...attributes,
                    ...elementAttributes,
                }
            }
        })
    })

    return attributes;
}

export const getCompanyPrimaryLanguageData = (state) => {
    const autoTranslatePrimaryLanguage =
        (getProfileValue('Company.Interface.AutoTranslatePrimaryLanguage', state) === 'Yes');
    const primaryLanguage = getProfileValue('Company.Interface.CompanyPrimaryLanguage', state);

    return {autoTranslatePrimaryLanguage, primaryLanguage};
}

export const getNotEmptyLangFieldsForPrimaryLang = (state) => {
    const { answers } = state.wizardReducer;
    const {primaryLanguage} = getCompanyPrimaryLanguageData(state);

    let attributes = {};
    const pages = getSelectedFormWizardPages(state);
    pages.forEach((page) => {
        page.Elements && page.Elements.forEach(element => {
            const pageVisible = getPageVisibility(state, page.Guid);
            if (element.Type === ELEMENT_TYPES.DESIGNER) {
                const elementVisible = getElementVisibility(state, element.Guid) !== VISIBILITY_TYPES.HIDDEN;
                element.Fields.forEach(field => {
                    const fieldName = field.TemplateAttribute.Name;
                    if (pageVisible && elementVisible) {
                        if (field.Properties) {
                            const languages = field.Properties.find((item) => item.Name === 'languages');
                            const primaryName = getMultiName(fieldName, primaryLanguage);
                            if (languages &&
                                languages.Data &&
                                !isValidAnswer(answers.fields[primaryName])) {
                                for (const isoCode of languages.Data.split(",")) {
                                    const multilingualAttributeName = getMultiName(fieldName, isoCode);

                                    if (isValidAnswer(answers.fields[multilingualAttributeName])) {
                                        attributes[fieldName] = {lang: isoCode, string: answers.fields[multilingualAttributeName]};
                                        break;
                                    }
                                }
                            }
                        }
                    }
                })
            }
        })
    });

    return attributes;
}
/* @todo use this everywhere to replace condition */
export const getMultiName = (fieldName, isoCode) => {
    return isoCode === 'en-gb' ? fieldName : `${fieldName}.${isoCode}`;
};

export const getElementAnswers = (state) => {
    const { answers } = state.wizardReducer;

    const { elements } = answers;

    return elements ? elements : {};
}

export const getSubPageObjectAnswers = (state) => {
    const { answers } = state.wizardReducer;

    const { subPageObjects } = answers;

    return subPageObjects ? subPageObjects : {};
}

const getFormQuestionsCount = state => {
    const pages = getSelectedFormVisibleWizardPages(state);
    return pages.reduce((count, page) => {
        return getPageQuestionsCount(count, page, state);
    }, 0);
}

export const getPageQuestionsCount = (count, page, state) => {
    const pageVisible = getPageVisibility(state, page.Guid);
    if (!pageVisible) {
        return count;
    } else if ((page.WizardSubForms && page.WizardSubForms.length > 0) || page.Guid === 'USER_PAGE') {
        return count + 1;
    } else if (page.Elements) {
        return count + page.Elements.reduce((elementCount, element) => {
            const visible = getElementVisibility(state, element.Guid);
            if (!visible) {
                return elementCount;
            } else if (element.Type === ELEMENT_TYPES.DESIGNER) {
                return elementCount + element.Fields.length
            } else {
                return elementCount + 1;
            }
        }, 0);
    } else {
        return count;
    }
}

const getFormAnswersCount = state => {
    const pages = getSelectedFormVisibleWizardPages(state);
    return pages.reduce((count, page) => {
        return getPageAnswersCount(count, page, state);
    }, 0);
}

export const setMultiLangAnswers = (multiAnswersArr, fields, name) => {
    Object.keys(fields).forEach((key) => {
        if ((key === name || isFieldIdMultilingualAnswer(name, key)) && isValidAnswer(fields[key])) {
            multiAnswersArr.push(fields[key]);
        }
    });
}
export const getPageAnswersCount = (count, page, state, answersData) => {
    const answers = answersData || state.wizardReducer.answers;
    const userTypeSelected = state.wizardReducer.userTypeSelected > -1;

    const pageVisible = getPageVisibility(state, page.Guid);
    if (!pageVisible) {
        return count;
    } else if (page.WizardSubForms && page.WizardSubForms.length > 0) {
        const subPageObjects = page.Elements && page.Elements[0] && answers.subPageObjects[page.Elements[0].Guid];
        return count + (_.isArray(subPageObjects) && subPageObjects.filter(o => !o.deleted).length > 0 ? 1 : 0);
    } else if (page.Elements) {
        const visibleElements = page.Elements.filter(e => {
            return getElementVisibility(state, e.Guid);
        });
        return count + visibleElements.reduce((elementAnswerCount, element) => {
            if (element.Type === ELEMENT_TYPES.DESIGNER) {
                const fields = element.Fields;
                const fieldAnswerCount = fields ? fields.filter(f => {
                    const fieldAnswer = answers.fields[f.TemplateAttribute.Name];
                    const multiAnswers = [];
                    if (fieldAnswer === undefined) {
                        setMultiLangAnswers(multiAnswers, answers.fields, f.TemplateAttribute.Name)
                    }
                    const isMultiLevel = f.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL || f.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL;
                    if (isMultiLevel) {
                        const possibleValues = getPossibleValues(state, f);
                        return !isInvalidMultiLevelAnswer(fieldAnswer, f, possibleValues);
                    } else {
                        return isValidAnswer(fieldAnswer) ? true : multiAnswers.length;
                    }
                }).length : 0;
                return elementAnswerCount + fieldAnswerCount;
            } else {
                const elementAnswer = answers.elements[element.Guid];
                return elementAnswerCount + (isValidAnswer(elementAnswer) ? 1 : 0);
            }
        }, 0);
    } else if (page.Guid === 'USER_PAGE') {
        return count + (userTypeSelected ? 1 : 0);
    } else {
        return count;
    }

}

export const getFormProgress = state => {
    const questionsCount = getFormQuestionsCount(state);
    const answersCount = getFormAnswersCount(state);
    return answersCount / questionsCount * 100;
}

export const getPageStatus = (state, pageGuid) => {
    const { answers } = state.wizardReducer;
    const pages = getSelectedFormVisibleWizardPages(state);
    const page = pages.find(p => { return p.Guid === pageGuid; });

    if (pageGuid === 'START_PAGE') {
        return PAGE_STATUS_TYPES.COMPLETE;
    } else if (pageGuid === 'USER_PAGE') {
        const userTypeSelected = state.wizardReducer.userTypeSelected;
        switch (userTypeSelected) {
            case USER_TYPES.MANUAL:
                const user = getFormUser(state);
                return (user.name && user.email && user.phone) ? PAGE_STATUS_TYPES.COMPLETE : PAGE_STATUS_TYPES.INCOMPLETE;
            case USER_TYPES.ANONYMOUS:
            case USER_TYPES.AUTHENTICATED:
            case USER_TYPES.SSOAUTHENTICATED:
            case USER_TYPES.B2CAUTHENTICATED:
            case USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN:
                return PAGE_STATUS_TYPES.COMPLETE;
            default:
                return PAGE_STATUS_TYPES.INCOMPLETE;
        }
    } else if (page.WizardSubForms && page.WizardSubForms.length > 0) {
        const subPageObjects = page.Elements && page.Elements[0] && answers.subPageObjects[page.Elements[0].Guid];
        return _.isArray(subPageObjects) && subPageObjects.filter(o => !o.deleted).length > 0 ? PAGE_STATUS_TYPES.COMPLETE : PAGE_STATUS_TYPES.INCOMPLETE;
    }

    return page.Elements && page.Elements.every(element => {
        const visible = getElementVisibility(state, element.Guid);
        if (!visible) {
            return true;
        } else if (element.Type === ELEMENT_TYPES.DESIGNER) {
            return element.Fields.every(field => {
                if (field.IsAlwaysDisabled) {
                    return true;
                }
                const multiAnswers = [];
                const fieldAnswer = answers.fields[field.TemplateAttribute.Name];
                if (fieldAnswer === undefined) {
                    setMultiLangAnswers(multiAnswers, answers.fields, field.TemplateAttribute.Name);
                }
                if (field.TemplateAttribute.ObjectName && getFieldObjectPicklistValues(state, field).length === 0) {
                    return true;
                } else if (field.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL || field.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL) {
                    const possibleValues = getPossibleValues(state, field);
                    return !isInvalidMultiLevelAnswer(fieldAnswer, field, possibleValues);
                } else {
                    return isValidAnswer(fieldAnswer) || multiAnswers.length;
                }
            });
        } else {
            return answers.elements[element.Guid];
        }
    }) ? PAGE_STATUS_TYPES.COMPLETE : PAGE_STATUS_TYPES.INCOMPLETE;
}

export const getDefaultValuesForForm = (state, formID) => {
    return state.appReducer.formTypes.find(formType => {
        return formType.Id === formID
    }).FormDefaultValues;
}

export const getLocationDependentDefaultValues = (state, formID) => {
    return getDefaultValuesForForm(state, formID).filter(d => {
        return d.DefaultValueType === DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE
            && d.SourceType === DEFAULT_VALUE_SOURCE_TYPES.LOCATION;
    });
}

export const getFromUserGroupDefaultValues = (state, formID) => {
    return getDefaultValuesForForm(state, formID).filter(d => {
        return d.DefaultValueType === DEFAULT_VALUE_TYPES.FROM_USER_GROUP;
    });
}

export const getFieldDependentDefaultValues = (state, formID, templateAttribute) => {
    return getDefaultValuesForForm(state, formID).filter(d => {
        return d.DefaultValueType === DEFAULT_VALUE_TYPES.SELECTED_CHOOSER_VALUE &&
        d.SourceTemplateAttribute === templateAttribute;
    });
}

export const getStaticDefaultValues = (state, formID) => {
    return getDefaultValuesForForm(state, formID).filter(d => {
        return d.DefaultValueType === DEFAULT_VALUE_TYPES.STATIC_VALUE;
    });
}

export const getDefaultValue = (state, context) => {
    const { field, elementGuid, element } = context;
    const { selectedFormTypeId, isHierarchy } = state.appReducer;
    let defaultValue;
    if (field) {
        defaultValue = getDefaultValuesForForm(state, selectedFormTypeId)
            .find(d => d.TemplateAttribute === field.TemplateAttribute.Name && (!elementGuid || d.ElementGuid === elementGuid));
    } else {
        defaultValue = getDefaultValuesForForm(state, selectedFormTypeId)
            .find(d => d.ElementGuid === element.Guid);
    }

    let value;
    if (defaultValue) {
        switch (defaultValue.DefaultValueType) {
            case DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE:
                if (state.appReducer.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE] &&
                    state.appReducer.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE][defaultValue.SourceType] &&
                    state.appReducer.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE][defaultValue.SourceType][defaultValue.SourceValue]) {
                    value = state.appReducer.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE][defaultValue.SourceType][defaultValue.SourceValue];
                    if (Array.isArray(value)) { value = value[0]; }

                    if (element && element.Type === ELEMENT_TYPES.CODED) {
                        if (element.Name === CODED_ELEMENT_TYPES.LOCATION_SELECTOR || element.Name === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR) {
                            value = isHierarchy ? { nodeExternalId: value } : value;
                        } else if (element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS) {
                            value = isHierarchy ? { hierarchyLocation: { nodeExternalId: value } } : { locationId: value }
                        }
                    }
                }
                break;

            case DEFAULT_VALUE_TYPES.STATIC_VALUE:
                switch (defaultValue.SourceType) {
                    case DEFAULT_VALUE_STATIC_TYPES.CURRENT_DATE:
                        value = moment().format('DD/MM/YYYY');
                        break;

                    case DEFAULT_VALUE_STATIC_TYPES.CURRENT_TIME:
                        value = moment().format('HH:mm');
                        break;

                    default:
                        if(field) {
                            switch (field.RenderingType) {
                                case FIELD_TYPES.DATE:
                                    value = convertFromDbDate(defaultValue.SourceValue);
                                    break;
                                case FIELD_TYPES.TIME:
                                    value = convertFromDbTime(defaultValue.SourceValue);
                                    break;
                                case FIELD_TYPES.SLIDER:
                                    value = parseInt(defaultValue.SourceValue);
                                    break;
                                case FIELD_TYPES.CHECKLIST:
                                    value = defaultValue.SourceValue ? defaultValue.SourceValue.split(",").map(Number) : [];
                                    break;
                                default:
                                    value = defaultValue.SourceValue;
                            }
                        }
                }
                break;

            default:
                break;
        }
    }

    return value;
}

const getPicklistActions = (state, field) => {
    const formActions = getFormActions(state);
    return formActions ? formActions.filter(item => item.TargetTemplateAttribute === field.TemplateAttribute.Name && item.TargetTemplateAttributeValue) : [];
}

const isPossibleValueHidden = (state, actions, answers, value) => {
    const showHideActions = actions.filter(item => (item.ActionType === 1 || item.ActionType === 2) && item.TargetTemplateAttributeValue === value.Identifier);
    return !calculateActions(state, true, showHideActions, answers);
}

export const getPossibleValues = (state, field) => {
    const objectPicklistValues = getFieldObjectPicklistValues(state, field);
    const { answers } = state.wizardReducer;
    const actions = getPicklistActions(state, field);
    const result = (!_.isEmpty(objectPicklistValues) && objectPicklistValues) || field.TemplateAttribute.PossibleValues.filter(v => !v.Deleted && !isPossibleValueHidden(state, actions, answers, v)) || [];
    
    result.forEach(element => {
        const label = element.Label.split(':').map(item => item.trim()).join(':');
        if (label !== element.Label) {
            element.Label = label;
        }
    });

    return result;
}

export const getFieldObjectPicklistValues = (state, field) => {
    const { objectPicklistValues } = state.wizardReducer;
    const objectName = field.TemplateAttribute.ObjectName;

    let values;
    if (objectName || !_.isEmpty(field.ColumnNames)) {
        values = objectPicklistValues[field.TemplateAttribute.Name] || [];
    }
    return values;
}

export const getFieldObjectPicklistLoading = (state, field) => {
    const { objectPicklistLoading } = state.wizardReducer;
    const objectName = field.TemplateAttribute.ObjectName;

    return objectName && objectPicklistLoading.indexOf(field.TemplateAttribute.Name) > -1;
}

export const getElementIsUpdated = (state, elementGuid) => {
    return state.wizardReducer.updated.elements.indexOf(elementGuid) > -1;
}

export const getFieldIsUpdated = (state, fieldID) => {
    return state.wizardReducer.updated.fields.indexOf(fieldID) > -1;
}

export const getUserTypeHasError = (state) => {
    return state.wizardReducer.invalid.pages.USER_PAGE.indexOf(USER_PAGE_VALIDATION_TYPES.USER_TYPE_SELECTION) > -1;
}

export const getManualNameHasError = (state) => {
    return state.wizardReducer.invalid.pages.USER_PAGE.indexOf(USER_PAGE_VALIDATION_TYPES.MANUAL_NAME) > -1;
}

export const getManualEmailHasError = (state) => {
    return state.wizardReducer.invalid.pages.USER_PAGE.indexOf(USER_PAGE_VALIDATION_TYPES.MANUAL_EMAIL) > -1;
}

export const getManualPhoneHasError = (state) => {
    return state.wizardReducer.invalid.pages.USER_PAGE.indexOf(USER_PAGE_VALIDATION_TYPES.MANUAL_PHONE) > -1;
}

export const getFieldSpeechToTextValue = (state, fieldID) => {
    return state.wizardReducer.speechToText[fieldID];
}

export const getFieldMicInUse = (state, fieldID) => {
    return state.wizardReducer.fieldMicInUse[fieldID];
}

const getFieldActions = (state, field) => {
    const formActions = getFormActions(state);
    return formActions ? formActions.filter(item => item.TargetTemplateAttribute === field.TemplateAttribute.Name && !item.TargetTemplateAttributeValue) : [];
}

export const getFieldVisibility = (state, field) => {
    const actions = getFieldActions(state, field);
    return calculateVisibility(state, actions);
}
