export const FIELD_TYPES = {
    TEXT_INPUT: 0,
    TOGGLE: 1,
    DATE: 2,
    TIME: 3,
    DROPDOWN: 4,
    CHECKLIST: 5,
    LOCATION: 6,
    NUMERIC: 8,
    FLOAT: 9,
    TEXT_AREA: 12,
    BUTTON_SELECT: 13,
    SINGLE_CHOOSER: 14,
    RADIO_BUTTONS: 15,
    MULTI_CHOOSER: 16,
    CHECKLIST_MULTI_LEVEL: 19,
    SLIDER: 20,
    DROPDOWN_MULTI_LEVEL: 93,
    MATRIX: 21
}
