import React, { Component } from 'react';
import './Field.scss';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FIELD_TYPES } from '../../constants/FieldTypes';
import { ATTRIBUTE_TYPES } from '../../constants/AttributeTypes';
import { EN_GB, getFlagCode, speechToTextLanguages } from '../../constants/LanguageFlags';
import {
    Dropdown, ButtonSelect, ButtonDatePicker, TimePicker, NumericInput,
    FloatInput, CheckboxGroup, Checkbox, RadioGroup, Spinner
} from 'spheracloud-common';
import TextArea from './TextArea/TextArea';
import MatrixField from './MatrixField/MatrixField';
import TextInput from './TextInput/TextInput';
import { SingleChooser, MultiChooser } from './Chooser/Chooser';
import ChecklistMultiLevel from './ChecklistMultiLevel/ChecklistMultiLevel';
import DropdownMultiLevel from './DropdownMultiLevel/DropdownMultiLevel';
import {
    updateFieldAnswer,
    loadFieldDependentDefaultValues,
    updateFieldSetting,
    clearScrollToFirst,
    speechToTextBegin,
    translationBegin,
    liveSearchBegin,
    liveSearchSuccess,
    resolveLabelBegin
} from '../../actions/wizardActions';
import {
    getCurrentPageFieldIsFirstInvalid,
    getCurrentPageFieldIsInvalid,
    getCurrentPageFieldIsMandatory,
    getDefaultValue,
    getFieldAnswer,
    getFieldIsUpdated,
    getFieldMicInUse,
    getFieldObjectPicklistLoading,
    getFieldSettings,
    getFieldSpeechToTextValue,    
    getPossibleValues,
    getSelectedLocationId
} from '../../selectors/wizardSelectors';
import Strings from '../../constants/Strings';
import HttpClient from '../../utils/httpClient';
import { getAppSettingsFromState, getString, getFirstReportSettings, getAvailableLanguages, getProfileValue, getActiveLocation, getUseFormLocationRestrictions } from '../../selectors/appSelectors';
import { RadioSelectedSvg, CheckboxChecked, CloseGrey, CheckboxDisabledChecked, CheckboxUnchecked, CheckboxDisabledUnchecked, CheckboxErrorChecked, CheckboxErrorUnchecked } from '../../icons';
import { getMultiLevelStructure, isValidAnswer } from '../../utils/convertAnswer';
import { checkIsLive, getNodeFromString } from '../../utils/utils';
import Flag from "react-flagkit";
import { showThisLanguageValueForm } from "../../actions/appActions";
import SliderInput from "./SliderInput/SliderInput";
import SimilarRecordSearch from '../SimilarRecords/SimilarRecordsSearch';
import { LOCATION_ID_ATTRIBUTE } from '../../constants/KnownAttributes';

class Field extends Component {

    constructor(props) {
        super(props);

        const { defaultValue, isUpdated } = props;

        let value = props.value;
        if (value === undefined && !isUpdated) {
            value = this.getActiveLocationDefaultValue() || defaultValue;

            if (value !== undefined) {
                this.handleFieldChange(value);
            }
        }

        this.state = {
            value
        };

        this.scrollIntoView = this.scrollIntoView.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getField = this.getField.bind(this);
        this.renderSimilarRecord = this.renderSimilarRecord.bind(this);
        this.container = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { value, isFirstInvalid, clearScrollToFirst, possibleValues, field } = this.props;

        if (prevProps.isFirstInvalid === false && isFirstInvalid === true) {
            this.container.current.scrollIntoView({ behavior: 'smooth' });
            clearScrollToFirst();
        }

        if (prevProps.value !== value && !(isNaN(value) && isNaN(prevProps.value))) {
            this.setState({ value: value });
        }

        if (!_.isEqual(prevProps.possibleValues, possibleValues)) {
            this.updateLiveSearchOptions();
        }

        if (field.TemplateAttribute.Type === ATTRIBUTE_TYPES.PICKLIST && !isNaN(value) && !checkIsLive(field) && !_.isEqual(prevProps.possibleValues, possibleValues) && !possibleValues.find(item => item.Identifier === value)) {
            this.setState({ value: null });
            this.handleFieldChange(null);
        }
    }

    componentDidMount(prevProps) {
        this.updateLiveSearchOptions();
    }

    updateLiveSearchOptions() {
        const { liveSearchSuccess, field, possibleValues } = this.props;
        const isLiveSearch = checkIsLive(field);
        if (isLiveSearch) {
            liveSearchSuccess(this.wrapWithPageData(possibleValues), field.TemplateAttribute.Name);
        }
    }

    getActiveLocationDefaultValue() {
        const { field, useLocationRestrictions, activeLocation } = this.props;
        return field.TemplateAttribute.Name === LOCATION_ID_ATTRIBUTE && useLocationRestrictions && activeLocation ? activeLocation.nodeExternalId : undefined;
    }

    scrollIntoView() {
        this.container.current && this.container.current.scrollIntoView({ block: 'nearest' });
    }

    handleFieldChange(value) {
        const { updateFieldAnswer, loadFieldDependentDefaultValues, field, fieldSettings, selectedLanguage } = this.props;

        this.setState({
            value: value
        });

        updateFieldAnswer(getFieldIdentifierForAnswer(field, fieldSettings, selectedLanguage), value);

        loadFieldDependentDefaultValues(field, value);
    }

    handleLanguageChange = (isoCode) => {
        const { updateFieldSetting, field } = this.props;
        updateFieldSetting(field.TemplateAttribute.Name, 'languageIsoCode', isoCode);
    }

    handleTranslateClick = (text, fromLanguage, toLanguage) => {
        const { field, fullState, showThisLanguageValueForm, translationBegin, updateFieldSetting } = this.props;
        const toBeChangedFieldLanguageValue =
            getFieldAnswer(fullState, field.TemplateAttribute.Name + `.${toLanguage}`);
        if (toBeChangedFieldLanguageValue && toBeChangedFieldLanguageValue.length) {
            showThisLanguageValueForm(field.TemplateAttribute.Name, text, fromLanguage, toLanguage);
        } else {
            translationBegin(
                field.TemplateAttribute.Name,
                text,
                fromLanguage,
                toLanguage);
            updateFieldSetting(field.TemplateAttribute.Name, 'languageIsoCode', toLanguage);
        }
    }

    getPageInfo() {
        const { field } = this.props;
        return field.TemplateAttribute.PageInfo || {};
    }

    getLanguageOptions = (fieldProperties, field) => {
        const {
            availableLanguages,
            selectedLanguage,
            fieldSettings,
            fullState,
            languagesThatCanBeTranslatedByApi
        } = this.props;

        const usedLanguage = (fieldSettings && fieldSettings.languageIsoCode) || selectedLanguage;
        const languageOptionsConfig = fieldProperties && fieldProperties.languages ?
            fieldProperties.languages.split(',').filter(language => {
                return language !== usedLanguage && availableLanguages.find(item => item.ISOCode === language);
            }) : [];

        const languageOptions = languageOptionsConfig.map((isoCode) => {
            const language = availableLanguages.find((item) => item.ISOCode === isoCode);
            return {
                value: language.ISOCode,
                label: <span className="LabelContainer">
                    <span className="InputFilledContainer">
                        <span className="LangLabel">View {language.LanguageName}</span>
                        {getFieldAnswer(fullState,
                            field.TemplateAttribute.Name +
                            (language.ISOCode !== EN_GB ? `.${language.ISOCode}` : '')) ?
                            <span className="HasTextIndicator" /> : ''}
                    </span>
                    <Flag country={getFlagCode(language.ISOCode.toLowerCase())} size="21" />
                </span>
            }
        });

        let allOptions = [...languageOptions];

        if (this.props.isEnabledAutoTranslation === "Yes") {
            const languageTranslateOptions = languageOptionsConfig
                .filter((isoCode) => {
                    const loweredIsoCode = isoCode.toLowerCase();
                    const parts = loweredIsoCode.split('-');
                    return !languagesThatCanBeTranslatedByApi ||
                        languagesThatCanBeTranslatedByApi[parts[0]] ||
                        languagesThatCanBeTranslatedByApi[loweredIsoCode]
                })
                .map((isoCode) => {
                    const language = availableLanguages.find((item) => item.ISOCode === isoCode);
                    return {
                        value: { iso: language.ISOCode, translateOpt: true },
                        label: <span className="LabelContainer">
                            <span className="InputFilledContainer">
                                <span className="LangLabel">Translate to {language.LanguageName}</span>
                                {getFieldAnswer(fullState, field.TemplateAttribute.Name + `.${language.ISOCode}`) ?
                                    <span className="HasTextIndicator" /> : ''}
                            </span>
                            <Flag country={getFlagCode(language.ISOCode.toLowerCase())} size="21" />
                        </span>
                    }
                });
            allOptions = [...languageOptions, ...languageTranslateOptions];
        }
        return allOptions;
    }

    renderSimilarRecord() {
        let result = <></>;
        const templateName = this.props.field.TemplateAttribute.Name;
        const lowerCaseTemplate = templateName.toLowerCase();

        if (templateName && (lowerCaseTemplate === 'accidents.description' || lowerCaseTemplate === 'accidents.shortdescription' || lowerCaseTemplate === 'accidents.title')) {
            const { isEnabledFuzzySearch, fuzzySearchViewId, isRestictedFuzzySearchToUserLocation,
                selectedLanguage, field, fieldSettings
            } = this.props;

            if (isEnabledFuzzySearch === "Yes" && fuzzySearchViewId) {
                const attributeName = getFieldIdentifierForAnswer(field, fieldSettings, selectedLanguage);
                const value = this.state.value || '';
                result = <SimilarRecordSearch
                    templateAttributeName={attributeName}
                    searchValue={value.replace(/[^\w\s]/gi, '')}
                    similarRecordsViewId={fuzzySearchViewId}
                    isRestictedSearchToUserLocation={isRestictedFuzzySearchToUserLocation === "Yes"}
                />;
            }
        }

        return result;
    }

    getField() {
        const { field, noResultsText, isInvalid, todayLabel, yesterdayLabel, otherLabel,
            earlierLabel, selectDateLabel, cancelLabel, applyLabel, selectedLanguage, selectedLocationId,
            objectPicklistLoading, requiredFieldLabel, contentDimensions, chooserTitle,
            speechToTextBegin, speechToTextValue, firstReportSettings, micInUse, fieldSettings,
            translationIsBeingProcessed, liveSearchIsBeingProcessed, liveSearchBegin, possibleValues,
            liveSearchOptions, userLanguage, resolveLabelBegin, isProccesingSimilarRecords, elementGuid
        } = this.props;

        let liveSearchOptionsSafe;
        const fieldProperties = _.isArray(field.Properties) ? field.Properties.reduce(function (properties, cur) {
            properties[cur.Name] = cur.Data;
            return properties;
        }, {}) : {};

        const columnKeys = field.ColumnNames && field.ColumnNames.map(c => c.Key).join(',');

        const maxDropdownHeight = contentDimensions && contentDimensions.height ? Math.min(contentDimensions.height - 60, 400) : 400;
        const isHidden = field.IsPermanentlyHidden;
        const disabled = this.props.disabled || field.IsAlwaysDisabled;
        const isMandatory = this.props.isMandatory && !disabled && !isHidden;

        if (objectPicklistLoading) {
            return <div className="FieldSpinner"><Spinner /></div>
        }
        let hasLiveSearch = false;

        switch (field.RenderingType) {
            case FIELD_TYPES.SLIDER:
                return <SliderInput
                    initialValue={this.props.value === undefined ? parseInt(this.props.defaultValue) : this.props.value}
                    disabled={disabled}
                    minValue={fieldProperties.minimum}
                    maxValue={fieldProperties.maximum}
                    leftLabel={fieldProperties.leftLabel?.toString()}
                    middleLabel={fieldProperties.middleLabel?.toString()}
                    rightLabel={fieldProperties.rightLabel?.toString()}
                    step={fieldProperties.step}
                    onValueChanged={this.handleFieldChange}
                    hasError={isInvalid}
                />

            case FIELD_TYPES.TEXT_INPUT:
                return <>
                    <TextInput initialText={this.props.value}
                        placeholder={field.Hint}
                        micInUse={micInUse}
                        translationIsBeingProcessed={translationIsBeingProcessed}
                        isLanguageSupported={speechToTextLanguages.includes((fieldSettings && fieldSettings.languageIsoCode && fieldSettings.languageIsoCode.toLowerCase()) || selectedLanguage.toLowerCase()) ? true : false}
                        speechToTextSettings={firstReportSettings.speechToTextSettings}
                        speechToTextValue={speechToTextValue}
                        onMicInputClick={() => speechToTextBegin(field.TemplateAttribute.Name)}
                        onTextChanged={this.handleFieldChange}
                        languageOptions={this.getLanguageOptions(fieldProperties, field)}
                        selectedLanguageIsoCode={(fieldSettings && fieldSettings.languageIsoCode) || selectedLanguage}
                        onLanguageChanged={this.handleLanguageChange}
                        onTranslateClick={this.handleTranslateClick}
                        hasError={isInvalid}
                        fieldId={field.TemplateAttribute.Name}
                        disabled={disabled}
                        shouldShowSpinner={isProccesingSimilarRecords} />
                    {this.renderSimilarRecord()}
                </>

            case FIELD_TYPES.TEXT_AREA:

                return <> <TextArea initialText={this.props.value}
                    placeholder={field.Hint}
                    micInUse={micInUse}
                    translationIsBeingProcessed={translationIsBeingProcessed}
                    isLanguageSupported={speechToTextLanguages.includes((fieldSettings && fieldSettings.languageIsoCode && fieldSettings.languageIsoCode.toLowerCase()) || selectedLanguage.toLowerCase()) ? true : false}
                    speechToTextSettings={firstReportSettings.speechToTextSettings}
                    speechToTextValue={speechToTextValue}
                    onMicInputClick={() => speechToTextBegin(field.TemplateAttribute.Name)}
                    onTextChanged={this.handleFieldChange}
                    languageOptions={this.getLanguageOptions(fieldProperties, field)}
                    selectedLanguageIsoCode={(fieldSettings && fieldSettings.languageIsoCode) || selectedLanguage}
                    onLanguageChanged={this.handleLanguageChange}
                    onTranslateClick={this.handleTranslateClick}
                    hasError={isInvalid}
                    fieldId={field.TemplateAttribute.Name}
                    disabled={disabled}
                    shouldShowSpinner={isProccesingSimilarRecords} />
                    {this.renderSimilarRecord()}
                </>

            case FIELD_TYPES.MATRIX:
                return <MatrixField field={field}
                    initialValue={this.props.value}
                    onValueChanged={this.handleFieldChange}
                    hasError={isInvalid}
                    disabled={disabled}
                />

            case FIELD_TYPES.NUMERIC:
                return <NumericInput
                    initialText={this.state.value && this.state.value.toString()}
                    placeholder={field.Hint}
                    onTextChanged={this.handleFieldChange}
                    hasError={isInvalid}
                    disabled={disabled} />

            case FIELD_TYPES.FLOAT:
                return <FloatInput
                    initialText={this.state.value && this.state.value.toString()}
                    placeholder={field.Hint}
                    onTextChanged={this.handleFieldChange}
                    hasError={isInvalid}
                    disabled={disabled} />

            case FIELD_TYPES.TOGGLE:
                return <div className="Checkbox-Container">
                    <Checkbox
                        className="Toggle"
                        isCheckedInitial={this.state.value}
                        label={field.Title}
                        onCheckedChanged={this.handleFieldChange}
                        hasError={isInvalid}
                        checkedIcon={CheckboxChecked}
                        uncheckedIcon={CheckboxUnchecked}
                        disabledCheckedIcon={CheckboxDisabledChecked}
                        disabledUncheckedIcon={CheckboxDisabledUnchecked}
                        errorCheckedIcon={CheckboxErrorChecked}
                        errorUncheckedIcon={CheckboxErrorUnchecked}
                        disabled={disabled}
                    />
                </div>

            case FIELD_TYPES.DROPDOWN:
                const selectedValue = this.props.value && this.props.value instanceof Array ? this.props.value[0] : this.props.value;
                const items = possibleValues.map(item => (
                    {
                        id: item.Identifier,
                        label: item.Label,
                        selected: selectedValue && (selectedValue == item.Identifier || selectedValue == item.DataGuid), //ignore type
                        image: item.image,
                        value: item.Identifier,
                        helpText: item.HelpText && getNodeFromString(item.HelpText),
                    }
                ));

                const dropdownHasImages = (items.every(item => {
                    return item.image;
                }));

                return dropdownHasImages ?
                    <ButtonSelect
                        options={items}
                        onChange={this.handleFieldChange}
                        initialSelection={this.state.value}
                        multiselect={field.multiselect}
                        hasError={isInvalid}
                        disabled={disabled}
                    />
                    :
                    <Dropdown
                        key={this.props.value}
                        label={field.Hint}
                        items={items.map(item => ({ ...item, value: item.value.toString() }))}
                        onSelect={(item) => this.handleFieldChange(item && item.id)}
                        onOpen={this.scrollIntoView}
                        maxHeight={`${maxDropdownHeight}px`}
                        hasError={isInvalid}
                        disabled={disabled}
                        showClearButton={true}
                    />

            case FIELD_TYPES.CHECKLIST:
                const checklistItems = possibleValues.map(item => ({
                    value: item.Identifier,
                    label: item.Label,
                    text: item.Label,
                    checked: this.state.value && this.state.value.includes(item.Identifier),
                    image: item.image,
                    helpText: item.HelpText && getNodeFromString(item.HelpText),
                }));

                const checklistHasImages = (checklistItems.every(item => {
                    return item.image;
                }))

                return checklistHasImages ?
                    <ButtonSelect
                        options={checklistItems}
                        onChange={this.handleFieldChange}
                        initialSelection={this.state.value}
                        multiselect={true}
                        hasError={isInvalid}
                        disabled={disabled}
                    />
                    :
                    <CheckboxGroup
                        items={checklistItems}
                        onSelectionChanged={this.handleFieldChange}
                        hasError={isInvalid}
                        checkedIcon={CheckboxChecked}
                        uncheckedIcon={CheckboxUnchecked}
                        disabledCheckedIcon={CheckboxDisabledChecked}
                        disabledUncheckedIcon={CheckboxDisabledUnchecked}
                        errorCheckedIcon={CheckboxErrorChecked}
                        errorUncheckedIcon={CheckboxErrorUnchecked}
                        disabled={disabled}
                        columnCount={fieldProperties.columnCount}
                    />

            case FIELD_TYPES.RADIO_BUTTONS:
                const radioButtonItems = possibleValues.map(item => ({
                    value: item.Identifier,
                    label: item.Label,
                    text: item.Label,
                    selected: this.state.value == item.Identifier, //ignore type
                    image: item.image,
                    helpText: item.HelpText && getNodeFromString(item.HelpText),
                }));

                const radioGroupHasImages = (radioButtonItems.every(item => {
                    return item.image;
                }))

                return radioGroupHasImages ?
                    <ButtonSelect
                        options={radioButtonItems}
                        onChange={this.handleFieldChange}
                        initialSelection={this.state.value}
                        multiselect={false}
                        hasError={isInvalid}
                        disabled={disabled}
                    />
                    :
                    <RadioGroup
                        items={radioButtonItems}
                        onSelectionChanged={this.handleFieldChange}
                        hasError={isInvalid}
                        selectedIcon={RadioSelectedSvg}
                        disabled={disabled}
                        columnCount={fieldProperties.columnCount}
                    />

            case FIELD_TYPES.SINGLE_CHOOSER:
                hasLiveSearch = !!field.Properties?.find(p => p.Name === 'liveSearch' && p.Data === true);

                const possibleValuesFormatted = possibleValues.map(item => ({
                    value: item.Identifier,
                    text: item.Label || '',
                    selected: this.state.value === item.Identifier,
                    additionalFields: item.AdditionalValues,
                }));

                let hasUnresolvedAnswers = true;
                liveSearchOptionsSafe = hasLiveSearch && _.get(liveSearchOptions, [field.TemplateAttribute.Name, 'Items']);
                const singleChooserItems = (liveSearchOptionsSafe
                    && liveSearchOptionsSafe.map((val) => {
                        const selected = this.state.value === val.value;
                        if (selected) {
                            hasUnresolvedAnswers = false;
                        }
                        return { ...val, selected };
                    }
                    ))
                    || possibleValuesFormatted;

                if (this.props.value && hasUnresolvedAnswers && singleChooserItems) {
                    if (_.get(this.props.resolvedLabels, [[field.TemplateAttribute.Name], [this.props.value]])) {
                        singleChooserItems && singleChooserItems.push && singleChooserItems.push({
                            value: this.props.value,
                            text: this.props.resolvedLabels[field.TemplateAttribute.Name][this.props.value],
                            selected: true
                        });
                    } else {
                        const values = Array.isArray(this.props.value) ? this.props.value : [this.props.value]
                        if (values.length > 0) {
                            resolveLabelBegin({ [field.TemplateAttribute.Name]: values });
                        }
                    }
                }

                return <SingleChooser
                    placeholder={field.Hint}
                    items={singleChooserItems}
                    noResultsText={noResultsText}
                    onSelectionChanged={this.handleFieldChange}
                    onOpen={this.scrollIntoView}
                    hasError={isInvalid}
                    showDropdownOnClick={true}
                    maxHeight={`${maxDropdownHeight}px`}
                    disabled={disabled}
                    liveSearchIsBeingProcessed={liveSearchIsBeingProcessed}
                    liveSearchFn={hasLiveSearch ? (text, Page = '') =>
                        liveSearchBegin(text, field.TemplateAttribute.Name, Page, userLanguage, elementGuid, selectedLocationId, columnKeys)
                        : null}
                    fullLiveSearchData={liveSearchOptions && liveSearchOptions[field.TemplateAttribute.Name] ||
                        this.wrapWithPageData(possibleValuesFormatted)}
                    fieldId={field.TemplateAttribute.Name}
                    columnNames={field.ColumnNames}
                    chooserTitle={chooserTitle}
                />

            case FIELD_TYPES.MULTI_CHOOSER:
                hasLiveSearch = !!field.Properties?.find(p => p.Name === 'liveSearch' && p.Data === true);

                const possibleValuesFormattedMulti = possibleValues.map(item => ({
                    value: item.Identifier,
                    text: item.Label || '',
                    selected: this.state.value && this.state.value.includes(item.Identifier),
                    additionalFields: item.AdditionalValues,
                }));

                liveSearchOptionsSafe = hasLiveSearch && _.get(liveSearchOptions, [field.TemplateAttribute.Name, 'Items']);
                const multiChooserItems = (liveSearchOptionsSafe
                    && liveSearchOptionsSafe.map((val) =>
                        this.state.value && this.state.value.includes(val.value) ? { ...val, selected: true } : val))
                    || possibleValuesFormattedMulti;
                const possibleItems = possibleValues.map(v => ({
                    value: v.Identifier,
                    text: v.Label,
                    selected: this.state.value && this.state.value.includes(v.Identifier),
                    additionalFields: v.AdditionalValues
                })) || possibleValuesFormattedMulti
                return <MultiChooser
                    placeholder={field.Hint}
                    items={possibleItems}
                    noResultsText={noResultsText}
                    onSelectionChanged={this.handleFieldChange}
                    onOpen={this.scrollIntoView}
                    hasError={isInvalid}
                    showDropdownOnClick={true}
                    maxHeight={`${maxDropdownHeight}px`}
                    disabled={disabled}
                    liveSearchFn={hasLiveSearch 
                        ? (text, Page = '') => liveSearchBegin(text, field.TemplateAttribute.Name, Page, userLanguage, elementGuid, selectedLocationId, columnKeys)
                        : null}
                    fullLiveSearchData={
                        (liveSearchOptions && liveSearchOptions[field.TemplateAttribute.Name]) ||
                        this.wrapWithPageData(possibleValuesFormattedMulti)}
                    liveSearchIsBeingProcessed={liveSearchIsBeingProcessed}
                    fieldId={field.TemplateAttribute.Name}
                    foundValues={multiChooserItems}
                    columnNames={field.ColumnNames}
                    chooserTitle={chooserTitle}
                />

            case FIELD_TYPES.BUTTON_SELECT:
                return <ButtonSelect
                    options={field.options}
                    onChange={this.handleFieldChange}
                    initialSelection={this.state.value}
                    multiselect={field.multiselect}
                    hasError={isInvalid}
                    disabled={disabled}
                />

            case FIELD_TYPES.DATE:
                const { pastDatesOnly, futureDatesOnly } = fieldProperties;
                return <ButtonDatePicker
                    onChange={this.handleFieldChange}
                    initialValue={this.state.value}
                    hasError={isInvalid}
                    todayLabel={todayLabel}
                    yesterdayLabel={yesterdayLabel}
                    otherLabel={otherLabel}
                    earlierLabel={earlierLabel}
                    selectDateLabel={selectDateLabel}
                    cancelLabel={cancelLabel}
                    applyLabel={applyLabel}
                    locale={selectedLanguage}
                    disabled={disabled}
                    pastDatesOnly={pastDatesOnly}
                    futureDatesOnly={futureDatesOnly}
                />

            case FIELD_TYPES.TIME:
                return <TimePicker
                    className="TimePicker-Override"
                    onChange={this.handleFieldChange}
                    initialValue={this.state.value || ""}
                    clearIcon={CloseGrey}
                    hasError={isInvalid}
                    is12Hour={field.Is12Hour}
                    disabled={disabled}
                />

            case FIELD_TYPES.CHECKLIST_MULTI_LEVEL:
                const checklistMultiLevelItems = possibleValues.map(item => ({
                    value: item.Identifier,
                    label: item.Label,
                    checked: this.state.value && this.state.value.includes(item.Identifier),
                    image: item.image,
                    helpText: item.HelpText && getNodeFromString(item.HelpText),
                }));

                return <ChecklistMultiLevel
                    options={getMultiLevelStructure(checklistMultiLevelItems)}
                    onSelectionChanged={this.handleFieldChange}
                    initialSelection={this.state.value}
                    hasError={isInvalid}
                    disabled={disabled}
                    isMandatory={isMandatory}
                    requiredFieldLabel={requiredFieldLabel}
                    columnCount={fieldProperties.columnCount}
                />

            case FIELD_TYPES.DROPDOWN_MULTI_LEVEL:
                const isLiveSearch = !!field.Properties?.find(p => p.Name === 'liveSearch' && p.Data === true);
                if (isLiveSearch) {
                    const possibleValuesFormattedMulti = possibleValues.map(item => ({
                        value: item.Identifier,
                        text: item.Label,
                        selected: this.props.value && (this.props.value.includes ?
                            this.props.value.includes(item.Identifier) : (this.props.value == item.Identifier)), //ignore type
                        additionalFields: item.AdditionalValues,
                    }));
                    let hasUnresolvedAnswers = true;
                    const multiChooserItems = (liveSearchOptions &&
                        liveSearchOptions[field.TemplateAttribute.Name] &&
                        liveSearchOptions[field.TemplateAttribute.Name]['Items'].map((item) => {
                            const selected = this.props.value && (this.props.value.includes ?
                                this.props.value.includes(item.value) : (this.props.value === item.value));
                            if (selected) {
                                hasUnresolvedAnswers = false;
                            }
                            return { ...item, selected };
                        }));
                    if (this.props.value && hasUnresolvedAnswers && multiChooserItems && multiChooserItems.length) {
                        if (_.get(this.props.resolvedLabels, [[field.TemplateAttribute.Name], [this.props.value]])) {
                            multiChooserItems && multiChooserItems.push && multiChooserItems.push({
                                value: this.props.value,
                                text: this.props.resolvedLabels[field.TemplateAttribute.Name][this.props.value],
                                selected: true
                            });
                        } else {
                            resolveLabelBegin({ [field.TemplateAttribute.Name]: [this.props.value] });
                        }
                    }

                    return <SingleChooser
                        placeholder={field.Hint}
                        items={multiChooserItems}
                        noResultsText={noResultsText}
                        onSelectionChanged={this.handleFieldChange}
                        onOpen={this.scrollIntoView}
                        hasError={isInvalid}
                        showDropdownOnClick={true}
                        maxHeight={`${maxDropdownHeight}px`}
                        disabled={disabled}
                        liveSearchFn={isLiveSearch ? (text, Page = '') =>
                            liveSearchBegin(text, field.TemplateAttribute.Name, Page, userLanguage, '', selectedLocationId, '')
                            : null}
                        fullLiveSearchData={
                            (liveSearchOptions && liveSearchOptions[field.TemplateAttribute.Name]) ||
                            this.wrapWithPageData(possibleValuesFormattedMulti)}
                        liveSearchIsBeingProcessed={liveSearchIsBeingProcessed}
                        fieldId={field.TemplateAttribute.Name}
                    />
                } else {
                    const dropdownMultiLevelItems = possibleValues.map(item => ({
                        value: item.Identifier,
                        label: item.Label,
                        selected: this.state.value && this.state.value.includes(item.Identifier),
                        image: item.image,
                        helpText: item.HelpText && getNodeFromString(item.HelpText),
                    }));
                    return <DropdownMultiLevel
                        options={getMultiLevelStructure(dropdownMultiLevelItems)}
                        onSelectionChanged={this.handleFieldChange}
                        initialSelection={this.state.value}
                        hasError={isInvalid}
                        disabled={disabled}
                        isMandatory={isMandatory}
                        requiredFieldLabel={requiredFieldLabel}
                        maxHeight={`${maxDropdownHeight}px`}
                    />;
                }

            default:
                return <div className="EmptyField"></div>;
        }
    }

    wrapWithPageData = (Items) => {
        return {
            ...this.getPageInfo(),
            Items
        };
    };

    render() {
        const { field, isMandatory, isInvalid, objectPicklistLoading, value, fieldSettings, selectedLanguage, possibleValues } = this.props;
        const objectPicklistHidden = field.TemplateAttribute.ObjectName && (objectPicklistLoading || (_.isEmpty(possibleValues)));
        const hideInvalidMessage = (field.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL || field.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL) && isValidAnswer(value);
        const fieldIsoCode = getMultilingualIsoCode(field, fieldSettings, selectedLanguage);
        const isHidden = field.IsPermanentlyHidden;
        const disabled = this.props.disabled || field.IsAlwaysDisabled;
        const isFieldMandatory = isMandatory && !disabled && !isHidden;

        return (
            <div ref={this.container} style={isHidden ? {display: 'none'} : null}>
                {
                    field.Title && field.RenderingType !== FIELD_TYPES.TOGGLE && !objectPicklistHidden &&
                    <div className="FieldLabel">
                        {field.Title}
                        {fieldIsoCode && ` (${fieldIsoCode.toLowerCase()})`}
                        {
                            (isFieldMandatory || isInvalid) &&
                            <span className="FieldMandatoryIndicator">
                                {' * '}
                                {isInvalid && !hideInvalidMessage && isInvalid.message}
                            </span>
                        }
                    </div>
                }
                {
                    !objectPicklistHidden &&
                    <div className="FieldContainer">
                        {this.getField()}
                    </div>
                }
            </div>
        );
    }
}

const getMultilingualIsoCode = (field, fieldSettings, userLanguage) => {
    let result = '';

    if (field.RenderingType === FIELD_TYPES.TEXT_INPUT || field.RenderingType === FIELD_TYPES.TEXT_AREA) {

        if (fieldSettings && fieldSettings.languageIsoCode) {
            result = fieldSettings.languageIsoCode;
        }
        else {
            let languageOptionsConfig = field.Properties && field.Properties.find((item) => item.Name === 'languages');

            if (languageOptionsConfig && languageOptionsConfig.Data) {
                const isoCodes = languageOptionsConfig.Data.split(',');
                if (isoCodes.includes(userLanguage)) {
                    result = userLanguage;
                }
                else if (isoCodes.length > 0) {
                    result = isoCodes[0];
                }
            }
        }
    }

    return result;
}

const getFieldIdentifierForAnswer = (field, fieldSettings, userLanguage) => {
    const fieldIsoCode = getMultilingualIsoCode(field, fieldSettings, userLanguage);

    return (fieldIsoCode && fieldIsoCode !== EN_GB) ? `${field.TemplateAttribute.Name}.${fieldIsoCode}` : field.TemplateAttribute.Name;
}


const mapStateToProps = (state, ownProps) => {
    const fieldIdentifier = getFieldIdentifierForAnswer(ownProps.field, getFieldSettings(state, ownProps.field.TemplateAttribute.Name), state.appReducer.userSettings.language);
    return {
        fullState: state,
        value: getFieldAnswer(state, fieldIdentifier),
        noResultsText: state.appReducer.appStrings[Strings.noResults],
        httpClient: new HttpClient(state),
        appSettings: getAppSettingsFromState(state),
        isMandatory: getCurrentPageFieldIsMandatory(ownProps.field.TemplateAttribute.Name, state),
        isInvalid: getCurrentPageFieldIsInvalid(ownProps.field.TemplateAttribute.Name, state),
        isFirstInvalid: getCurrentPageFieldIsFirstInvalid(ownProps.field.TemplateAttribute.Name, state),
        todayLabel: getString(Strings.today, state),
        yesterdayLabel: getString(Strings.yesterday, state),
        otherLabel: getString(Strings.other, state),
        earlierLabel: getString(Strings.earlier, state),
        selectDateLabel: getString(Strings.selectDate, state),
        cancelLabel: getString(Strings.cancel, state),
        applyLabel: getString(Strings.apply, state),
        selectedLanguage: state.appReducer.userSettings.language,
        defaultValue: getDefaultValue(state, { field: ownProps.field, elementGuid: ownProps.elementGuid, element: undefined }),
        possibleValues: getPossibleValues(state, ownProps.field),
        objectPicklistLoading: getFieldObjectPicklistLoading(state, ownProps.field),
        isUpdated: getFieldIsUpdated(state, ownProps.field.TemplateAttribute.Name),
        requiredFieldLabel: getString(Strings.requiredField, state),
        contentDimensions: state.appReducer.contentDimensions,
        translationIsBeingProcessed: state.wizardReducer.translationIsBeingProcessed,
        liveSearchIsBeingProcessed: state.wizardReducer.liveSearchIsBeingProcessed,
        chooserTitle: getString(Strings.chooserTitle, state),
        speechToTextValue: getFieldSpeechToTextValue(state, ownProps.field.TemplateAttribute.Name),
        firstReportSettings: getFirstReportSettings(state),
        micInUse: getFieldMicInUse(state, ownProps.field.TemplateAttribute.Name),
        availableLanguages: getAvailableLanguages(state),
        languagesThatCanBeTranslatedByApi: state.appReducer.languagesThatCanBeTranslatedByApi,
        fieldSettings: getFieldSettings(state, ownProps.field.TemplateAttribute.Name),
        liveSearchOptions: state.wizardReducer.liveSearchOptions,
        resolvedLabels: state.wizardReducer.answers.resolvedLabels,
        userLanguage: state.appReducer.userSettings.language,
        isProccesingSimilarRecords: state.appReducer.similarRecordsFieldSpinner[fieldIdentifier],
        isEnabledFuzzySearch: getProfileValue('Company.FirstReport.SimilarRecordSearch', state),
        fuzzySearchViewId: getProfileValue('Company.FirstReport.SimilarRecordsView', state),
        isRestictedFuzzySearchToUserLocation: getProfileValue('Company.FirstReport.LimitSimilarRecordSearchToUserToCurrentLocation', state),
        isEnabledAutoTranslation: getProfileValue('Company.Interface.AutoTranslation', state),
        useLocationRestrictions: getUseFormLocationRestrictions(state),
        activeLocation: getActiveLocation(state),
        selectedLocationId: getSelectedLocationId(state),        
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFieldAnswer: (fieldId, value) => {
        dispatch(updateFieldAnswer(fieldId, value));
    },
    loadFieldDependentDefaultValues: (fieldId, value) => {
        dispatch(loadFieldDependentDefaultValues(fieldId, value));
    },
    updateFieldSetting: (fieldId, name, value) => {
        dispatch(updateFieldSetting(fieldId, name, value));
    },
    clearScrollToFirst: () => {
        dispatch(clearScrollToFirst());
    },
    speechToTextBegin: (fieldId) => {
        dispatch(speechToTextBegin(fieldId));
    },
    showThisLanguageValueForm: (name, text, fromLanguage, toLanguage) => {
        dispatch(showThisLanguageValueForm(name, text, fromLanguage, toLanguage));
    },
    translationBegin: (fieldId, settingValue, fromLanguage, toLanguage) => {
        dispatch(translationBegin(fieldId, settingValue, fromLanguage, toLanguage));
    },
    liveSearchBegin: (SearchQuery, AttributeName, Page, Language, ElementGuid, LocationId, ColumnNames) => {
        dispatch(liveSearchBegin(SearchQuery, AttributeName, Page, Language, ElementGuid, LocationId, ColumnNames));
    },
    liveSearchSuccess: (data, AttributeName) => {
        dispatch(liveSearchSuccess(data, AttributeName));
    },
    resolveLabelBegin: (paramObj) => {
        dispatch(resolveLabelBegin(paramObj));
    }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Field);
