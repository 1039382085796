import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import style from './ReportingUser.module.scss';
import { TextInput, ButtonSelect } from 'spheracloud-common';
import { setManualUser } from '../../actions/appActions';
import { setAuthType } from '../../actions/authActions';
import { goToNextPageBegin, setUserType } from '../../actions/wizardActions';
import { getAppSettingsFromState, getFormUser, getProfileValue, getSelectedForm, getDefaultUserTypeId } from '../../selectors/appSelectors';
import { Incognito, AccountBlue, Details, SSO } from '../../icons/index';
import { USER_TYPES } from '../../constants/UserTypes';
import Strings from '../../constants/Strings';
import Translation from '../../containers/Translation/Translation';
import { getUserTypeHasError, getManualNameHasError, getManualEmailHasError, getManualPhoneHasError } from '../../selectors/wizardSelectors';
import { isLoggedInUserType } from '../../utils/utils';

class ReportingUser extends React.Component {

    constructor(props) {
        super(props);
        this.handleReportingUserTypeChange = this.handleReportingUserTypeChange.bind(this);
        this.manualNameInput = React.createRef();

        const { userTypeSelected, user, setUserType, defaultUserTypeId } = props;

        if (user === null && isLoggedInUserType(userTypeSelected)) {
            setUserType(defaultUserTypeId);
        }
    }

    handleReportingUserTypeChange(selected) {
        const { setAuthType, history, defaultUserTypeId, userTypeSelected } = this.props;

        if (selected !== '') {
            setAuthType(parseInt(selected), history);
        } else {
            setAuthType(userTypeSelected === defaultUserTypeId ? -1 : defaultUserTypeId, history);
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.userTypeSelected === USER_TYPES.MANUAL && this.props.userTypeSelected !== prevProps.userTypeSelected) {
            this.manualNameInput.current.focus();
        }
    }

    onUserInfoChanged(field, value) {
        const { setManualUser } = this.props;

        setManualUser({
            [field]: value
        });
    }

    render() {
        const { page, user, userInfo, userTypeSelected, shortCode, userTypeHasError, ssoLink, isSSOOnly, domainHint, manualNameHasError, manualEmailHasError, manualPhoneHasError, selectedForm, allowAnonReporting, appSettings, loginButtonLabel, ssoLoginButtonLabel } = this.props;

        const isB2C = appSettings.AUTHENTICATED_USER_SETTINGS.ISB2C === 'true';

        const items = [
            ...(selectedForm.AllowAnonReporting && selectedForm.ShowAnonReportingOption && allowAnonReporting  ?
                [{
                    label: <Translation>{Strings.reportAnonymously}</Translation>,
                    image: Incognito,
                    value: USER_TYPES.ANONYMOUS.toString(),
                    backgroundSize: '108px',
                }]
                :
                []
            ),
            ...(!isSSOOnly && !isB2C ?
                [{
                    label: !!user & userTypeSelected === USER_TYPES.AUTHENTICATED ? <><Translation>{Strings.loggedInAs}</Translation> {userInfo.name}</> : loginButtonLabel,
                    image: AccountBlue,
                    value: USER_TYPES.AUTHENTICATED.toString(),
                    disabled: userTypeSelected === USER_TYPES.SSOAUTHENTICATED,
                    backgroundSize: '84px',
                }]
                :
                []
            ),
            ...(ssoLink && !isB2C ?
                [{
                    label: !!user & userTypeSelected === USER_TYPES.SSOAUTHENTICATED ? <><Translation>{Strings.loggedInAs}</Translation> {userInfo.name}</> : ssoLoginButtonLabel,
                    image: SSO,
                    value: USER_TYPES.SSOAUTHENTICATED.toString(),
                    disabled: userTypeSelected === USER_TYPES.AUTHENTICATED,
                    backgroundSize: '108px',
                }]
                :
                []
            ),
            ...(isB2C ?
                [{
                    label: !!user & userTypeSelected === USER_TYPES.B2CAUTHENTICATED ? <><Translation>{Strings.loggedInAs}</Translation> {userInfo.name}</> : loginButtonLabel,
                    image: AccountBlue,
                    value: USER_TYPES.B2CAUTHENTICATED.toString(),
                    disabled: userTypeSelected === USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN,
                    backgroundSize: '84px',
                }]
                :
                []
            ),
            ...(isB2C && domainHint ?
                [{
                    label: !!user & userTypeSelected === USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN ? <><Translation>{Strings.loggedInAs}</Translation> {userInfo.name}</> : ssoLoginButtonLabel,
                    image: SSO,
                    value: USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN.toString(),
                    disabled: userTypeSelected === USER_TYPES.B2CAUTHENTICATED,
                    backgroundSize: '108px',
                }]
                :
                []
            ),
            ...(selectedForm.AllowAnonReporting && selectedForm.ShowManualReportingOption ?
                [{
                    label: <Translation>{Strings.enterInfoManually}</Translation>,
                    image: Details,
                    value: USER_TYPES.MANUAL.toString(),
                    backgroundSize: '84px',
                }]
                :
                []
            )
        ];



        return (
            <>
                <div className="WizardPageTitle">{page.ScreenTitle}</div>
                <div className="WizardPageHelpText">{page.HelpText}</div>
                <div className="WizardPageElements">
                    <div className="ReportingUserContainer">
                        <ButtonSelect
                            disabled={isLoggedInUserType(userTypeSelected) && !!shortCode}
                            options={items}
                            onChange={this.handleReportingUserTypeChange}
                            initialSelection={userTypeSelected.toString()}
                            hasError={userTypeHasError}
                        />
                        {
                            userTypeSelected === USER_TYPES.MANUAL ?
                                <div className={style.ReportingUserManualInfoContainer}>
                                    <div className="ReportingUserInputContainer">
                                        <div className="FieldLabel">
                                        <Translation>{Strings.name}</Translation>
                                            {
                                                (selectedForm.ManualReportingNameMandatory || manualNameHasError) &&
                                                <span className="FieldMandatoryIndicator">
                                                    {' * '}
                                                    {manualNameHasError && <Translation>{Strings.requiredField}</Translation>}
                                                </span>
                                            }
                                        </div>
                                        <div className="FieldContainer">
                                            <TextInput placeholder={"Enter Name..."} initialText={userInfo.name} hasError={manualNameHasError} onTextChanged={(val) => this.onUserInfoChanged('name', val)} ref={this.manualNameInput} />
                                        </div>
                                    </div>
                                    <div className="FieldLabel">
                                    <Translation>{Strings.email}</Translation>
                                        {
                                            (selectedForm.ManualReportingEmailMandatory || manualEmailHasError) &&
                                            <span className="FieldMandatoryIndicator">
                                                {' * '}
                                                {manualEmailHasError && <Translation>{userInfo.email ? Strings.invalidEmail : Strings.requiredField}</Translation>}
                                            </span>
                                        }
                                    </div>
                                    <div className="FieldContainer">
                                        <TextInput placeholder={"Enter Email..."} initialText={userInfo.email} hasError={manualEmailHasError} onTextChanged={(val) => this.onUserInfoChanged('email', val)} />
                                    </div>
                                    <div className="FieldLabel">
                                    <Translation>{Strings.phoneNumber}</Translation>
                                        {
                                            (selectedForm.ManualReportingPhoneMandatory || manualPhoneHasError) &&
                                            <span className="FieldMandatoryIndicator">
                                                {' * '}
                                                {manualPhoneHasError && <Translation>{userInfo.phone ? Strings.invalidPhone : Strings.requiredField}</Translation>}
                                            </span>
                                        }
                                    </div>
                                    <div className="FieldContainer">
                                        <TextInput placeholder={"Enter Phone Number..."} initialText={userInfo.phone} hasError={manualPhoneHasError} type="tel" onTextChanged={(val) => this.onUserInfoChanged('phone', val)} />
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userTypeSelected: state.wizardReducer.userTypeSelected,
    shortCode: state.appReducer.draftShortCode,
    user: state.auth.user,
    userInfo: getFormUser(state),
    userTypeHasError: getUserTypeHasError(state),
    manualNameHasError: getManualNameHasError(state),
    manualEmailHasError: getManualEmailHasError(state),
    manualPhoneHasError: getManualPhoneHasError(state),
    ssoLink: state.appReducer.linkForSSO,
    isSSOOnly: state.appReducer.isSSOOnly,
    domainHint: state.appReducer.domainHint,
    selectedForm: getSelectedForm(state),
    allowAnonReporting: state.appReducer.allowAnonReporting,
    appSettings: getAppSettingsFromState(state),
    loginButtonLabel: getProfileValue('Company.Portal.LoginButtonLabel', state),
    ssoLoginButtonLabel: getProfileValue('Company.Portal.SSOLoginButtonLabel', state),
    defaultUserTypeId: getDefaultUserTypeId(state),
});

const mapDispatchToProps = dispatch => ({
    setManualUser: (userInfo) => { dispatch(setManualUser(userInfo)); },
    goToNextPageBegin: (userInfo) => { dispatch(goToNextPageBegin(userInfo)); },
    setAuthType: (type, history) => { dispatch(setAuthType(type, history)); },
    setUserType: (type) => { dispatch(setUserType(type)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingUser));
