import React from 'react';
import './BodyMapBody.scss';

class BodyMapBody extends React.Component {

    getFillClass(part) {
        const { selected } = this.props;
        return selected[part] ? "BodyMapPartSelected" : "BodyMapPart";
    }

    render() {
        const { onPartClick } = this.props;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352.09 331">
                <title>Body Part Chart</title>
                <path id="back-arms" className="BodyMapPartUnclickable" d="M273.54,123.54l-3.14-4.36c-1.48,3.3-3.38,15.34-3.29,21s-4,16.23-4.64,18.43-5.84,18.95-8.7,22.5a11.43,11.43,0,0,0-2.17,5.43A72.9,72.9,0,0,0,250.19,198c0,4.23-1.86,6.34-3,9.88s-1,8.78-2.83,8.78c-1.63-.22-.64-5.73-.73-6.73a20.81,20.81,0,0,1,.62-4c-.23.74-2.28,7.4-2.65,8.47s-.9,4.36-2.58,4.18.72-7.64.81-8.86a19.9,19.9,0,0,1,1.36-4.12c-.91,1.13-3.22,7.74-3.67,9s-1,4.13-2,4-1.09-.36-1.13-1.93,1.81-6.62,2.31-8.8.32-2,.32-2c-1,.55-1.68,5-3,8s-2,2.83-2,2.83c-2.31-.05-.27-5,0-6.25s1-4.34,1.27-6,.26-2.87.4-3.93.32-3,.32-3a8.21,8.21,0,0,0-2.63,1.8,4.24,4.24,0,0,1-3.48,1.12c-1.63-.36-1.13-1.52-.5-2.28a53.42,53.42,0,0,1,9.82-9.71c5.44-4,4.95-10.12,5.49-14.77s1.23-12.82,2.4-18.65,5.16-14.33,8.78-21.29,2.35-16.8,2.13-25.51c-.2-7.37.59-10.34,2.86-14.17.24-.42.47-.83.69-1.26a25.37,25.37,0,0,1,15.77-13c7.86-2,12.16-5,14.2-8.26H315.1c2,3.3,6.34,6.24,14.21,8.28a25.39,25.39,0,0,1,15.77,12.93c.22.43.45.84.69,1.26,2.27,3.83,3.06,6.8,2.86,14.17-.22,8.71-1.49,18.55,2.13,25.51s7.6,15.45,8.78,21.29,1.85,14,2.4,18.65.05,10.77,5.49,14.77a53.73,53.73,0,0,1,9.82,9.71c.6.76,1.13,1.92-.41,2.29a4.24,4.24,0,0,1-3.48-1.12,8.44,8.44,0,0,0-2.63-1.8s.19,1.94.32,3,.08,2.23.4,3.93,1,4.75,1.27,6,2.31,6.25,0,6.25c0,0-.68.23-2-2.83s-2.09-7.5-3-8c0,0-.18-.15.32,2s2.36,7.23,2.31,8.8-.13,1.8-1.13,1.93-1.54-2.72-1.95-4-2.76-7.9-3.67-9a20.51,20.51,0,0,1,1.36,4.12c.09,1.22,2.48,8.69.81,8.86s-2.24-3.15-2.58-4.18-2.42-7.73-2.65-8.47a21.62,21.62,0,0,1,.62,4c-.09,1,.9,6.51-.73,6.73-1.83,0-1.68-5.25-2.83-8.78s-3-5.65-3-9.88a73.19,73.19,0,0,0-1.53-11.43A11.64,11.64,0,0,0,351,181.1c-2.86-3.55-8.06-20.3-8.7-22.5s-4.73-12.76-4.64-18.43-1.81-17.71-3.29-21l-3.15,4.37Z" transform="translate(-25.76 -26.52)" />
                <path id="back-legs" className="BodyMapPartUnclickable" d="M272.3,161.54h60.12a155,155,0,0,1,5.08,37.07c.62,26.86-2.47,37.57-2.16,49.86s5.46,34.62,1.55,48.74-9,25.28-9,32.2v9.82a12.48,12.48,0,0,0,2.23,7.11c1.56,2.28,3.65,5,5.33,5.81,3.08,1.56.62,5.37-3.86,5.37h-12a5.56,5.56,0,0,1-3.09-.94,4.56,4.56,0,0,1-2-4.24c.34-3.19.95-9,1.55-15.72.93-10.32.62-35.73-.92-45.2s4.16-19.5,1.39-26.28c-2.67-6.6-5.58-11-6.16-33.54v-1.79a19.56,19.56,0,0,0-2.17-8.17c-2.23-4.73-5.27-18.59-5.84-27.1-.57,8.51-3.61,22.37-5.84,27.1a19.27,19.27,0,0,0-2.17,8.17v1.79c-.56,22.54-3.42,26.91-6.14,33.54-2.77,6.78,2.93,16.82,1.39,26.28s-1.85,34.88-.92,45.2c.55,6.72,1.21,12.53,1.55,15.72a4.58,4.58,0,0,1-2,4.24,5.59,5.59,0,0,1-3.09.94h-12c-4.48,0-6.94-3.81-3.86-5.37,1.68-.81,3.77-3.53,5.33-5.81a12.63,12.63,0,0,0,2.23-7.11v-9.82c0-6.92-5.15-18.07-9-32.2s1.25-36.46,1.55-48.74-2.79-23-2.16-49.86A155,155,0,0,1,272.3,161.54Z" transform="translate(-25.76 -26.52)" />
                <path id="back-lower" onClick={() => {onPartClick('back-lower')}} className={this.getFillClass('back-lower')} d="M275,144.54h54.66A50.51,50.51,0,0,0,330,152c.27,1.69,1,4.17,1.94,7.54H272.83c.92-3.36,1.66-5.83,1.94-7.53A50.73,50.73,0,0,0,275,144.54Z" transform="translate(-25.76 -26.52)" />
                <path id="back-mid" onClick={() => {onPartClick('back-mid')}} className={this.getFillClass('back-mid')} d="M274.1,125.54h56.52c-.22,3-.72,10.55-.89,17H275C274.82,136.08,274.31,128.56,274.1,125.54Z" transform="translate(-25.76 -26.52)" />
                <path id="back-upper" onClick={() => {onPartClick('back-upper')}} className={this.getFillClass('back-upper')} d="M334.32,119.17l-3.15,4.37H273.54l-3.14-4.36-14.33-11c-.2-7.37.59-10.34,2.86-14.17.24-.42.47-.83.69-1.26a25.37,25.37,0,0,1,15.77-13c7.86-2,12.16-5,14.2-8.26H315.1c2,3.3,6.34,6.24,14.21,8.28a25.39,25.39,0,0,1,15.77,12.93c.22.43.45.84.69,1.26,2.27,3.83,3.06,6.8,2.86,14.17Z" transform="translate(-25.76 -26.52)" />
                <path id="back-head" onClick={() => {onPartClick('back-head')}} className={this.getFillClass('back-head')} d="M290.54,69.54a10.47,10.47,0,0,0,.57-3.44c0-5.37-1.23-7.06-1.23-7.06-3.52-.86-5-5.7-5.52-8.76a2.24,2.24,0,0,1,2.36-2.54c0-21.86,14.35-21.29,15.63-21.2,1.28-.07,15.63-.64,15.66,21.22a2.22,2.22,0,0,1,1.77.71,2.27,2.27,0,0,1,.59,1.83c-.52,3.06-2,7.9-5.52,8.76,0,0-1.26,1.69-1.26,7.06a10.34,10.34,0,0,0,.56,3.42Z" transform="translate(-25.76 -26.52)" />
                <path id="foot-left" onClick={() => {onPartClick('foot-left')}} className={this.getFillClass('foot-left')} d="M115.06,336.12l11.78,3.6a12.56,12.56,0,0,0,2.23,6.63,19.63,19.63,0,0,0,5.25,5.72c3.09,1.56.61,5.36-3.86,5.36H118.52a5.5,5.5,0,0,1-3.09-.93,4.61,4.61,0,0,1-2-4.23c.34-3.18,1-8.95,1.55-15.72C115,336.41,115.05,336.27,115.06,336.12Z" transform="translate(-25.76 -26.52)" />
                <path id="ankle-left" onClick={() => {onPartClick('ankle-left')}} className={this.getFillClass('ankle-left')} d="M115.21,334.08c.1-1.63.18-3.47.23-5.47L128,322.2a29.42,29.42,0,0,0-1.18,7.23v8.2" transform="translate(-25.76 -26.52)" />
                <path id="shin-left" onClick={() => {onPartClick('shin-left')}} className={this.getFillClass('shin-left')} d="M114.61,280.54l21.67-11c1,9.73,1.6,20-.51,27.78-2.34,8.62-5.18,16.16-7,22.3l-13.26,6.75c.23-11.92-.24-27.88-1.39-35A32.34,32.34,0,0,1,114.61,280.54Z" transform="translate(-25.76 -26.52)" />
                <path id="knee-left" onClick={() => {onPartClick('knee-left')}} className={this.getFillClass('knee-left')} d="M115.08,278.06c1-4.9,1.88-9.44.42-13-.28-.69-.57-1.36-.85-2L134.49,253c.36,4.25,1,9.21,1.57,14.41Z" transform="translate(-25.76 -26.52)" />
                <path id="thigh-left" onClick={() => {onPartClick('thigh-left')}} className={this.getFillClass('thigh-left')} d="M104.14,211.84l32.14-16.38c0,1,.08,2.13.11,3.23.63,26.84-2.46,37.56-2.15,49.85,0,.72,0,1.47.1,2.25l-20.47,10.43c-2.15-5.23-4.06-11.61-4.51-29.67v-1.79a19.72,19.72,0,0,0-2.2-8.13A55.77,55.77,0,0,1,104.14,211.84Z" transform="translate(-25.76 -26.52)" />
                <path id="hand-left" onClick={() => {onPartClick('hand-left')}} className={this.getFillClass('hand-left')} d="M152.12,187l10.35-3.57a11.37,11.37,0,0,0,3.86,4.91,53.74,53.74,0,0,1,9.83,9.7c.63.76,1.14,1.93-.53,2.25a4.15,4.15,0,0,1-3.41-1.12,8.3,8.3,0,0,0-2.63-1.79s.2,1.91.32,3a36.62,36.62,0,0,0,.39,3.94c.33,1.66,1.06,4.81,1.28,6s2.27,6.24,0,6.24c0,0-.76.15-2-2.84s-2-7.45-2.94-7.94c0,0-.2-.2.31,2s2.37,7.25,2.32,8.82-.12,1.79-1.13,1.93-1.55-2.74-2-4a59.17,59.17,0,0,0-3.65-9,20,20,0,0,1,1.35,4.13c.08,1.22,2.49,8.68.82,8.85s-2.24-3.17-2.58-4.18-2.42-7.73-2.66-8.46a20.9,20.9,0,0,1,.61,4c-.08,1.06,1,6.55-.71,6.77-1.88,0-1.69-5.27-2.83-8.79s-3-5.63-3-9.88A74.32,74.32,0,0,0,152.12,187Z" transform="translate(-25.76 -26.52)" />
                <path id="forearm-left" onClick={() => {onPartClick('forearm-left')}} className={this.getFillClass('forearm-left')} d="M149,132.27a15.3,15.3,0,0,0,.67,1.42c3.65,7,7.57,15.48,8.77,21.29s1.86,14,2.4,18.61a56.75,56.75,0,0,0,1,8L151.73,185a11.34,11.34,0,0,0-1.87-3.94c-2.85-3.56-8.05-20.29-8.68-22.49s-4.76-12.8-4.64-18.45c0-1,0-2.22-.12-3.55" transform="translate(-25.76 -26.52)" />
                <path id="shoulder-left" onClick={() => {onPartClick('shoulder-left')}} className={this.getFillClass('shoulder-left')} d="M131,80.76a25.36,25.36,0,0,1,13,12c.24.43.46.85.7,1.26,2.27,3.84,3,6.8,2.86,14.18s-1.15,15.61.81,22.19l-12.11,4.17c-.58-5.83-1.91-12.9-3-15.38l-1.6,2.18Z" transform="translate(-25.76 -26.52)" />
                <path id="head" onClick={() => {onPartClick('head')}} className={this.getFillClass('head')} d="M113,69.52H89.45A10.48,10.48,0,0,0,90,66C90,60.65,88.78,59,88.78,59c-3.53-.85-5-5.69-5.51-8.75a2.22,2.22,0,0,1,.6-1.78,2.27,2.27,0,0,1,1.76-.71c0-21.86,14.35-21.2,15.62-21.2s15.63-.64,15.63,21.25a2.3,2.3,0,0,1,1.79.72,2.26,2.26,0,0,1,.59,1.82c-.57,3.07-2,7.91-5.53,8.75,0,0-1.23,1.71-1.23,7.08A10.62,10.62,0,0,0,113,69.52Z" transform="translate(-25.76 -26.52)" />
                <path id="face" onClick={() => {onPartClick('face')}} className={this.getFillClass('face')} d="M111.92,46.31c0-7.58-4.76-13.72-10.63-13.72S90.67,38.73,90.67,46.31,95.42,60,101.29,60,111.89,53.85,111.92,46.31Z" transform="translate(-25.76 -26.52)" />
                <path id="chest" onClick={() => {onPartClick('chest')}} className={this.getFillClass('chest')} d="M114,71.52c2,3.31,6.33,6.27,14.24,8.31l.7.2.77,44-.09.12s-1.76,22.17-.77,27.82c.24,1.35.76,3.18,1.41,5.56H72.33c.67-2.36,1.18-4.18,1.4-5.52.55-3.29.2-12.15-.19-19h.3l-.92-52.84c.45-.16.91-.3,1.38-.44,7.83-2,12.13-4.93,14.18-8.2Z" transform="translate(-25.76 -26.52)" />
                <path id="shoulder-right" onClick={() => {onPartClick('shoulder-right')}} className={this.getFillClass('shoulder-right')} d="M70.93,80.93l.72,41.44-2.3-3.17c-1,2.23-2.18,8.43-2.82,14l-12-4.15c1.48-6.32.65-14,.46-20.93-.19-7.42.59-10.34,2.86-14.19.24-.4.48-.82.7-1.25A25.4,25.4,0,0,1,70.93,80.93Z" transform="translate(-25.76 -26.52)" />
                <path id="forearm-right" onClick={() => {onPartClick('forearm-right')}} className={this.getFillClass('forearm-right')} d="M66.32,135.21c-.17,1.88-.27,3.61-.25,5,.09,5.67-4,16.26-4.64,18.44s-5.85,18.94-8.7,22.49a11.77,11.77,0,0,0-1.85,3.82l-10.19-3.51a57.93,57.93,0,0,0,1-7.94c.54-4.6,1.23-12.78,2.4-18.61s5.17-14.41,8.79-21.29A17.11,17.11,0,0,0,54,131Z" transform="translate(-25.76 -26.52)" />
                <path id="hand-right" onClick={() => {onPartClick('hand-right')}} className={this.getFillClass('hand-right')} d="M50.49,186.92A72.18,72.18,0,0,0,49,198c0,4.24-1.86,6.34-3,9.88s-1.19,9-2.83,8.78-.63-5.74-.73-6.77a20.29,20.29,0,0,1,.63-4c-.24.73-2.31,7.38-2.66,8.46s-.89,4.35-2.58,4.18.8-7.63.8-8.85A20.85,20.85,0,0,1,40,205.52a60.75,60.75,0,0,0-3.67,9c-.41,1.27-.94,4.15-1.95,4s-1.11-.35-1.14-1.93,1.83-6.66,2.32-8.81.31-2,.31-2c-.91.49-1.67,4.93-2.94,7.94s-2,2.84-2,2.84c-2.31,0-.28-5,0-6.24s.85-4.3,1.26-6a25.48,25.48,0,0,0,.41-4c.14-1,.32-3,.32-3a8,8,0,0,0-2.62,1.7,4.17,4.17,0,0,1-3.5,1.11c-1.62-.35-1.13-1.52-.5-2.28a54.38,54.38,0,0,1,9.83-9.7,11.2,11.2,0,0,0,3.88-4.93Z" transform="translate(-25.76 -26.52)" />
                <path id="waist" onClick={() => {onPartClick('waist')}} className={this.getFillClass('waist')} d="M134.9,179.52H67.59a148.57,148.57,0,0,1,4.18-20h59A150.49,150.49,0,0,1,134.9,179.52Z" transform="translate(-25.76 -26.52)" />
                <path id="groin" onClick={() => {onPartClick('groin')}} className={this.getFillClass('groin')} d="M103.68,209.83a109.93,109.93,0,0,1-2.37-15.27,110.69,110.69,0,0,1-2.21,14.5L66.36,192.38c.22-3.92.56-7.54,1-10.86h67.83c.44,3.57.8,7.48,1,11.75Z" transform="translate(-25.76 -26.52)" />
                <path id="foot-right" onClick={() => {onPartClick('foot-right')}} className={this.getFillClass('foot-right')} d="M87.5,336c0,.21,0,.42,0,.62.6,6.75,1.22,12.54,1.56,15.72a4.59,4.59,0,0,1-2,4.25,5.55,5.55,0,0,1-3.09.93H72c-4.47,0-6.92-3.81-3.85-5.37a19.84,19.84,0,0,0,5.34-5.8,12.59,12.59,0,0,0,2.15-7.23V339Z" transform="translate(-25.76 -26.52)" />
                <path id="ankle-right" onClick={() => {onPartClick('ankle-right')}} className={this.getFillClass('ankle-right')} d="M87.36,334l-11.68,2.91v-7.58a26.24,26.24,0,0,0-.82-5.81l12.32,6.28C87.23,331.29,87.29,332.7,87.36,334Z" transform="translate(-25.76 -26.52)" />
                <path id="shin-right" onClick={() => {onPartClick('shin-right')}} className={this.getFillClass('shin-right')} d="M87.11,327.5l-12.93-6.59c-1.8-6.36-4.89-14.41-7.44-23.75-2-7.47-1.57-17.23-.64-26.59l22.07,11.24a30.24,30.24,0,0,1,.31,9.63C87.29,298.72,86.84,315.48,87.11,327.5Z" transform="translate(-25.76 -26.52)" />
                <path id="knee-right" onClick={() => {onPartClick('knee-right')}} className={this.getFillClass('knee-right')} d="M87.73,279.34l-21.41-10.9c.78-7.23,1.75-14.1,2-19.31l21.12,10.76c-.7,1.82-1.45,3.44-2.21,5.23C85.53,269,86.71,274,87.73,279.34Z" transform="translate(-25.76 -26.52)" />
                <path id="thigh-right" onClick={() => {onPartClick('thigh-right')}} className={this.getFillClass('thigh-right')} d="M90.07,258,68.31,246.91c0-11.69-2.74-22.61-2.19-48.34,0-1.37.08-2.7.13-4l32.4,16.51a61.58,61.58,0,0,1-3.18,10.55,19.26,19.26,0,0,0-2.17,8.16v1.79C93,246.18,91.69,253.1,90.07,258Z" transform="translate(-25.76 -26.52)" />
            </svg>
        );
    }
}

export default BodyMapBody;