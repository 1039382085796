import _ from 'lodash';
import HttpClient from '../utils/httpClient';
import * as AppActions from '../actions/appActions';
import * as WizardActions from '../actions/wizardActions';
import * as AuthActions from '../actions/authActions';
import endpoints from '../constants/Endpoints';
import {
    getAppSettings,
    getSelectedTemplateFormName,
    getFormUser,
    getString,
    getAllFormFieldAttributeNames,
    getAllRelatedObjectAttributeNames,
    getSelectedFormTypeId,
    getFormField,
    getRelatedObjectField,
    getDraftAccidentId,
    getDraftShortCode,
    getElements,
    getRelatedObjectElements,
    isAuthenticated,
    getAuthInfo,
    getAppSettingsFromState,
    getAccidentGuid,
    getActiveLocationId,
} from '../selectors/appSelectors';
import {
        getAnswerAttributes,
    getElementAnswers,
    getSubPageObjectAnswers,
    getLocationElements,
    getElementVisibility,
    getNotEmptyLangFieldsForPrimaryLang, getMultiName, getCompanyPrimaryLanguageData, getSelectedLocationId
} from '../selectors/wizardSelectors';
import { USER_TYPES } from '../constants/UserTypes';
import {
    REPORTING_USER,
    IS_DRAFT_ATTRIBUTE,
    ACCIDENT_GUID_ATTRIBUTE,
    FORM_ID_ATTRIBUTE,
    THIRD_PARTIES_ACCIDENT_ID_ATTRIBUTE,
    SHORTCODE_ATTRIBUTE,
    FIRSTREPORT_ATTRIBUTE,
    PAGE_REACHED_ATTRIBUTE,
    EMAIL_LIST_ATTRIBUTE,
    LOCATION_ID_ATTRIBUTE,
} from '../constants/KnownAttributes';
import Strings from '../constants/Strings';
import { convertFromDbValue, convertToDbValue, isValidAnswer } from '../utils/convertAnswer';
import { convertAttributesToElementAnswers } from '../utils/convertElement';
import { combineSimilarRecordKey } from '../utils/utils';
import { CODED_ELEMENT_TYPES, ELEMENT_TYPES } from '../constants/ElementTypes';
import { LookupService } from 'spheracloud-hierarchy';
import { FIELD_TYPES } from '../constants/FieldTypes';
import { VISIBILITY_TYPES } from '../constants/VisibilityTypes';
import { translate, reloadObjectPicklistValues, reloadFormPermissions } from "./ConfigService";
import {updateFieldAnswer} from "../actions/wizardActions";
import {translationFailure} from "../actions/wizardActions";
import {v4 as uuidv4} from 'uuid';

const getLookUpServiceInstance = (state) => {
    const hierarchyEnvironment = state.appReducer.hierarchyEnvironment;
    const authInfo = getAuthInfo(state);
    const anonGuid = state.appReducer.anonGuid;
    const token = state.auth.user ? state.auth.user.access_token : null;
    const hierarchyApiUrl = endpoints.getHierarchyAPIUrl(getAppSettingsFromState(state), isAuthenticated(state));
    const userGuid = (authInfo && authInfo.userGuid) || anonGuid;

    const apiSettings = {
        apiRoot: `${hierarchyApiUrl}`,
        userGuid,
        token,
        hierarchyEnvironment,
    };

    return new LookupService(apiSettings);
}

const onContinueDraft = async (shortCode, history, next, store) => {
    next(AppActions.continueDraftBegin());

    const state = store.getState();
    const appSettings = getAppSettings(store);

    try {
        const accidentId = await getAccidentIDFromShortCode(shortCode, appSettings, state);
        
        const formAttributes = await getAccidentData(accidentId, [
            LOCATION_ID_ATTRIBUTE,
            FORM_ID_ATTRIBUTE, 
            IS_DRAFT_ATTRIBUTE, 
            ACCIDENT_GUID_ATTRIBUTE,
            REPORTING_USER.TYPE].join(','), appSettings, state);
        
        const formId = formAttributes[FORM_ID_ATTRIBUTE][0];
        const isDraft = formAttributes[IS_DRAFT_ATTRIBUTE];
        const userTypeSelected = formAttributes[REPORTING_USER.TYPE];

        if (!isDraft) {
            next(AppActions.continueDraftFailure(getString(Strings.accidentNotInDraftError, state)));
            return;
        }

        const locationId = formAttributes[LOCATION_ID_ATTRIBUTE];
        if (locationId && locationId[0]) {
            await reloadFormPermissions(locationId, formId, store, next);
        }

        if (!state.appReducer.formTypes || !state.appReducer.formTypes.find(type => {
            return type.Id === formId;
        })) {
            if (userTypeSelected === USER_TYPES.AUTHENTICATED ||
                userTypeSelected === USER_TYPES.B2CAUTHENTICATED ||
                userTypeSelected === USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN
            ) {
                next(AppActions.continueDraftSetData(shortCode, accidentId, formId));
                if (state.auth.user) {
                    next(WizardActions.setUserType(userTypeSelected));
                    store.dispatch(AppActions.loadForm(formId));
                } else {
                    next(AppActions.continueDraftCancel());
                    next(AuthActions.setAuthType(userTypeSelected, history));
                }
            } else {
                next(AppActions.continueDraftSetData(shortCode, accidentId, formId));
                next(AuthActions.setAuthType(userTypeSelected, history));
                store.dispatch(AppActions.loadForm(formId));
            }
        }
        else {
            if (userTypeSelected === USER_TYPES.AUTHENTICATED ||
                userTypeSelected === USER_TYPES.B2CAUTHENTICATED ||
                userTypeSelected === USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN
            ) {
                next(AppActions.continueDraftSetData(shortCode, accidentId, formId));
                if (state.auth.user) {
                    next(WizardActions.setUserType(userTypeSelected));
                    window.location.reload();
                } else {
                    next(AppActions.continueDraftCancel());
                    next(AuthActions.setAuthType(userTypeSelected, history));
                }
            } else if (userTypeSelected === USER_TYPES.SSOAUTHENTICATED) {
                next(AppActions.continueDraftSetData(shortCode, accidentId, formId));
                next(AuthActions.setAuthType(userTypeSelected, history));
            } else {
                onContinueDraftLoad(shortCode, accidentId, formId, next, store);
            }
        }
    } catch (error) {
        next(AppActions.continueDraftFailure(getString(Strings.draftNotFoundError, state)));
    }
}

const onContinueDraftLoad = async (shortCode, accidentId, formId, next, store) => {
    next(AppActions.continueDraftBegin());
    let state = store.getState();
    const appSettings = getAppSettings(store);

    try {
        // load minimum attributes for reloading configuration, 
        // assuming there might be conditions based on selected location        
        let attributesList = buildAccidentAttributesList(formId, state, true);
        let attributes = await getAccidentData(accidentId, attributesList, appSettings, state);
        
        const attrValue = attributes[LOCATION_ID_ATTRIBUTE];
        const locationId = attrValue && attrValue[0] || -1;

        await reloadFormPermissions(locationId, formId, store, next);
        state = store.getState();

        // load all attributes required by the form/location configuration
        attributesList = buildAccidentAttributesList(formId, state);
        attributes = await getAccidentData(accidentId, attributesList, appSettings, state);

        // apply attributes
        const convertedAttributes = convertFromDbValues(formId, attributes, '', state);
        const answers = buildFormAnswers(formId, convertedAttributes, undefined, state);
        await updateThirdPartiesAndLocationsAnswers(state, store, formId, accidentId, answers, appSettings);
        
        next(AppActions.continueDraftSuccess(accidentId, shortCode, answers));

        let userTypeSelected = attributes[REPORTING_USER.TYPE];
        next(WizardActions.setUserType(userTypeSelected));

        switch (userTypeSelected) {
            case USER_TYPES.ANONYMOUS:
                next(AppActions.setAnonymousUser());
                break;
            case USER_TYPES.MANUAL:
                next(AppActions.setManualUser({
                    name: attributes[REPORTING_USER.NAME],
                    email: attributes[REPORTING_USER.EMAIL],
                    phone: attributes[REPORTING_USER.PHONE]
                }));
                break;
            default:
                break;
        }

        const pageReached = attributes[PAGE_REACHED_ATTRIBUTE];
        if (pageReached) {
            next(WizardActions.setPageReached(pageReached));
        }

        const emailList = attributes[EMAIL_LIST_ATTRIBUTE];
        if (emailList) {
            const additionalRecipients = emailList.split(';').sort();
            next(WizardActions.updateAdditionalRecipients(additionalRecipients))
        }

        next(AppActions.setFormType(formId));
        next(WizardActions.loadLocationAttributes(locationId, true));        

        next(AppActions.continueDraftRemoveData());
    } catch (error) {
        next(AppActions.continueDraftFailure(getString(Strings.draftNotFoundError, state)));
    }
}

const updateThirdPartiesAndLocationsAnswers = async (state, store, formId, accidentId, answers, appSettings) => {
    const selectedHierarchyId = state.appReducer.selectedHierarchyId;
    const relatedObjectsElements = getRelatedObjectsElements(store, formId);

    if (relatedObjectsElements) {
        for (const relatedObjectsElement of relatedObjectsElements) {
            const object = await loadRelatedObjects(store, 'Rivo.Santiago.ThirdParties', accidentId, formId, relatedObjectsElement.Guid);
            if (!_.isEmpty(object)) {
                answers.subPageObjects[relatedObjectsElement.Guid] = object;
            }
        }
    }

    const relatedDocumentsElement = getRelatedDocumentsElement(store, formId);
    if (relatedDocumentsElement) {
        const documents = await loadRelatedDocuments(appSettings, state, accidentId);
        if (!_.isEmpty(documents)) {
            answers.elements[relatedDocumentsElement.Guid] = documents;
        }
    }

    if (state.appReducer.isHierarchy) {
        const lookupService = getLookUpServiceInstance(state);
        const locationElements = getLocationElements(state, formId);

        for (let locationElement of locationElements) {
            const isAccidentsMaps = locationElement.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS;

            if (isAccidentsMaps) {
                const location = answers.elements[locationElement.Guid].hierarchyLocation;
                const response = await lookupService.getLocationNode(selectedHierarchyId, location.nodeExternalId);
                answers.elements[locationElement.Guid].hierarchyLocation = response;
            } else {
                const location = answers.elements[locationElement.Guid];
                const response = await lookupService.getLocationNode(selectedHierarchyId, location.nodeExternalId);
                answers.elements[locationElement.Guid] = response;
            }
        }
    }
};

const loadRelatedObjects = async (store, className, parentID, parentFormID, relatedObjectElementGuid) => {
    const state = store.getState();
    const appSettings = getAppSettings(store);

    const query = {
        attributeNames: [
            'ThirdParties.FormID',
            ..._.uniq(getAllRelatedObjectAttributeNames(parentFormID, relatedObjectElementGuid, state))
        ],
        condition: {
            attributeName: THIRD_PARTIES_ACCIDENT_ID_ATTRIBUTE,
            operator: 0, // equal
            value: parentID
        }
    };

    const httpClient = new HttpClient(state);

    const response = await httpClient.post(endpoints.getRelatedObjects(appSettings, isAuthenticated(state), className), query);
    const { data } = response.data;

    const objects = [];

    for (const relatedObject of data) {
        const convertedAttributes = convertFromDbValues(parentFormID, relatedObject.attributeValues, relatedObjectElementGuid, state);
        const answers = buildFormAnswers(parentFormID, convertedAttributes, relatedObjectElementGuid, state);
        answers.id = relatedObject.id;
        answers.formId = relatedObject.attributeValues['ThirdParties.FormID'][0];

        const relatedDocumentsElement = getRelatedObjectsDocumentsElement(store, parentFormID, relatedObjectElementGuid);
        if (relatedDocumentsElement) {
            const documents = await loadRelatedDocuments(appSettings, state, relatedObject.id);
            if (!_.isEmpty(documents)) {
                answers.elements[relatedDocumentsElement.Guid] = documents;
            }
        }

        objects.push(answers);
    }

    return objects;
}

const loadRelatedDocuments = async (appSettings, state, relatedId) => {

    const query = {
        condition: {
            attributeName: 'RelatedDocuments.RelatedID',
            operator: 0, // equal
            value: relatedId
        }
    };

    const httpClient = new HttpClient(state);

    const response = await httpClient.post(endpoints.getRelatedDocuments(appSettings, isAuthenticated(state)), query);
    const { data } = response.data;

    const documents = [];

    for (const relatedDocument of data) {

        if (relatedDocument.attributeValues['RelatedDocuments.Link']) {
            documents.push(
                {
                    id: relatedDocument.id,
                    isLink: true,
                    ...relatedDocument.attributeValues
                }
            );
        }
        else {
            const data = await getRelatedDocumentFile(httpClient, appSettings, state, relatedDocument.id);

            for (const attribute in relatedDocument.attributeValues) {
                data[attribute] = relatedDocument.attributeValues[attribute];
            }

            documents.push(data);
        }
    }

    return documents;
}

const getRelatedDocumentFile = async (httpClient, appSettings, state, relatedDocumentId) => {
    const imageResponse = await httpClient.get(endpoints.getCompanyFormImage(appSettings, isAuthenticated(state), relatedDocumentId), 'blob', 'formimagecache');
    const data = imageResponse.data;
    data.id = relatedDocumentId;

    return data;
}

const loadRelatedDocument = async (elementGuid, relatedDocumentId, fileName, next, store) => {
    const state = store.getState();

    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);

    const document = await getRelatedDocumentFile(httpClient, appSettings, state, relatedDocumentId);
    document.name = fileName;

    next(WizardActions.loadRelatedDocumentSuccess(elementGuid, document));
}



const onSubmitForm = async (formData, next, store) => {
    next(AppActions.submitFormBegin());

    let state = store.getState();
    const isUserAuthenticated = isAuthenticated(state);
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const { primaryLanguage, autoTranslatePrimaryLanguage } = getCompanyPrimaryLanguageData(state);

    if (autoTranslatePrimaryLanguage && primaryLanguage) {
        const emptyPrimaryLanguageFields = getNotEmptyLangFieldsForPrimaryLang(state);
        if (!_.isEmpty(emptyPrimaryLanguageFields)) {
            await Promise.all(Object.keys(emptyPrimaryLanguageFields).map(fieldId => {
                return translate(store,
                    emptyPrimaryLanguageFields[fieldId].string,
                    emptyPrimaryLanguageFields[fieldId].lang,
                    primaryLanguage).then(
                    (result) => {
                        const translated = result.data || "";
                        next(updateFieldAnswer(getMultiName(fieldId, primaryLanguage), translated));
                    },
                    (ex) => {
                        next(translationFailure(ex));
                    }
                );
            }));
            state = store.getState();
        }
    }

    const attributes = {
        ...getReportingUserAttributes(state),
        ...getAccidentAttributes(state),
        [IS_DRAFT_ATTRIBUTE]: false,
        [ACCIDENT_GUID_ATTRIBUTE]: getAccidentGuid(state),
        [SHORTCODE_ATTRIBUTE]: '',
        [FIRSTREPORT_ATTRIBUTE]: true,
        [EMAIL_LIST_ATTRIBUTE]: state.wizardReducer.additionalRecipients.join(';')
    };

    try {
        const dataId = await createOrUpdateRecord(store, next, attributes, false, false);

        if (isUserAuthenticated) {
            const savedAccidentsResponse = await httpClient.get(endpoints.getSavedAccidents(appSettings, isUserAuthenticated));
            const savedAccidents = savedAccidentsResponse.data;
            next(AppActions.setSavedAccidents(savedAccidents))
        }

        next(AppActions.submitFormSuccess(dataId));
    } catch {
        next(AppActions.submitFormFailure(getString(Strings.genericSubmitError, store.getState())));
    }
};

const onSubmitDraft = async (formData, next, store) => {

    next(AppActions.submitDraftBegin());

    const state = store.getState();
    const isUserAuthenticated = isAuthenticated(state);
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const draftAccidentId = getDraftAccidentId(state);
    let shortCode = getDraftShortCode(state);

    const attributes = {
        ...getReportingUserAttributes(state),
        ...getAccidentAttributes(state),
        [IS_DRAFT_ATTRIBUTE]: true,
        [FIRSTREPORT_ATTRIBUTE]: true,
        [PAGE_REACHED_ATTRIBUTE]: state.wizardReducer.pageReached,
        [EMAIL_LIST_ATTRIBUTE]: state.wizardReducer.additionalRecipients.join(';')
    }

    if (!draftAccidentId) {
        shortCode = generateShortCode();
        attributes[SHORTCODE_ATTRIBUTE] = shortCode;
    }

    try {

        await createOrUpdateRecord(store, next, attributes, false, true);

        if (isUserAuthenticated) {
            const savedAccidentsResponse = await httpClient.get(endpoints.getSavedAccidents(appSettings, isUserAuthenticated));
            const savedAccidents = savedAccidentsResponse.data;
            next(AppActions.setSavedAccidents(savedAccidents))
        }

        next(AppActions.submitDraftSuccess(shortCode));
    } catch {
        next(AppActions.submitDraftFailure(getString(Strings.genericSaveError, store.getState())));
    }
};

const getRelatedObjectsElements = (store, formId) => {
    const state = store.getState();
    const elements = getElements(formId, state);

    const elementTypesWithSubPages = [
        CODED_ELEMENT_TYPES.THIRD_PARTIES
    ];

    return elements.filter(element => elementTypesWithSubPages.indexOf(element.Name) > -1);
}

const submitRelatedObjects = async (parentID, parentClass, store, next) => {
    const relatedObjectsElements = getRelatedObjectsElements(store, getSelectedFormTypeId(store.getState()));

    const state = store.getState();
    const subPageObjectAnswers = Object.entries(getSubPageObjectAnswers(state));
    const elementVisible = relatedObjectsElements && getElementVisibility(state, relatedObjectsElements.Guid) !== VISIBILITY_TYPES.HIDDEN
    if (relatedObjectsElements && relatedObjectsElements.length) {
        if (subPageObjectAnswers) {
            for (const [elementId, answers] of subPageObjectAnswers) {
                const element = relatedObjectsElements.find(e => { return e.Guid === elementId });
                for (const [index, relatedObject] of answers.entries()) {
                    if (relatedObject.deleted || !elementVisible) {
                        if (relatedObject.id) {
                            const appSettings = getAppSettings(store);
                            await deleteRelatedObject(appSettings, state, 'Rivo.Santiago.ThirdParties', relatedObject.id);
                        }
                    } else {
                        const relatedObjectAttributes = getRelatedObjectAttributes(parentID, element, relatedObject, state);
                        await createOrUpdateRelatedObjectRecord(store, relatedObject.id, 'Rivo.Santiago.ThirdParties', relatedObjectAttributes, false, elementId, index);
                    }
                }
            }
        }
    }
}

const deleteRelatedObject = async (appSettings, state, className, relatedObjectId) => {

    const httpClient = new HttpClient(state, { forceAnonymous: false });

    const record = {
        id: relatedObjectId,
        className: className,
        attributeValues: {
            "ThirdParties.Deleted": 1
        },
    };

    await httpClient.put(endpoints.putFormData(appSettings, isAuthenticated(state), className, record.id, true), record);
}

const onSelectForm = (next, store, selectedFormTypeId) => {
    const state = store.getState();
    const { allowAnonReporting } = state.appReducer;

    const form = state.appReducer.formTypes && state.appReducer.formTypes.find(type => {
        return type.Id === selectedFormTypeId;
    });

    next(AppActions.setAccidentGuid(uuidv4()));

    if(allowAnonReporting && form && form.AllowAnonReporting && !state.wizardReducer.userTypeSelected) {
        let userType = null;
        if (form.ShowAnonReportingOption && form.ShowManualReportingOption) {
            userType = form.DefaultReportingMethod;
        }
        else if (form.ShowAnonReportingOption) {
            userType =  USER_TYPES.ANONYMOUS;
        }
        else if (form.ShowManualReportingOption) {
            userType = USER_TYPES.MANUAL;
        }

        if (userType !== null) {
            next(WizardActions.setUserType(userType));
        }
    }
};

const onCancelForm = (next, store) => {
    const state = store.getState();
    next(WizardActions.clearWizard());
    next(AppActions.clearForm());
    next(AppActions.clearFormType());
    next(AppActions.clearAuthError());
    next(AppActions.setAccidentGuid(null));

    if (!isAuthenticated(state)) {
        next(WizardActions.setUserType(0));
        next(AppActions.clearActiveLocation());
    }
    else {
        const activeLocationId = getActiveLocationId(state);
        if (activeLocationId) {
            store.dispatch(AppActions.reloadForms(activeLocationId));
        }
    }
};

const getRelatedDocumentsElement = (store, formId) => {
    const state = store.getState();
    const elements = getElements(formId, state);

    return elements.find(element => element.Name === CODED_ELEMENT_TYPES.RELATED_DOCUMENTS)
}

const getRelatedObjectsDocumentsElement = (store, formId, relatedObjectElementGuid) => {
    const state = store.getState();
    const elements = getRelatedObjectElements(formId, relatedObjectElementGuid, state);

    return elements.find(element => element.Name === CODED_ELEMENT_TYPES.RELATED_DOCUMENTS)
}

const hasRelatedObjectsOrDocuments = (store) => {
    const relatedObjectsElements = getRelatedObjectsElements(store, getSelectedFormTypeId(store.getState()));
    const state = store.getState();
    const subPageObjectAnswers = Object.entries(getSubPageObjectAnswers(state));
    let elementVisible = relatedObjectsElements && getElementVisibility(state, relatedObjectsElements.Guid) !== VISIBILITY_TYPES.HIDDEN
    if (relatedObjectsElements && relatedObjectsElements.length) {
        if (subPageObjectAnswers) {
            for (const [elementId, answers] of subPageObjectAnswers) {
                const element = relatedObjectsElements.find(e => { return e.Guid === elementId });
                for (const [index, relatedObject] of answers.entries()) {
                    if (!relatedObject.deleted && elementVisible) {
                        return true;
                    }
                }
            }
        }
    }

    const relatedDocumentsElement = getRelatedDocumentsElement(store, getSelectedFormTypeId(store.getState()));

    elementVisible = relatedDocumentsElement && getElementVisibility(state, relatedDocumentsElement.Guid) !== VISIBILITY_TYPES.HIDDEN
    const answers = getElementAnswers(state);
    const documents = answers && relatedDocumentsElement && answers[relatedDocumentsElement.Guid];

    if (relatedDocumentsElement) {
        if (documents && elementVisible) {
            if (documents.length > 0) {
                return true;
            }
        }
    }

    return false;
}

const submitRelatedDocuments = async (relatedID, relatedClass, relatedObjectElementGuid, relatedObjectIndex, store, next) => {
    const relatedDocumentsElement = relatedObjectElementGuid ?
        getRelatedObjectsDocumentsElement(store, getSelectedFormTypeId(store.getState()), relatedObjectElementGuid) :
        getRelatedDocumentsElement(store, getSelectedFormTypeId(store.getState()));

    const state = store.getState();
    const elementVisible = relatedDocumentsElement && getElementVisibility(state, relatedDocumentsElement.Guid) !== VISIBILITY_TYPES.HIDDEN
    const answers = relatedObjectElementGuid ? getSubPageObjectAnswers(state)[relatedObjectElementGuid][relatedObjectIndex].elements : getElementAnswers(state);
    const documents = answers && relatedDocumentsElement && answers[relatedDocumentsElement.Guid];

    if (relatedDocumentsElement) {
        if (documents && elementVisible) {
            for (const document of documents) {
                const appSettings = getAppSettings(store);

                if (!document.deleted && !document.hasError) {
                    await postRelatedDocument(appSettings, state, relatedID, relatedClass, document, relatedDocumentsElement);
                }
                else {
                    if (document.id) {
                        await deleteRelatedDocument(appSettings, state, document.id);
                    }
                }
            }
        } else {
            if (documents) {
                for (const document of documents) {
                    const appSettings = getAppSettings(store);
                    if (document.id) {
                        await deleteRelatedDocument(appSettings, state, document.id);
                    }
                }
            }
        }
    }
}

const uploadRelatedDocuments = async (elementGuid, documents, next, store) => {

    if (documents) {
        const state = store.getState();

        next(WizardActions.updateElementAnswer(elementGuid, documents));

        for (let index = 0; index < documents.length; index++) {
            const document = documents[index];

            const appSettings = getAppSettings(store);

            if (!document.id) { //new document
                if (!document.deleted) {
                    uploadRelatedDocument(appSettings, state, next, elementGuid, document, index);
                }
            }
            else if (document.deleted) {
                await deleteRelatedDocument(appSettings, state, document.id);
            }
        }
    }
}

const uploadRelatedDocument = async (appSettings, state, next, elementGuid, document, index) => {

    next(WizardActions.uploadRelatedDocumentBegin(elementGuid, index));

    try {
        const className = 'Rivo.RiskEngine.RelatedDocuments';
        const httpClient = new HttpClient(state, { forceAnonymous: false });
        const createResponse = await httpClient.post(endpoints.postFormData(appSettings, isAuthenticated(state), className), null);

        document.id = createResponse.data.id;

        if (!document.isLink) {
            await postRelatedDocumentContent(appSettings, state, document.id, document);
        }
        next(WizardActions.uploadRelatedDocumentSuccess(elementGuid, index));
    }
    catch (error) {
        next(WizardActions.uploadRelatedDocumentFailure(elementGuid, error, index));
    }
}

const getAdditionalAttributes = (formConfigJSON, document) => {
    let result = {};

    if (formConfigJSON) {
        const formConfig = JSON.parse(formConfigJSON);

        formConfig.Elements.forEach(e => {
            e.Fields.forEach(field => {
                const attributeTitle = field.TemplateAttribute.Name;

                if (field.RenderingType === FIELD_TYPES.TEXT_INPUT) {
                    result[attributeTitle] = document[attributeTitle];
                }
            });
        });
    }

    return result;
}

const postRelatedDocument = async (appSettings, state, relatedID, relatedClass, document, relatedDocumentsElement) => {
    const className = 'Rivo.RiskEngine.RelatedDocuments';

    const httpClient = new HttpClient(state, { forceAnonymous: false });

    const relatedDocumentId = document.id;

    const record = {
        id: relatedDocumentId,
        className: className,
        attributeValues: {
            "RelatedDocuments.RelatedClass": relatedClass,
            "RelatedDocuments.RelatedID": relatedID
        },
    };

    const configData = relatedDocumentsElement.ConfigData;

    if (document.isLink) {
        record.attributeValues = {
            ...record.attributeValues,
            "RelatedDocuments.Description": document["RelatedDocuments.Description"],
            "RelatedDocuments.Link": document["RelatedDocuments.Link"],
            ...getAdditionalAttributes(configData && configData.LinkFormConfig, document)
        }
    }
    else {
        record.attributeValues = {
            ...record.attributeValues,
            "RelatedDocuments.OriginalFilename": document["RelatedDocuments.OriginalFilename"],
            ...getAdditionalAttributes(configData && configData.DocumentFormConfig, document)
        }
    }

    await httpClient.put(endpoints.putFormData(appSettings, isAuthenticated(state), className, record.id, false), record);
}

const postRelatedDocumentContent = async (appSettings, state, relatedDocumentId, document) => {

    var data = new FormData();
    data.append('Document', document, document.name);

    const httpClient = new HttpClient(state, { forceAnonymous: false });
    await httpClient.post(endpoints.postRelatedDocumentContent(appSettings, isAuthenticated(state), relatedDocumentId), data);
}

const deleteRelatedDocument = async (appSettings, state, relatedDocumentId) => {
    const className = 'Rivo.RiskEngine.RelatedDocuments';

    const httpClient = new HttpClient(state, { forceAnonymous: false });

    const record = {
        id: relatedDocumentId,
        className: className,
        attributeValues: {
            "RelatedDocuments.Deleted": true
        },
    };

    await httpClient.put(endpoints.putFormData(appSettings, isAuthenticated(state), className, record.id, false), record);
}


const buildUserInfo = (attributesValues) => {
    const result = Object.entries(attributesValues)
        .filter(([field]) => Object.values(REPORTING_USER).indexOf(field) > -1)
        .map(([field, value]) => {
            const key = Object.keys(REPORTING_USER).find(key => REPORTING_USER[key] === field);
            const res = {
                [key]: value
            };
            return res;
        }).reduce((userInfos, info)=>({...userInfos, ...info}));

    return  result;
};

const buildFormAnswers = (formId, convertedAttributes, relatedObjectElementGuid, state) => {
    const fieldAnswers = Object.entries(convertedAttributes)
        .map(([attributeName, value]) => {
            const field = relatedObjectElementGuid ? getRelatedObjectField(formId, relatedObjectElementGuid, attributeName, state) : getFormField(formId, attributeName, state);
            return [field, value, attributeName];
        })
        .filter(([field, value]) => {
            return field && isValidAnswer(value);
        })
        .map(([field, value, attributeName]) => ({ [attributeName]: value }))
        .reduce((answers, answer) => ({ ...answers, ...answer }), {});

    const elements = relatedObjectElementGuid ? getRelatedObjectElements(formId, relatedObjectElementGuid, state) : getElements(formId, state);
    const elementAnswers = elements
        .map(e => [e.Guid, convertAttributesToElementAnswers(e, convertedAttributes, state.appReducer.isHierarchy)])
        .filter(([elementGuid, answer]) => {
            return isValidAnswer(answer);
        })
        .map(([elementId, answer]) => ({ [elementId]: answer }))
        .reduce((answers, answer) => ({ ...answers, ...answer }), {});

    return {
        elements: elementAnswers,
        fields: fieldAnswers,
        ...(relatedObjectElementGuid ? {} : { subPageObjects: {} })
    };
}

const buildAccidentAttributesList = (formId, state, skipFieldAttributes) => ([
    LOCATION_ID_ATTRIBUTE,
    REPORTING_USER.TYPE,
    REPORTING_USER.NAME,
    REPORTING_USER.PHONE,
    REPORTING_USER.EMAIL,
    EMAIL_LIST_ATTRIBUTE,
    PAGE_REACHED_ATTRIBUTE].concat(!skipFieldAttributes 
        ? _.uniq(getAllFormFieldAttributeNames(formId, state))
        : [])
);

const getAccidentAttributes = (state) => {
    const formId = getSelectedFormTypeId(state);
    const templateClassName = getSelectedTemplateFormName(state);
    const attributes = getAnswerAttributes(state);

    const convertedAnswers = convertToDbValues(formId, attributes, '', state);
    return {
        [`${templateClassName}.FormId`]: [formId], // JTD - must be array for picklist handler
        ...convertedAnswers,
    };
};

const getRelatedObjectAttributes = (parentID, relatedObjectElement, answers, state) => {
    const formId = getSelectedFormTypeId(state);
    const templateClassName = relatedObjectElement.Name;

    const subFormId = answers.formId;
    const fieldAttributes = answers.fields;
    const elementAttributes = answers.elements;

    const attributes = {
        ...fieldAttributes,
        ...elementAttributes,
    }
    const convertedAnswers = convertToDbValues(formId, attributes, relatedObjectElement.Guid, state);

    return {
        [`${templateClassName}.FormID`]: [subFormId],
        [`${templateClassName}.AccidentID`]: parentID,
        ...convertedAnswers,
    };
};

const getReportingUserAttributes = (state) => {
    const user = getFormUser(state);

    switch (user.type) {
        case USER_TYPES.AUTHENTICATED:
        case USER_TYPES.SSOAUTHENTICATED:
            return {
                [REPORTING_USER.TYPE]: user.type,
                [REPORTING_USER.USER_ID]: [user.userId], // Safeguard requires user IDs sent as a list
                [REPORTING_USER.EMAIL]: user.email,
                [REPORTING_USER.NAME]: user.name,
            };
        case USER_TYPES.MANUAL:
            return {
                [REPORTING_USER.TYPE]: user.type,
                [REPORTING_USER.EMAIL]: user.email,
                [REPORTING_USER.NAME]: user.name,
                [REPORTING_USER.PHONE]: user.phone,
            };
        case USER_TYPES.ANONYMOUS:
        default:
            return {
                [REPORTING_USER.TYPE]: user.type,
                [REPORTING_USER.EMAIL]: '',
                [REPORTING_USER.NAME]: '',
                [REPORTING_USER.PHONE]: '',
            };
    }
}

const getAccidentIDFromShortCode = async (shortCode, appSettings, state) => {
    const httpClient = new HttpClient(state);
    const response = await httpClient.get(endpoints.getAccidentIDFromShortCode(appSettings, shortCode));

    return response.data;
}

const getAccidentData = async (accidentId, attributes, appSettings, state) => {
    try {
        const httpClient = new HttpClient(state);
        const response = await httpClient.get(endpoints.getFormData(appSettings, isAuthenticated(state), accidentId, attributes));
        const { data } = response;
        const { attributeValues } = data;
        return attributeValues;
    } catch (ex) {
        throw new Error('No accident found');
    }
}

const createOrUpdateRecord = async (store, next, attributes, useAnonymousUser, isDraft) => {
    const state = store.getState();

    const appSettings = getAppSettings(store);

    const httpClient = new HttpClient(state, { forceAnonymous: useAnonymousUser });
    const className = 'Rivo.Santiago.Accidents';

    let id = getDraftAccidentId(state);

    let record = {
        className: className,
        attributeValues: { ...attributes },
    };

    if (id) {
        record.id = id;
    }

    const templateClassName = getSelectedTemplateFormName(state);

    const createOrUpdateRecord = (commit) => !id 
        ? httpClient.post(endpoints.postFormData(appSettings, isAuthenticated(state), className, true, commit), record)
        : httpClient.put(endpoints.putFormData(appSettings, isAuthenticated(state), className, id, true), record);

    if (!hasRelatedObjectsOrDocuments(store)) {
        // no related items, commit record immediately if not a draft
        if (!isDraft) {
            record.attributeValues[`${templateClassName}.IsCommitted`] = true;
        }
        const createResponse = await createOrUpdateRecord(!isDraft);
        id = createResponse.data.id;
    } else {
        // has related items, commit after creating record and attaching related items
        const createResponse = await createOrUpdateRecord(false);
        id = createResponse.data.id;

        await submitRelatedObjects(id, className, store, next);

        await submitRelatedDocuments(id, 'Santiago.Accidents'/* Rivo prefix needs to removed */, undefined, undefined, store, next);

        if (!isDraft) {
            const submitRecord = {
                id: id,
                className: className,
                attributeValues: {
                    [`${templateClassName}.IsCommitted`] : true
                },
            };

            await httpClient.put(endpoints.putFormData(appSettings, isAuthenticated(state), className, id, true), submitRecord);
        }
    }

    return id;
};

const createOrUpdateRelatedObjectRecord = async (store, recordId, relatedClass, attributes, useAnonymousUser, relatedObjectElementGuid, relatedObjectIndex) => {
    const state = store.getState();

    const appSettings = getAppSettings(store);

    const httpClient = new HttpClient(state, { forceAnonymous: useAnonymousUser });

    let id = recordId;
    if (!recordId) {
        const createResponse = await httpClient.post(endpoints.postFormData(appSettings, isAuthenticated(state), relatedClass), null);
        id = createResponse.data.id;
    }

    const record = {
        id: id,
        className: relatedClass,
        attributeValues: { ...attributes },
    };

    await httpClient.put(endpoints.putFormData(appSettings, isAuthenticated(state), relatedClass, record.id, true), record);

    await submitRelatedDocuments(id, 'Santiago.ThirdParties'/* Rivo prefix needs to removed */, relatedObjectElementGuid, relatedObjectIndex, store);

    return recordId;
};

const convertFromDbValues = (formId, attributes, relatedObjectElementGuid, state) => {
    return convertAttributes(formId, attributes, relatedObjectElementGuid, state, convertFromDbValue);
}

const convertToDbValues = (formId, attributes, relatedObjectElementGuid, state) => {
    return convertAttributes(formId, attributes, relatedObjectElementGuid, state, convertToDbValue);
}

const convertAttributes = (formId, attributes, relatedObjectElementGuid, state, convert) => {
    return Object.entries(attributes).map(([attributeName, value]) => (relatedObjectElementGuid ? [getRelatedObjectField(formId, relatedObjectElementGuid, attributeName, state), value, attributeName] : [getFormField(formId, attributeName, state), value, attributeName]))
        .filter(([field]) => field)
        .map(([field, value, attributeName]) => ({ name: attributeName, value: convert(value, field, state) }))
        .reduce((answers, attribute) => {
        answers[attribute.name] = attribute.value;
        return answers;
    }, {});
}

const loadSiteMap = async (id, elementGuid, next, store) => {

    const state = store.getState();
    const appSettings = getAppSettings(store);
    const httpClient = new HttpClient(state);

    if (!id) {
        next(WizardActions.getSiteMapImageFailure("error", elementGuid));
    } else {
        next(WizardActions.getSiteMapImageBegin(elementGuid));
        try {
            const imageResponse = await httpClient.get(endpoints.getCompanyFormImage(appSettings, isAuthenticated(state), id), 'blob');
            if (imageResponse.data) {
                imageResponse.data.id = id;
                imageResponse.data.name = "SiteMap";
                next(WizardActions.getSiteMapImageSuccess(URL.createObjectURL(imageResponse.data), elementGuid));
            } else {
                next(WizardActions.getSiteMapImageFailure("error", elementGuid));
            }
        } catch (ex) {
            next(WizardActions.getSiteMapImageFailure("error", elementGuid));
        }
    }
}

const loadSiteMapList = async (locationId, elementGuid, next, store) => {
    const state = store.getState();
    const appSettings = getAppSettings(store);

    if (!locationId) {
        next(WizardActions.getSiteMapImageListFailure("error", elementGuid));
    } else {
        next(WizardActions.getSiteMapImageListBegin(elementGuid));
        try {
            const documents = await loadRelatedDocuments(appSettings, state, locationId);
            const documentList = documents.map(d => ({
                id: d.id,
                name: d['RelatedDocuments.Description'] || `Doc ${d.id}`
            }));
            next(WizardActions.getSiteMapImageListSuccess(documentList, elementGuid));
        } catch (ex) {
            next(WizardActions.getSiteMapImageFailure("error", elementGuid));
        }
    }
}

const generateShortCode = () => {
    let shortCode = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 5; i++) {
        shortCode += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return shortCode;
}

const onClearFormTypeAndLogout = (next, store) => {

    next(AppActions.setLoggingOffUser(true));
    next(WizardActions.clearWizard());
    next(AppActions.clearForm());
    next(AppActions.clearFormType());
    next(AuthActions.setAuthType(0));
    next(AppActions.clearActiveLocation());
}

const onClearFormTypeAndStayLoggedIn = (next, store) => {

    const state = store.getState();
    if (state.wizardReducer.userTypeSelected === USER_TYPES.MANUAL) {
        next(AppActions.setAnonymousUser());
        next(WizardActions.setUserType(USER_TYPES.ANONYMOUS));
    }
    next(WizardActions.clearWizard());
    next(AppActions.clearForm());
    next(AppActions.clearFormType());
    next(AppActions.clearAuthError());

    const activeLocationId = getActiveLocationId(state);
    if (activeLocationId) {
        store.dispatch(AppActions.reloadForms(activeLocationId));
    }
}

const deleteDraftRecord = async (shortcode, next, store) => {

    next(AppActions.deleteDraftRecordStart());

    const state = store.getState();
    const appSettings = getAppSettings(store);
    const httpClient = new HttpClient(state);

    const savedAccidentsResponse = await httpClient.get(endpoints.deleteAccidentRecord(appSettings, isAuthenticated(state), shortcode));
    const savedAccidents = savedAccidentsResponse.data;

    next(AppActions.setSavedAccidents(savedAccidents));

    next(AppActions.deleteDraftRecordEnd());

}

const searchSimilarRecords = async (templateAttributeName, viewId, searchValue, isSearchResctictedToUserLocation, next, store) => {
    const state = store.getState();
    const key = combineSimilarRecordKey(templateAttributeName, searchValue);

    if(state.appReducer.similarRecords[key])
        return;

    const appSettings = getAppSettings(store);
    const httpClient = new HttpClient(state);
    const formId = state.appReducer.selectedFormTypeId;
    next(AppActions.setSimilarRecordFieldSpinner(true, templateAttributeName));

    try {
        const similarRecords = await httpClient.get(endpoints.similarRecordsSearch(appSettings, isAuthenticated(state), templateAttributeName, viewId, searchValue, isSearchResctictedToUserLocation, formId));

        next(AppActions.setSimilarRecordsData(similarRecords.data, templateAttributeName, searchValue));
        next(AppActions.setSimilarRecordFieldSpinner(false, templateAttributeName));
    }
    catch(e) {
        next(AppActions.setSimilarRecordFieldSpinner(false, templateAttributeName));
    }
}

const showSimilarRecordSummary = async (accidentId, next, store) => {
    const state = store.getState();
    const appSettings = getAppSettings(store);
    next(AppActions.setSummaryModalState({ isEnabled: true, currentRecordId: accidentId }));

    if (state.appReducer.similarRecordSummaries[accidentId]) {
        return;
    }

    const formId = state.appReducer.selectedFormTypeId;
    const attributesList = buildAccidentAttributesList(formId, state);
    const summaryData = await getAccidentData(accidentId, attributesList, appSettings, state);
    const convertedAttributes = convertFromDbValues(formId, summaryData, '', state);
    const recordData = buildFormAnswers(formId, convertedAttributes, undefined, state);
    recordData.userInfo = buildUserInfo(summaryData);
    await updateThirdPartiesAndLocationsAnswers(state, store, formId, accidentId, recordData, appSettings);

    next(AppActions.setSimilarRecordSummary(accidentId, recordData));
}

const loadActiveLocation = async (store) => {
    const state = store.getState();
    const locationId = state.appReducer.activeLocationId || state.appReducer.initialActiveLocationId;
    const useLocationRestrictions = state.appReducer.useFormLocationRestrictions;
    const selectedHierarchyId = state.appReducer.selectedHierarchyId;
    const hierarchyEnvironment = state.appReducer.hierarchyEnvironment;

    if (locationId && locationId !== -1 && useLocationRestrictions && selectedHierarchyId && hierarchyEnvironment) {
        const lookupService = getLookUpServiceInstance(state)
        const activeLocation = await lookupService.getLocationNode(selectedHierarchyId, locationId);

        if (activeLocation && activeLocation.userHasAccess) {
            store.dispatch(AppActions.setActiveLocation(activeLocation));
        }
        else {
            store.dispatch(AppActions.clearActiveLocation());
        }
    }
}

const validateLocationAnswers = async (store) => {
    const state = store.getState();
    const selectedFormTypeId = getSelectedFormTypeId(store.getState());
    if (!selectedFormTypeId) return;

    const useLocationRestrictions = state.appReducer.useFormLocationRestrictions;

    if (useLocationRestrictions) {
        const elements = getElements(selectedFormTypeId, state);
        const answers = state.wizardReducer.answers;
        const lookupService = getLookUpServiceInstance(state);
        const selectedHierarchyId = state.appReducer.selectedHierarchyId;

        for(let i = 0; i < elements.length; i++) {
            var locationCleared = false;
            var element = elements[i];

            if (element.Type === ELEMENT_TYPES.CODED && (element.Name === CODED_ELEMENT_TYPES.LOCATION_SELECTOR || element.Name === CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR || element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS)) {
                if (answers.elements[element.Guid]) {
                    var location = null;
                    if (element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS) {
                        location = answers.elements[element.Guid].hierarchyLocation;
                    } else {
                        location = answers.elements[element.Guid];
                    }

                    if (location && location.nodeExternalId && location.nodeExternalId !== -1) {
                        const response = await lookupService.getLocationNode(selectedHierarchyId, location.nodeExternalId);

                        if (!response || !response.userHasAccess) {
                            locationCleared = true;
                            store.dispatch(WizardActions.updateElementAnswer(element.Guid, null));
                        }
                    }
                }
            }
            else if (element.Type === ELEMENT_TYPES.DESIGNER) {
                const locationField = element.Fields.find(field => {
                    return field.TemplateAttribute.Name === LOCATION_ID_ATTRIBUTE
                });

                if (locationField) {
                    const locationId = answers.fields[locationField.TemplateAttribute.Name];

                    if (locationId && locationId !== -1) {
                        const response = await lookupService.getLocationNode(selectedHierarchyId, locationId);

                        if (!response || !response.userHasAccess) {
                            locationCleared = true;
                            store.dispatch(WizardActions.updateFieldAnswer(locationField.TemplateAttribute.Name, null));
                        }
                    }
                }
            }

            if (locationCleared) {
                store.dispatch(AppActions.reloadFormPermissions(-1, selectedFormTypeId));
            }
        } 
    } else {
        const locationId = getSelectedLocationId(state);
        if (locationId > 0) {
            store.dispatch(AppActions.reloadFormPermissions(locationId, selectedFormTypeId));
            store.dispatch(WizardActions.reloadObjectPicklistValues(locationId, selectedFormTypeId));
            store.dispatch(WizardActions.loadLocationAttributes(locationId, true));
        }
    }
}

const DataService = store => next => (action) => {
    next(action);
    switch (action.type) {
        case (AppActions.CONTINUE_DRAFT):
            onContinueDraft(action.code, action.history, next, store);
            break;
        case (AppActions.CONTINUE_DRAFT_LOAD):
            onContinueDraftLoad(action.shortCode, action.accidentId, action.formId, next, store);
            break;
        case (AppActions.SUBMIT_DRAFT):
            onSubmitDraft(action.data, next, store);
            break;
        case (AppActions.SUBMIT_FORM):
            onSubmitForm(action.data, next, store);
            break;
        case (AppActions.CLEAR_FORM_TYPE_LOGOUT):
            onClearFormTypeAndLogout(next, store);
            break;
        case (AppActions.CLEAR_FORM_TYPE_STAY_LOGGED_IN):
            onClearFormTypeAndStayLoggedIn(next, store);
            break;
        case (AppActions.SET_FORM_TYPE):
            onSelectForm(next, store, action.formType);
            break;
        case (AppActions.CANCEL_FORM):
            onCancelForm(next, store);
            break;
        case (WizardActions.GET_SITEMAP_IMAGE_LIST):
            loadSiteMapList(action.locationId, action.elementGuid, next, store);
            break;
        case (WizardActions.GET_SITEMAP_IMAGE):
            loadSiteMap(action.imageId, action.elementGuid, next, store)
            break;
        case (WizardActions.UPLOAD_RELATED_DOCUMENTS):
            uploadRelatedDocuments(action.elementGuid, action.documents, next, store);
            break;
        case (WizardActions.LOAD_RELATED_DOCUMENT):
            loadRelatedDocument(action.elementGuid, action.relatedDocumentId, action.fileName, next, store);
            break;
        case (WizardActions.DELETE_DRAFT_RECORD):
            deleteDraftRecord(action.shortcode, next, store);
            break
        case (AppActions.SIMILAR_RECORDS_SEARCH):
            searchSimilarRecords(action.templateAttributeName, action.viewId, action.searchValue,
                action.isSearchResctictedToUserLocation, next, store);
            break
        case (AppActions.SHOW_SIMILAR_RECORD_SUMMARY):
            showSimilarRecordSummary(action.accidentId, next, store);
            break
        case (AppActions.FETCH_COMPANY_DATA_SUCCESS):
            loadActiveLocation(store);
            validateLocationAnswers(store);
            break;
        default:
            break;
    }
};

export default DataService;


