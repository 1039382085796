import _ from 'lodash';
import localforage from 'localforage';
import HttpClient from '../utils/httpClient';
import * as AppActions from '../actions/appActions';
import * as AuthActions from '../actions/authActions';
import * as WizardActions from '../actions/wizardActions';
import endpoints from '../constants/Endpoints';
import { getAppSettings, isAuthenticated, getFormUser, getObjectPicklistFormFields, getAuthInfo, getActiveLocationId } from '../selectors/appSelectors';
import appStrings from '../constants/Strings';
import { USER_TYPES } from '../constants/UserTypes';
import { allSettled, checkIsLive, isLoggedInUserType } from '../utils/utils';
import { FIELD_TYPES} from "../constants/FieldTypes";
import { profiles } from '../constants/Profiles'
import { getPossibleValues } from '../selectors/wizardSelectors';

const fetchCompanyData = async (store, next) => {
    next(AppActions.fetchCompanyDataBegin());
    try {
        let state = store.getState();
        const httpClient = new HttpClient(state);
        const appSettings = getAppSettings(store);
        const isUserAuthenticated = isAuthenticated(state);

        loadCompanyLogo(store, next);

        let language = await getSavedUserLanguage(state);

        if (!language) {
            const languageResponse = await httpClient.get(endpoints.getUserLanguage(appSettings, isUserAuthenticated));
            language = languageResponse.data || 'en-gb';
        }

        next(AppActions.setLanguage(language));

        let locationRestrictionResponse = await getHasFormLocationRestrictions(store);
        let useFormLocationRestrictions = locationRestrictionResponse.data && locationRestrictionResponse.data.hasLocationRestrictions;

        next(AppActions.setUseFormLocationRestrictions(useFormLocationRestrictions));

        store.dispatch(AppActions.loadSSOSettings());

        if (isUserAuthenticated) {
            const savedAccidentsResponse = await httpClient.get(endpoints.getSavedAccidents(appSettings, isUserAuthenticated));
            const savedAccidents = savedAccidentsResponse.data;

            next(AppActions.setSavedAccidents(savedAccidents));
        }

        const profileValuesResponse = await httpClient.post(endpoints.getCompanyProfileValues(appSettings, isUserAuthenticated, language), profiles);
        const profileValues = profileValuesResponse.data;

        let activeLocationId = getActiveLocationId(state);
        let selectedFormTypeId = state.appReducer.selectedFormTypeId;
        let companyData = {};

        try {
            companyData.summaryFormTypes = companyData.formTypes = useFormLocationRestrictions ? activeLocationId ? await getCompanyFormSummaryData(store, activeLocationId, language) : [] : await getCompanyFormSummaryData(store, -1, language);
        } catch (ex) {
            companyData.summaryFormTypes = [];
        }
        if (selectedFormTypeId !== -1) {
            if (isUserAuthenticated && !companyData.summaryFormTypes.find(f => f.Id === selectedFormTypeId)) {
                // form no longer available after login
                store.dispatch(AppActions.showLocationRestrictionUserError());
            }
            let selectedLocationId = (useFormLocationRestrictions && activeLocationId) ? activeLocationId : -1;
            companyData.formTypes = await getCompanyFormsData(store, selectedLocationId, selectedFormTypeId, language, companyData.summaryFormTypes)
        } else {
            companyData.formTypes = companyData.summaryFormTypes;
        }

        companyData.companyProfiles = profileValues.reduce((obj, profile) => ({
            ...obj,
            [profile.ProfileName]: profile.Value
        }), {});

        companyData.availableLanguages = await getAvailableLanguages(httpClient, appSettings, isUserAuthenticated);
        companyData.appStrings = await getTranslatedAppStrings(companyData.availableLanguages, language, httpClient, appSettings, isUserAuthenticated);
        companyData.hierarchyData = await getHierarchyDetails(httpClient, appSettings, isUserAuthenticated);
        const firstReportSettings = await getFirstReportSettings(httpClient, appSettings, isUserAuthenticated);
        companyData.firstReportSettings = firstReportSettings;

        next(AppActions.fetchCompanyDataSuccess(companyData));

        next(AppActions.loadReportingUserDependentDefaultValues());

        const { shortCode, accidentId, formId } = state.appReducer.loadingForm;
        if (shortCode && accidentId && formId) {
            const user = getFormUser(state);
            if (isLoggedInUserType(user.type) && user.userId && user.userId > -1) {
                next(AppActions.continueDraftLoad(shortCode, accidentId, formId));
            }
        }
    } catch (error) {
        // If there is a user, log him out and try again.
        // This error can occur if redux loses the user GUID, but the application retains the JWT.
        store.dispatch(AuthActions.logoutUser());
        next(AppActions.fetchCompanyDataFailure(error.response));
    }
}

const getCompanyFormSummaryData = async (store, locationId, language) => {
    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const isUserAuthenticated = isAuthenticated(state);
    const formGroup = state.appReducer.formGroup;

    const companyFormSummaryResponse = await httpClient.get(endpoints.getCompanyFormSummaries(appSettings, isUserAuthenticated, formGroup, language, locationId));
    const companyFormSummaries = companyFormSummaryResponse.data;

    let summaryFormTypes = [];

    if (companyFormSummaries) {
        summaryFormTypes = companyFormSummaries.filter(f => f.HasWizardPages)
        await loadSummaryFormImages(summaryFormTypes, httpClient, appSettings, isUserAuthenticated);
    }

    return summaryFormTypes
}

const loadSummaryFormImages = async (summaryFormTypes, httpClient, appSettings, isUserAuthenticated) => {

    const imagesToLoad = _.uniq(summaryFormTypes.flatMap(formType => {
        return [
            ...formType.ImageId > -1 ? [formType.ImageId] : [],
        ]
    }));

    const imageAPICalls = imagesToLoad.map(imageId => {
        return new Promise(async (resolve, reject) => {
            try {
                const imageResponse = await httpClient.get(endpoints.getCompanyFormImage(appSettings, isUserAuthenticated, imageId), 'blob', 'formimagecache');
                resolve({
                    imageId,
                    image: URL.createObjectURL(imageResponse.data)
                });
            } catch (ex) {
                reject();
            }
        });
    });

    const responses = await allSettled(imageAPICalls) || [];
    const images = responses.filter(response => response.status === 'fulfilled').reduce((i, response) => {
        const { imageId, image } = response.value;
        return {
            ...i,
            [imageId]: image
        }
    }, {});

    summaryFormTypes.forEach(formType => {
        if (formType.ImageId > -1) {
            formType.image = images[formType.ImageId];
        }
    });
}


const getCompanyFormsData = async (store, locationId, formId, language, summaryForms) => {

    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const isUserAuthenticated = isAuthenticated(state);
    const formGroup = state.appReducer.formGroup;

    let companyFormTypes = summaryForms ? summaryForms : [];

    if (formId !== -1) {
        const companyFormsResponse = await httpClient.get(endpoints.getCompanyForms(appSettings, isUserAuthenticated, formGroup, language, locationId, formId));
        const companyForms = companyFormsResponse.data;

        if (companyForms) {
            const companyWizards = companyForms.filter(f => f.HasWizardPages);

            if (companyWizards.length === 1) {
                const formTypeIndex = companyFormTypes ? companyFormTypes.findIndex(f => f.Id === formId) : -1;
                if (formTypeIndex !== -1) {
                    companyFormTypes[formTypeIndex] = companyWizards[0];
                } else {
                    companyFormTypes.push(companyWizards[0]);
                }
                await loadFormImages(companyFormTypes, httpClient, appSettings, isUserAuthenticated, state);
            }
        }
    }
    return companyFormTypes;
}

const loadFormImages = async (companyFormTypes, httpClient, appSettings, isUserAuthenticated, state) => {

    const imagesToLoad = _.uniq(companyFormTypes.flatMap(formType => {
        return [
            ...formType.WizardPages.flatMap(wizardPage =>
                wizardPage.Elements.flatMap(element =>
                    element.Fields.filter(field => {
                        return field.TemplateAttribute && field.TemplateAttribute.PossibleValues && field.TemplateAttribute.PossibleValues
                            .filter(value => !value.Deleted)
                            .every(value => value.ImageId);
                    }).flatMap(field => {
                        return field.TemplateAttribute.PossibleValues
                            .filter(value => !value.Deleted)
                            .map(value => value.ImageId);
                    })
                )
            )
        ]
    }));

    const imageAPICalls = imagesToLoad.map(imageId => {
        return new Promise(async (resolve, reject) => {
            try {
                const imageResponse = await httpClient.get(endpoints.getCompanyFormImage(appSettings, isUserAuthenticated, imageId), 'blob', 'formimagecache');
                resolve({
                    imageId,
                    image: URL.createObjectURL(imageResponse.data)
                });
            } catch (ex) {
                reject();
            }
        });
    });

    const responses = await allSettled(imageAPICalls) || [];
    const images = responses.filter(response => response.status === 'fulfilled').reduce((i, response) => {
        const { imageId, image } = response.value;
        return {
            ...i,
            [imageId]: image
        }
    }, {});

    companyFormTypes.forEach(formType => {
        formType.WizardPages.forEach(wizardPage => {
            wizardPage.Elements.forEach(element => {
                element.Fields.forEach(field => {
                    if (field.TemplateAttribute) {
                        getPossibleValues(state, field).forEach(value => {
                            if (value.ImageId) {
                                value.image = images[value.ImageId];
                            }
                        });
                    }
                });
            });
        });
    });
}

const getSavedUserLanguage = async (state) => {
    const authInfo = getAuthInfo(state);
    const userId = (authInfo && authInfo.userId) || 'anonymous';
    try {
        const settings = localforage.createInstance({
            name: `usersettings${userId || ''}`
        });
        const language = await settings.getItem("language");
        if (language) {
            return language
        } else {
            return ''
        };
    } catch (ex) {
        return '';
    }
}

const getAvailableLanguages = async (httpClient, appSettings, isUserAuthenticated) => {
    const companyLanguagesResponse = await httpClient.get(endpoints.getCompanyLanguages(appSettings, isUserAuthenticated));
    const companyLanguages = companyLanguagesResponse.data;

    return companyLanguages || {};
};

const getTranslatedAppStrings = async (availableLanguages, language, httpClient, appSettings, isUserAuthenticated) => {
    const stringsArray = _.uniq([
        ...Object.values(appStrings),
        ...availableLanguages.flatMap(l => {
            return [
                l.LanguageName,
                l.CountryName
            ];
        }),
    ]);
    const translationsResponse = await httpClient.post(endpoints.getTranslations(appSettings, language, isUserAuthenticated), stringsArray);
    const translations = translationsResponse.data || {};

    return { ...appStrings, ...translations };
};

export const translate = (store, text, fromLanguage, toLanguage) => {
    const http = new HttpClient(store.getState());
    const isUserAuthenticated = isAuthenticated(store.getState());
    const appSettings = getAppSettings(store);
    return http.post(endpoints.translate(appSettings, isUserAuthenticated, fromLanguage, toLanguage), `"${text}"`);
};

export const requestTranslationLanguages = (store, text) => {
    const http = new HttpClient(store.getState());
    const isUserAuthenticated = isAuthenticated(store.getState());
    const appSettings = getAppSettings(store);
    return http.get(endpoints.requestTranslationLanguages(appSettings, isUserAuthenticated));
};

export const requestResolveLabel = (store, paramObj) => {
    const http = new HttpClient(store.getState());
    const isUserAuthenticated = isAuthenticated(store.getState());
    const appSettings = getAppSettings(store);
    return http.post(endpoints.requestResolveLabel(appSettings, isUserAuthenticated, appSettings.language), paramObj);
};

export const requestLiveSearch = (store, SearchQuery, AttributeName, Page, Language, ElementGuid, LocationId, AdditionalColumns) => {
    const http = new HttpClient(store.getState(), {skipHttpCache: false});
    const cancelTokenSource = http.cancelTokenSource;
    const isUserAuthenticated = isAuthenticated(store.getState());
    const appSettings = getAppSettings(store);
    return {
        req:
            http.get(
                endpoints.liveSearch(appSettings, isUserAuthenticated, SearchQuery, AttributeName, Page, Language, ElementGuid, LocationId, AdditionalColumns),
                undefined,
                undefined,
                cancelTokenSource),
        cancelTokenSource
    };
};

const getHierarchyDetails = async (httpClient, appSettings, isUserAuthenticated) => {
    try {
        const hierarchyDataReponse = await httpClient.get(endpoints.getHierarchyDetails(appSettings, isUserAuthenticated));

        const hierarchyData = {
            isHierarchy: hierarchyDataReponse.data.IsActive,
            selectedHierarchyId: hierarchyDataReponse.data.Id,
            hierarchyEnvironment: hierarchyDataReponse.data.Environment,
        }

        return hierarchyData || '';

    } catch (ex) {
        return '';
    }
};

const getFirstReportSettings = async (httpClient, appSettings, isUserAuthenticated) => {
    try {

        const firstReportResponse = await httpClient.get(endpoints.getFirstReportSettings(appSettings, isUserAuthenticated))
        const dataResponse = firstReportResponse.data;

        const firstReportSettingsData = {
            speechToTextSettings: {
                region: dataResponse.SpeechToTextSettings.Region,
                isActive: dataResponse.SpeechToTextSettings.IsActive
            },
        };

        return firstReportSettingsData;

    }
    catch (ex) {
        return '';
    }
}

export const reloadObjectPicklistValues = async (locationId, formId, initialLoad, store, next) => {

    const state = store.getState();

    let formObjectPicklists = getObjectPicklistFormFields(formId ? formId : state.appReducer.selectedFormTypeId, state);
    ensureObjectBasedPicklistValues(formId, store, next);

    formObjectPicklists = formObjectPicklists
        .filter(field => !initialLoad || field.TemplateAttribute.IsLocationBasedObjectPicklist === false)
        .filter(field => {
            const isLiveSearch = checkIsLive(field);
            return !isLiveSearch;
        });

    if (formObjectPicklists.length > 0) {

        const attributes = formObjectPicklists.map(field => field.TemplateAttribute.Name);

        const request = formObjectPicklists.map(
            field => ({
                AttributeName: field.TemplateAttribute.Name,
                ColumnIDs: field.ColumnNames.map(item => item.Key)
            })
        )

        next(WizardActions.reloadObjectPicklistValuesBegin(attributes));

        if (!locationId || locationId < 0) {
            const values = attributes.reduce((object, i) => {
                return {
                    ...object,
                    [i]: []
                }
            }, {});
            next(WizardActions.reloadObjectPicklistValuesSuccess(values))
        } else {
            try {
                const httpClient = new HttpClient(state);
                const appSettings = getAppSettings(store);
                const isUserAuthenticated = isAuthenticated(state);
                const language = state.appReducer.userSettings.language;

                const objectPicklistValuesResponse = await httpClient.post(endpoints.getObjectPicklistValues(appSettings, isUserAuthenticated, locationId, language), request);
                const picklistValues = objectPicklistValuesResponse.data.reduce((object, i) => {
                    return {
                        ...object,
                        [i.AttributeName]: i.Values
                    }
                }, {});

                next(WizardActions.reloadObjectPicklistValuesSuccess(picklistValues));

            } catch (error) {
                next(WizardActions.reloadObjectPicklistValuesFailure(error, attributes));
            }
        }
    }
}

const ensureObjectBasedPicklistValues = (formId, store, next) => {
    const state = store.getState();
    
    let formObjectPicklists = getObjectPicklistFormFields(formId ? formId : state.appReducer.selectedFormTypeId, state)
        .filter(field => field.TemplateAttribute.IsLocationBasedObjectPicklist === true);

    if (formObjectPicklists.length > 0){
        const { answers } = state.wizardReducer;

        for (var formObjectPicklist of formObjectPicklists){
            const answer = answers.fields[formObjectPicklist.TemplateAttribute.Name]
            const possibleValues = formObjectPicklist.TemplateAttribute.PossibleValues
            if (answer && (!possibleValues || !possibleValues.find(v => v.Identifier === answer))){
                next(WizardActions.updateFieldAnswer(formObjectPicklist.TemplateAttribute.Name, null));
            }
        }
    }
}

export const reloadFormPermissions = async (locationId, formId, store, next) => {

    const state = store.getState();
    const language = state.appReducer.userSettings.language;
    const companyFormsData = await getCompanyFormsData(store, locationId, formId, language);
    const formType = companyFormsData && companyFormsData[0];

    if (formType) {
        next(AppActions.updateFormType(formType, formId));
        next(AppActions.loadReportingUserDependentDefaultValues());
    }
}

const loadCompanyLogo = async (store, next) => {
    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const isUserAuthenticated = isAuthenticated(state);
    try {
        const companyLogoResponse = await httpClient.get(endpoints.getCompanyLogo(appSettings, isUserAuthenticated));
        if (companyLogoResponse && companyLogoResponse.data) {
            next(AppActions.setCompanyLogo(companyLogoResponse.data));
        }
    } catch (ex) {}
}

const loadSSOSettings = async (redirectToB2C, store, next) => {
    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const isUserAuthenticated = isAuthenticated(state);
    const formGroup = state.appReducer.formGroup;

    const ssoSettingsReponse = await httpClient.get(endpoints.getCompanySSOSettings(appSettings, isUserAuthenticated, formGroup));
    const ssoSettings = ssoSettingsReponse.data;

    if (ssoSettings) {
        next(AppActions.setSSOSettings(ssoSettings.FRSsoReturnURL ? ssoSettings.FRSsoReturnURL : undefined,
            ssoSettings.IsCompanySsoOnly,
            ssoSettings.DomainHint ? ssoSettings.DomainHint : undefined));
    }

    if (redirectToB2C) {
        next(AuthActions.setAuthType(USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN));
    }
}

const getHasFormLocationRestrictions = async (store) => {
    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    const isUserAuthenticated = isAuthenticated(state);
    const formGroup = state.appReducer.formGroup;

    return await httpClient.get(endpoints.getFormLocationRestrictions(appSettings, isUserAuthenticated, formGroup));
}

const loadForm = async (store, formId, next) => {
    const state = store.getState();
    const language = state.appReducer.userSettings.language;
    const companyFormsData = await getCompanyFormsData(store, -1, formId, language);
    next(AppActions.updateFormType(companyFormsData[0], formId));
    next(AppActions.loadReportingUserDependentDefaultValues());
}

const reloadForms = async (store, locationId, next) => {
    const state = store.getState();
    const language = state.appReducer.userSettings.language;
    const companySummaryForms = await getCompanyFormSummaryData(store, locationId, language);
    next(AppActions.updateFormTypes(companySummaryForms));
}

const ConfigService = store => next => (action) => {
    next(action);
    switch (action.type) {
        case AppActions.LOAD_DATA:
            fetchCompanyData(store, next);
            break;
        case WizardActions.RELOAD_OBJECT_PICKLIST_VALUES:
            reloadObjectPicklistValues(action.locationId, action.formId, false, store, next);
            break;
        case AppActions.RELOAD_FORM_PERMISSIONS:
            reloadFormPermissions(action.locationId, action.formId, store, next);
            break;
        case AppActions.SET_FORM_TYPE:
            reloadFormPermissions(-1, action.formType, store, next);
            reloadObjectPicklistValues(-1, action.formType, true, store, next);
            break;
        case AppActions.LOAD_COMPANY_LOGO:
            loadCompanyLogo(store, next);
            break;
        case AppActions.LOAD_SSO_SETTINGS:
            loadSSOSettings(action.redirectToB2C, store, next);
            break;
        case AppActions.LOAD_FORM:
            loadForm(store, action.formId, next);
            break;
        case AppActions.RELOAD_FORMS:
            reloadForms(store, action.locationId, next);
            break;
        default:
            break;
    }
};

export default ConfigService;
