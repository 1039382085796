import React from 'react';
import './SiteMapPreview.scss';
import { connect } from 'react-redux';
import { PinSmall } from '../../icons';
import { CODED_ELEMENT_TYPES } from '../../constants/ElementTypes';

class SiteMapPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            containerWidth: undefined,
            pinX: undefined,
            pinY: undefined,
            imageWidth: undefined,
        }

        this.container = React.createRef();
        this.siteMapPreview = React.createRef();
        this.onLoadImage = this.onLoadImage.bind(this);
    }

    componentDidMount() {
        this.setState({
            containerWidth: this.container.current && this.container.current.offsetWidth
        });
    }

    onLoadImage() {
        const { answer } = this.props;
        const { containerWidth } = this.state;
        const imageWidth = this.siteMapPreview.current.naturalWidth;
        const width = Math.min(containerWidth, 400);

        const imageRatio = imageWidth / width;
        const topCoordinate = (answer.hotspotY / imageRatio) - 34;
        const leftCoordinate = (answer.hotspotX / imageRatio) - 18;

        this.setState({
            pinX: leftCoordinate,
            pinY: topCoordinate,
            imageWidth: width,
        });
    }

    render() {
        const { element, getSiteMap, answer } = this.props;
        const { pinX, pinY, containerWidth, imageWidth } = this.state;
        const siteMap = element.Name === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS ? getSiteMap(element.Guid) : undefined;

        return (
            siteMap && answer && answer.hotspotX && answer.hotspotY ? (
                <div className="ReviewPageSiteMapPreview" ref={this.container} style={{ visibility: imageWidth ? '' : 'hidden' }}>
                    {
                        containerWidth && <>
                            <img className="ReviewPageSiteMapPreviewImage" ref={this.siteMapPreview} src={siteMap} onLoad={() => this.onLoadImage()} alt="" style={{ width : imageWidth }}/>
                            {
                                pinX && pinY && <img className="ReviewPageSiteMapPreviewPin" src={PinSmall} alt="" style={{ left: pinX, top: pinY }}/>
                            }
                        </>
                    }
                </div>
            ) : <></>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        getSiteMap: (elementGuid) => { return state.wizardReducer.siteMaps[elementGuid] },
    };
};


export default connect(mapStateToProps)(SiteMapPreview);