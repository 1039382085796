import React, { Component } from 'react';
import style from './Wizard.module.scss';
import { connect } from 'react-redux';
import WizardPage from '../WizardPage/WizardPage';
import SideMenu from '../SideMenu/SideMenu';
import Header from '../Header/Header';
import ReviewPage from '../ReviewPage/ReviewPage';
import SimilarRecordSummaryModal from '../SimilarRecords/SummaryModal';
import { getIsReviewPage } from '../../selectors/wizardSelectors';

class Wizard extends Component {

    render() {
        const { isReviewPage } = this.props;
        return (
            <div className={style.Wizard}>
                <Header></Header>
                <div className={style.WizardContent}>
                    <SideMenu />
                    {
                        isReviewPage ? <ReviewPage /> : <WizardPage />
                    }
                    
                    <SimilarRecordSummaryModal />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isReviewPage: getIsReviewPage(state)
    };
};


export default connect(mapStateToProps)(Wizard);
