import React, { Component } from 'react';
import style from './ReviewPage.module.scss';
import { connect } from 'react-redux';
import { submitForm } from '../../actions/appActions';
import { goToPreviousPage, goToPageBegin, resolveLabelBegin } from '../../actions/wizardActions';
import { PrimaryButton, SecondaryButton } from 'spheracloud-common';
import { getPreviousPageTitle, getSelectedFormWizardPages, getStartPage, getElementVisibility, getPageAnswersCount, getPageQuestionsCount } from '../../selectors/wizardSelectors';
import { getSelectedForm, getFormUser, getString, getProfileValue } from '../../selectors/appSelectors';
import { USER_TYPES } from '../../constants/UserTypes';
import { ArrowLeft, Account, SsoSmall, IncognitoHeader, EmailAdd, PrintIcon, DetailsSmall } from '../../icons';
import Strings from '../../constants/Strings';
import Translation from '../Translation/Translation';
import ReactToPrint from "react-to-print";
import AdditionalRecipientsModal from './AdditionalRecipientsModal/AdditionalRecipientsModal';
import classNames from 'classnames';

import ReviewPageData from './ReviewPageData/ReviewPageData';

class ReviewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAdditionalRecipientsModal: false,
        };

        this.printingRef = React.createRef();
        this.getAboutYouPageSummary = this.getAboutYouPageSummary.bind(this);
    }

    getAboutYouPageSummary() {
        const { user, anonymous } = this.props;

        switch (user.type) {
            case USER_TYPES.AUTHENTICATED:
            case USER_TYPES.B2CAUTHENTICATED:
                return (
                    <div className={style.ReviewPageAccountContainer}>
                        <img src={Account} alt="user-icon" />
                        <span>{user.name || user.email}</span>
                    </div>
                )
            case USER_TYPES.SSOAUTHENTICATED:
            case USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN:
                return (
                    <div className={style.ReviewPageAccountContainer}>
                        <img src={SsoSmall} alt="user-icon" />
                        <span>{user.name || user.email}</span>
                    </div>
                )
            case USER_TYPES.MANUAL:
                return (
                    <div className={style.ReviewPageAccountContainerManual}>
                        <div className={style.ReviewPageAccountHeader}>
                            <img src={user.name ? DetailsSmall : IncognitoHeader} alt="user-icon" />
                            <span>{user.name}</span>
                        </div>
                        <div className={style.ReviewPageAccountContent}>
                            <div>{user.email}</div>
                            <div>{user.phone}</div>
                        </div>
                    </div>
                )
            case USER_TYPES.ANONYMOUS:
                return (
                    <div className={style.ReviewPageAccountContainer}>
                        <img src={IncognitoHeader} alt="user-icon" />
                        <span>{anonymous}</span>
                    </div>
                )
            default:
                return <div><Translation>{Strings.anonymous}</Translation></div>;
        }
    }

    getEmailList() {
        const { additionalRecipients } = this.props;
        return additionalRecipients && additionalRecipients.map((email, index) => {
            return <div key={index} className={style.ReviewPageAdditionalRecipients}>{email}</div>;
        })
    }

    openAdditionalRecipientsModal() {
        this.setState({ showAdditionalRecipientsModal: true });
    }

    hideAdditionalRecipientsModal() {
        this.setState({ showAdditionalRecipientsModal: false });
    }

    render() {
        const { startPage, selectedForm, additionalRecipientsLabel,
            pages, previousPageTitle, elementIsVisible, untitledPage,
            previousPage, goToPage, submitForm, selectedLanguage,
            pageAnswerCount, pageQuestionCount, companyLogo, additionalRecipients,
            watermarkText, answers, liveSearchOptions, objectPicklistValues, resolvedLabels, resolveLabelBegin, isHierarchy } = this.props;

        const { showAdditionalRecipientsModal } = this.state;

        return (

            <div className={style.ReviewPage} >
                {
                    showAdditionalRecipientsModal && <AdditionalRecipientsModal onClose={() => this.hideAdditionalRecipientsModal()} />
                }
                <div className={style.ReviewPageContent} ref={this.printingRef}>

                    <div className={style.Watermark}>
                        {watermarkText}
                    </div>

                    {
                        companyLogo && <img className={style.ReviewPageCompanyLogo} src={companyLogo} alt="Company Logo" />
                    }
                    <div className={style.ReviewPageHeader}>
                        <div className={style.ReviewPageTitle}>
                            <Translation>{Strings.reviewPageScreenTitle}</Translation>
                        </div>
                        <div className={style.ReviewPagePrintTitle}>
                            <Translation>{Strings.reviewPagePrintScreenTitle}</Translation>
                        </div>
                        <div className={style.ReviewPageButtonContainer}>
                            <ReactToPrint
                                trigger={() =>
                                    <SecondaryButton className={classNames([style.ReviewPagePrintButton, style.noPrint])}>
                                        <img src={PrintIcon} alt="Print" />
                                        <span><Translation>{Strings.printReviewPageButtonLabel}</Translation></span>
                                    </SecondaryButton>
                                }
                                content={() => this.printingRef.current}
                            />
                            <SecondaryButton className={classNames([style.ReviewPageEmailButton, style.noPrint])} onClick={() => this.openAdditionalRecipientsModal()}>
                                <img src={EmailAdd} alt="Add" />
                                <span><Translation>{Strings.addEmailButtonLabel}</Translation></span>
                            </SecondaryButton>
                        </div>
                    </div>

                    <div className={style.ReviewPageHelpText}>
                        <Translation>{Strings.reviewPageHelpText}</Translation>
                    </div>

                    <ReviewPageData
                        key='reviewPage'
                        startPageDescription={selectedForm.ShortDescription}
                        pages={pages}
                        pageAnswerCount ={pageAnswerCount}
                        pageQuestionCount={pageQuestionCount}
                        onEditPage={goToPage}
                        getAboutUserPageSummary={this.getAboutYouPageSummary}
                        answers={answers}
                        elementIsVisible={elementIsVisible}
                        selectedLanguage={selectedLanguage}
                        startPage = {startPage}
                        recipients = {
                            additionalRecipients && additionalRecipients.length > 0 ?
                            {
                                data: this.getEmailList(),
                                label: additionalRecipientsLabel
                            }
                            : undefined
                        }
                        resolveLabelBegin={resolveLabelBegin.bind(this)}
                        liveSearchOptions={liveSearchOptions}
                        objectPicklistValues={objectPicklistValues}
                        resolvedLabels={resolvedLabels}
                        isHierarchy={isHierarchy}
                    />

                </div>
                <div className={classNames([style.ReviewPageFooter, style.noPrint])}>
                    <SecondaryButton className={style.ReviewPagePrevious} onClick={() => { previousPage() }}>
                        <span className={style.ReviewPagePreviousIcon}>
                            <img src={ArrowLeft} alt="Back" />
                        </span>
                        <span className={style.ReviewPagePreviousText}>
                            <Translation>{Strings.back}</Translation>
                            {`: ${previousPageTitle ? previousPageTitle : untitledPage}`}
                        </span>
                    </SecondaryButton>
                    <PrimaryButton className={style.ReviewPageSubmit} onClick={() => { submitForm() }}>
                        <Translation>{Strings.submitInitialReport}</Translation>
                    </PrimaryButton>
                </div>
                <div className={style.ReviewPageFooterMobile}>
                    <SecondaryButton className={style.ReviewPagePrevious} onClick={() => { previousPage() }}>
                        <Translation>{Strings.back}</Translation>
                    </SecondaryButton>
                    <PrimaryButton className={style.ReviewPageSubmit} onClick={() => { submitForm() }}>
                        <Translation>{Strings.submit}</Translation>
                    </PrimaryButton>
                </div>
            </div>

        );



    }
}

const mapStateToProps = state => {
    return {
        startPage: getStartPage(state),
        selectedForm: getSelectedForm(state),
        pages: getSelectedFormWizardPages(state),
        previousPageTitle: getPreviousPageTitle(state),
        answers: state.wizardReducer.answers,
        user: getFormUser(state),
        objectPicklistValues: state.wizardReducer.objectPicklistValues,
        additionalRecipients: state.wizardReducer.additionalRecipients,
        liveSearchOptions: state.wizardReducer.liveSearchOptions,
        resolvedLabels: state.wizardReducer.answers.resolvedLabels,
        additionalRecipientsLabel: getString(Strings.additionalRecipients, state),
        untitledPage: getString(Strings.untitledPage, state),
        elementIsVisible: (elementGuid) => { return getElementVisibility(state, elementGuid) },
        isHierarchy: state.appReducer.isHierarchy,
        selectedLanguage: state.appReducer.userSettings.language,
        anonymous: getString(Strings.anonymous, state),
        pageAnswerCount: (page) => { return getPageAnswersCount(0, page, state) },
        pageQuestionCount: (page) => { return getPageQuestionsCount(0, page, state) },
        companyLogo: state.appReducer.companyLogo,
        watermarkText: getProfileValue('Company.FirstReport.ReviewPageWatermark', state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    previousPage: () => {
        dispatch(goToPreviousPage());
    },
    goToPage: page => {
        dispatch(goToPageBegin(page));
    },
    submitForm: (data) => {
        dispatch(submitForm(data));
    },
    resolveLabelBegin: (paramObj) => {
        dispatch(resolveLabelBegin(paramObj));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage);
