import React, { Component } from 'react';
import './IncidentTypeButton.scss';
import ReactTooltip from 'react-tooltip';
import { Incident, HelpCircle } from '../../icons';
import { Modal } from 'spheracloud-common';
import { getNodeFromString } from '../../utils/utils';

class IncidentTypeButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            helpTextModalOpen: false,
        };

        this.toggleHelpTextModal = this.toggleHelpTextModal.bind(this);
    }

    toggleHelpTextModal(open) {
        this.setState({
            helpTextModalOpen: open
        })
    }

    render() {
        const { onClick, incidentType, image, helpText, formTypeId } = this.props;
        const { helpTextModalOpen } = this.state;
        const tooltipName = `help-text-${formTypeId}`
        const imageSrc = image || Incident;
        const isMobileScreen = window.innerWidth <= 480;

        const helpButtonAttributes = isMobileScreen ? {
            'onClick': () => {this.toggleHelpTextModal(true)}
        } : {
            'data-tip': true,
            'data-for': tooltipName,
            'data-event': 'click mouseenter',
            'data-event-off': 'mouseleave'
        }

        return (
            <div className="IncidentTypeButton">
                <div className="IncidentTypeButtonImage" onClick={onClick} style={{backgroundImage: `url("${imageSrc}")`}}></div>
                <div className="IncidentTypeButtonFooter">
                    <div className="IncidentTypeButtonTitle">{incidentType}</div>
                    {
                        helpText && (
                            <div className="IncidentTypeButtonHelp" {...helpButtonAttributes}>
                                <img src={HelpCircle} alt="Help"></img>
                            </div>
                        )
                    }
                </div>
                {
                    helpText &&
                    (
                        <>
                            {
                                helpTextModalOpen &&
                                <Modal
                                    apply={""}
                                    cancel={""}
                                    onApply={() => {}}
                                    onCancel={() => {this.toggleHelpTextModal(false)} }
                                    className="HelpTextModal"
                                >
                                    {getNodeFromString(helpText)}
                                </Modal>
                            }
                            <ReactTooltip id={tooltipName} effect="solid" place="bottom" type="light" globalEventOff="click" clickable={true}>
                                {getNodeFromString(helpText)}
                            </ReactTooltip>
                        </>
                    )
                }
            </div>
        );
    }
}

export default IncidentTypeButton;