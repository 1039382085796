import React, { Component } from 'react';
import style from './WizardPage.module.scss';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getCurrentParentPage, getPreviousPageTitle, getIsUserPage, getCurrentPageHasMandatoryFields } from '../../selectors/wizardSelectors';
import WizardPageElementList from './WizardPageElementList/WizardPageElementList';
import WizardPageWithAdd from './WizardPageWithAdd/WizardPageWithAdd';
import WizardPageFooter from '../WizardPageFooter/WizardPageFooter';
import ReportingUser from '../ReportingUser/ReportingUser'
import { clearScrollToTop, moveToPage, clearNERWarning } from '../../actions/wizardActions';
import { setContentDimensions } from '../../actions/appActions';
import { getProfileValue } from '../../selectors/appSelectors';
import PagingSpinner from '../../components/Field/PagingSpinner/PagingSpinner';

class WizardPage extends Component {

    constructor(props) {
        super(props);
        props.clearScrollToTop();
        this.content = React.createRef();
        this.calculateContentDimensions = _.debounce(this.calculateContentDimensions, 250).bind(this);
    }

    componentDidMount() {
        this.calculateContentDimensions();
        window.addEventListener('resize', this.calculateContentDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateContentDimensions);
    }

    calculateContentDimensions() {
        try {
            const { setContentDimensions } = this.props;
            const elementWidth = this.content.current.clientWidth;
            const elementHeight = this.content.current.clientHeight;
            const computedStyle = getComputedStyle(this.content.current);
            const elementHorizontalPadding = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            const elementVerticalPadding = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
            setContentDimensions(elementHeight - elementVerticalPadding, elementWidth - elementHorizontalPadding);
        } catch (ex) { }
    }

    componentDidUpdate(prevProps) {
        const { scrollToTop, clearScrollToTop } = this.props;
        if (scrollToTop === true) {
            this.content.current && this.content.current.scrollTo && this.content.current.scrollTo(0, 0);
            clearScrollToTop();
        }
    }

    getPage(page) {
        const { isUserPage, includesMandatoryFields, clearNERWarning, nameDetectionActive, namesDetected, moveToPage } = this.props;

        if (isUserPage) {
            return <ReportingUser page={page} />
        } else if (page.WizardSubForms && page.WizardSubForms.length > 0) {
            return <WizardPageWithAdd />
        } else {
            return <WizardPageElementList page={page} clearNERWarning={clearNERWarning}  moveToPage={moveToPage} namesDetected={namesDetected} nameDetectionActive={nameDetectionActive} includesMandatoryFields={includesMandatoryFields} />
        }
    }

    render() {
        const { page, isPageLoading } = this.props;

        return (

            <>
            {isPageLoading &&
                <div className="WizardPageSpinner">
                    <PagingSpinner />
                </div>
            }
            {!isPageLoading &&
                <div className={style.WizardPage}>
                  <div className={style.WizardPageContent} ref={this.content}>
                        {this.getPage(page)}
                </div>
                <WizardPageFooter />
            </div >
        }
        </>
        );
    }
}

const mapStateToProps = state => {
    return {
        page: getCurrentParentPage(state),
        previousPageTitle: getPreviousPageTitle(state),
        isUserPage: getIsUserPage(state),
        includesMandatoryFields: getCurrentPageHasMandatoryFields(state),
        scrollToTop: state.wizardReducer.scrollToTop,
        nameDetectionActive: getProfileValue("Company.FirstReport.AzureNameDetection", state) === "Yes",
        isPageLoading: state.wizardReducer.isPageLoading,
        namesDetected: state.wizardReducer.namesDetected,
    };
};

const mapDispatchToProps = dispatch => ({
    clearScrollToTop: () => {
        dispatch(clearScrollToTop());
    },
    setContentDimensions: (height, width) => {
        dispatch(setContentDimensions(height, width));
    },
    moveToPage: () => {
        dispatch(moveToPage(undefined));
    },
    clearNERWarning: () => {
        dispatch(clearNERWarning());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardPage);
