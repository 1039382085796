import React from 'react';
import PropTypes from 'prop-types';
import styles from './SliderInput.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Play } from "../../../icons";
import classNames from 'classnames';

class SliderInput extends React.Component {
    constructor(props) {
        super(props);

        const { onValueChanged, initialValue, minValue } = props;
        this.handleChange = this.handleChange.bind(this);

        if (initialValue === undefined) {
            onValueChanged(minValue);
        }
    }

    handleChange(value) {
        const { onValueChanged } = this.props;
        onValueChanged(value);
    }

    generateMarks() {
        const returnVal = {};
        const {
            maxValue,
            minValue,
            step
        } = this.props;
        let current = minValue;
        while (current <= maxValue) {
            returnVal[current] = current;
            current += step;
        }
        return returnVal;
    }

    increment(shift) {
        const { onValueChanged, initialValue, minValue, maxValue, step } = this.props;
        const delta = shift ? (10 * step) : step;
        const nextValue = isNaN(initialValue) ? minValue : Math.min((initialValue + delta), maxValue);
        onValueChanged(nextValue);
    }

    decrement(shift) {
        const { onValueChanged, initialValue, minValue, step } = this.props;
        const delta = shift ? (10 * step) : step;
        const nextValue = isNaN(initialValue) ? minValue : Math.max((initialValue - delta), minValue);
        onValueChanged(nextValue);
    }

    render() {
        const {
            disabled,
            maxValue,
            minValue,
            step,
            leftLabel,
            middleLabel,
            rightLabel,
            onValueChanged,
            initialValue,
            showArrows,
            hasError,
        } = this.props;

        const hasLabel = leftLabel || middleLabel || rightLabel;

        return (
            <div className={styles["Input-Container"]}>
                {
                    showArrows &&
                    <div className={styles["Oval-Rotated"]} onClick={(e) => {
                        this.decrement(e.shiftKey);
                    }}>
                        <img className={styles["Play"]} src={Play} alt="Arrow" />
                    </div>
                }
                <div className={styles["Flex-Container"]}>
                    <Slider
                        value={initialValue}
                        disabled={disabled}
                        onChange={this.handleChange}
                        max={maxValue}
                        min={minValue}
                        step={step} />
                    {
                        hasLabel &&
                        <div className={styles["Labels-Container"]}>
                            <div className={styles["Labels"]}>
                                <div className={styles["Left-Label"]}>{leftLabel}</div>
                                <div className={styles["Middle-Label"]}>{middleLabel}</div>
                                <div className={styles["Right-Label"]}>{rightLabel}</div>
                            </div>
                        </div>
                    }
                </div>
                {
                    showArrows &&
                    <div className={styles["Oval"]} onClick={(e) => {
                        this.increment(e.shiftKey);
                    }}>
                        <img className={styles["Play"]} src={Play} alt="Arrow" />
                    </div>
                }
                <div className={styles["Label"]}>
                    <input
                        type="number"
                        min={minValue}
                        max={maxValue}
                        step={step}
                        style={{ width: ((('' + maxValue).length + 2) + 'rem') }}
                        disabled={disabled}
                        className={classNames([
                            styles["Text-Input"],
                            ...(hasError ? [styles["Text-Input-Error"]] : [])
                        ])}
                        onChange={(event) => {
                            let v = parseInt(event.target.value) || minValue || 0;

                            if (maxValue && v > maxValue) 
                                v = maxValue;
                            else if (minValue && v < minValue) 
                                v = minValue;
                                
                            onValueChanged(v);
                        }} value={initialValue} />
                </div>
            </div>
        );
    }
}

export const propTypes = {
    disabled: PropTypes.bool,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    leftLabel: PropTypes.string,
    middleLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    onValueChanged: PropTypes.func,
    step: PropTypes.number,
    hasError: PropTypes.bool,
    invalidMessage: PropTypes.string,
};

export const defaultProps = {
    disabled: false,
    id: undefined,
    minValue: 0,
    maxValue: 100,
    leftLabel: '',
    middleLabel: '',
    rightLabel: '',
    step: 1,
    showArrows: true,
    hasError: false,
    invalidMessage: '',
};

SliderInput.propTypes = propTypes;
SliderInput.defaultProps = defaultProps;

export default SliderInput;
