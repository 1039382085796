import React, { Component } from 'react';
import './SubmitDraftPage.module.scss';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'spheracloud-common';
import { clearDraftFailure, clearFormTypeAndStayLoggedIn, clearFormTypeAndLogout } from '../../actions/appActions';
import { getSubmittedDraftId, getString } from '../../selectors/appSelectors';
import Strings from '../../constants/Strings';
import Translation from '../Translation/Translation';
import { USER_TYPES } from '../../constants/UserTypes';

class SubmitDraftPage extends Component {
    render() {
        const {
            isDraftSubmitting, isDraftSubmitted, submitDraftError, submittedDraftId,
            errorLabel, submittingTitle, submittedTitle, okLabel, exitLabel,
            clearDraftFailure, clearFormTypeAndLogout, clearFormTypeAndStayLoggedIn, logout,
            userType,
        } = this.props;

        if (submitDraftError) {
            return (
                <Modal
                    className="SubmitDraftModal"
                    title={errorLabel}
                    apply={okLabel}
                    cancel={""}
                    onApply={() => clearDraftFailure()}
                    onCancel={() => clearDraftFailure()}
                >
                    <span>{submitDraftError}</span>
                </Modal>
            );
        }

        if (isDraftSubmitting) {
            return (
                <Modal
                    className="SubmitDraftModal"
                    title={submittingTitle}
                    apply={exitLabel}
                    cancel={""}
                    onCancel={() => {}}
                    disabled={true}
                >
                    <Spinner />
                </Modal>
            );
        }

        if (isDraftSubmitted) {
            return (
                <Modal
                    className="SubmitDraftModal"
                    title={submittedTitle}
                    apply={userType !== USER_TYPES.AUTHENTICATED && userType !== USER_TYPES.B2CAUTHENTICATED ? exitLabel : logout}
                    cancel={userType !== USER_TYPES.AUTHENTICATED && userType !== USER_TYPES.B2CAUTHENTICATED ? "" : exitLabel}
                    onApply={() => {userType === USER_TYPES.ANONYMOUS || userType === USER_TYPES.MANUAL ? clearFormTypeAndStayLoggedIn() : clearFormTypeAndLogout()}}
                    onCancel={() => clearFormTypeAndStayLoggedIn()}
                >
                    <Translation
                        replace={{ FormId: submittedDraftId }}
                        containsHtml={true}>
                        {Strings.submittedDraftMessage}
                    </Translation>
                </Modal>
            );
        }
    }
}

const mapStateToProps = state => {
    const { appReducer } = state;

    return {
        isDraftSubmitting: appReducer.isDraftSubmitting,
        isDraftSubmitted: appReducer.isDraftSubmitted,
        submitDraftError: appReducer.errors.submitDraft,
        user: state.auth.user,
        submittedDraftId: getSubmittedDraftId(state),
        errorLabel: getString(Strings.error, state),
        submittingTitle: getString(Strings.saving, state),
        submittedTitle: getString(Strings.submittedDraftTitle, state),
        logout: getString(Strings.logout, state),
        okLabel: getString(Strings.ok, state),
        exitLabel: getString(Strings.exit, state),
        userType: state.wizardReducer.userTypeSelected,
    };
};

const mapDispatchToProps = dispatch => ({
    clearFormTypeAndStayLoggedIn: () => {
        dispatch(clearFormTypeAndStayLoggedIn());
    },
    clearFormTypeAndLogout: () => {
        dispatch(clearFormTypeAndLogout());
    },
    clearDraftFailure: () => {
        dispatch(clearDraftFailure());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitDraftPage);
