import React from 'react';
import { connect } from 'react-redux';
import { DocumentSelect } from 'spheracloud-common';
import { getString } from '../../../selectors/appSelectors';
import { FIELD_TYPES } from '../../../constants/FieldTypes';
import Strings from '../../../constants/Strings';
import { loadRelatedDocument, uploadRelatedDocuments } from '../../../actions/wizardActions';

class RelatedDocuments extends React.Component {

    componentDidMount() {
        const { value } = this.props;

        if (value) {
            value.forEach(item => {
                const isFileOrBlob = item instanceof File || item instanceof Blob;
                if (!isFileOrBlob) {
                    const { elementGuid, loadRelatedDocument } = this.props;

                    loadRelatedDocument(elementGuid, item.id, item.path);
                }
            });
        }
    }

    handleRelatedDocumentElementChange = (value) => {
        const { elementGuid, uploadRelatedDocuments } = this.props;

        const newValues = [...value];

        const currentValues = this.props.value;
        if (currentValues) {
            currentValues.forEach(searchedItem => {
                if (!value.find(item => item.id === searchedItem.id)) {
                    searchedItem.deleted = true;
                    newValues.push(searchedItem);
                }
            });
        }

        uploadRelatedDocuments(elementGuid, newValues);
    }

    getAdditionalFields = (formConfigJSON, skippedAttributes) => {
        let result = [];

        if (formConfigJSON) {
            const formConfig = JSON.parse(formConfigJSON);
    
            formConfig.Elements.forEach(e => {
                e.Fields.forEach(field => {
                    const attributeTitle = field.TemplateAttribute.Name;
    
                    if (field.RenderingType === FIELD_TYPES.TEXT_INPUT && !skippedAttributes.includes(attributeTitle) ) {
                        result.push({
                            id: attributeTitle,
                            label: field.Title
                        });
                    }
                });
            });
        }
    
        return result;
    }

    render() {
        const { configData, disabled, fileText, linkText, addLinkDialogTitle, saveLabel, cancelLabel, nameFieldLabel, linkFieldLabel, editFileDialogTitle, fileNameLabel } = this.props;

        const linkSupport = configData.ShowLinkButton === '1';

        const linkConfig = {
            nameField: {
                id: 'RelatedDocuments.Description', label: nameFieldLabel
            },
            linkField: {
                id: 'RelatedDocuments.Link', label: linkFieldLabel
            },
            additionalFields: linkSupport && configData && this.getAdditionalFields(configData.LinkFormConfig, ['RelatedDocuments.Description', 'RelatedDocuments.Link']),
            dialogTitleText: addLinkDialogTitle,
            saveButtonText: saveLabel,
            cancelButtonText: cancelLabel
        };

        const fileConfig = {
            dialogTitleText: editFileDialogTitle,
            saveButtonText: saveLabel,
            cancelButtonText: cancelLabel,
            nameField: {
                id: 'RelatedDocuments.OriginalFilename', label: fileNameLabel
            },
            additionalFields: configData && this.getAdditionalFields(configData.DocumentFormConfig, ['RelatedDocuments.OriginalFilename']),
          };
        
        return <DocumentSelect
            initialFiles={this.props.value ? this.props.value.filter(item => !item.deleted) : []}
            onChange={this.handleRelatedDocumentElementChange}
            addFileButtonText={fileText.toUpperCase()}
            addLinkButtonText={linkText.toUpperCase()}
            fileConfig={fileConfig} 
            isFileNameEditable={false}          
            linkSupport={linkSupport}
            linkConfig={linkConfig}
            disabled={disabled}
        />
    }
}

const mapStateToProps = state => {
    return {
        saveLabel: getString(Strings.save, state),
        cancelLabel: getString(Strings.cancel, state),
        addLinkDialogTitle: getString(Strings.addLinkDialogTitle, state),
        fileText: getString(Strings.upload, state),
        linkText: getString(Strings.link, state),
        nameFieldLabel: getString(Strings.nameField, state),
        linkFieldLabel: getString(Strings.linkField, state),
        editFileDialogTitle: getString(Strings.editFileDialogTitle, state),
        fileNameLabel: getString(Strings.fileName, state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        uploadRelatedDocuments: (elementGuid, documents) => dispatch(uploadRelatedDocuments(elementGuid, documents)),
        loadRelatedDocument: (elementGuid, relatedDocumentId, fileName) => dispatch(loadRelatedDocument(elementGuid, relatedDocumentId, fileName))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedDocuments);