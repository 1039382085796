const bodyParts = {
    body: [
        { id: "back-lower", name: "Back - Lower" },
        { id: "back-mid", name: "Back - Middle" },
        { id: "back-upper", name: "Back - Upper" },
        { id: "back-head", name: "Head - Back" },
        { id: "foot-left", name: "Foot - Left" },
        { id: "ankle-left", name: "Ankle - Left" },
        { id: "shin-left", name: "Shin - Left" },
        { id: "knee-left", name: "Knee - Left" },
        { id: "thigh-left", name: "Thigh - Left" },
        { id: "hand-left", name: "Hand - Left" },
        { id: "forearm-left", name: "Fore Arm - Left" },
        { id: "shoulder-left", name: "Shoulder - Left" },
        { id: "head", name: "Head" },
        { id: "face", name: "Face" },
        { id: "chest", name: "Chest" },
        { id: "shoulder-right", name: "Shoulder - Right" },
        { id: "forearm-right", name: "Fore Arm - Right" },
        { id: "hand-right", name: "Hand - Right" },
        { id: "waist", name: "Waist" },
        { id: "groin", name: "Groin" },
        { id: "foot-right", name: "Foot - Right" },
        { id: "ankle-right", name: "Ankle - Right" },
        { id: "shin-right", name: "Shin - Right" },
        { id: "knee-right", name: "Knee - Right" },
        { id: "thigh-right", name: "Thigh - Right" }
    ],
    head: [],
    hands: [],
    feet: []
};

export default bodyParts;