import React from 'react';
import './LocationMap.scss';
import { connect } from 'react-redux';
import { Map } from 'spheracloud-common';
import { getCurrentLocation } from '../../../actions/appActions';
import { getAppSettingsFromState } from '../../../selectors/appSelectors';
import endpoints from '../../../constants/Endpoints';

class LocationMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mapDimensions: props.contentDimensions
        };

        this.handleClickData = this.handleClickData.bind(this);
        this.map = React.createRef();
    }

    componentDidMount() {
        const { initialLocation, currentLocation, getCurrentLocation, onChange, disabled } = this.props;

        if (!initialLocation && !disabled) {
            if (!currentLocation){
                getCurrentLocation();
            } else {
                onChange && onChange(currentLocation);
            }
        }
    }

    handleClickData(data) {
        const { onChange } = this.props;

        if (!data || data.length !== 2) {
            return;
        }
        const selectedLocation = {
            longitude: data[0],
            latitude: data[1]
        };

        onChange && onChange(selectedLocation);
    }

    componentDidUpdate(prevProps) {
        const { currentLocation, onChange, contentDimensions } = this.props;
        const prevLat = prevProps.currentLocation && prevProps.currentLocation.latitude;
        const prevLong = prevProps.currentLocation && prevProps.currentLocation.longitude;
        if (currentLocation &&
            (prevLat !== currentLocation.latitude || prevLong !== currentLocation.longitude)) {
            onChange && onChange(currentLocation);
        }

        if (!prevProps.contentDimensions && contentDimensions) {
            this.setState({
                mapDimensions: contentDimensions
            });
        } else if (prevProps.contentDimensions && contentDimensions) {
            const prevHeight = prevProps.contentDimensions.height;
            const prevWidth = prevProps.contentDimensions.width;
            if (prevHeight !== contentDimensions.height || prevWidth !== contentDimensions.width) {
                this.map.current && this.map.current.resize(Math.max(contentDimensions.height - 160, 240), contentDimensions.width)
            }
        }
    }

    renderMap() {
        const { mapHost, mapRoute, initialLocation, currentLocation, disabled } = this.props;
        const { mapDimensions } = this.state;
        const location = initialLocation || currentLocation;
        const points = location && location.latitude && location.longitude ? [[location.longitude, location.latitude]] : [];

        return (
            <Map
                points={points}
                proxyRoute={mapRoute}
                captureClickData={this.handleClickData}
                host={mapHost}
                zoom={12}
                width={mapDimensions.width}
                height={Math.max(mapDimensions.height - 160, 240)}
                deployFolder={''}
                ref={this.map}
                disabled={disabled}
                lightTheme={true} />
        )
    }

    render() {
        const { initialLocation, currentLocation, isLoadingCurrentLocation } = this.props;
        const { mapDimensions } = this.state;
        const locationReady = !!(initialLocation || currentLocation) && !isLoadingCurrentLocation;

        return (
            <div className="LocationMapContainer">
                {
                    mapDimensions && locationReady && this.renderMap()
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const appSettings = getAppSettingsFromState(state);
    return {
        mapHost: endpoints.getMapHost(appSettings),
        mapRoute: endpoints.getMapRoute(appSettings),
        currentLocation: state.appReducer.currentLocation,
        isLoadingCurrentLocation: state.appReducer.isLoadingCurrentLocation,
        errorLoadingLocation: state.appReducer.errors.currentLocation,
        contentDimensions: state.appReducer.contentDimensions,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentLocation: () => {
            dispatch(getCurrentLocation());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMap);