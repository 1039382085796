import React, { Component } from 'react';
import style from './AdditionalRecipientsModal.module.scss';
import { connect } from 'react-redux';
import Strings from '../../../constants/Strings';
import { Modal } from 'spheracloud-common';
import Translation from '../../Translation/Translation';
import { getString } from '../../../selectors/appSelectors';
import { CloseCircle, AddCircleBlue } from '../../../icons';
import { updateAdditionalRecipients } from '../../../actions/wizardActions';
import { v4 } from 'uuid';

class AdditionalRecipientsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            additionalRecipients: props.additionalRecipients,
            enteredText: '',
            emailListValid: true,
        }

        this.textInput = React.createRef();
    }

    addRecipients() {
        const { updateAdditionalRecipients } = this.props;
        const { enteredText, additionalRecipients } = this.state;

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
        const emailList = enteredText.split(",").map(e => e.trim());
        const emailListValid = emailList.every(email => {
            return email !== '' && emailRegex.test(email);
        });

        if (emailListValid) {
            const updatedEmailList = [
                ...additionalRecipients,
                ...emailList.filter(e => additionalRecipients.indexOf(e) === -1)
            ].sort();

            this.setState({
                additionalRecipients: updatedEmailList,
                emailListValid: true,
                enteredText: '',
            }, () => {
                updateAdditionalRecipients(updatedEmailList)
            });
        } else {
            this.setState({ emailListValid: false });
        }
    }

    removeRecipient(index) {
        const { updateAdditionalRecipients } = this.props;
        const { additionalRecipients } = this.state;
        const updatedEmailList = additionalRecipients.filter((e, i) => { return i !== index });
        this.setState({
            additionalRecipients: updatedEmailList,
        }, () => {
            updateAdditionalRecipients(updatedEmailList)
        });
    }

    render() {
        const { emailModalTitleLabel, emailModalHelpLabel, emailModalPlaceholderLabel, onClose } = this.props;
        const { additionalRecipients, emailListValid, enteredText } = this.state;

        return <>
            <Modal
                className="EmailModal"
                title={emailModalTitleLabel}
                onCancel={() => { onClose && onClose() }}
                apply={''}
            >
                <div className={style.AdditionalRecipientsHelpText}>{emailModalHelpLabel}</div>
                {
                    !emailListValid ?
                        <div className={style.AdditionalRecipientsError}>
                            <span>{' * '}<Translation>{Strings.invalidEmail}</Translation></span>
                        </div>
                        : <div className={style.AdditionalRecipientsErrorPlaceholder}></div>
                }
                <div className="AdditionalRecipientsInputContainer" >
                    <span className="Text-Input-Container">
                        <input
                            className={`Text-Input${!emailListValid ? ' Text-Input-Error' : ''}`}
                            value={enteredText}
                            placeholder={emailModalPlaceholderLabel}
                            onChange={(e) => {
                                this.setState({
                                    enteredText: e.target.value,
                                    emailListValid: true
                                })
                            }}
                            onKeyPress={(e) => { if (e.charCode === 13) { this.addRecipients() } }}
                        />
                    </span>
                    <div className={style.AdditionalRecipientsAdd} onClick={() => this.addRecipients()}>
                        <img src={AddCircleBlue} alt="Add" />
                    </div>
                </div>
                <div className={style.AdditionalRecipientsList}>
                    {
                        additionalRecipients.map((email, index) => {
                            return <div key={v4()} className={style.AdditionalRecipientsRow} >
                                <div className={style.AdditionalRecipientsRowEmail}>{email}</div>
                                <div className={style.AdditionalRecipientsRowRemove} onClick={() => { this.removeRecipient(index) }}>
                                    <img src={CloseCircle} alt="Remove" />
                                </div>
                            </div>
                        })
                    }
                </div>
            </Modal>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        emailModalHelpLabel: getString(Strings.emailModalHelpText, state),
        emailModalPlaceholderLabel: getString(Strings.emailModalPlaceholderText, state),
        emailModalTitleLabel: getString(Strings.emailModalTitle, state),
        additionalRecipients: state.wizardReducer.additionalRecipients,
    };
};

const mapDispatchToProps = dispatch => ({
    updateAdditionalRecipients: (recipients) => {
        dispatch(updateAdditionalRecipients(recipients));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalRecipientsModal);
