import React from 'react';
import './BodyMapPartRow.scss';
import BodyPartInjuryTypes from '../BodyPartInjuryTypes';
import { ArrowDropdown, CheckboxChecked, CheckboxUnchecked } from '../../../../icons';

class BodyMapPartRow extends React.Component {

    render() {

        const {
            onClick,
            onInjuryTypeDropdownClick,
            onInjuryTypeClick,
            part,
            partInjuries,
            injuryDropdownOpen
        } = this.props;

        return (
            <div className="BodyMapPartRow">
                <div className="BodyMapPartSelectedContainer" onClick={onClick}>
                    <img className="BodyMapPartCheckbox" src={CheckboxChecked} alt="checkbox" />
                    <div className="BodyMapPartName">{part.name}</div>
                </div>
                <div className="BodyMapInjuryTypeContainer" onClick={onInjuryTypeDropdownClick}>
                    <div className="BodyMapInjurySelected">
                        <div className="BodyMapInjurySelectedText">
                            {
                                partInjuries.length > 0 ? partInjuries.length + " selected" : "Select Injury Type"
                            }
                        </div>
                        <img className="BodyMapInjurySelectedIcon" src={ArrowDropdown} alt="Select Injury Type" />
                    </div>
                    {
                        injuryDropdownOpen ?
                            <div className="BodyMapInjuryTypeDropdown">
                                {
                                    BodyPartInjuryTypes.map(injuryType => {
                                        return <div key={injuryType.id} className="BodyMapInjuryTypeDropdownItem" onClick={(e) => {onInjuryTypeClick(part.id, injuryType.id); e.stopPropagation();}}>
                                            <img className="BodyMapInjuryTypeDropdownItemCheckbox" src={partInjuries.indexOf(injuryType.id) > -1 ? CheckboxChecked : CheckboxUnchecked} alt="checkbox" />
                                            <div className="BodyMapInjuryTypeDropdownItemName">{injuryType.name}</div>
                                        </div>
                                    })
                                }
                            </div>
                            :
                            ''
                    }
                </div>
            </div>
        );
    }
}

export default BodyMapPartRow;