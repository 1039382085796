import { combineReducers } from 'redux';
import appReducer from './appReducer';
import wizardReducer from './wizardReducer';
import { reducer as oidcReducer } from 'redux-oidc';

export default combineReducers({
    appReducer: appReducer.reducer,
    wizardReducer: wizardReducer.reducer,
    auth: oidcReducer
});
