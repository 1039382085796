import React, { Component } from 'react';
import './ContinueDraftInput.scss';
import { ArrowDropdownGrey, CloseCircle, CloseGrey } from '../../../icons/index'
import moment from 'moment/min/moment-with-locales';
import { Modal, Spinner } from 'spheracloud-common';
import Strings from '../../../constants/Strings'
class ContinueDraftInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listItemOpen: false,
            searchText: '',
            filteredResults: '',
            deleteRecord: '',
            accidentDeleteModalOpen: false
        };

        this.container = React.createRef();
        this.searchInput = React.createRef();
        this.closeIfNeeded = this.closeIfNeeded.bind(this);
        this.focusSearchInput = this.focusSearchInput.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.deleteAccidentRecord = this.deleteAccidentRecord.bind(this);
        this.deleteAccidentRecordStart = this.deleteAccidentRecordStart.bind(this);
        this.deleteAccidentRecordEnd = this.deleteAccidentRecordEnd.bind(this);
    }

    static applySearch(items, searchText) {
        if (!searchText) {
            return [];
        }

        const searchLower = searchText.toLowerCase();
        return items
            .filter(i => i && i.ShortCode).filter(i => i.ShortCode.toLowerCase().includes(searchLower));
    }

    onSearchTextChange(searchText) {

        const { onTextChanged, savedAccidents, isUserAuthenticated } = this.props;

        this.setState({ searchText });

        if (savedAccidents && isUserAuthenticated) {
            this.setState({filteredResults: ContinueDraftInput.applySearch(savedAccidents, searchText)})
        }

        onTextChanged(searchText);

        if (searchText === '') {
            this.focusSearchInput();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.closeIfNeeded, false);
        document.addEventListener('focusin', this.closeIfNeeded, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeIfNeeded);
        document.addEventListener('focusin', this.closeIfNeeded);
    }

    closeIfNeeded(e) {
        if (this.container.current && !this.container.current.contains(e.target)) {
            this.setState({ searchText: '', listItemOpen: false });
        }
    }

    focusSearchInput() {
        if (this.searchInput.current) {
            this.setState({ listItemOpen: true });
            this.searchInput.current.focus();
        }
    }

    handleSelection(text) {
        const { onTextChanged, savedAccidents } = this.props;
        this.setState({ listItemOpen: false, searchText: text, filteredResults: ContinueDraftInput.applySearch(savedAccidents, text) });
        onTextChanged(text);
    }

    deleteAccidentRecord(shortcode) {
        this.setState({ listItemOpen: false, accidentDeleteModalOpen: true, deleteRecord: shortcode })
    }

    deleteAccidentRecordStart() {
        const { onDeleteRecord } = this.props;
        const { deleteRecord } = this.state;
        this.setState({ listItemOpen: true, searchText: '', accidentDeleteModalOpen: false })
        onDeleteRecord(deleteRecord);
    }

    deleteAccidentRecordEnd() {
        const { onTextChanged } = this.props
        this.setState({ listItemOpen: true, accidentDeleteModalOpen: false, searchText: '', deleteRecord: '' })
        onTextChanged('')
    }

    render() {

        const { value, savedAccidents, placeholder, disabled, selectedLanguage, isUserAuthenticated, onKeyPress, savedAccidentsDeleteLoading } = this.props;
        const { listItemOpen, searchText, filteredResults, accidentDeleteModalOpen, deleteRecord } = this.state;

        const accidentList = searchText ? filteredResults : savedAccidents;

        return (
            <>
                {!savedAccidentsDeleteLoading &&
                    <div
                        className="ContinueDraftInputContainer"
                        role="presentation"
                        ref={this.container}
                    >

                        <div className="SearchBarInputContainer">
                            <input
                                className="SearchBarInput"
                                onClick={this.focusSearchInput}
                                value={value}
                                spellCheck={false}
                                placeholder={placeholder}
                                // onClick={() => onInputClick()}
                                onChange={e => this.onSearchTextChange(e.target.value)}
                                ref={this.searchInput}
                                disabled={disabled}
                                onKeyPress={onKeyPress}
                            />
                            <div className="ContinueDraftInputIcons">
                                {
                                    searchText && (
                                        <div className="ContinueDraftInputIcon" onClick={() => this.onSearchTextChange('')}>
                                            <img src={CloseCircle} alt="Remove" />
                                        </div>
                                    )
                                }
                                {
                                    !!isUserAuthenticated && (
                                        <div className="ContinueDraftInputIcon">
                                            <img className="ContinueDraftDropdownIcon" onClick={this.focusSearchInput} src={ArrowDropdownGrey} alt="Search" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>


                        {!!isUserAuthenticated && listItemOpen && accidentList && !savedAccidentsDeleteLoading &&
                            <div className="AccidentList">
                                {
                                    accidentList.map(i => (
                                        <div className={"ChooseListItemContainer"}>
                                            <div key={i.ShortCode}
                                                className="ChooseListItem"
                                                onClick={() => this.handleSelection(i.ShortCode)}
                                                role="switch"
                                                aria-checked={i.ShortCode}
                                                tabIndex={0}
                                            >
                                                <div className={"EventMainInfo"}>
                                                    <span>{i.ShortCode}</span>
                                                    <span>{i.FormName}</span>
                                                </div>
                                                <div className={"EventTime"}>
                                                    <span>{moment.utc(i.DateTime, 'DD/MM/YYYY HH:mm:ss').locale(selectedLanguage).fromNow()}</span>
                                                </div>
                                            </div>
                                            <img src={CloseGrey} className={"ChooseListItemDelete"} alt='Delete' onClick={() => this.deleteAccidentRecord(i.ShortCode)} />
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        {accidentDeleteModalOpen &&
                            <Modal
                                className="deleteRecordModal"
                                title={Strings.deleteDraftTitle}
                                apply={'Yes'}
                                cancel={'No'}
                                onApply={() => this.deleteAccidentRecordStart()}
                                onCancel={() => this.deleteAccidentRecordEnd()}
                            >
                                {Strings.deleteDraftDescription} <strong>{deleteRecord}?</strong>
                            </Modal>
                        }
                    </div>
                }
                {
                    savedAccidentsDeleteLoading &&
                    <Spinner></Spinner>
                }
            </>
        )
    }
}

export default ContinueDraftInput;