import queryString from 'query-string';

const getAPIUrl = (appSettings) => {
    const apiPath = appSettings.API_URLS.GATEWAY;
    
    let apiUrl;

    if (appSettings.isDevelopmentInstance === 'true') {
        apiUrl = apiPath;        
    }
    else {
        //use active host name to call apis for vanity url support
        const currentOrigin = `${window.location.protocol}//${window.location.host}`;
        const path = apiPath.split('/').slice(3).join('/');
        apiUrl = `${currentOrigin}${path ? `/${path}` : ''}`;
    }

    return apiUrl;    
}

const verifyUser = (appSettings, anonGuid) => {
    return `${getAPIUrl(appSettings)}/api/data/accounts/verify-anon/${anonGuid}`;
}

const getConfigAPIUrl = (appSettings, isUserAuthenticated) => `${getAPIUrl(appSettings)}/api/config${(isUserAuthenticated ?  '' : '/anon')}`;
const getDataAPIUrl = (appSettings, isUserAuthenticated) => `${getAPIUrl(appSettings)}/api/data${(isUserAuthenticated ?  '' : '/anon')}`;
const getLegacyAPIUrl = (appSettings) => `${getAPIUrl(appSettings)}/api/auth`;
const getHierarchyAPIUrl = (appSettings, isUserAuthenticated) => `${getAPIUrl(appSettings)}/api/hierarchy${(isUserAuthenticated ?  '' : '/anon')}`;

const getMapHost = (appSettings) => getAPIUrl(appSettings).split('/')[2];

const getMapRoute = (appSettings) => `${getAPIUrl(appSettings).split('/').slice(3).join('/')}/api/maps`;

const getCompanyLogo = (appSettings, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/firstreport-logo`;
}

const translate = (appSettings, isUserAuthenticated, fromLanguage, toLanguage) => {
    return `${getAPIUrl(appSettings)}/api/translation${(isUserAuthenticated ?  '' : '/anon')}/translate?fromLanguage=${fromLanguage}&toLanguage=${toLanguage}`;
}

const requestTranslationLanguages = (appSettings, isUserAuthenticated) => {
    return `${getAPIUrl(appSettings)}/api/translation${(isUserAuthenticated ?  '' : '/anon')}/languages`; 
}

const requestResolveLabel = (appSettings, isUserAuthenticated,language) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/tav-titles?language=${language}`;
}

const liveSearch = (appSettings, isUserAuthenticated, SearchQuery, AttributeName, Page = '', Language = '', ElementGuid = '', LocationId = '', AdditionalColumns = '') => {
    const PageString = Page ? `&Page=${Page}` : Page;
    const LangString = Language ? `&Language=${Language}` : Language;
    const elementGuid = ElementGuid ? `&ElementGuid=${ElementGuid}` : ElementGuid;
    const locationId = LocationId ? `&LocationId=${LocationId}` : LocationId;
    const additionalColumns = AdditionalColumns ? `&AdditionalColumns=${AdditionalColumns}` : AdditionalColumns;
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/picklist-values/search?` +
    `SearchQuery=${SearchQuery}&AttributeName=${AttributeName}${LangString}${PageString}${elementGuid}${locationId}${additionalColumns}`;
}

const getUserLanguage = (appSettings, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/userLanguage`;
}

const getCompanyForms = (appSettings, isUserAuthenticated, formGroup, language, locationId, formId) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/forms?formGroupName=${formGroup}&language=${language}&portalOnly=true&locationId=${locationId}&formId=${formId}`;
};

const getCompanyFormSummaries = (appSettings, isUserAuthenticated, formGroup, language, locationId) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/forms/summaries?formGroupName=${formGroup}&language=${language}&portalOnly=true&locationId=${locationId}`;
}

const getCompanyLanguages = (appSettings, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/languages`;
};

const getCompanySSOSettings = (appSettings, isUserAuthenticated, formGroup) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/sso-settings?formGroupName=${formGroup}`;
};

const getTranslations = (appSettings, language, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/translations?language=${language}`;
};

const getHierarchyDetails = (appSettings, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/hierarchy-details`;
}

const getCompanyFormImage = (appSettings, isUserAuthenticated, imageId) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/Rivo.RiskEngine.RelatedDocuments/${imageId}/filecontent`;
};

const getAccidentIDFromShortCode = (appSettings, shortCode) => {
    return `${getDataAPIUrl(appSettings, false)}/Rivo.Santiago.Accidents/shortcode/${shortCode}`;
}

const getFormData = (appSettings, isUserAuthenticated, id, attributes) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/Rivo.Santiago.Accidents/${id}?attributes=${attributes}`;
}

const getRelatedObjects = (appSettings, isUserAuthenticated, className) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/${className}/actions/query`;
}

const getRelatedDocuments = (appSettings, isUserAuthenticated) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/Rivo.RiskEngine.RelatedDocuments/actions/query`;
}

const postRelatedDocumentContent = (appSettings, isUserAuthenticated, id) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/Rivo.RiskEngine.RelatedDocuments/${id}/filecontent`;
}

const postFormData = (appSettings, isUserAuthenticated, className, skipReturnAttributeValues, commit) => {
    const queryParams = {
        skipReturnAttributeValues,
        commit
    };
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/${className}?${queryString.stringify(queryParams)}`;
};

const putFormData = (appSettings, isUserAuthenticated, className, id, skipReturnAttributeValues) => {
    const queryString = skipReturnAttributeValues ? '?skipReturnAttributeValues=true' : '';
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/${className}/${id}${queryString}`;
};

const getRecord = (appSettings, isUserAuthenticated, className, id, attributes) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/${className}/${id}?attributes=${attributes}`;
}

const searchUsersInGroups = (appSettings, isUserAuthenticated, locationId) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/users/locations/${locationId}`
}

const getObjectPicklistValues = (appSettings, isUserAuthenticated, locationId, language) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/object-picklist-values?locationId=${locationId}&language=${language}`
}

const loginBasicAuth = (appSettings, anonGuid) => {
    return `${getLegacyAPIUrl(appSettings)}/userToken/${anonGuid}`;
}

const getFirstReportSettings = (appSettings, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/firstReportSettings`;
}

const getSpeechToTextToken = (appSettings, isUserAuthenticated) => {
    return `${getAPIUrl(appSettings)}/api/speechToText${(isUserAuthenticated ?  '' : '/anon')}/token`;
}

const getSavedAccidents = (appSettings, isUserAuthenticated) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/getSavedAccidents`;
}

const getCompanyProfileValues = (appSettings, isUserAuthenticated, language) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/profile-values?language=${language}`
}

const deleteAccidentRecord = (appSettings, isUserAuthenticated, shortcode) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/deleteSavedAccident?shortcode=${shortcode}`;
}

const azureNER = (appSettings, isUserAuthenticated) => {
    return `${getAPIUrl(appSettings)}/api/ner${(isUserAuthenticated ?  '' : '/anon')}/check-answer`;    
}

const similarRecordsSearch = (appSettings, isUserAuthenticated, templateName, viewId, searchValue, isSearchResctictedToUserLocation, formId) => {
    return `${getDataAPIUrl(appSettings, isUserAuthenticated)}/Rivo.Santiago.Accidents/fuzzySearch?templateName=${templateName}&fuzzySearchViewId=${viewId}&searchValue=${searchValue}&isRestrictedSearchToUserLocation=${isSearchResctictedToUserLocation}&formId=${formId}`
}

const getFormLocationRestrictions = (appSettings, isUserAuthenticated, formGroupName) => {
    return `${getConfigAPIUrl(appSettings, isUserAuthenticated)}/forms/locationrestrictions?formGroupName=${formGroupName}&portalOnly=true`;
}

const endpoints = {
    azureNER,
    deleteAccidentRecord,
    getAccidentIDFromShortCode,
    getCompanyFormImage,
    getCompanyFormSummaries,
    getCompanyForms,
    getCompanyLanguages,
    getCompanyLogo,
    getCompanyProfileValues,
    getCompanySSOSettings,
    getFirstReportSettings,
    getFormData,
    getFormLocationRestrictions,
    getHierarchyAPIUrl,
    getHierarchyDetails,
    getMapHost,
    getMapRoute,
    getObjectPicklistValues,
    getRecord,
    getRelatedDocuments,
    getRelatedObjects,
    getSavedAccidents,
    getSpeechToTextToken,
    getTranslations,
    getUserLanguage,
    liveSearch,
    loginBasicAuth,
    postFormData,
    postRelatedDocumentContent,
    putFormData,
    requestResolveLabel,
    requestTranslationLanguages,
    searchUsersInGroups,
    similarRecordsSearch,
    translate,
    verifyUser,
};

export default endpoints;
