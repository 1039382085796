import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { withRouter } from 'react-router';
import { Spinner } from 'spheracloud-common';
import './Callback.scss';
import getUserManager from '../../../utils/userManager';
import { userSuccessLogin, userFailedLogin } from '../../../actions/authActions';

const Callback = (props) => {
    const { appSettings, onAuthSuccess, onAuthError } = props;

    return (
        <CallbackComponent
            userManager={getUserManager(appSettings)}
            successCallback={onAuthSuccess}
            errorCallback={(err) => onAuthError(err)}
        >
            <div className="CallbackLoading">
                <Spinner />
            </div>
        </CallbackComponent>
    );
}

const mapStateToProps = state => ({
    appSettings: state.appReducer.appSettings,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAuthSuccess: () => dispatch(userSuccessLogin(ownProps.history)),
        onAuthError: (err) => dispatch(userFailedLogin(ownProps.history, err)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Callback));
