export const POLICY_TYPES = {
    LOGIN: 0,
    FORGOT_PASSWORD: 1,
}

export const LOGIN_ERROR_CODES = {
    FORGOT_PASSWORD: 'AADB2C90118',
    USER_CANCELLED_FLOW: 'AADB2C90091',
}

export const LOGIN_ERROR_MESSAGES = {
    USER_CANCELLED_FLOW: 'Error: authority mismatch on settings vs. signin state',
}