import React from 'react';
import PropTypes from 'prop-types';
import ChosenItem from '../ChosenItem/ChosenItem';
import { CloseCircle, Search } from '../../../../icons';
import './SearchBar.css';
import FieldSpinner from "../../../FieldSpinner/FieldSpinner";

class SearchBar extends React.Component {
  render() {
    const {
      searchText, selectedItems, placeholder, onSearchChange, onItemRemove, inputRef, hasError, onInputClick, disabled,
      showLoadingSpinner, onInputBlur
    } = this.props;

    let classes = 'SearchBarContainer';
    if (hasError) {
      classes = `${classes} SearchBarContainerError`;
    }
    if (disabled) {
      classes = `${classes} SearchBarContainerDisabled`;
    }

    return (
      <div className={classes}>
        {selectedItems.map(i => (
          <ChosenItem
            key={i.value}
            label={i.text}
            onRemove={() => onItemRemove(i.value)}
            disabled={disabled}
          />
        ))}
        <div className="SearchBarInputContainer">
          <input
            className="SearchBarInput"
            value={searchText}
            placeholder={placeholder}
            onFocus={() => onInputClick()}
            onChange={e => onSearchChange(e.target.value)}
            ref={inputRef}
            disabled={disabled}
            onBlur={() => onInputBlur()}
          />
          {showLoadingSpinner && <FieldSpinner/>}
          {searchText && (
          <button
            className="SearchBarClearButton"
            onClick={() => onSearchChange('')}
            type="button"
          >
            <img
              src={CloseCircle}
              alt="Remove"
            />
          </button>
          )}
                <img src={Search} alt="Search" onClick={() => onInputClick()} />
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  searchText: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  onSearchChange: PropTypes.func,
  onInputClick: PropTypes.func,
  onInputBlur: PropTypes.func,
  onItemRemove: PropTypes.func,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
};

SearchBar.defaultProps = {
  searchText: '',
  selectedItems: [],
  placeholder: '',
  onSearchChange: () => {},
  onInputClick: () => {},
  onInputBlur: () => {},
  onItemRemove: () => {},
  inputRef: undefined,
  hasError: false,
  disabled: false,
  showLoadingSpinner: false,
};

export default SearchBar;
