const LANGUAGE_FLAGS = {
    'en-gb': 'GB',
    'fr-fr': 'FR',
    'es-es': 'ES',
    'de-de': 'DE',
    'pl-pl': 'PL',
    'nl-nl': 'NL',
    'cs-cz': 'CZ',
    'sk-sk': 'SK',
    'hu-hu': 'HU',
    'zh-cn': 'CN',
    'ar-ae': 'AE',
    'pt-br': 'BR',
    'ko-kr': 'KR',
    'zh-hans': 'CN',
    'en-us': 'US',
    'pt-pt': 'PT',
    'vi-vn': 'VN',
    'ms-my': 'MY',
    'fi-fi': 'FI',
    'it-it': 'IT',
    'ja-jp': 'JP',
    'ru-ru': 'RU',
    'sv-se': 'SE',
    'da-dk': 'DK',
    'fr-ca': 'CA',
    'tr-tr': 'TR',
    'nb-no': 'NO',
    'es-cl': 'CL',
    'zh-tw': 'TW',
    'zh-hk': 'HK',
    'zh-hant': 'CN',
    'es-mx': 'MX',
    'th-th': 'TH',
    'id-id': 'ID',
    'hi-in': 'IN',
    'ro-ro': 'RO',
    'lv-lv': 'LV',
    'et-ee': 'EE',
    'bg-bg': 'BG',
    'lt-lt': 'LT',
    'sr-cyrl-cs': 'RS',
    'sr-latn': 'RS',
    'ur-pk': 'PK',
    'hr-hr': 'HR',
    'sl-si': 'SI',
    'mk-mk': 'MK',
    'bs-latn-ba': 'BA',
    'el-gr': 'GR',
    'uk-ua': 'UA',
    'kk-kz': 'KZ',
    'mr-in': 'IN'
};

export const EN_GB = 'en-gb';
    
export const getFlagCode = (language) => {
    return LANGUAGE_FLAGS[language] || 'GB';
};

export const getIsoCodes = () => {
    return Object.keys(LANGUAGE_FLAGS);
};

export const speechToTextLanguages = [
    'ar-ae',
    'ar-bh',
    'ar-eg',
    'ar-il',
    'ar-jo',
    'ar-kw',
    'ar-lb',
    'ar-ps',
    'ar-qa',
    'ar-sa',
    'ar-sy',
    'ca-es',
    'cs-cz',
    'da-dk',
    'de-de',
    'en-au',
    'en-ca',
    'en-gb',
    'en-in',
    'en-nz',
    'en-us',
    'en-nz',
    'en-ph',
    'en-sg',
    'en-us',
    'en-za',
    'es-ar',
    'es-bo',
    'es-cl',
    'es-co',
    'es-cr',
    'es-cu',
    'es-do',
    'es-ec',
    'es-es',
    'es-gt',
    'es-hn',
    'es-mx',
    'es-ni',
    'es-pa',
    'es-pe',
    'es-pr',
    'es-py',
    'es-sv',
    'es-us',
    'es-uy',
    'es-ve',
    'fi-fi',
    'fr-ca',
    'fr-fr',
    'gu-in',
    'hi-in',
    'hu-hu',
    'it-it',
    'ja-jp',
    'ko-kr',
    'mr-in',
    'nb-no',
    'nl-nl',
    'pl-pl',
    'pt-br',
    'pt-pt',
    'ru-ru',
    'sv-se',
    'ta-in',
    'te-in',
    'th-th',
    'tr-tr',
    'zh-cn',
    'zh-hans',
    'zh-hant',
    'zh-hk',
    'zh-tw',
];
