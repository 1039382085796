import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { POLICY_TYPES } from '../constants/AuthConstants';

let CACHED_USER_MANAGER_INSTANCE = null;
let CACHED_POLICY_TYPE = null;

const createUserManagerInstance = (appSettings, policyType = POLICY_TYPES.LOGIN) => {
    const authSettings = appSettings.AUTHENTICATED_USER_SETTINGS;
    const isB2C = authSettings.ISB2C === 'true';

    let oAuthAuthority;
    let hostName;

    if (isB2C) {
        hostName = appSettings.serverUrl;
        oAuthAuthority = appSettings.API_URLS.IDENTITY;
        if (policyType === POLICY_TYPES.LOGIN && authSettings.POLICY) {
            oAuthAuthority = `${oAuthAuthority}?p=${authSettings.POLICY}`;
        } else if (policyType === POLICY_TYPES.FORGOT_PASSWORD && authSettings.FORGOT_PASSWORD_POLICY) {
            oAuthAuthority = `${oAuthAuthority}?p=${authSettings.FORGOT_PASSWORD_POLICY}`;
        }
    } else {
        hostName = window.location.origin;
        oAuthAuthority = appSettings.API_URLS.IDENTITY;
    }

    const managerConfig = {
        authority: oAuthAuthority,
        client_id: authSettings.CLIENT_ID,
        redirect_uri: `${hostName}${process.env.PUBLIC_URL}/callback`,
        response_type: 'id_token token',
        scope: authSettings.SCOPE,
        post_logout_redirect_uri: `${window.location.origin}${process.env.PUBLIC_URL}/logout`,
        silent_redirect_uri: `${hostName}${process.env.PUBLIC_URL}/silent-refresh`,
        automaticSilentRenew: policyType === POLICY_TYPES.LOGIN,
        accessTokenExpiringNotificationTime: 120,
        silentRequestTimeout: 60000, // milliseconds
        monitorSession: true,        
        userStore: new WebStorageStateStore({ store: localStorage }),
        loadUserInfo: !isB2C
    };

    CACHED_USER_MANAGER_INSTANCE = createUserManager(managerConfig);
    CACHED_POLICY_TYPE = policyType;
}

export default function getUserManager(appSettings, policyType = POLICY_TYPES.LOGIN) {
    if (!CACHED_USER_MANAGER_INSTANCE || policyType !== CACHED_POLICY_TYPE) {        
        createUserManagerInstance(appSettings, policyType);
    }

    return CACHED_USER_MANAGER_INSTANCE;
}
