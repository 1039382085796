import React from 'react';
import PropTypes from 'prop-types';
import { ButtonSelect, Dropdown } from 'spheracloud-common';
import style from './DropdownMultiLevel.module.scss';
import { v4 } from 'uuid';
import _ from 'lodash';

class DropdownMultiLevel extends React.Component {
    constructor(props) {
        super(props);

        const { initialSelection } = this.props;

        this.state = {
            selectedItems: initialSelection || [],
        };

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.getAvailableSelection = this.getAvailableSelection.bind(this);
        this.renderDropdown = this.renderDropdown.bind(this);
        this.scrollIntoView = this.scrollIntoView.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { initialSelection } = this.props;
        if (initialSelection !== prevProps.initialSelection) {
            if (!_.isEqual(this.state.selectedItems, initialSelection)) {
                this.setState({
                    selectedItems: initialSelection || []
                });
            }
        }
    }
    
    handleDropdownChange(options, value) {
        const { onSelectionChanged } = this.props;
        const { selectedItems } = this.state;

        const updatedSelectedItems = selectedItems.filter(item => {
            return options.findIndex(option => option.path === item) === -1;
        }).concat(value);

        const availableSelection = this.getAvailableSelection(updatedSelectedItems);
        const availableSelectedItems = updatedSelectedItems.filter(i => availableSelection.indexOf(i) > -1);

        this.setState({ selectedItems: updatedSelectedItems }, onSelectionChanged && onSelectionChanged(availableSelectedItems));
    }

    getAvailableSelection(selected) {
        const { options } = this.props;

        const insert = (available = [], children) => {
            available = available.concat(children.map(o => o.path));
            children.forEach(o => {
                if (selected.indexOf(o.path) > -1 && o.children && o.children.length > 0) {
                    available = insert(available, o.children);
                }
            })
            return available;
        }

        return insert([], options);
    }

    scrollIntoView(key) {
        const element = document.getElementById(`DropdownMultiLevel-${key}`);
        if (element) {
            element.scrollIntoView({ block: 'nearest' });
        }
    }

    renderDropdown(options, label) {
        const { hasError, disabled, isMandatory, requiredFieldLabel, maxHeight } = this.props;
        const { selectedItems } = this.state;
        const dropdownHasImages = (options.every(item => {
            return item.image;
        }));
        const levelHasError = hasError && options.every(o => selectedItems.indexOf(o.path) === -1);

        const key = v4();

        return <div key={key}>
            {
                label && <div className={style.DropdownMultiLevelLabel}>
                    {label}
                    {
                        (isMandatory || levelHasError) &&
                        <span className="FieldMandatoryIndicator">
                            {' * '}
                            {levelHasError && requiredFieldLabel}
                        </span>
                    }
                </div>
            }
            {
                dropdownHasImages ?
                    <ButtonSelect
                        options={options.map(option => ({
                            ...option,
                            value: option.path
                        }))}
                        onChange={value => this.handleDropdownChange(options, value)}
                        initialSelection={selectedItems.find(v => options.findIndex(o => o.path === v) > -1)}
                        multiselect={false}
                        hasError={levelHasError}
                        disabled={disabled}
                    />
                    :
                    <div id={`DropdownMultiLevel-${key}`}>
                        <Dropdown
                            label={''}
                            items={options.map(option => ({
                                ...option,
                                label: option.label,
                                id: option.path,
                                selected: selectedItems.indexOf(option.path) > -1
                            }))}
                            onSelect={item => this.handleDropdownChange(options, item && item.id)}
                            onOpen={() => this.scrollIntoView(key)}
                            hasError={levelHasError}
                            disabled={disabled}
                            maxHeight={maxHeight}
                            showClearButton={true}
                        />
                    </div>
            }
            {
                options.filter(option => option.children.length > 0 && selectedItems.indexOf(option.path) > -1).map(option => {
                    return this.renderDropdown(option.children, option.label);
                })
            }
        </div>
    }

    render() {
        const { options } = this.props;

        return <div className={style.DropdownMultiLevelContainer}>
            {
                this.renderDropdown(options)
            }
        </div>
    }
}

DropdownMultiLevel.propTypes = {
    disabled: PropTypes.bool,
    onSelectionChanged: PropTypes.func,
    hasError: PropTypes.bool,
    isMandatory: PropTypes.bool,
    requiredFieldLabel: PropTypes.string,
    maxHeight: PropTypes.string,
};

DropdownMultiLevel.defaultProps = {
    disabled: false,
    onSelectionChanged: undefined,
    hasError: false,
    isMandatory: false,
    requiredFieldLabel: 'Required field',
    maxHeight: undefined,
};

export default DropdownMultiLevel;
