import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styles from './MatrixField.module.css';

class MatrixField extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValue } = this.props;

    this.state = {
      value: initialValue
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;

    if (initialValue !== prevProps.initialValue) {
        this.setState({
            value: initialValue
        });
    }
  }

  handleChange(e) {
    const { onValueChanged } = this.props;

    const value = e.target.value;

    this.setState({ value }, () => {
      if (onValueChanged) {
        onValueChanged(value);
      }
    });
  }

  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item['Data'],
      };
    }, initialValue);
  };

  render() {
    const { field, hasError, disabled } = this.props;
    const { value } = this.state;

    const attributeName = field.TemplateAttribute.Name
    const propertiesField = get(field, 'Properties', {});
    const properties = this.convertArrayToObject(propertiesField, 'Name')
    const cells = JSON.parse(get(properties, 'cells', []))

    const columnCountValue = parseInt(get(properties, 'columnCount'));
    const rowCountValue = parseInt(get(properties, 'rowCount'));

    let headerRow = {};
    let subheaderCells = [];
    let subheaderRows = [];
    let dataRows = [];
    let infoCells = [];
    let infoRows = [];

    if (columnCountValue && rowCountValue) {
      const width = 80 / columnCountValue + '%';
      const headerWidth = '20%';

      subheaderCells.push({ width });
      for (let i = 1; i <= columnCountValue; i++) {
        const label = (get(properties, 'columnHeader' + i) ? get(properties, 'columnHeader' + i) : '');
        subheaderCells.push({ label, width });
      }
      subheaderRows = [
        { cells: subheaderCells },
      ]

      for (let i = 0; i <= columnCountValue; i++) {
        let label = i;
        let isRowHeader = false;
        if (i === 0) {
          isRowHeader = true;
          label = (get(properties, 'rowHeader0') ? get(properties, 'rowHeader0') : '');
        }

        infoCells.push({ label, width, isRowHeader });
      }

      infoRows = [
        { cells: infoCells }
      ];

      headerRow = {
        cells: [
          {
            label: get(properties, 'columnHeader0') ? get(properties, 'columnHeader0') : '',
            colspan: columnCountValue
          }]
      };

      let dataCellIndex = 0;
      for (let i = 0; i < rowCountValue; i++) {
        let cellsArray = [];
        let cellItem;
        for (let j = 0; j <= columnCountValue; j++) {
          if (j === 0) {
            cellItem = { label: get(properties, 'rowHeader' + (i + 1)) ? get(properties, 'rowHeader' + (i + 1)) : '', width: headerWidth, isFirstColumn: true };
            cellsArray.push(cellItem);
            cellItem = { label: i + 1, isRowIndex: true }
            cellsArray.push(cellItem);
          }
          else {
            cellItem = { ...cells[dataCellIndex], id: dataCellIndex, width, dataCell: true };
            cellsArray.push(cellItem);

            dataCellIndex++;
          }
        }

        dataRows.push({ cells: cellsArray });
      }
    }

    return (
      <div className={hasError && styles['risk-matrix-error']}>
        <table className={styles['risk-matrix']} width="100%">
          <thead>
            {<tr>
              <th className={styles['no-border']}></th>
              <th className={styles['inter-column']}></th>
              {headerRow.cells.map(cell => {
                const colspan = cell.colspan > 1 ? cell.colspan : "";
                return <th colspan={colspan} className={styles['bold-header']}>{cell.label}</th>
              })}
            </tr>}
            {subheaderRows.map(row => {
              return (
                <tr>
                  {row.cells.map(cell => {
                    if (cell.label == undefined) {
                      return <th colspan={2} className={styles['no-border']}></th>
                    }
                    return <th className={styles['header']}>{cell.label}</th>
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody>
            {infoRows.map(row => {
              return (
                <tr>
                  {row.cells.map(cell => {
                    if (cell.isRowHeader) {
                      return <td colspan={2} className={styles['bold-header']}>{cell.label}</td>
                    }

                    return <td>{cell.label}</td>
                  })}
                </tr>
              )
            })}
            {dataRows.map(row => {
              return (
                <tr>
                  {row.cells.map(cell => {
                    if (cell.isFirstColumn) {
                      return <td className={styles['header']}>{cell.label}</td>
                    }

                    if (cell.isRowIndex) {
                      return <td className={styles['inter-column']}>{cell.label}</td>
                    }

                    if (cell.value) {
                      return <td style={{ backgroundColor: cell.backgroundColor }}>
                        <div className={styles[cell.dataCell ? 'dataCellStyle' : 'infoCellStyle']}>
                          {cell.label}
                        </div>
                        <input
                          data-savedvalue={cell.value}
                          value={cell.value}
                          type="radio"
                          disabled={disabled}
                          checked={cell.value === value.toString()}
                          onChange={this.handleChange}
                          name={'Attributes.' + attributeName}
                          id={'TemplateAttributeValueID' + cell.value}
                          ></input>
                      </td>
                    }

                    return <td className={styles['infoCellStyle']}></td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
export const propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  initialValue: PropTypes.string,
  onValueChanged: PropTypes.func,
};

export const defaultProps = {
  disabled: false,
  hasError: false,
  initialValue: '',
  onValueChanged: undefined,
};

MatrixField.propTypes = propTypes;
MatrixField.defaultProps = defaultProps;

export default MatrixField;
