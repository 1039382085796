import React, { Component } from 'react';
import style from './LoginBasicAuth.module.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { loginUserBasicAuth } from '../../actions/authActions';
import { clearBasicAuthLoginError } from '../../actions/appActions';
import { getAnonGuid, getFormGroup, getString } from '../../selectors/appSelectors';
import { TextInput, PrimaryButton } from 'spheracloud-common';
import { CloseDark } from '../../icons';
import Strings from '../../constants/Strings';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

class LoginBasicAuth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        };

        this.loginUser = this.loginUser.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.cancelLogin = this.cancelLogin.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleUsernameChange(username) {
        const { clearBasicAuthLoginError } = this.props;
        this.setState({
            username
        });
        clearBasicAuthLoginError();
    }

    handlePasswordChange(password) {
        const { clearBasicAuthLoginError } = this.props;
        this.setState({
            password
        });
        clearBasicAuthLoginError();
    }

    handleKeyPress(event) {
        const { username, password } = this.state;
        const { isLoggingIn } = this.props;
        if (event.key === 'Enter' && username !== "" && password !== "" && !isLoggingIn) {
            this.loginUser();
        }
      }

    loginUser() {
        const { username, password } = this.state;
        const { loginUserBasicAuth } = this.props;
        const basicAuth = btoa(`${username}:${password}`);
        loginUserBasicAuth(basicAuth);
    }

    cancelLogin() {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { signInLabel, usernameLabel, enterUsernameLabel, passwordLabel, enterPasswordLabel, incorrectEmailOrPasswordLabel, submitLabel, loginError, isLoggingIn } = this.props;
        const { username, password } = this.state;

        return (
            <div className="LoginBasicAuthPage">
                <CompanyLogo />
                <div className={style.LoginBasicAuthContainer}>
                    <div className={style.LoginBasicAuthHeader}>
                        <div>{signInLabel}</div>
                        <div className={style.LoginBasicAuthCancel}>
                            <img src={CloseDark} onClick={() => this.cancelLogin()} alt="Cancel"/>
                        </div>
                    </div>
                    <div className="LoginBasicAuthContent">
                        <div className={style.LoginBasicAuthErrorContainer}>
                            {loginError && incorrectEmailOrPasswordLabel}
                        </div>
                        <div className={style.LoginBasicAuthUsernameContainer}>
                            <div className={style.LoginBasicAuthLabel}>{usernameLabel}</div>
                            <TextInput
                                placeholder={enterUsernameLabel}
                                initialText={username}
                                onTextChanged={this.handleUsernameChange}
                                onKeyPress={this.handleKeyPress}
                                disabled={isLoggingIn}
                            ></TextInput>
                        </div>
                        <div className={style.LoginBasicAuthPasswordContainer}>
                            <div className={style.LoginBasicAuthLabel}>{passwordLabel}</div>
                            <TextInput
                                type={'password'}
                                placeholder={enterPasswordLabel}
                                initialText={password}
                                onTextChanged={this.handlePasswordChange}
                                onKeyPress={this.handleKeyPress}
                                disabled={isLoggingIn}
                            ></TextInput>
                        </div>
                        <div className={style.LoginBasicAuthFooter}>
                            <PrimaryButton
                                className={style.LoginBasicAuthSignInButton}
                                onClick={() => this.loginUser()}
                                disabled={username === "" || password === "" || isLoggingIn}
                            >
                                {submitLabel}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { appReducer } = state;
    return {
        anonGuid: getAnonGuid(state),
        formGroup: getFormGroup(state),
        signInLabel: getString(Strings.signIn, state),
        usernameLabel: getString(Strings.username, state),
        enterUsernameLabel: getString(Strings.enterUsername, state),
        passwordLabel: getString(Strings.password, state),
        enterPasswordLabel: getString(Strings.enterPassword, state),
        incorrectEmailOrPasswordLabel: getString(Strings.incorrectEmailOrPassword, state),
        submitLabel: getString(Strings.submit, state),
        isLoggingIn: appReducer.isLoggingIn,
        loginError: appReducer.basicAuthLoginError,
    };
};

const mapDispatchToProps = dispatch => ({
    loginUserBasicAuth: basicAuth => {
        dispatch(loginUserBasicAuth(basicAuth));
    },
    clearBasicAuthLoginError: () => {
        dispatch(clearBasicAuthLoginError());
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginBasicAuth));
