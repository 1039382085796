import React from 'react';
import DomPurify from 'dompurify';
import { FIELD_TYPES } from '../constants/FieldTypes';
import { USER_TYPES } from '../constants/UserTypes';

DomPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node.tagName === 'A' && 'target' in node && node.getAttribute('target') === '_blank') {
        node.setAttribute('rel', 'noopener');
    }
});

export const getNodeFromString = (string) => {
    const sanitized = DomPurify.sanitize(string, { ADD_ATTR: ['target'] });
    return <div dangerouslySetInnerHTML={{__html: sanitized}}></div>;
}

export const allSettled = (promises) => {
    let wrappedPromises = promises.map(p => Promise.resolve(p)
    .then(
        val => ({ status: 'fulfilled', value: val }),
        err => ({ status: 'rejected', reason: err })
    ));
    return Promise.all(wrappedPromises);
}

export const checkIsLive = (field) => {
    return field.RenderingType === FIELD_TYPES.SINGLE_CHOOSER ||
        field.RenderingType === FIELD_TYPES.MULTI_CHOOSER ||
        (field.Properties && field.Properties.find((val) => val.Data && val.Name === 'liveSearch'));
};

export const combineSimilarRecordKey = (templateNameWithLang, searchValue) => `${templateNameWithLang}_${searchValue}`;

export const isLoggedInUserType = (userType) => {
    return userType === USER_TYPES.AUTHENTICATED || userType === USER_TYPES.SSOAUTHENTICATED ||
        userType === USER_TYPES.B2CAUTHENTICATED || userType === USER_TYPES.B2CAUTHENTICATED_WITH_DOMAIN;
}