import React, { Component } from 'react';
import './StartPage.scss';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { HelpCircle } from '../../icons';
import IncidentTypeButton from '../../components/IncidentTypeButton/IncidentTypeButton';
import { SecondaryButton, Spinner, Modal } from 'spheracloud-common';
import { reloadForms, setFormType, showContinueDraft, setActiveLocation, clearActiveLocation } from '../../actions/appActions';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import Strings from '../../constants/Strings';
import Translation from '../Translation/Translation';
import HeaderReportingUser from '../Header/HeaderReportingUser/HeaderReportingUser';
import { getNodeFromString } from '../../utils/utils';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { getActiveLocationId, getProfileValue, getUseFormLocationRestrictions, getIsFormListLoading, getActiveLocation, getAuthInfo, isAuthenticated } from '../../selectors/appSelectors';
import HierarchyLocationSelector from '../../components/Element/HierarchyLocationSelector/HierarchyLocationSelector';

class StartPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            helpTextModalOpen: false,
        };

        this.toggleHelpTextModal = this.toggleHelpTextModal.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
    }

    toggleHelpTextModal(open) {
        this.setState({
            helpTextModalOpen: open
        })
    }

    handleLocationChange(value) {
        let { activeLocationId, setActiveLocation, clearActiveLocation, reloadForms } = this.props;
        
        if (value && value.nodeExternalId) {
            setActiveLocation(value);

            let newLocationId = (value && value.nodeExternalId) || activeLocationId;
            if (newLocationId !== activeLocationId) {
                reloadForms(newLocationId);
            }            
        }
        else if(activeLocationId) {
            clearActiveLocation();            
        }                        
    }

    getErrorMessage() {
        const { loadingError } = this.props;

        const errorText = loadingError ? `: ${loadingError.status} ${loadingError.statusText}` : '';
        return <>
            <Translation>{Strings.applicationFailedToLoad}</Translation>
            {errorText}
        </>;
    }

    render() {
        const { isLoaded, isLoading, summaryFormTypes, showContinueDraft, companySupportDetails, isContinuingDraft, isLoggingOff, useFormLocationRestrictions, isHierarchy, activeLocation, isFormListLoading, isFormLoading } = this.props;
        const { helpTextModalOpen } = this.state;
        const tooltipName = 'company-support-details';
        const isMobileScreen = window.innerWidth <= 480;        

        const helpButtonAttributes = isMobileScreen ? {
            'onClick': () => { this.toggleHelpTextModal(true) }
        } : {
                'data-tip': true,
                'data-for': tooltipName,
                'data-event': 'click mouseenter',
                'data-event-off': 'mouseleave'
            }

        return (
            <div className="StartPage">
                <div className="StartPageHeader">
                    <CompanyLogo />
                    {
                        isLoaded && companySupportDetails && !isLoggingOff && !isFormLoading &&
                        <>
                            <div className="StartPageHeaderRightSection">                                
                                <div className="StartPageReportingUser">
                                    <HeaderReportingUser />
                                </div>
                                <div className="StartPageHelpContainer" {...helpButtonAttributes}>
                                    <img src={HelpCircle} alt="Help" className="StartPageHelp" />
                                </div>
                                {
                                    helpTextModalOpen &&
                                    <Modal
                                        apply={""}
                                        cancel={""}
                                        onApply={() => { }}
                                        onCancel={() => { this.toggleHelpTextModal(false) }}
                                        className="HelpTextModal"
                                    >
                                        {getNodeFromString(companySupportDetails)}
                                    </Modal>
                                }
                                <ReactTooltip id={tooltipName} effect="solid" place="bottom" type="light" globalEventOff="click" delayShow={1} delayHide={1} clickable={true}>
                                    {getNodeFromString(companySupportDetails)}
                                </ReactTooltip>
                            </div>
                        </>
                    }
                </div>
                {
                    isLoaded && !isContinuingDraft && !isLoggingOff && !isFormLoading ?
                        <>
                            <div className="StartPageContent">
                                <div className="StartPageTitle">
                                    <Translation>{useFormLocationRestrictions ? Strings.startPageLocationSelection : Strings.startPageScreenTitle}</Translation>
                                </div>
                                {
                                    isHierarchy && useFormLocationRestrictions &&
                                    <div className="StartPageLocationChooser">
                                        <HierarchyLocationSelector onChange={this.handleLocationChange} initialLocation={activeLocation}/>
                                    </div>
                                }
                                {
                                    (!isHierarchy || !useFormLocationRestrictions || activeLocation) && <div className="StartPageIncidentTypes">
                                        {
                                            isFormListLoading ? <div className="StartPageSpinnerContainer"><Spinner /></div>
                                            :
                                                (summaryFormTypes && summaryFormTypes.length > 0) ?
                                                    summaryFormTypes.map(formTypeData => {
                                                    return (
                                                        <IncidentTypeButton
                                                            key={formTypeData.Id}
                                                            formTypeId={formTypeData.Id}
                                                            incidentType={formTypeData.ShortDescription}
                                                            helpText={formTypeData.LongDescription}
                                                            image={formTypeData.image}
                                                            onClick={() => this.props.selectFormType(formTypeData.Id)}
                                                        />
                                                    )
                                                })
                                                :
                                                <div className="StartPageHelpText">
                                                    <Translation>{Strings.noFormTypesConfigured}</Translation>
                                                </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="StartPageFooter">
                                <LanguagePicker />
                                <SecondaryButton
                                    className="ContinueExistingButton"
                                    onClick={() => showContinueDraft()}
                                >
                                    <Translation>{Strings.orContinueAnExistingEvent}</Translation>
                                </SecondaryButton>
                                <SecondaryButton
                                    className="ContinueExistingButtonMobile"
                                    onClick={() => showContinueDraft()}
                                >
                                    <Translation>{Strings.continueExisting}</Translation>
                                </SecondaryButton>                                
                            </div>
                        </>
                        :
                        <div className="StartPageContent">
                            {
                                isLoading || isContinuingDraft || isLoggingOff || isFormLoading ? <Spinner /> : <div className="StartPageHelpText">{this.getErrorMessage()}</div>
                            }
                        </div>
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { appReducer } = state;

    return {
        isLoading: appReducer.isLoading,
        isLoaded: appReducer.isLoaded,
        loadingError: appReducer.errors.companyData,
        summaryFormTypes: appReducer.summaryFormTypes,
        companySupportDetails: getProfileValue("Company.Support.Details", state),
        isContinuingDraft: appReducer.isContinuingDraft,
        isLoggingOff: appReducer.isLoggingOff,
        isHierarchy: state.appReducer.isHierarchy,
        useFormLocationRestrictions: getUseFormLocationRestrictions(state),
        activeLocation: getActiveLocation(state),        
        activeLocationId: getActiveLocationId(state),
        isFormListLoading: getIsFormListLoading(state),
        isAuthenticated: isAuthenticated(state),
        authInfo: getAuthInfo(state),
        isFormLoading: appReducer.isFormLoading,
    };
};

const mapDispatchToProps = dispatch => ({
    selectFormType: formType => {
        dispatch(setFormType(formType));
    },
    showContinueDraft: () => dispatch(showContinueDraft()),
    setActiveLocation: (location) => dispatch(setActiveLocation(location)),
    clearActiveLocation: () => dispatch(clearActiveLocation()),
    reloadForms: (locationId) => dispatch(reloadForms(locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartPage);