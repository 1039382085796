import _ from 'lodash';
import appStrings from '../constants/Strings';
import {
    LOAD_APP_SETTINGS,
    SET_FORM_GROUP,
    ALLOW_ANON_REPORTING,
    FETCH_COMPANY_DATA_BEGIN,
    FETCH_COMPANY_DATA_SUCCESS,
    FETCH_COMPANY_DATA_FAILURE,
    SET_FORM_TYPE,
    SET_ACCIDENT_GUID,
    CLEAR_FORM_TYPE,
    SHOW_CANCEL_FORM,
    HIDE_CANCEL_FORM,
    SET_COMPANY_LOGO,
    SET_LANGUAGE,
    UPDATE_LANGUAGE,
    SUBMIT_FORM_BEGIN,
    SUBMIT_FORM_SUCCESS,
    SUBMIT_FORM_FAILURE,
    SHOW_AUTH_ERROR,
    CLEAR_AUTH_ERROR,
    SET_MANUAL_USER,
    SET_ANONYMOUS_USER,
    SET_ANON_GUID,
    SET_AUTH_INFO,
    CLEAR_FORM,
    SUBMIT_DRAFT_BEGIN,
    SUBMIT_DRAFT_SUCCESS,
    SUBMIT_DRAFT_FAILURE,
    SHOW_CONTINUE_DRAFT,
    HIDE_CONTINUE_DRAFT,
    CONTINUE_DRAFT_BEGIN,
    CONTINUE_DRAFT_CANCEL,
    CONTINUE_DRAFT_SUCCESS,
    CONTINUE_DRAFT_FAILURE,
    CLEAR_DRAFT_FAILURE,
    CLEAR_FORM_FAILURE,
    GET_CURRENT_LOCATION_BEGIN,
    GET_CURRENT_LOCATION_SUCCESS,
    GET_CURRENT_LOCATION_FAILURE,
    CONTINUE_DRAFT_SET_DATA,
    CONTINUE_DRAFT_REMOVE_DATA,
    LOAD_USER_DEPENDENT_DEFAULT_VALUES_SUCCESS,
    SET_LOGGING_OFF,
    SET_SSO_SETTINGS,
    SET_FROM_SSO,
    UPDATE_FORM_TYPE,
    BASIC_AUTH_LOGIN_BEGIN,
    BASIC_AUTH_LOGIN_SUCCESS,
    BASIC_AUTH_LOGIN_FAILURE,
    CLEAR_BASIC_AUTH_LOGIN_ERROR,
    SET_CONTENT_DIMENSIONS,
    HIDE_LOGGED_OUT_FORM,
    SET_SPEECH_TO_TEXT_TOKEN_SETTINGS,
    SET_MIC_INUSE,
    SET_SAVED_ACCIDENTS,
    SHOW_THIS_LANGUAGE_VALUE_FORM,
    HIDE_THIS_LANGUAGE_VALUE_FORM,
    DELETE_DRAFT_START,
    DELETE_DRAFT_END,
    SET_SIMILAR_RECORDS_DATA,
    SET_SIMILAR_RECORD_SUMMARY,
    SET_SUMMARY_MODAL_VALUE,
    SET_SIMILAR_RECORDS_SPINNER_VALUE,
    SET_USE_FORM_LOCATION_RESTRICTIONS,
    UPDATE_FORM_TYPES,
    RELOAD_FORMS,
    SET_ACTIVE_LOCATION,
    CLEAR_ACTIVE_LOCATION,
    SET_ACTIVE_LOCATION_ID,
    LOAD_FORM,
    SHOW_LOCATION_RESTRICTION_USER_ERROR,
    CLEAR_LOCATION_RESTRICTION_USER_ERROR,
} from '../actions/appActions';
import { DEFAULT_VALUE_TYPES, DEFAULT_VALUE_SOURCE_TYPES } from '../constants/DefaultValueTypes';
import {combineSimilarRecordKey} from '../utils/utils';
import {
    TRANSLATION_LANGUAGES_LIST_FAILURE,
    TRANSLATION_LANGUAGES_LIST_REQUEST_START,
    TRANSLATION_LANGUAGES_LIST_SUCCESS
} from "../actions/wizardActions";
import produce, { enableES5 } from "immer";
import { USER_LOGGED_OUT } from "../actions/authActions"
import { SILENT_RENEW_ERROR } from 'redux-oidc';
enableES5();

const initialState = {
    appSettings: {},
    userSettings: {},
    selectedFormTypeId: -1,
    userInfo: {
        name: '',
        email: '',
        phone: ''
    },
    formTypes: [],
    summaryFormTypes: [],
    availableLanguages: [
        'en-gb'
    ],
    appStrings: appStrings,
    companyLogo: undefined,
    companySupportDetails: undefined,
    isLoading: true,
    isLoaded: false,
    isDraftSubmitting: false,
    isDraftSubmitted: false,
    submittedDraftId: undefined,
    isSubmitting: false,
    isSubmitted: false,
    submittedFormId: undefined,
    sideMenuOpen: false,
    errors: {},
    authErr: undefined,
    showCancelForm: false,
    showLoggedOutForm: false,
    showThisLanguageValueForm: false,
    showAuthErrorModal: false,
    showContinueDraft: false,
    isContinuingDraft: false,
    draftAccidentId: undefined,
    draftShortCode: undefined,
    currentLocation: undefined,
    isLoadingCurrentLocation: false,
    allowAnonReporting: true,
    loadingForm: {},
    defaultValues: {},
    isHierarchy: false,
    selectedHierarchyId: undefined,
    environment: undefined,
    isLoggingOff: false,
    linkForSSO: undefined,
    fromSSO: false,
    isSSOOnly: false,
    domainHint: undefined,
    isLoggingIn: false,
    basicAuthLoginError: undefined,
    contentDimensions: undefined,
    firstReportSettings: {},
    micInUse: false,
    savedAccidents: {},
    savedAccidentsDeleteLoading: false,
    translationLanguagesIsBeingProcessed: false,
    languagesThatCanBeTranslatedByApi: null,
    similarRecords: {},
    similarRecordSummaries: {},
    similarRecordsModal: { isEnabled: false, currentRecordId: 0 },
    similarRecordsFieldSpinner:  { },
    showLocationRestrictionUserError: false,
    initialActiveLocationId: undefined,
    accidentGuid: undefined,
};

function loadAppSettings(state, appSettings) {
    return {
        ...state,
        appSettings
    }
}

function setFormGroup(state, formGroup) {
    return {
        ...state,
        formGroup
    }
}

function setAnonGuid(state, anonGuid) {
    return {
        ...state,
        anonGuid
    }
}

function allowAnonReporting(state, allowAnonReporting) {
    return {
        ...state,
        allowAnonReporting
    }
}

function setAuthInfo(state, authInfo) {
    return {
        ...state,
        authInfo
    }
}

function setAuthError(state, err) {
    return {
        ...state,
        authErr: err,
        showAuthErrorModal: true
    }
}

function clearAuthError(state) {
    return {
        ...state,
        authErr: undefined,
        showAuthErrorModal: false
    }
}

function fetchCompanyDataBegin(state) {
    return {
        ...state,
        isLoading: true,
        isLoaded: false
    };
}

function fetchCompanyDataSuccess(state, companyData) {
    return {
        ...state,
        formTypes: companyData.formTypes,
        summaryFormTypes: companyData.summaryFormTypes,
        availableLanguages: companyData.availableLanguages,
        appStrings: companyData.appStrings,
        errors: {
            ...state.errors,
            companyData: null
        },
        isHierarchy: companyData.hierarchyData.isHierarchy,
        selectedHierarchyId: companyData.hierarchyData.selectedHierarchyId,
        hierarchyEnvironment: companyData.hierarchyData.hierarchyEnvironment,
        firstReportSettings: companyData.firstReportSettings,
        companyProfiles: companyData.companyProfiles,
    };
}

function updateFormType(state, formType, formId) {
    const formTypeIndex = state.formTypes ? state.formTypes.findIndex(f => f.Id === formId) : -1;
    const formTypes = state.formTypes ? [...state.formTypes] : [];

    if (formTypeIndex > -1) {
        formTypes[formTypeIndex] = formType;
    }
    else {
        formTypes.push(formType);
    }

    return {
        ...state,
        formTypes,
        isLoading: false,
        isLoaded: true,
        isFormLoading: false
    }
}

function setLoadingError(state, error) {
    return {
        ...state,
        errors: {
            ...state.errors,
            companyData: error
        },
        isLoading: false,
        isLoaded: false
    };
}

function setFormType(state, selectedFormTypeId) {
    const formTypeIndex = state.formTypes.findIndex(f => f.Id === selectedFormTypeId);
    const loading = formTypeIndex >= 0 && state.formTypes[formTypeIndex].WizardPages.length == 0;

    return {
        ...state,
        selectedFormTypeId,
        isLoading: loading,
        isLoaded: !loading,
        initialActiveLocationId: state.activeLocation && state.activeLocation.nodeExternalId
    };
}

function setAccidentGuid(state, accidentGuid) {
    return {
        ...state,
        accidentGuid
    };
}

function clearFormType(state) {
    return {
        ...state,
        selectedFormTypeId: -1,
        isDraftSubmitted: false,
        isDraftSubmitting: false,
        submittedDraftId: undefined,
        isSubmitting: false,
        isSubmitted: false,
        submittedFormId: undefined,
        similarRecords: {},
        initialActiveLocationId: undefined,
    };
}

function showCancelForm(state) {
    return {
        ...state,
        showCancelForm: true,
    };
}

function hideCancelForm(state) {
    return {
        ...state,
        showCancelForm: false,
    };
}

function showThisLanguageValue(state, payload) {
    return {
        ...state,
        showThisLanguageValueForm: {...payload},
    };
}

function hideThisLanguageValue(state) {
    return {
        ...state,
        showThisLanguageValueForm: false,
    };
}

function showLoggedOutForm(state) {
    return {
        ...state,
        showLoggedOutForm: true,
    };
}

function hideLoggedOutForm(state) {
    return {
        ...state,
        showLoggedOutForm: false,
    };
}

function setCompanyLogo(state, companyLogo) {
    return {
        ...state,
        companyLogo
    }
}

function setLanguage(state, language) {
    return {
        ...state,
        userSettings: {
            ...state.userSettings,
            language
        }
    };
}

function showContinueDraft(state) {
    return {
        ...state,
        showContinueDraft: true,
    };
}

function hideContinueDraft(state) {
    return {
        ...state,
        showContinueDraft: false
    };
}

function continueDraftBegin(state) {
    return {
        ...state,
        isContinuingDraft: true,
    }
}

function continueDraftCancel(state) {
    return {
        ...state,
        isContinuingDraft: false,
    }
}

function continueDraftSuccess(state, accidentId, draftShortCode) {
    return {
        ...state,
        isContinuingDraft: false,
        showContinueDraft: false,
        draftAccidentId: accidentId,
        draftShortCode,
    }
}

function continueDraftFailure(state, error) {
    return {
        ...state,
        isContinuingDraft: false,
        errors: {
            ...state.errors,
            continueDraft: error,
        },
    }
}

function clearDraftFailure(state) {
    return {
        ...state,
        errors: {
            ...state.errors,
            submitDraft: undefined,
            continueDraft: undefined,
        }
    }
}

function submitDraftBegin(state) {
    return {
        ...state,
        isDraftSubmitting: true,
    };
}

function submitDraftSuccess(state, draftId) {
    return {
        ...state,
        isDraftSubmitting: false,
        isDraftSubmitted: true,
        submittedDraftId: draftId,
        errors: {
            ...state.errors,
            submitDraft: null,
        },
    }
}

function submitDraftFailure(state, error) {
    return {
        ...state,
        isDraftSubmitting: false,
        isDraftSubmitted: false,
        errors: {
            ...state.errors,
            submitDraft: error,
        },
    }
}

function submitFormBegin(state) {
    return {
        ...state,
        isSubmitting: true
    };
}

function submitFormSuccess(state, formId) {
    return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
        submittedFormId: formId,
        errors: {
            ...state.errors,
            submitForm: null
        }
    };
}

function submitFormFailure(state, error) {
    return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
        errors: {
            ...state.errors,
            submitForm: error
        }
    };
}

function clearFormFailure(state) {
    return {
        ...state,
        errors: {
            ...state.errors,
            submitForm: undefined,
        }
    }
}

function setManualUser(state, userInfo) {
    return {
        ...state,
        userInfo: {
            ...state.userInfo,
            ...userInfo,
        },
    };
}

function setAnonymousUser(state) {
    return {
        ...state,
        userInfo: {
            ...initialState.userInfo
        }
    };
}

function clearForm(state) {
    return {
        ...state,
        draftAccidentId: undefined,
        draftShortCode: undefined,
        showCancelForm: false,
        showLoggedOutForm: false,
    };
}

function getCurrentLocationBegin(state) {
    return {
        ...state,
        isLoadingCurrentLocation: true,
        errors: {
            ...state.errors,
            currentLocation: undefined,
        }
    }
}

function getCurrentLocationSuccess(state, currentLocation) {
    return {
        ...state,
        currentLocation,
        isLoadingCurrentLocation: false,
        errors: {
            ...state.errors,
            currentLocation: undefined,
        }
    }
}

function getCurrentLocationFailure(state, error) {
    return {
        ...state,
        isLoadingCurrentLocation: false,
        errors: {
            ...state.errors,
            currentLocation: error,
        }
    }
}

function continueDraftSetData(state, shortCode, accidentId, formId) {
    return {
        ...state,
        loadingForm: {
            shortCode,
            accidentId,
            formId
        }
    }
}

function continueDraftRemoveData(state) {
    return {
        ...state,
        loadingForm: {}
    }
}

function loadReportingUserDependentDefaultValuesSuccess(state, defaultValues) {
    const newDefaultValues = !_.isEmpty(defaultValues) ? {
        ...state.defaultValues,
        [DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE]: {
            ...state.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE] || {},
            [DEFAULT_VALUE_SOURCE_TYPES.CURRENT_USER]: state.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE] ? {
                ...state.defaultValues[DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE][DEFAULT_VALUE_SOURCE_TYPES.CURRENT_USER],
                ...defaultValues,
            } : {
                ...defaultValues
            }
        }
    } : {
        ...state.defaultValues
    };

    return {
        ...state,
        defaultValues: newDefaultValues,
        isLoading: false,
        isLoaded: true,
    }
}

function setLoggingOff(state, isLoggingOff) {
    return {
        ...state,
        isLoggingOff
    }
}

function setFromSSO(state, fromSSO) {
    return{
        ...state,
        fromSSO
    }
}

function setSSOSettings(state, link, isSSOOnly, domainHint) {
    return {
        ...state,
        linkForSSO: link,
        isSSOOnly,
        domainHint,
    }
}

function basicAuthLoginBegin(state) {
    return {
        ...state,
        isLoggingIn: true,
        basicAuthLoginError: undefined,
    }
}

function basicAuthLoginSuccess(state) {
    return {
        ...state,
        isLoggingIn: false,
        basicAuthLoginError: undefined,
    }
}

function basicAuthLoginFailure(state, basicAuthLoginError) {
    return {
        ...state,
        isLoggingIn: false,
        basicAuthLoginError,
    }
}

function clearBasicAuthLoginError(state) {
    return {
        ...state,
        basicAuthLoginError: undefined
    }
}

function setContentDimensions(state, contentHeight, contentWidth) {
    return {
        ...state,
        contentDimensions: {
            width: contentWidth,
            height: contentHeight,
        },
    }
}

function setSpeechToTextTokenSettings(state, token) {
    return {
        ...state,
        firstReportSettings: {
            ...state.firstReportSettings,
            speechToTextSettings: {
                ...state.firstReportSettings.speechToTextSettings,
                token,
                timeStamp: new Date().getTime()
            }
        }
    }
}

function setMicInUse(state, inUse) {
    return {
        ...state,
        micInUse: inUse
    }
}

function setSavedAccidents(state, list){
    return {
        ...state,
        savedAccidents: list
    }
}

function setDeleteDraftLoading(state, draftDeleteLoading){
    return {
        ...state,
        savedAccidentsDeleteLoading: draftDeleteLoading
    }
}

function lowerCaseObjectKeys (value) {
    return _.mapKeys(value, (value, k) => k.toLowerCase())
}

function setSimilarRecordsData (state, templateName, searchValue, recordsData) {
    const key = combineSimilarRecordKey(templateName, searchValue);

    if (_.isEmpty(state.similarRecords[key])) {
        return {
            ...state,
            similarRecords: {
                ...state.similarRecords,
                [key]: recordsData
            }
        };
    }

    return state;
}

function setSimilarRecordSummaries (state, accidentId, summary) {
    if(_.isEmpty(state.similarRecordSummaries[accidentId])){
        return {
            ...state,
            similarRecordSummaries: {
                ...state.similarRecordSummaries,
                [accidentId]: summary
            }
        };
    }

    return state;
}

function setSummaryModalState(state, modalValue) {
    return {
        ...state,
        similarRecordsModal: modalValue
    };
}

function updateFormTypes(state, summaryFormTypes) {
    return {
        ...state,
        summaryFormTypes,
        isFormListLoading: false,
        formTypes: summaryFormTypes
    };
}

function reducer(state = initialState, action) {
    let newState = null;

    switch (action.type) {
        case (LOAD_APP_SETTINGS):
            newState = loadAppSettings(state, action.settings);
            break;
        case (SET_FORM_GROUP):
            newState = setFormGroup(state, action.formGroup);
            break;
        case (SET_ANON_GUID):
            newState = setAnonGuid(state, action.guid);
            break;
        case (ALLOW_ANON_REPORTING):
            newState = allowAnonReporting(state, action.allowAnonReporting);
            break;
        case (SET_AUTH_INFO):
            newState = setAuthInfo(state, action.authInfo);
            break;
        case (SHOW_AUTH_ERROR):
            newState = setAuthError(state, action.error);
            break;
        case (CLEAR_AUTH_ERROR):
            newState = clearAuthError(state);
            break;
        case (FETCH_COMPANY_DATA_BEGIN):
            newState = fetchCompanyDataBegin(state);
            break;
        case (FETCH_COMPANY_DATA_SUCCESS):
            newState = fetchCompanyDataSuccess(state, action.companyData);
            break;
        case (FETCH_COMPANY_DATA_FAILURE):
            newState = setLoadingError(state, action.error);
            break;
        case (SET_FORM_TYPE):
            newState = setFormType(state, action.formType);
            break;
        case (SET_ACCIDENT_GUID):
            newState = setAccidentGuid(state, action.accidentGuid);
            break;
        case (CLEAR_FORM_TYPE):
            newState = clearFormType(state);
            break;
        case (SHOW_CANCEL_FORM):
            newState = showCancelForm(state);
            break;
        case (HIDE_CANCEL_FORM):
            newState = hideCancelForm(state);
            break;
        case (SHOW_THIS_LANGUAGE_VALUE_FORM):
            newState = showThisLanguageValue(state, action.payload);
            break;
        case (HIDE_THIS_LANGUAGE_VALUE_FORM):
            newState = hideThisLanguageValue(state);
            break;
        case (SILENT_RENEW_ERROR):
            newState = showLoggedOutForm(state);
            break;
        case (HIDE_LOGGED_OUT_FORM):
            newState = hideLoggedOutForm(state);
            break;
        case (SET_COMPANY_LOGO):
            newState = setCompanyLogo(state, action.companyLogo);
            break;
        case (SET_LANGUAGE):
        case (UPDATE_LANGUAGE):
            newState = setLanguage(state, action.language);
            break;
        case (SHOW_CONTINUE_DRAFT):
            newState = showContinueDraft(state);
            break;
        case (HIDE_CONTINUE_DRAFT):
            newState = hideContinueDraft(state);
            break;
        case (CONTINUE_DRAFT_BEGIN):
            newState = continueDraftBegin(state);
            break;
        case (CONTINUE_DRAFT_CANCEL):
            newState = continueDraftCancel(state);
            break;
        case (CONTINUE_DRAFT_SUCCESS):
            newState = continueDraftSuccess(state, action.accidentId, action.shortCode);
            break;
        case (CONTINUE_DRAFT_FAILURE):
            newState = continueDraftFailure(state, action.error);
            break;
        case (CLEAR_DRAFT_FAILURE):
            newState = clearDraftFailure(state);
            break;
        case (SUBMIT_DRAFT_BEGIN):
            newState = submitDraftBegin(state);
            break;
        case (SUBMIT_DRAFT_SUCCESS):
            newState = submitDraftSuccess(state, action.draftId);
            break;
        case (SUBMIT_DRAFT_FAILURE):
            newState = submitDraftFailure(state, action.error);
            break;
        case (SUBMIT_FORM_BEGIN):
            newState = submitFormBegin(state);
            break;
        case (SUBMIT_FORM_SUCCESS):
            newState = submitFormSuccess(state, action.formId);
            break;
        case (SUBMIT_FORM_FAILURE):
            newState = submitFormFailure(state, action.error);
            break;
        case (CLEAR_FORM_FAILURE):
            newState = clearFormFailure(state);
            break;
        case (SET_MANUAL_USER):
            newState = setManualUser(state, action.userInfo);
            break;
        case (SET_ANONYMOUS_USER):
            newState = setAnonymousUser(state);
            break;
        case (CLEAR_FORM):
            newState = clearForm(state);
            break;
        case (GET_CURRENT_LOCATION_BEGIN):
            newState = getCurrentLocationBegin(state);
            break;
        case (GET_CURRENT_LOCATION_SUCCESS):
            newState = getCurrentLocationSuccess(state, action.currentLocation);
            break;
        case (GET_CURRENT_LOCATION_FAILURE):
            newState = getCurrentLocationFailure(state, action.error);
            break;
        case (CONTINUE_DRAFT_SET_DATA):
            newState = continueDraftSetData(state, action.shortCode, action.accidentId, action.formId);
            break;
        case (CONTINUE_DRAFT_REMOVE_DATA):
            newState = continueDraftRemoveData(state);
            break;
        case (LOAD_USER_DEPENDENT_DEFAULT_VALUES_SUCCESS):
            newState = loadReportingUserDependentDefaultValuesSuccess(state, action.defaultValues);
            break;
        case (SET_LOGGING_OFF):
            newState = setLoggingOff(state, action.isLoggingOff);
            break;
        case (SET_SSO_SETTINGS):
            newState = setSSOSettings(state, action.link, action.isSSOOnly, action.domainHint);
            break;
        case (SET_FROM_SSO):
            newState = setFromSSO(state, action.fromSSO);
            break;
        case (UPDATE_FORM_TYPE):
            newState = updateFormType(state, action.formType, action.formId);
            break;
        case (BASIC_AUTH_LOGIN_BEGIN):
            newState = basicAuthLoginBegin(state);
            break;
        case (BASIC_AUTH_LOGIN_SUCCESS):
            newState = basicAuthLoginSuccess(state);
            break;
        case (BASIC_AUTH_LOGIN_FAILURE):
            newState = basicAuthLoginFailure(state, action.basicAuthLoginError);
            break;
        case (CLEAR_BASIC_AUTH_LOGIN_ERROR):
            newState = clearBasicAuthLoginError(state);
            break;
        case (SET_CONTENT_DIMENSIONS):
            newState = setContentDimensions(state, action.height, action.width);
            break;
        case (SET_SPEECH_TO_TEXT_TOKEN_SETTINGS):
            newState = setSpeechToTextTokenSettings(state, action.token);
            break;
        case (SET_MIC_INUSE):
            newState = setMicInUse(state, action.inUse);
            break;
        case (SET_SAVED_ACCIDENTS):
            newState = setSavedAccidents(state, action.list);
            break;
        case (DELETE_DRAFT_START):
            newState = setDeleteDraftLoading(state, true);
            break;
        case (DELETE_DRAFT_END):
            newState = setDeleteDraftLoading(state, false);
            break;
        case TRANSLATION_LANGUAGES_LIST_SUCCESS:
            newState = produce(state, draftState => {
                const lowered = lowerCaseObjectKeys(action.payload);
                if (lowered['yue']) {
                    lowered["zh-hk"] = lowered['yue'];
                }
                draftState.languagesThatCanBeTranslatedByApi = lowered;
                draftState.translationLanguagesIsBeingProcessed = false;
            });
            break;
        case TRANSLATION_LANGUAGES_LIST_REQUEST_START:
            newState = {...state, translationLanguagesIsBeingProcessed: true};
            break;
        case TRANSLATION_LANGUAGES_LIST_FAILURE:
            newState = {...state, translationLanguagesIsBeingProcessed: false};
            break;
        case SET_SIMILAR_RECORDS_DATA:
            newState = setSimilarRecordsData(state, action.templateAttributeName, action.searchValue, action.recordsData);
            break;
        case SET_SIMILAR_RECORD_SUMMARY:
            newState = setSimilarRecordSummaries(state, action.accidentId, action.recordSummary);
            break;
        case SET_SUMMARY_MODAL_VALUE:
            newState = setSummaryModalState(state, action.value);
            break;
        case SET_SIMILAR_RECORDS_SPINNER_VALUE:
            newState = {...state, similarRecordsFieldSpinner: { [action.fieldName]: action.value }};
            break;
        case SET_USE_FORM_LOCATION_RESTRICTIONS:
            newState = {...state, useFormLocationRestrictions: action.useFormLocationRestrictions};
            break;
        case SET_ACTIVE_LOCATION_ID:
            newState = { ...state, activeLocationId: action.locationId };
            break;
        case SET_ACTIVE_LOCATION:
            newState = { ...state, activeLocation: action.location, activeLocationId: undefined };
            break;
        case USER_LOGGED_OUT:
            if (state.selectedFormTypeId) {
                newState = { ...state, activeLocationId: state.activeLocation && state.activeLocation.nodeExternalId, activeLocation: undefined };
            }
            else {
                newState = { ...state, activeLocation: undefined };
            }
            break;
        case CLEAR_ACTIVE_LOCATION:
            newState = { ...state, activeLocation: undefined };
            break;
        case RELOAD_FORMS:
            newState = { ...state, isFormListLoading: true };
            break;
        case UPDATE_FORM_TYPES:
            newState = updateFormTypes(state, action.summaryFormTypes);
            break;
        case LOAD_FORM:
            newState = { ...state, isFormLoading: true };
            break;
        case SHOW_LOCATION_RESTRICTION_USER_ERROR:
            newState = { ...state, showLocationRestrictionUserError: true };
            break;
        case CLEAR_LOCATION_RESTRICTION_USER_ERROR:
            newState = { ...state, showLocationRestrictionUserError: false };
            break;        
        default:
            newState = state;
            break;
    }

    return newState;
}

function getPersistedState(state) {
    return {
        selectedFormTypeId: state.selectedFormTypeId,
        formGroup: state.formGroup,
        anonGuid: state.anonGuid,
        draftAccidentId: state.draftAccidentId,
        draftShortCode: state.draftShortCode,
        isDraftSubmitted: state.isDraftSubmitted,
        submittedDraftId: state.submittedDraftId,
        isSubmitted: state.isSubmitted,
        submittedFormId: state.submittedFormId,
        authErr: state.authErr,
        showAuthErrorModal: state.showAuthErrorModal,
        allowAnonReporting: state.allowAnonReporting,
        loadingForm: state.loadingForm,
        appStrings: state.appStrings,
        activeLocation: state.activeLocation,
        initialActiveLocationId: state.initialActiveLocationId,
        accidentGuid: state.accidentGuid,
    };
};

function getLocalPersistedState(state) {
    return {
        fromSSO: state.fromSSO,
    }
}

export default {
    reducer,
    initialState,
    getPersistedState,
    getLocalPersistedState
};
