import _ from 'lodash';
import * as WizardActions from '../actions/wizardActions';
import {
    getCurrentPageFieldValidationRules,
    getCurrentPageElementIsMandatory,
    getFieldObjectPicklistValues,
    getFieldAnswerArray,
    getPossibleValues
} from '../selectors/wizardSelectors';
import { getCurrentPageFields, getCurrentPageCodedElements, getFieldAnswer, getElementAnswer, getIsUserPage, getIsReviewPage, getIsLastSubPage, getSubPageElementGUID } from '../selectors/wizardSelectors';
import { CODED_ELEMENT_TYPES } from '../constants/ElementTypes';
import { VALIDATION_TYPES, USER_PAGE_VALIDATION_TYPES } from '../constants/ValidationTypes';
import { getString, getFormUser, getSelectedForm, getProfileValue } from '../selectors/appSelectors';
import Strings from '../constants/Strings';
import { USER_TYPES } from '../constants/UserTypes';
import { FIELD_TYPES } from '../constants/FieldTypes';
import { isValidAnswer, isInvalidMultiLevelAnswer } from '../utils/convertAnswer';

const getFailedValidation = (rule,
                             answer,
                             state,
                             defaultMessage,
                             invalidMultiLevelAnswer,
                             isEmptyObjectPicklist) => {
    switch (rule.Type) {
        case VALIDATION_TYPES.MANDATORY:
        case VALIDATION_TYPES.MANDATORY_DATE:
        case VALIDATION_TYPES.MANDATORY_TIME:
            if ((!isValidAnswer(answer) || invalidMultiLevelAnswer) && !isEmptyObjectPicklist) {
                defaultMessage.val = getString(Strings.requiredField, state);
                return true;
            }
            break;
        case VALIDATION_TYPES.REGEX:
            const regex = RegExp(rule.Data);
            if (answer && !regex.test(answer)) {
                defaultMessage.val = getString(Strings.invalidValue, state);
                return true;
            }
            break;
        case VALIDATION_TYPES.MIN_LENGTH:
            const minLength = parseInt(rule.Data);
            if ((!isNaN(minLength) && answer && answer.length < parseInt(rule.Data)) || !isValidAnswer(answer))  {
                defaultMessage.val = `${getString(Strings.minimumLength, state)}: ${rule.Data}`;
                return true;
            }
            break;
        case VALIDATION_TYPES.MAX_LENGTH:
            const maxLength = parseInt(rule.Data);
            if ((!isNaN(maxLength) && answer && answer.length > parseInt(rule.Data)) || !isValidAnswer(answer)) {
                defaultMessage.val = `${getString(Strings.maximumLength, state)}: ${rule.Data}`;
                return true;
            }
            break;
        default:
            break;
    }

    return false;
};

const getSliderFailedValidation = (answer, field, state, next) => {

    const fieldProperties = _.isArray(field.Properties) ? field.Properties.reduce(function (properties, cur) {
        properties[cur.Name] = cur.Data;
        return properties;
    }, {}) : {};
    const min = fieldProperties.minimum;
    const max = fieldProperties.maximum;
    const step = fieldProperties.step;

    let failedValidation;
    if (answer > max) {
        failedValidation = { Alert: `${getString(Strings.maximum, state)} ${max}` };
    } else if (answer < min) {
        failedValidation = { Alert: `${getString(Strings.minimum, state)} ${min}` };
    }

    if (step && !failedValidation) {
        const rounded = Math.round(answer/step) * step;
        if (rounded !== answer) {
            next(WizardActions.updateFieldAnswer(field.TemplateAttribute.Name, rounded));
        }
    }

    return failedValidation;
}

const validateCurrentPage = (next, store, page) => {

    let state = store.getState();
    const { answers } = state.wizardReducer;

    const checkForNames = getProfileValue('Company.FirstReport.AzureNameDetection', state)
    const answersForNER = []

    const { selectedSubPage, selectedPage } = state.wizardReducer;

    let invalid = {
        fields: [],
        elements: [],
        pages: {
            USER_PAGE: []
        },
        scrollToFirst: ''
    }

    if (getIsUserPage(state)) {
        const selectedForm = getSelectedForm(state);
        const { userTypeSelected } = state.wizardReducer;
        if (userTypeSelected === -1 ||
            (
                !selectedForm.AllowAnonReporting && (userTypeSelected === USER_TYPES.ANONYMOUS || userTypeSelected === USER_TYPES.MANUAL)
            )
        ) {
            invalid.pages.USER_PAGE.push(USER_PAGE_VALIDATION_TYPES.USER_TYPE_SELECTION);
        } else if (userTypeSelected === USER_TYPES.MANUAL) {
            const user = getFormUser(state);

            const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
            const phoneRegex = /^[+]*[\s]*[0-9]*[\s]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

            if (selectedForm.ManualReportingNameMandatory && !user.name) {
                invalid.pages.USER_PAGE.push(USER_PAGE_VALIDATION_TYPES.MANUAL_NAME);
            }
            if ((selectedForm.ManualReportingEmailMandatory && !user.email) || (user.email && !emailRegex.test(user.email))) {
                invalid.pages.USER_PAGE.push(USER_PAGE_VALIDATION_TYPES.MANUAL_EMAIL);
            }
            if ((selectedForm.ManualReportingPhoneMandatory && !user.phone) || (user.phone && !phoneRegex.test(user.phone))) {
                invalid.pages.USER_PAGE.push(USER_PAGE_VALIDATION_TYPES.MANUAL_PHONE);
            }
        }
    }

    if (!getIsUserPage(state) && !getIsReviewPage(state)) {
        const elements = getCurrentPageCodedElements(state);
        elements.forEach(element => {
            const attributeName = element.Name;
            const answer = getElementAnswer(state, element.Guid);
            const elementAnswered = attributeName === CODED_ELEMENT_TYPES.ACCIDENTS_MAPS ?
                (answer && answer.locationId) || (answer && answer.hierarchyLocation && answer.hierarchyLocation.nodeExternalId)
                :
                isValidAnswer(answer);

            if (element.visible && getCurrentPageElementIsMandatory(attributeName, state) && !elementAnswered) {
                invalid.elements.push({
                    element: element.Guid,
                    message: getString(Strings.requiredField, state)
                });
                if (!invalid.scrollToFirst) {
                    invalid.scrollToFirst = 'element'
                }
            }
        });

        const fields = getCurrentPageFields(state);
        fields.forEach(field => {
            const attributeName = field.TemplateAttribute.Name;
            const answer = getFieldAnswer(state, attributeName);
            const answersForMultilingualField = getFieldAnswerArray(state, attributeName);

            let isEmptyObjectPicklist = false;
            if (field.TemplateAttribute.ObjectName) {
                const objectPicklistValues = getPossibleValues(state, field);
                isEmptyObjectPicklist = _.isEmpty(objectPicklistValues);
            }
            let invalidMultiLevelAnswer = false;
            if (field.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL || field.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL) {
                const possibleValues = getPossibleValues(state, field);
                invalidMultiLevelAnswer = isInvalidMultiLevelAnswer(answer, field, possibleValues);
            }

            if (field.visible) {
                const validationRules = getCurrentPageFieldValidationRules(attributeName, state);
                let defaultMessage = {val: ''};
                let failedValidation;
                // for multilingual answer will be undefined
                if (!answer && answersForMultilingualField.length) {
                    // if multilingual field - loop through and build answers for ner for each isocode
                    if (field.Properties && checkForNames && checkForNames === "Yes" && (field.RenderingType === FIELD_TYPES.TEXT_AREA || field.RenderingType === FIELD_TYPES.TEXT_INPUT)) {
                        const languages = field.Properties.find((item) => item.Name === 'languages');
                        if (languages && languages.Data) {
                            languages.Data.split(",").forEach((isoCode) => {
                                const multilingualAttributeName = `${field.TemplateAttribute.Name}.${isoCode}`
                                let multilingualAnswer = answers.fields[multilingualAttributeName];
                                if (isValidAnswer(multilingualAnswer)) {
                                    answersForNER.push({ answer: multilingualAnswer, name: field.Title ? `${field.Title} (${isoCode})` : multilingualAttributeName});
                                }
                            });
                        }
                    }

                    const failedValidations = answersForMultilingualField.map(((answer) => {
                        return validationRules.find((rule) =>
                        getFailedValidation(rule, answer, state, defaultMessage, invalidMultiLevelAnswer,
                            isEmptyObjectPicklist));
                    }));

                    const validValueExists = failedValidations.some((validation) => !validation);
                    if (!validValueExists) {
                        //take any to show error
                        failedValidation = failedValidations[0];
                    }
                } else if (field.RenderingType === FIELD_TYPES.SLIDER) {
                    failedValidation = getSliderFailedValidation(answer, field, state, next);
                } else {

                    if (answer && checkForNames && checkForNames === "Yes" && (field.RenderingType === FIELD_TYPES.TEXT_AREA || field.RenderingType === FIELD_TYPES.TEXT_INPUT)) {
                        answersForNER.push({ answer, name: field.Title ? field.Title : field.TemplateAttribute.Name })
                    }

                    failedValidation = validationRules.find((rule) =>
                        getFailedValidation(rule, answer, state, defaultMessage, invalidMultiLevelAnswer,
                            isEmptyObjectPicklist));
                }

                if (failedValidation) {
                    invalid.fields.push({
                        field: attributeName,
                        message: (failedValidation.Alert !== field.Title) ? failedValidation.Alert : defaultMessage.val
                    });
                    if (!invalid.scrollToFirst) {
                        invalid.scrollToFirst = 'field';
                    }
                }
            }
        });
    }

    const isValidPage = !(invalid.fields.length > 0 || invalid.elements.length > 0 || invalid.pages.USER_PAGE.length > 0)
    const nerCheck = (checkForNames && checkForNames === "Yes" && answersForNER && answersForNER.length > 0)

    if (!getIsUserPage(state) && !getIsReviewPage(state) && isValidPage && nerCheck &&
        ((!page && page !== 0) || //Check if no page is selected from the sidemenu
        page > selectedPage || //Only validate if moving forward from sidemenu
        ((page >= selectedPage || !page) && selectedSubPage !== -1)) ) { //Validation on subform when moving forwards only
        next(WizardActions.checkForNameEntity(answersForNER, page));
    } else {
        handlePageChange(next, store, page, invalid);
    }
}

const handlePageChange = (next, store, page, invalid) => {

    const state = store.getState();
    const { selectedSubPage, selectedPage } = state.wizardReducer;

    if (!invalid) {
        invalid = {
            fields: [],
            elements: [],
            pages: {
                USER_PAGE: []
            },
            scrollToFirst: ''
        }
    }

    if (page !== undefined) {
        if (page > selectedPage && (invalid.fields.length > 0 || invalid.elements.length > 0 || invalid.pages.USER_PAGE.length > 0)) {
            next(WizardActions.setInvalid(invalid));
        } else {
            next(WizardActions.goToPage(page));
        }
    } else if (selectedSubPage === -1) {
        if (invalid.fields.length > 0 || invalid.elements.length > 0 || invalid.pages.USER_PAGE.length > 0) {
            next(WizardActions.setInvalid(invalid));
        } else {
            next(WizardActions.goToNextPage());
        }
    } else {
        const subPageElementGUID = getSubPageElementGUID(state);
        const isLastSubPage = getIsLastSubPage(state);
        const selectedSubPageObject = state.wizardReducer.selectedSubPageObject;

        if (invalid.fields.length > 0 || invalid.elements.length > 0) {
            next(WizardActions.setInvalid(invalid, true));
        } else {
            if (isLastSubPage) {
                if (selectedSubPageObject > -1) {
                    next(WizardActions.updateSubPageObject(subPageElementGUID, selectedSubPageObject));
                } else {
                    next(WizardActions.addSubPageObject(subPageElementGUID));
                }
            } else {
                next(WizardActions.goToNextSubPage());
            }
        }
    }
}


const ValidationService = store => next => (action) => {
    next(action);

    switch (action.type) {
        case WizardActions.GO_TO_NEXT_PAGE_BEGIN:
        case WizardActions.GO_TO_NEXT_SUB_PAGE_BEGIN:
        case WizardActions.GO_TO_PAGE_BEGIN:
            validateCurrentPage(next, store, action.page);
            break;
        case WizardActions.MOVE_TO_PAGE:
            handlePageChange(next, store, undefined, undefined)
            break;
        default:
            break;
    }
};

export default ValidationService;
