import React from 'react';
import BodyParts from './BodyParts';
import './BodyMap.scss';
import BodyMapPartRow from './BodyMapPartRow/BodyMapPartRow';
import BodyMapBody from './BodyMapBody/BodyMapBody';
import { TextInput } from 'spheracloud-common';
import { CheckboxUnchecked, Search } from '../../../icons';
import _ from 'lodash';

class BodyMap extends React.Component {
    constructor(props) {
        super(props);

        const { initialSelection } = this.props;

        let selection = initialSelection || {};

        this.state = {
            bodyPartSearch: '',
            injuredBodyParts: selection,
            injuryTypeDropdown: ''
        };

        this.setBodyPartSearch = this.setBodyPartSearch.bind(this);
        this.selectBodyPart = this.selectBodyPart.bind(this);
        this.selectInjuryTypeDropdownClick = this.selectInjuryTypeDropdownClick.bind(this);
        this.selectInjuryTypeClick = this.selectInjuryTypeClick.bind(this);
    }

    setBodyPartSearch(searchTerm) {
        this.setState({
            bodyPartSearch: searchTerm
        });
    }

    selectBodyPart(part) {
        const { onChange } = this.props;
        const { injuredBodyParts } = this.state;

        let newInjuredBodyParts = (part in injuredBodyParts) ?
            _.pickBy(injuredBodyParts, (value, key) => {
                return key !== part.toString();
            })
            :
            {
                ...injuredBodyParts,
                [part]: []
            }

        this.setState({
            injuredBodyParts: newInjuredBodyParts
        }, onChange && onChange(newInjuredBodyParts));
    }

    selectInjuryTypeDropdownClick(part) {
        const { injuryTypeDropdown } = this.state;
        this.setState({
            injuryTypeDropdown: injuryTypeDropdown === part ? '' : part
        });
    }

    selectInjuryTypeClick(selectedPart, selectedInjuryType) {
        const { onChange } = this.props;
        const { injuredBodyParts } = this.state;

        let partInjuries = injuredBodyParts[selectedPart];
        const newInjuries = partInjuries.indexOf(selectedInjuryType) > -1 ?
            partInjuries.filter(injuryType => {
                return injuryType !== selectedInjuryType;
            })
            :
            [
                ...partInjuries,
                selectedInjuryType
            ];

        const newInjuredBodyParts = {
            ...injuredBodyParts,
            [selectedPart]: newInjuries
        };

        this.setState({
            injuredBodyParts: newInjuredBodyParts
        }, onChange && onChange(newInjuredBodyParts));
    }

    render() {
        const { injuredBodyParts, bodyPartSearch, injuryTypeDropdown } = this.state;

        return (
            <div className="BodyMapContainer">
                <div className="BodyMapImage">
                    {
                        <BodyMapBody selected={injuredBodyParts} onPartClick={this.selectBodyPart}/>
                    }
                </div>
                <div className="BodyMapPartListContainer">
                    <div className="BodyMapPartListSearch">
                        <TextInput
                            placeholder="Search body parts..."
                            onTextChanged={this.setBodyPartSearch}
                            leftIcon={<img src={Search} alt="Search" className="BodyMapPartListSearchLeftIcon"/>}

                            />
                    </div>
                    {
                        Object.keys(injuredBodyParts).length > 0 ?
                            <div className="BodyMapPartListSelected">
                                {
                                    BodyParts.body.filter(part => {
                                        return part.id in injuredBodyParts;
                                    }).map(part => {
                                        const partInjuries = injuredBodyParts[part.id];

                                        return <BodyMapPartRow
                                            key={part.id}
                                            onClick={() => { this.selectBodyPart(part.id) }}
                                            onInjuryTypeDropdownClick={() => { this.selectInjuryTypeDropdownClick(part.id) }}
                                            onInjuryTypeClick={this.selectInjuryTypeClick}
                                            part={part}
                                            partInjuries={partInjuries}
                                            injuryDropdownOpen={injuryTypeDropdown === part.id}
                                        />
                                    })
                                }
                            </div>
                            :
                            ''
                    }
                    <div className="BodyMapPartList">
                        {
                            BodyParts.body.filter(part => {
                                return !(part.id in injuredBodyParts) && (bodyPartSearch === "" || part.name.toLowerCase().indexOf(bodyPartSearch) > -1);
                            }).map(part => {
                                return <div key={part.id} className="BodyMapPartContainer" onClick={() => { this.selectBodyPart(part.id) }}>
                                    <img className="BodyMapPartCheckbox" src={CheckboxUnchecked} alt="checkbox" />
                                    <div className="BodyMapPartName">{part.name}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>


        );
    }
}

export default BodyMap;
