import React, { Component } from 'react';
import style from './SubmitPage.module.scss';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { HelpCircle } from '../../icons';
import { PrimaryButton, Modal } from 'spheracloud-common';
import { clearFormFailure, clearFormTypeAndStayLoggedIn, clearFormTypeAndLogout } from '../../actions/appActions';
import { getSubmittedFormId, getFormUser, getProfileValue } from '../../selectors/appSelectors';
import Strings from '../../constants/Strings';
import Translation from '../Translation/Translation';
import { USER_TYPES } from '../../constants/UserTypes';
import { getNodeFromString } from '../../utils/utils';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

class SubmitPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            helpTextModalOpen: false,
        };

        this.toggleHelpTextModal = this.toggleHelpTextModal.bind(this);
    }

    toggleHelpTextModal(open) {
        this.setState({
            helpTextModalOpen: open
        })
    }

    getSubmitPageContent() {
        const { isSubmitting, isSubmitted, submitError, submittedFormId } = this.props;

        if (isSubmitting) {
            return <div className={style.SubmitPageStatus}><Translation>{Strings.submitting}</Translation></div>
        }
        else if (isSubmitted) {
            return <>
                <div className={style.SubmitPageSubmittedIcon}></div>
                <div className={style.SubmitPageTitle}>
                    <Translation replace={{ FormId: submittedFormId }}>{Strings.submitPageTitle}</Translation>
                </div>
                <div className={style.SubmitPageHelpText}>
                    <Translation>{Strings.submitPageHelpText}</Translation>
                </div>
            </>
        } else {
            const errorText = submitError && `: ${submitError}`;
            return (
                <div className={style.SubmitPageStatus}>
                    {
                        isSubmitting ?
                            <Translation>{Strings.submitting}</Translation>
                            :
                            <>
                                <Translation>{Strings.failedToSubmit}</Translation>
                                {errorText}
                            </>
                    }
                </div>
            )
        }
    }

    render() {
        const { clearFormTypeAndLogout, clearFormTypeAndStayLoggedIn, fromSSO, clearFormFailure, submitError, isSubmitting, user, companySupportDetails, allowMultipleSubmitSSO } = this.props;
        const { helpTextModalOpen } = this.state;
        const tooltipName = 'company-support-details';
        const isMobileScreen = window.innerWidth <= 480;

        const helpButtonAttributes = isMobileScreen ? {
            'onClick': () => { this.toggleHelpTextModal(true) }
        } : {
                'data-tip': true,
                'data-for': tooltipName,
                'data-event': 'click mouseenter',
                'data-event-off': 'mouseleave'
            }

        return (
            <div className={style.SubmitPage}>
                <div className={style.SubmitPageHeader}>
                    <CompanyLogo />
                    {
                        companySupportDetails &&
                        <>
                            <div className="SubmitPageHeaderRightSection">
                                <div className={style.SubmitPageHelpContainer} {...helpButtonAttributes}>
                                    <img src={HelpCircle} alt="Help" className="SubmitPageHelp" />
                                </div>
                                {
                                    helpTextModalOpen &&
                                    <Modal
                                        apply={""}
                                        cancel={""}
                                        onApply={() => { }}
                                        onCancel={() => { this.toggleHelpTextModal(false) }}
                                        className="HelpTextModal"
                                    >
                                        {getNodeFromString(companySupportDetails)}
                                    </Modal>
                                }
                                <ReactTooltip id={tooltipName} effect="solid" place="bottom" type="light" globalEventOff="click" delayShow={1} delayHide={1} clickable={true}>
                                    {getNodeFromString(companySupportDetails)}
                                </ReactTooltip>
                            </div>
                        </>
                    }

                </div>
                <div className={style.SubmitPageContent}>
                    {this.getSubmitPageContent()}
                </div>
                {(!isSubmitting) && (
                    <div className={style.SubmitPageFooter}>
                        {(user.type === USER_TYPES.AUTHENTICATED || user.type === USER_TYPES.B2CAUTHENTICATED) && !submitError &&
                            <PrimaryButton className={style.DoneButton} onClick={() => clearFormTypeAndLogout()}>
                                <Translation>{Strings.logout}</Translation>
                            </PrimaryButton>
                        }
                        <PrimaryButton className={style.DoneButton} onClick={() => submitError ? clearFormFailure() : !fromSSO || (allowMultipleSubmitSSO && allowMultipleSubmitSSO === 'Yes') ? clearFormTypeAndStayLoggedIn() : clearFormTypeAndLogout()}>
                            <Translation>{submitError ? Strings.back : !fromSSO || (allowMultipleSubmitSSO && allowMultipleSubmitSSO === 'Yes') ? Strings.startANewReport : Strings.logout}</Translation>
                        </PrimaryButton>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { appReducer } = state;

    return {
        isSubmitting: appReducer.isSubmitting,
        isSubmitted: appReducer.isSubmitted,
        submitError: appReducer.errors.submitForm,
        user: getFormUser(state),
        submittedFormId: getSubmittedFormId(state),
        companySupportDetails: getProfileValue("Company.Support.Details", state),
        allowMultipleSubmitSSO: getProfileValue("Company.FirstReport.AllowMultipleSubmitSSO", state),
        fromSSO: appReducer.fromSSO,
    };
};

const mapDispatchToProps = dispatch => ({
    clearFormTypeAndLogout: () => {
        dispatch(clearFormTypeAndLogout())
    },
    clearFormTypeAndStayLoggedIn: () => {
        dispatch(clearFormTypeAndStayLoggedIn())
    },
    clearFormFailure: () => {
        dispatch(clearFormFailure());
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitPage);
