import * as WizardActions from '../actions/wizardActions';
import { getNextPageIndex, getPreviousPageIndex } from '../selectors/wizardSelectors';

const goToPreviousPage = (next, store) => {
    const state = store.getState();
    const previousPageIndex = getPreviousPageIndex(state);

    next(WizardActions.goToPage(previousPageIndex));
};

const goToNextPage = (next, store) => {
    const state = store.getState();
    const nextPageIndex = getNextPageIndex(state);

    next(WizardActions.goToPage(nextPageIndex));
};

const PageService = store => next => (action) => {
    next(action);

    switch (action.type) {
        case WizardActions.GO_TO_PREVIOUS_PAGE:
            goToPreviousPage(next, store);
            break;
        case WizardActions.GO_TO_NEXT_PAGE:
            goToNextPage(next, store);
            break;
        default:
            break;
    }
};

export default PageService;