import _ from 'lodash';
import { USER_TYPES } from '../constants/UserTypes'
import { DEFAULT_VALUE_TYPES, DEFAULT_VALUE_SOURCE_TYPES } from '../constants/DefaultValueTypes';
import { EN_GB } from '../constants/LanguageFlags';
import { isLoggedInUserType } from '../utils/utils';

export const getAppSettings = store => store.getState().appReducer.appSettings;

export const getAppSettingsFromState = state => state.appReducer.appSettings;

export const getAvailableLanguages = state => state.appReducer.availableLanguages

export const getSelectedForm = state => {
    return state.appReducer.formTypes && state.appReducer.formTypes.find(type => {
        return type.Id === state.appReducer.selectedFormTypeId;
    });
}

export const isFormLoaded = (formId, state) => {
    return state.appReducer.formTypes && state.appReducer.formTypes.find(type => {
        return type.Id === formId;
    }) !== undefined;
}

export const getSelectedTemplateFormName = state => {
    return getSelectedForm(state).TemplateFormName;
}

export const getDraftAccidentId = state => state.appReducer.draftAccidentId;
export const getDraftShortCode = state => state.appReducer.draftShortCode;
export const getSSOUrl = state => state.appReducer.linkForSSO;

export const getAccidentGuid = state => state.appReducer.accidentGuid;

export const getElements = (formId, state) => {
    const form = state.appReducer.formTypes && state.appReducer.formTypes.find(type => {
        return type.Id === formId;
    });

    if (!form) {
        return [];
    }

    return Object.values(form.WizardPages)
        .flatMap(w => Object.values(w.Elements));
}

export const getAllFormFields = (formId, state) => {
    return getElements(formId, state)
        .flatMap(e => e.Fields);
}

export const getAllFormFieldAttributeNames = (formId, state) => {
    return _.flatten(getAllFormFields(formId, state).map(field => {
        const fieldName = field.TemplateAttribute.Name;
        const result = [fieldName];
        if (field.Properties) {
            const languages = field.Properties.find((item) => item.Name === 'languages');
            if (languages && languages.Data) {
                languages.Data.split(",").forEach((isoCode) => {
                    if (isoCode !== EN_GB) {
                        const multilingualAttributeName = `${fieldName}.${isoCode}`
                        result.push(multilingualAttributeName);
                    }
                });
            }
        }

        return result;
    }));
}

export const getFormField = (formId, attributeName, state) => {
    return getAllFormFields(formId, state).find(f => f.TemplateAttribute.Name === attributeName || attributeName.match(`^${f.TemplateAttribute.Name}.[a-zA-Z]{2}-[a-zA-Z-]*$`));
}

export const getObjectPicklistFormFields = (formId, state) => {
    return getAllFormFields(formId, state).filter(field => {
        return !!field.TemplateAttribute.ObjectName || (!_.isEmpty(field.ColumnNames));
    });
}

export const getProfileValue = (profileName, state) => (state.appReducer.companyProfiles && state.appReducer.companyProfiles[profileName]) || '';

export const getRelatedObjectElements = (formId, relatedObjectElementGuid, state) => {
    const form = state.appReducer.formTypes && state.appReducer.formTypes.find(type => {
        return type.Id === formId;
    });

    if (!form) {
        return [];
    }

    return Object.values(form.WizardPages).filter(w => w.WizardSubForms && w.Elements && w.Elements[0] && w.Elements[0].Guid === relatedObjectElementGuid).flatMap(w => {
        const allSubFormPages = w.WizardSubForms.flatMap(f => f.WizardPages);
        return Object.values(allSubFormPages).flatMap(s => Object.values(s.Elements));
    });
}

export const getAllRelatedObjectFields = (formId, relatedObjectElementGuid, state) => {
    return getRelatedObjectElements(formId, relatedObjectElementGuid, state).flatMap(e => e.Fields);
}

export const getAllRelatedObjectAttributeNames = (formId, relatedObjectElementGuid, state) => {
    return getAllRelatedObjectFields(formId, relatedObjectElementGuid, state).map(f => f.TemplateAttribute.Name);
}

export const getRelatedObjectField = (formId, relatedObjectElementGuid, attributeName, state) => {
    return getAllRelatedObjectFields(formId, relatedObjectElementGuid, state).find(f => f.TemplateAttribute.Name === attributeName);
}

export const getSpeechToTextTokenValue = (state) => {
    return state.appReducer.firstReportSettings.speechToTextSettings ? state.appReducer.firstReportSettings.speechToTextSettings : '';
}

export const getFirstReportSettings = (state) => {
    return state.appReducer.firstReportSettings;
}

export const getFormUser = state => {

    const type = state.wizardReducer.userTypeSelected;

    if(state.auth.user) {
        return {
            type,
            email: state.auth.user && state.auth.user.profile ? state.auth.user.profile.name : '',
            ...getAuthInfo(state)
        }
    }
    else if(type === USER_TYPES.MANUAL) {
        const { userInfo } = state.appReducer;
        return {
            type,
            ...userInfo
        }
    }
    else {
        return {
            type
        }
    }
}


export const getUserFromProfile = state => {
    const companyId = state.appReducer.appSettings.companyId;
    const users = state.auth.user ? JSON.parse(state.auth.user.profile.users) : null;
    let user;

    if (companyId && users) {
        const userFromProfile = users.find(company => company.CompanyID === companyId);
        user = (userFromProfile) || null;
    }

    return user;
};

export const isAuthenticated = state => {
    return (isLoggedInUserType(state.wizardReducer.userTypeSelected) && state.auth.user);
}

export const getFormGroup = state => state.appReducer.formGroup;

export const getSelectedFormTypeId = state => state.appReducer.selectedFormTypeId;

export const getSubmittedFormId = state => state.appReducer.submittedFormId;

export const getSubmittedDraftId = state => state.appReducer.submittedDraftId;

export const getAnonGuid = state => state.appReducer.anonGuid;

export const getAuthInfo = state => state.appReducer.authInfo;

export const getString = (string, state) => state.appReducer.appStrings[string] || string;

export const getDefaultValues = (state) => {
    return state.appReducer.formTypes ? state.appReducer.formTypes.flatMap(formType => {
        return formType.FormDefaultValues;
    }) : [];
}

export const getReportingUserDependentDefaultValues = (state) => {
    return getDefaultValues(state).filter(d => {
        return d.DefaultValueType === DEFAULT_VALUE_TYPES.CURRENT_FORM_SOURCE &&
        d.SourceType === DEFAULT_VALUE_SOURCE_TYPES.CURRENT_USER;
    });
}

export const getSavedAccidents = (state) => {
    return state.appReducer.savedAccidents;
}

export const getUseFormLocationRestrictions = state => state.appReducer.useFormLocationRestrictions;

export const getActiveLocation = state => state.appReducer.activeLocation;

export const getActiveLocationId = state => state.appReducer.activeLocationId || (state.appReducer.activeLocation && state.appReducer.activeLocation.nodeExternalId);

export const getActiveLocationPath = (state) => {
    const activeLocation = getActiveLocation(state);
    let path = '';

    if (activeLocation && activeLocation.path) {
        path =  `${state.appReducer.activeLocation.path.map(p=>p.nodeName).join('/')}`;

        if (path.substring(path.length - activeLocation.nodeName.length, path.length) !== activeLocation.nodeName) {
            path = `${path}/${activeLocation.nodeName}`;
        }
    }

    return path;
}

export const getIsFormListLoading = state => state.appReducer.isFormListLoading;

export const isVanityUrl = (state) => !state.appReducer.appSettings.serverUrl.toLowerCase().includes(window.location.host.toLowerCase());

export const getDefaultUserTypeId = state => {
    const selectedForm = getSelectedForm(state);
    return selectedForm?.ShowAnonReportingOption ? USER_TYPES.ANONYMOUS : -1;
}