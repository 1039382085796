import React from 'react';
import _ from 'lodash';
import './LocationSelectorWithSiteMap.scss';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { HelpCircle } from '../../../icons';
import { Map, SiteMap, Modal } from 'spheracloud-common';
import { getCurrentLocation } from '../../../actions/appActions';
import { SingleChooser } from '../../Field/Chooser/Chooser';
import TabButtonBar from '../../TabButtonBar/TabButtonBar';
import Strings from '../../../constants/Strings';
import { getSiteMapImage, getSiteMapImageList, loadHierarchySiteMapAttributes } from '../../../actions/wizardActions'
import { getString, getAppSettingsFromState } from '../../../selectors/appSelectors';
import endpoints from '../../../constants/Endpoints';
import Translation from '../../../containers/Translation/Translation';
import HierarchyLocationSelector from '../HierarchyLocationSelector/HierarchyLocationSelector';
import SiteMapPicker from './SiteMapPicker/SiteMapPicker';

class LocationSelectorWithSiteMap extends React.Component {
    constructor(props) {
        super(props);

        const { initialLocation, currentLocation, elementGuid, isHierarchy, getSiteMapImageList, loadHierarchySiteMapAttributes } = this.props;
        const latitude = (initialLocation && initialLocation.latitude) || (currentLocation && currentLocation.latitude);
        const longitude = (initialLocation && initialLocation.longitude) || (currentLocation && currentLocation.longitude);
        const documentID = initialLocation && initialLocation.documentID;

        this.state = {
            mapDimensions: props.contentDimensions,
            selectedLocation: {
                locationId: initialLocation && initialLocation.locationId,
                hierarchyLocation: initialLocation && initialLocation.hierarchyLocation,
                hierarchyLocationUpdated: false,
                latitude,
                longitude,
                hotspotX: initialLocation && initialLocation.hotspotX,
                hotspotY: initialLocation && initialLocation.hotspotY,
                documentID,
                selectedMap: (initialLocation && initialLocation.selectedMap) || 0,
            },
            helpTextModalOpen: false,
        };

        if (isHierarchy) {
            loadHierarchySiteMapAttributes(initialLocation.hierarchyLocation && initialLocation.hierarchyLocation.nodeExternalId, elementGuid);
        }

        const locationId = isHierarchy ? initialLocation && initialLocation.hierarchyLocation && initialLocation.hierarchyLocation.nodeExternalId : initialLocation && initialLocation.locationId;
        if (locationId) {
            getSiteMapImageList(locationId, elementGuid);
        }

        this.locationSelectorContainer = React.createRef();
        this.map = React.createRef();
        this.selectMap = this.selectMap.bind(this);
        this.selectSiteMap = this.selectSiteMap.bind(this);
        this.handleLocationChooserChange = this.handleLocationChooserChange.bind(this);
        this.handleSiteMapClick = this.handleSiteMapClick.bind(this);
        this.handleMapClickData = this.handleMapClickData.bind(this);
        this.updateMapCamera = this.updateMapCamera.bind(this);
        this.scrollLocationSelectorIntoView = this.scrollLocationSelectorIntoView.bind(this);
    }

    selectMap(selectedMap) {
        const { onChange } = this.props;
        const { mapDimensions } = this.state;

        const newLocation = {
            ...this.state.selectedLocation,
            selectedMap
        }
        this.setState({ selectedLocation: newLocation }, () => {
            onChange && onChange(newLocation);
            if (selectedMap === 0 && this.map.current && mapDimensions) {
                // fix for case when loading map initially as hidden, has incorrect size when displayed
                this.map.current.resize(Math.max(mapDimensions.height - 160, 240), mapDimensions.width);
            }
        });
    }

    selectSiteMap(documentID) {
        const { onChange, elementGuid, getSiteMapImage } = this.props;
        const { selectedLocation } = this.state;

        if (selectedLocation && selectedLocation.documentID !== documentID) {
            const newLocation = {
                ...selectedLocation,
                documentID,
                hotspotX: undefined,
                hotspotY: undefined,
            }
            this.setState({ selectedLocation: newLocation }, () => {
                onChange && onChange(newLocation);
                getSiteMapImage(documentID, elementGuid);
            });
        }
    }

    toggleHelpTextModal(open) {
        this.setState({
            helpTextModalOpen: open
        })
    }

    handleLocationChooserChange(chosenLocation) {
        const { onChange, elementGuid, items, isHierarchy, loadHierarchySiteMapAttributes } = this.props;
        const { selectedLocation } = this.state;

        let locationId, hierarchyLocation, latitude, longitude;

        if (isHierarchy) {
            hierarchyLocation = chosenLocation;
            const newLocation = {
                ...selectedLocation,
                hierarchyLocation,
                documentID: undefined,
                hotspotX: undefined,
                hotspotY: undefined,
            }
            this.setState({ selectedLocation: newLocation }, () => {
                onChange && onChange(newLocation);
                loadHierarchySiteMapAttributes(hierarchyLocation && hierarchyLocation.nodeExternalId, elementGuid);
            });
        } else {
            locationId = chosenLocation;

            const location = items.find(i => {
                return i.value === locationId
            });

            latitude = location && location.latitude;
            longitude = location && location.longitude;

            const newLocation = {
                ...selectedLocation,
                locationId,
                hierarchyLocation,
                documentID: undefined,
                hotspotX: undefined,
                hotspotY: undefined,
                latitude: latitude ? latitude : selectedLocation.latitude,
                longitude: longitude ? longitude : selectedLocation.longitude,
            }

            this.setState({ selectedLocation: newLocation }, () => {
                onChange && onChange(newLocation);
                getSiteMapImageList(locationId, elementGuid);

                this.updateMapCamera();
            });
        }
    }

    handleSiteMapClick(data) {
        const { onChange, disabled } = this.props;

        if (!disabled) {
            const newLocation = {
                ...this.state.selectedLocation,
                hotspotX: data.x,
                hotspotY: data.y
            }
            this.setState({ selectedLocation: newLocation }, onChange && onChange(newLocation));
        }
    }

    handleMapClickData(data) {
        const { onChange } = this.props;

        if (!data || data.length !== 2) {
            return;
        }
        const newLocation = {
            ...this.state.selectedLocation,
            longitude: data[0],
            latitude: data[1]
        }
        this.setState({ selectedLocation: newLocation }, onChange && onChange(newLocation));
    }

    scrollLocationSelectorIntoView() {
        this.locationSelectorContainer.current && this.locationSelectorContainer.current.scrollIntoView({ block: 'nearest' });
    }

    componentDidMount() {
        const { initialLocation, currentLocation, getCurrentLocation, onChange, disabled } = this.props;

        if (!initialLocation && !disabled) {
            if (!currentLocation) {
                getCurrentLocation();
            } else {
                onChange && onChange(currentLocation);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { currentLocation, onChange, initialLocation, getSiteMapImageList, getSiteMapImage, elementGuid, isHierarchy, contentDimensions, siteMapImageList } = this.props;
        const { selectedLocation } = this.state;
        const prevLat = prevProps.currentLocation && prevProps.currentLocation.latitude;
        const prevLong = prevProps.currentLocation && prevProps.currentLocation.longitude;

        if (currentLocation &&
            (prevLat !== currentLocation.latitude || prevLong !== currentLocation.longitude)) {
            const newLocation = {
                ...selectedLocation,
                latitude: currentLocation.latitude,
                longitude: currentLocation.longitude,
            }
            this.setState({
                selectedLocation: newLocation
            }, onChange && onChange(newLocation));
        } else if (isHierarchy && initialLocation && initialLocation.hierarchyLocationUpdated) {
            const newLocation = {
                ...selectedLocation,
                ...(initialLocation.latitude && initialLocation.longitude ? { latitude: initialLocation.latitude, longitude: initialLocation.longitude } : {}),
                hierarchyLocationUpdated: false,
            }

            this.setState({
                selectedLocation: newLocation
            }, () => {
                onChange && onChange(newLocation);
                getSiteMapImageList(newLocation.hierarchyLocation && newLocation.hierarchyLocation.nodeExternalId, elementGuid);

                this.updateMapCamera();
            });
        }

        if (!_.isEqual(prevProps.siteMapImageList, siteMapImageList)) {
            if (selectedLocation.documentID && selectedLocation.documentID !== -1) {
                getSiteMapImage(selectedLocation.documentID, elementGuid);
            } else if (_.isArray(siteMapImageList) && siteMapImageList.length > 0) {
                const firstDocumentID = siteMapImageList[0].id;
                const newLocation = {
                    ...selectedLocation,
                    documentID: firstDocumentID
                }
                this.setState({
                    selectedLocation: newLocation
                }, getSiteMapImage(firstDocumentID, elementGuid));
            } else {
                const newLocation = {
                    ...selectedLocation,
                    documentID: undefined
                }
                this.setState({
                    selectedLocation: newLocation
                }, getSiteMapImage(undefined, elementGuid));
            }
        }

        if (!prevProps.contentDimensions && contentDimensions) {
            this.setState({
                mapDimensions: contentDimensions
            });
        } else if (prevProps.contentDimensions && contentDimensions) {
            const prevHeight = prevProps.contentDimensions.height;
            const prevWidth = prevProps.contentDimensions.width;
            if (prevHeight !== contentDimensions.height || prevWidth !== contentDimensions.width) {
                this.setState({
                    mapDimensions: contentDimensions
                });
                this.map.current && this.map.current.resize(Math.max(contentDimensions.height - 160, 240), contentDimensions.width)
            }
        }
    }

    updateMapCamera() {
        if (this.map.current) {
            this.map.current.updateCamera();
        }
    }

    renderMap() {
        const { host, mapRoute, disabled } = this.props;
        const { mapDimensions, selectedLocation } = this.state;
        const points = selectedLocation && selectedLocation.latitude && selectedLocation.longitude ? [[selectedLocation.longitude, selectedLocation.latitude]] : [];

        return (
            <Map
                points={points}
                proxyRoute={mapRoute}
                captureClickData={this.handleMapClickData}
                host={host}
                zoom={12}
                width={mapDimensions.width}
                height={Math.max(mapDimensions.height - 160, 240)}
                deployFolder={''}
                ref={this.map}
                disabled={disabled}
                lightTheme={true}
            />
        )
    }

    render() {
        const { isLoadingCurrentLocation, placeholder, items, noResultsText, hasError, siteMapImage, disabled, isHierarchy,
            siteMapImageListLoading, siteMapImageLoading, siteMapLoadingError, noLocationSelectedError, contentDimensions, elementGuid,
            siteMapImageList, hierarchySiteMapAttributesLoading } = this.props;
        const { mapDimensions, selectedLocation, helpTextModalOpen } = this.state;
        const locationReady = !!(selectedLocation && selectedLocation.latitude && selectedLocation.longitude) && !isLoadingCurrentLocation;
        const selectedMap = selectedLocation && selectedLocation.selectedMap;
        const tooltipName = 'site-map-help-text';
        const isMobileScreen = window.innerWidth <= 480;
        const mapHeight = mapDimensions ? Math.max(mapDimensions.height - 160, 240) : 400;
        const maxDropdownHeight = contentDimensions && contentDimensions.height ? Math.min(contentDimensions.height - 60, 400) : 400;
        const locationId = isHierarchy ? selectedLocation && selectedLocation.hierarchyLocation && selectedLocation.hierarchyLocation.nodeExternalId : selectedLocation && selectedLocation.locationId;
        const siteMapError = locationId ? siteMapLoadingError : noLocationSelectedError;

        const helpButtonAttributes = isMobileScreen ? {
            'onClick': () => { this.toggleHelpTextModal(true) }
        } : {
                'data-tip': true,
                'data-for': tooltipName,
                'data-event': 'click mouseenter',
                'data-event-off': 'mouseleave'
            }

        const singleChooserItemsForSiteMap = items && items.map(item => ({
            value: item.value,
            text: item.text,
            selected: selectedLocation.locationId === item.value,
            imageId: item.imageId
        }));

        return (
            <div className="LocationSelectorWithSiteMapContainer">
                <div ref={this.locationSelectorContainer}>
                    {
                        isHierarchy ?
                            <HierarchyLocationSelector
                                hasError={hasError}
                                initialLocation={selectedLocation.hierarchyLocation}
                                onChange={this.handleLocationChooserChange}
                                onHeightChange={this.scrollLocationSelectorIntoView}
                                disabled={disabled} />
                            :
                            <SingleChooser
                                placeholder={placeholder}
                                items={singleChooserItemsForSiteMap}
                                noResultsText={noResultsText}
                                onSelectionChanged={this.handleLocationChooserChange}
                                hasError={hasError}
                                showDropdownOnClick={true}
                                maxHeight={`${maxDropdownHeight}px`}
                                disabled={disabled}
                            />
                    }
                </div>
                <div className="LocationSelectorWithSiteMapTabBarContainer">
                    <div className="LocationSelectorWithSiteMapTabBarLeft">
                        <TabButtonBar onChange={this.selectMap} initialSelected={selectedMap} buttons={[{ text: <Translation>{Strings.globalMap}</Translation> }, { text: <Translation>{Strings.facilityMap}</Translation> }]} />
                        {
                            selectedMap === 1 && _.isArray(siteMapImageList) && siteMapImageList.length > 1 &&
                            <SiteMapPicker options={siteMapImageList} selected={selectedLocation.documentID} onChange={this.selectSiteMap} elementGuid={elementGuid} />
                        }
                    </div>
                    <div className="LocationSelectorWithSiteMapHelpContainer" {...helpButtonAttributes}>
                        <img src={HelpCircle} alt="Help" className="StartPageHelp" />
                    </div>
                    {
                        helpTextModalOpen &&
                        <Modal
                            apply={""}
                            cancel={""}
                            onApply={() => { }}
                            onCancel={() => { this.toggleHelpTextModal(false) }}
                            className="HelpTextModal"
                        >
                            <Translation>{Strings.siteMapHelpText}</Translation>
                        </Modal>
                    }
                    <ReactTooltip id={tooltipName} effect="solid" place="left" type="light" globalEventOff="click" delayShow={1} delayHide={1} clickable={true}>
                        <Translation>{Strings.siteMapHelpText}</Translation>
                    </ReactTooltip>
                </div>
                {
                    mapDimensions &&
                    <div className="LocationMapContainer" style={{ height: `${mapHeight}px` }}>
                        <>
                            <div className="LocationSiteMap" style={{ display: selectedMap !== 1 ? "none" : "" }}>
                                <SiteMap
                                    onClickFunction={this.handleSiteMapClick}
                                    image={siteMapImage}
                                    point={{
                                        x: selectedLocation.hotspotX,
                                        y: selectedLocation.hotspotY
                                    }
                                    }
                                    width={mapDimensions.width}
                                    height={mapHeight}
                                    disabled={disabled}
                                    isLoading={hierarchySiteMapAttributesLoading || siteMapImageListLoading || siteMapImageLoading}
                                    loadingError={siteMapError}
                                />
                            </div>
                            <div className="LocationMapMap" style={{ display: selectedMap === 1 ? "none" : "" }}>
                                {mapDimensions && locationReady && this.renderMap()}
                            </div>
                        </>
                    </div>
                }
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { appReducer, wizardReducer } = state;
    const appSettings = getAppSettingsFromState(state);
    return {
        host: endpoints.getMapHost(appSettings),
        mapRoute: endpoints.getMapRoute(appSettings),
        currentLocation: appReducer.currentLocation,
        noResultsText: appReducer.appStrings[Strings.noResults],
        siteMapImageList: wizardReducer.siteMapImageLists[ownProps.elementGuid],
        siteMapImageListLoading: wizardReducer.siteMapImageListLoading.includes(ownProps.elementGuid),
        siteMapImage: wizardReducer.siteMaps[ownProps.elementGuid],
        siteMapImageLoading: wizardReducer.siteMapLoading.includes(ownProps.elementGuid),
        siteMapLoadingError: getString(Strings.siteMapLoadingError, state),
        hierarchySiteMapAttributesLoading: wizardReducer.hierarchySiteMapAttributesLoading.includes(ownProps.elementGuid),
        noLocationSelectedError: getString(Strings.noLocationSelectedError, state),
        contentDimensions: state.appReducer.contentDimensions,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentLocation: () => {
            dispatch(getCurrentLocation());
        },
        getSiteMapImage: (imageId, elementGuid) => {
            dispatch(getSiteMapImage(imageId, elementGuid));
        },
        getSiteMapImageList: (locationId, elementGuid) => {
            dispatch(getSiteMapImageList(locationId, elementGuid));
        },
        loadHierarchySiteMapAttributes: (locationId, elementGuid) => {
            dispatch(loadHierarchySiteMapAttributes(locationId, elementGuid));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectorWithSiteMap);
