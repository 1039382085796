import  React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { searchSimilarRecord, showSimilarRecordSummary } from '../../actions/appActions';
import useDebounce from '../../utils/useDebounce';
import SimilarRecordsGrid from './SimilarRecordsGrid';
import { combineSimilarRecordKey } from '../../utils/utils';

const { useEffect } = React;

const SimilarRecordsSearch = (props) => {
  const debouncedValue = useDebounce(props.searchValue, 1000);

  useEffect(()=>{
    if(debouncedValue && debouncedValue.length > 1) {
      props.startSearch(props.templateAttributeName, props.similarRecordsViewId, debouncedValue, props.isRestictedSearchToUserLocation);
    }
  }, [debouncedValue, props.templateAttributeName]);

  const onSummaryView = (accidentId) => {
    props.loadSummary(accidentId);
  };

  const renderGridData = () => {
    return <SimilarRecordsGrid
            columns={props.gridData.Columns}
            onRecordSelected={onSummaryView}
            selectedLanguage={props.selectedLanguage}
            result={props.gridData.Result.sort((a, b) => {
              return new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime();
            })}
            />;
  }

  return props.gridData
    ? renderGridData()
    : <></>;
};

SimilarRecordsSearch.propTypes = {
    templateAttributeName: PropTypes.string,
    similarRecordsViewId: PropTypes.number,
    searchValue: PropTypes.string,
    isRestictedSearchToUserLocation: PropTypes.bool,
    gridData: PropTypes.object,
    startSearch: PropTypes.func,
    loadSummary: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  const key = combineSimilarRecordKey(ownProps.templateAttributeName, ownProps.searchValue);
  return { 
    gridData: state.appReducer.similarRecords[key],
    selectedLanguage: state.appReducer.userSettings.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startSearch: (templateAttributeName, viewId, searchValue, isSearchResctictedToUserLocation) => {dispatch(searchSimilarRecord(templateAttributeName, viewId, searchValue, isSearchResctictedToUserLocation));},
  loadSummary: (accidentId) => {dispatch(showSimilarRecordSummary(accidentId))}
});

export default connect(mapStateToProps, mapDispatchToProps)(SimilarRecordsSearch);