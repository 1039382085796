import React, { Component } from 'react';
import '../StartPage/StartPage.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setAuthType, loginUserWithToken } from '../../actions/authActions';
import { setFormGroup, setAnonGuid, allowAnonReporting, loadCompanyLogo, loadSSOSettings } from '../../actions/appActions';
import { USER_TYPES } from '../../constants/UserTypes';
import { Spinner } from 'spheracloud-common';
import queryString from 'query-string';
import { getAppSettingsFromState } from '../../selectors/appSelectors';

class Login extends Component {

    componentDidMount() {
        const { setFormGroup, setAnonGuid, allowAnonReporting, setAuthType, loginUserWithToken, match, history, loadCompanyLogo, appSettings, loadSSOSettings } = this.props;

        setFormGroup(match.params.formGroup);
        setAnonGuid(match.params.anonGuid);
        loadCompanyLogo();

        if (this.props.location.search.includes("?token=")) {
            const parsed = queryString.parse(this.props.location.search);

            if (parsed.allowAnonymous === "0") {
                allowAnonReporting(false);
            }

            loginUserWithToken(parsed.token);
        }
        else {
            allowAnonReporting(false);

            if (appSettings.AUTHENTICATED_USER_SETTINGS.ISB2C === 'true') {
                loadSSOSettings(true);
            } else {
                setAuthType(USER_TYPES.AUTHENTICATED, history);
            }
        }
    }

    render() {
        return (
            <div className="StartPage">
                <div className="StartPageContent">
                    <Spinner />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        appSettings: getAppSettingsFromState(state)
    }
}
const mapDispatchToProps = dispatch => ({
    setAuthType: (userType, history) => {dispatch(setAuthType(userType, history))},
    loginUserWithToken: (token) => {dispatch(loginUserWithToken(token))},
    setFormGroup: (className) => {dispatch(setFormGroup(className))},
    setAnonGuid: (guid) => {dispatch(setAnonGuid(guid))},
    allowAnonReporting: (allow) => {dispatch(allowAnonReporting(allow))},
    loadCompanyLogo: () => {dispatch(loadCompanyLogo())},
    loadSSOSettings: (redirectToB2C) => {dispatch(loadSSOSettings(redirectToB2C))},
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));