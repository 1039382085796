import _ from 'lodash';
import * as AppActions from '../actions/appActions';
import * as WizardActions from '../actions/wizardActions';
import HttpClient from '../utils/httpClient';
import endpoints from '../constants/Endpoints';
import {
    getAppSettings,
    getReportingUserDependentDefaultValues,
    isAuthenticated,
    getAuthInfo,
    getFormField
} from '../selectors/appSelectors';
import {
    getLocationDependentDefaultValues,
    getFieldDependentDefaultValues,
    getFromUserGroupDefaultValues,
    getStaticDefaultValues,
    getFieldAnswer
} from '../selectors/wizardSelectors';
import { FIELD_TYPES } from '../constants/FieldTypes';
import { ATTRIBUTE_TYPES } from '../constants/AttributeTypes';
import { DEFAULT_VALUE_STATIC_TYPES } from '../constants/DefaultValueTypes';
import { convertFromDbDate, convertFromDbTime, convertFromDbValue } from '../utils/convertAnswer';

const loadReportingUserDependentDefaultValues = async (next, store) => {

    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);

    const reportingUserDependentDefaultValues = getReportingUserDependentDefaultValues(state);
    const isUserAuthenticated = isAuthenticated(state);

    setCurrentUserDefaultValues(isUserAuthenticated, next, store);

    let defaultValues = {};

    if (isUserAuthenticated) {
        const authInfo = getAuthInfo(state);
        const userId = authInfo && authInfo.userId;

        let attributes = [];
        for (let defaultValue of reportingUserDependentDefaultValues) {
            attributes.push(defaultValue.SourceValue);
        }
        const attributeList = _.uniq(attributes).join(',');

        if(attributeList) {
            try {
                const response = await httpClient.get(endpoints.getRecord(appSettings, isUserAuthenticated, 'Rivo.RiskEngine.Users', userId, attributeList));
                defaultValues = response.data.attributeValues;
            } catch (error) {
                next(AppActions.loadReportingUserDependentDefaultValuesFailure(error));
            }
        }
    }

    next(AppActions.loadReportingUserDependentDefaultValuesSuccess(defaultValues));
}

const loadFieldDependentDefaultValues = async (field, value, next, store) => {

    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);

    const fieldId = field.TemplateAttribute.Name;
    const dataSource = field.TemplateAttribute.DataSource;
    const fieldDependentDefaultValues = getFieldDependentDefaultValues(state, state.appReducer.selectedFormTypeId, fieldId);

    if (dataSource && fieldDependentDefaultValues.length > 0) {
        let values;
        if (value) {
            let attributes = [];
            for (let defaultValue of fieldDependentDefaultValues) {
                attributes.push(defaultValue.SourceValue);
            }

            const isUserAuthenticated = isAuthenticated(state);
            const attributeList = _.uniq(attributes).join(',');

            try {
                const response = await httpClient.get(endpoints.getRecord(appSettings, isUserAuthenticated, dataSource, value, attributeList));
                values = response.data.attributeValues;
            } catch (error) {
                next(WizardActions.loadFieldDependentDefaultValuesFailure(error));
            }
        }

        for (let defaultValue of fieldDependentDefaultValues) {
            const answerSource = values && values[defaultValue.SourceValue];
            const answer = answerSource && Array.isArray(answerSource) ? answerSource[0] : answerSource;
            next(WizardActions.updateFieldAnswer(defaultValue.TemplateAttribute, answer));
        }
    }

    if (field.RenderingType === FIELD_TYPES.MATRIX) {
        const properties = convertArrayToObject(field.Properties, 'Name');
        const cells = JSON.parse(properties.cells);
        const currentCell = cells.filter(c => c !== null && c.value === value)[0];
        let score = 0;
        let rating = '';
        if (currentCell) {
            if (currentCell.score) {
                score = currentCell.score;
            }

            if (currentCell.rating) {
                rating = currentCell.rating;
            }
        }

        next(WizardActions.updateFieldAnswer(properties.scoreAttributeTitle, score));
        next(WizardActions.updateFieldAnswer(properties.ratingAttributeTitle, rating));
    }
}

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
        ...obj,
        [item[key]]: item['Data'],
        };
    }, initialValue);
};

const loadAndPopulateLocationAttributes = async (locationId, setIfEmptyOnly, next, store) => {
    
    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);
    let locationDependentDefaultValues = getLocationDependentDefaultValues(state, state.appReducer.selectedFormTypeId);    
    const fromUserGroupDefaultValues = getFromUserGroupDefaultValues(state, state.appReducer.selectedFormTypeId);
    if (setIfEmptyOnly && locationDependentDefaultValues && locationDependentDefaultValues.length) {
        locationDependentDefaultValues = locationDependentDefaultValues.filter(
            defaultValue => !getFieldAnswer(state, defaultValue.TemplateAttribute))
    }
    const isUserAuthenticated = isAuthenticated(state);
    const language = state.appReducer.userSettings.language;

    if (fromUserGroupDefaultValues.length > 0) {
        let uGroupValues;
        if (locationId) {
            let uGroupArray = [];

            for (const uGroupDefault of fromUserGroupDefaultValues) {
                uGroupArray.push({ "TemplateAttribute": uGroupDefault.TemplateAttribute, "SourceValue": uGroupDefault.SourceValue })
            }

            try {
                const uGroupResponse = await httpClient.post(endpoints.searchUsersInGroups(appSettings, isUserAuthenticated, locationId), uGroupArray);
                uGroupValues = uGroupResponse.data;
            } catch (error) {
                next(WizardActions.loadLocationAttributesFailure(error));
            }
        }

        for (const uGroupName of fromUserGroupDefaultValues) {
            const uGroupValue = uGroupValues && uGroupValues.find(u => u.TemplateAttribute === uGroupName.TemplateAttribute);
            next(WizardActions.updateFieldAnswer(uGroupName.TemplateAttribute, uGroupValue && uGroupValue.UserIds));
        }
    }

    if (locationDependentDefaultValues.length > 0) {
        let values;
        if (locationId) {
            let attributes = [];

            for (const defaultValue of locationDependentDefaultValues) {
                attributes.push(defaultValue.SourceValue);
            }

            const attributeList = _.uniq(attributes).join(',');

            try {
                const response = await httpClient.get(endpoints.getRecord(appSettings, isUserAuthenticated, 'Rivo.RiskEngine.Locations', locationId, attributeList));
                values = response.data.attributeValues;
            } catch (error) {
                next(WizardActions.loadLocationAttributesFailure(error));
            }
        }

        for (const defaultValue of locationDependentDefaultValues) {
            let answer = values && values[defaultValue.SourceValue];

            const targetField = defaultValue.TemplateAttribute && getFormField(state.appReducer.selectedFormTypeId, defaultValue.TemplateAttribute, state);
            const targetTemplateAttribute = targetField && targetField.TemplateAttribute;

            if (targetTemplateAttribute && targetTemplateAttribute.Type === ATTRIBUTE_TYPES.PICKLIST && !targetTemplateAttribute.DataSource) {
                const response = await httpClient.post(endpoints.requestResolveLabel(appSettings, isUserAuthenticated,language), { [defaultValue.SourceValue]: answer });
                const tavValues = response && response.data && response.data[defaultValue.SourceValue];

                const currentValue = tavValues &&
                    targetTemplateAttribute.PossibleValues
                        .filter(possibleItem => {
                            return tavValues.find(valueItem => possibleItem.Label === valueItem.Label);
                        })
                        .map(item => item.Identifier);

                answer = currentValue && currentValue.length > 0 ? currentValue : undefined;

                if (targetField.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL
                    || targetField.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL) {
                    answer = convertFromDbValue(answer, targetField, state);
                }
            }

            if (Array.isArray(answer) && answer.length === 1 && targetField
                && !(targetField.RenderingType === FIELD_TYPES.MULTI_CHOOSER
                    || targetField.RenderingType === FIELD_TYPES.CHECKLIST
                    || targetField.RenderingType === FIELD_TYPES.CHECKLIST_MULTI_LEVEL
                    || targetField.RenderingType === FIELD_TYPES.DROPDOWN_MULTI_LEVEL)) {
                answer = answer[0];
            }

            if (targetField) {
                switch (targetField.RenderingType) {
                    case FIELD_TYPES.DATE:
                        answer = answer && convertFromDbDate(answer);
                        break;
                    case FIELD_TYPES.TIME:
                        answer = answer && convertFromDbTime(answer);
                        break;
                    case FIELD_TYPES.MULTI_CHOOSER, FIELD_TYPES.CHECKLIST, FIELD_TYPES.CHECKLIST_MULTI_LEVEL, FIELD_TYPES.DROPDOWN_MULTI_LEVEL:
                        answer = !answer || answer === '' ? [] : answer;
                        break;
                }
            }

            if (defaultValue.TemplateAttribute) {
                next(WizardActions.updateFieldAnswer(defaultValue.TemplateAttribute, answer));
            }
        }
    }
}

const loadHierarchySiteMapAttributes = async (elementGuid, locationId, next, store) => {

    const state = store.getState();
    const httpClient = new HttpClient(state);
    const appSettings = getAppSettings(store);

    if (locationId) {
        next(WizardActions.loadHierarchySiteMapAttributesBegin(elementGuid));
        const isUserAuthenticated = isAuthenticated(state);

        try {
            const coordsResponse = await httpClient.get(endpoints.getRecord(appSettings, isUserAuthenticated, 'Rivo.RiskEngine.Locations', locationId, 'Locations.Latitude,Locations.Longitude'));
            const coords = coordsResponse.data.attributeValues;

            const query = {
                condition: {
                    attributeName: 'RelatedDocuments.RelatedID',
                    operator: 0, // equal
                    value: locationId
                }
            };

            const imageResponse = await httpClient.post(endpoints.getRelatedDocuments(appSettings, isAuthenticated(state)), query);
            const documentID = imageResponse && imageResponse.data && imageResponse.data.data && imageResponse.data.data[0] && imageResponse.data.data[0].id;
            const data = {
                latitude: coords['Locations.Latitude'],
                longitude: coords['Locations.Longitude'],
                documentID: documentID || undefined
            }

            next(WizardActions.loadHierarchySiteMapAttributesSuccess(data, elementGuid));

        } catch (error) {
            next(WizardActions.loadHierarchySiteMapAttributesFailure(elementGuid));
        }
    } else {
        next(WizardActions.loadHierarchySiteMapAttributesFailure(elementGuid));
    }
}

const AttributeService = store => next => (action) => {
    next(action);

    switch (action.type) {
        case AppActions.LOAD_USER_DEPENDENT_DEFAULT_VALUES:
            loadReportingUserDependentDefaultValues(next, store);
            break;
        case WizardActions.LOAD_FIELD_DEPENDENT_DEFAULT_VALUES:
            loadFieldDependentDefaultValues(action.field, action.value, next, store);
            break;
        case WizardActions.LOAD_LOCATION_ATTRIBUTES:
            loadAndPopulateLocationAttributes(action.locationId, !!action.setIfEmptyOnly, next, store);
            break;
        case WizardActions.LOAD_HIERARCHY_SITEMAP_ATTRIBUTES:
            loadHierarchySiteMapAttributes(action.elementGuid, action.locationId, next, store);
            break;
        default:
            break;
    }
};

export default AttributeService;

function setCurrentUserDefaultValues (isUserAuthenticated, next, store) {
    const state = store.getState();
    if (state.appReducer.selectedFormTypeId && state.appReducer.selectedFormTypeId !== -1) {
        const staticDefaultValues = getStaticDefaultValues(state, state.appReducer.selectedFormTypeId);

        const userId = getAuthInfo(state).userId;
        for (let staticValue of staticDefaultValues) {
            const targetField = getFormField(state.appReducer.selectedFormTypeId, staticValue.TemplateAttribute, state);
            if (staticValue.SourceType === DEFAULT_VALUE_STATIC_TYPES.CURRENT_USER && targetField && targetField.RenderingType === FIELD_TYPES.SINGLE_CHOOSER) {
                if (isUserAuthenticated) {                    
                    next(WizardActions.updateFieldAnswer(staticValue.TemplateAttribute, userId));                    
                    loadFieldDependentDefaultValues(targetField, userId, next, store);
                } else {
                    next(WizardActions.updateFieldAnswer(staticValue.TemplateAttribute));
                }
            }
        }
    }
}
