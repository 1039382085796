export const VALIDATION_TYPES = {
    MANDATORY: "Mandatory",
    MANDATORY_DATE: "MandatoryDate",
    MANDATORY_TIME: "MandatoryTime",
    MIN_LENGTH: "MinimumLength",
    MAX_LENGTH: "MaximumLength",
    REGEX: "RegularExpression",
}

export const USER_PAGE_VALIDATION_TYPES = {
    USER_TYPE_SELECTION: "UserTypeSelection",
    MANUAL_NAME: "ManualName",
    MANUAL_EMAIL: "ManualEmail",
    MANUAL_PHONE: "ManualPhone",
}