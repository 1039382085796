export const ELEMENT_TYPES = {
    CODED: 1,
    DESIGNER: 2
}

export const CODED_ELEMENT_TYPES = {
    RELATED_DOCUMENTS: 'RelatedDocuments',
    BODY_MAP: 'BodyMap',
    MAP_COORDS: 'GeoMapCoordinates',
    THIRD_PARTIES: 'ThirdParties',
    LOCATION_SELECTOR: 'Locations',
    SPLIT_LOCATION_SELECTOR: 'SplitLocations',
    ACCIDENTS_MAPS: 'AccidentsMaps'
}