import React from 'react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { v4 } from 'uuid';
import { FIELD_TYPES } from '../constants/FieldTypes';
import { ELEMENT_TYPES, CODED_ELEMENT_TYPES } from '../constants/ElementTypes';
import BodyParts from '../components/Element/BodyMap/BodyParts';
import BodyPartInjuryTypes from '../components/Element/BodyMap/BodyPartInjuryTypes';
import { Attachment, Link } from '../icons';
import Translation from '../containers/Translation/Translation';
import strings from '../constants/Strings';
import SiteMapPreview from '../components/SiteMapPreview/SiteMapPreview';
import { getMultiLevelStructure } from './convertAnswer';
import reviewPageStyles from '../containers/ReviewPage/ReviewPageItem/ReviewPageItem.module.scss';
import {checkIsLive} from "./utils";

export function formatFieldAnswer(answer, field, selectedLanguage,
                                  liveSearchOptions, resolveLabelBegin, resolvedLabels, objectPicklistValues, possibleValues) {
    const attribute = field.TemplateAttribute;
    const attributePossibleValues = possibleValues == undefined ? attribute.PossibleValues: possibleValues;
    switch (field.RenderingType) {
        case FIELD_TYPES.TEXT_INPUT:
        case FIELD_TYPES.TEXT_AREA:
        case FIELD_TYPES.NUMERIC:
        case FIELD_TYPES.FLOAT:
        case FIELD_TYPES.LOCATION:
            return answer;

        case FIELD_TYPES.DATE:
            return moment(answer, 'DD/MM/YYYY').locale(selectedLanguage).format('l');

        case FIELD_TYPES.TIME:
            return field.Is12Hour ? moment(answer, "HH:mm").format("hh:mm A") : answer;

        case FIELD_TYPES.TOGGLE:
            return answer
                ? <Translation>{strings.yes}</Translation>
                : <Translation>{strings.no}</Translation>

        case FIELD_TYPES.DROPDOWN:
        case FIELD_TYPES.RADIO_BUTTONS:
            let selectedItem = attributePossibleValues.find(v => v.Identifier === answer || v.DataGuid === answer);
            if ((!selectedItem || !selectedItem.Label) && Array.isArray(objectPicklistValues)) {
                selectedItem = objectPicklistValues.find(v => v.Identifier === answer);
            }
            return selectedItem && selectedItem.Label;

        case FIELD_TYPES.SINGLE_CHOOSER:
            let selectedChooserItem = ((liveSearchOptions &&
                liveSearchOptions[field.TemplateAttribute.Name] &&
                liveSearchOptions[field.TemplateAttribute.Name].Items) || []
            ).find(o => answer.includes ? answer.includes(o.value) : (answer === o.path || answer === o.value));
            const labelFromResolved = _.get(resolvedLabels, [[field.TemplateAttribute.Name], [answer]]);
            if (answer && !selectedChooserItem) {
                if (!labelFromResolved) {
                    // answer is not present in options, need to resolve by API
                    resolveLabelBegin({[field.TemplateAttribute.Name]: [answer]});
                } else {
                    return labelFromResolved;
                }
            }
            return selectedChooserItem && (selectedChooserItem.text || selectedChooserItem.Label);

        case FIELD_TYPES.CHECKLIST:
        case FIELD_TYPES.BUTTON_SELECT:
        case FIELD_TYPES.MULTI_CHOOSER:
            const selectedItems = attributePossibleValues
                .filter(v => answer.includes(v.Identifier))
                .map(v => v.Label);
            return selectedItems.join(', ');

        case FIELD_TYPES.CHECKLIST_MULTI_LEVEL:
        case FIELD_TYPES.DROPDOWN_MULTI_LEVEL:
            const isLiveSearch = checkIsLive(field);
            if (isLiveSearch) {
                const selectedOptions = ((liveSearchOptions &&
                    liveSearchOptions[field.TemplateAttribute.Name] &&
                    liveSearchOptions[field.TemplateAttribute.Name].Items) || []
                ).filter(o => answer.includes ? answer.includes(o.value) : (answer === o.path || answer === o.value));
                const labelFromResolved = _.get(resolvedLabels, [[field.TemplateAttribute.Name], [answer]]);
                if (answer && !selectedOptions.length) {
                    if (!labelFromResolved) {
                        // answer is not present in options, need to resolve by API
                        resolveLabelBegin({[field.TemplateAttribute.Name]: [answer]});
                    } else {
                        return renderMultiLevelAnswerIsLive([{value: answer, text: labelFromResolved}]);
                    }
                }
                return renderMultiLevelAnswerIsLive(selectedOptions);
            } else {
                return renderMultiLevelAnswer(answer, attribute, field.RenderingType, objectPicklistValues);
            }

        default:
            return answer;
    }
}

function renderMultiLevelAnswerIsLive(liveOptions) {
    const renderLevel = (options) => {

        return <div className="ReviewPageSummaryItemLevel">
            {
                options.map(o => o.text.replace(/:/gi, '>')).join(', ')
            }
        </div>
    };

    return <div className={reviewPageStyles.ReviewPageSummaryMultiLevelContainer}>
        {
            renderLevel(liveOptions)
        }
    </div>
}

function renderMultiLevelAnswer(answer, attribute, renderingType, objectPicklistValues) {

    const possibleValues = (!_.isEmpty(objectPicklistValues) && objectPicklistValues) || (attribute && attribute.PossibleValues);
    const options = (Array.isArray(possibleValues)) ? getMultiLevelStructure(possibleValues.map(v => ({
        value: v.Identifier,
        label: v.Label
    }))) : [];

    const renderLevel = (options, parentLabel) => {
        const selected = options.filter(o => answer.includes ? answer.includes(o.path) : (answer === o.path || answer === o.value));

        return <div className="ReviewPageSummaryItemLevel">
            {
                renderingType !== FIELD_TYPES.DROPDOWN_MULTI_LEVEL && selected.length > 0 && parentLabel &&
                <span className={reviewPageStyles.ReviewPageLevelLabel}>{parentLabel}:</span>
            }
            {
                selected.map(o => o.label).join(', ')
            }
            {
                selected.map(o => {
                    return <div key={v4()}>
                        {
                            o.children && o.children.length > 0 && renderLevel(o.children, o.label)
                        }
                    </div>
                })
            }
        </div>
    }

    return <div className={reviewPageStyles.ReviewPageSummaryMultiLevelContainer}>
        {
            renderLevel(options)
        }
    </div>
}

export function formatElementAnswer(answer, element, isHierarchy) {

    switch (element.Name) {
        case CODED_ELEMENT_TYPES.RELATED_DOCUMENTS:
            const relatedDocs = answer.filter(file => !file.deleted && !file.hasError);
            const documentTypes = _.uniq(relatedDocs.map(file => {
                if (file.isLink) {
                    return 'link';
                } else if (file.type && file.type.split('/')[0] === 'image') {
                    return 'image';
                } else {
                    return 'other';
                }
            }));

            return <div className={reviewPageStyles.ReviewPageRelatedDocuments}>
                {
                    documentTypes.map(type => {
                        switch (type) {
                            case 'image':
                                const images = relatedDocs.filter(file => file.type && file.type.split('/')[0] === 'image');
                                return images.map(file => {
                                    return <div key={v4()} className={reviewPageStyles.ReviewPageRelatedDocumentImage} style={{backgroundImage: `url("${URL.createObjectURL(file)}")`}}></div>
                                })
                            case 'link':
                                const links = relatedDocs.filter(file => file.isLink);
                                return <div key={v4()} className={reviewPageStyles.ReviewPageRelatedDocument} style={{backgroundImage: `url("${Link}")`}}>
                                    <div className={reviewPageStyles.ReviewPageRelatedDocumentCount}>{links.length}</div>
                                </div>
                            default:
                                const docs = relatedDocs.filter(file => !file.isLink && !(file.type && file.type.split('/')[0] === 'image'));
                                return <div key={v4()} className={reviewPageStyles.ReviewPageRelatedDocument} style={{backgroundImage: `url("${Attachment}")`}}>
                                    <div className={reviewPageStyles.ReviewPageRelatedDocumentCount}>{docs.length}</div>
                                </div>
                        }

                    })
                }
            </div>

        case CODED_ELEMENT_TYPES.BODY_MAP:
            return BodyParts.body.map(part => {
                return answer[part.id] ?
                    <div key={part.id} className={reviewPageStyles.ReviewPageItemBodyMapPart}>
                        <div className={reviewPageStyles.ReviewPageItemPrimaryText}>{part.name}</div>
                        <div className={reviewPageStyles.ReviewPageItemSecondaryText}>
                            {
                                answer[part.id].map(injury => {
                                    return BodyPartInjuryTypes.find(type => {
                                        return type.id === injury
                                    }).name
                                }).join(', ')
                            }
                        </div>
                    </div>
                    :
                    ''
            }).filter(part => {
                return part !== '';
            });


        case CODED_ELEMENT_TYPES.MAP_COORDS:
            return (answer && answer.latitude && answer.longitude)
                && `(${answer.latitude.toFixed(3)}, ${answer.longitude.toFixed(3)})`

        case CODED_ELEMENT_TYPES.LOCATION_SELECTOR:
        case CODED_ELEMENT_TYPES.SPLIT_LOCATION_SELECTOR:
            if (isHierarchy) {
                return <div className="ReviewPageHierarchyPath">
                    <div>{answer && answer.nodeName}</div>
                    <div className="ReviewPageHierarchyPathNode">
                        {
                            answer && answer.path && (
                                " " + answer.path
                                    .filter((hierarchyLocation, index) => { return !(index === answer.path.length - 1 && hierarchyLocation.nodeName === answer.nodeName) })
                                    .map(hierarchyLocation => {
                                        return hierarchyLocation.nodeName;
                                    })
                                    .join(' / ')
                            )
                        }
                    </div>
                </div>
            } else {
                const locationValues = element.Fields && element.Fields[0] && element.Fields[0].TemplateAttribute && element.Fields[0].TemplateAttribute.PossibleValues;
                const selectedValue = locationValues && locationValues.find(v => v.Identifier === answer);
                return selectedValue && selectedValue.Label;
            }

        case CODED_ELEMENT_TYPES.ACCIDENTS_MAPS:

            let selectedLocation;
            const coordinates = (answer && answer.latitude && answer.longitude) ? `(${answer.latitude.toFixed(3)}, ${answer.longitude.toFixed(3)})` : '';

            if (isHierarchy) {
                const hierarchyLocation = answer.hierarchyLocation;
                selectedLocation = <div className="ReviewPageHierarchyPath">
                    <div>{hierarchyLocation && hierarchyLocation.nodeName}</div>
                    <div className="ReviewPageHierarchyPathNode">
                        {
                            hierarchyLocation && hierarchyLocation.path && (
                                " " + hierarchyLocation.path
                                    .filter((hierarchyLoc, index) => { return !(index === hierarchyLocation.path.length - 1 && hierarchyLoc.nodeName === hierarchyLocation.nodeName) })
                                    .map(hierarchyLocation => {
                                        return hierarchyLocation.nodeName;
                                    })
                                    .join(' / ')
                            )
                        }
                    </div>
                </div>
            } else {
                const locationField = element.Fields && element.Fields.find(f => f.TemplateAttribute.Name === 'Accidents.LocationID');
                const locationValues = locationField && locationField.TemplateAttribute && locationField.TemplateAttribute.PossibleValues;
                const selectedLocationValue = locationValues && locationValues.find(v => v.Identifier === answer.locationId);
                selectedLocation = selectedLocationValue && selectedLocationValue.Label;
            }

            return <div>
                {
                    selectedLocation && <div>{selectedLocation}</div>
                }
                {
                    coordinates && <div>{coordinates}</div>
                }
                <SiteMapPreview element={element} answer={answer}/>
            </div>

        default:
            return answer;
    }
}

export function getSubPageObjectAnswer(subForms, subPageObject, attributeName) {
    const subPages = subForms.find(form => form.Id === subPageObject.formId).WizardPages;

    const elementList = subPages.reduce((elements, page) => {
        return elements.concat(page.Elements.filter(element => { return element.Type !== ELEMENT_TYPES.DESIGNER }));
    }, []);

    const fieldList = subPages.reduce((fields, page) => {
        return fields.concat(
            page.Elements.reduce((elementArr, element) => {
                return elementArr.concat(element.Fields);
            }, [])
        );
    }, [])

    const element = elementList.find(element => {
        return element.Name === attributeName;
    });
    const field = fieldList.find(field => {
        return field.TemplateAttribute.Name === attributeName;
    });

    if (element && subPageObject.elements && subPageObject.elements[attributeName]) {
        return formatElementAnswer(subPageObject.elements[attributeName], element);
    } else if (field && subPageObject.fields && subPageObject.fields[attributeName]) {
        return formatFieldAnswer(subPageObject.fields[attributeName], field);
    } else {
        return '';
    }
}
