import React, { Component } from 'react';
import style from './WizardPageWithAdd.module.scss';
import { connect } from 'react-redux';
import { ArrowRight, AddCircle } from '../../../icons';
import { getCurrentParentPage, getCurrentSubFormPages, getCurrentPageSubPageObjects, getSubPageElementGUID, getSubPageAddButtonTitle } from '../../../selectors/wizardSelectors';
import { ActionButton } from 'spheracloud-common';
import { selectSubForm, goToSubPage, selectSubPageObject, removeSubPageObject, moveToPage, clearNERWarning } from '../../../actions/wizardActions';
import WizardPageElementList from '../WizardPageElementList/WizardPageElementList';
import SubPageSummary from '../../../containers/SubPageSummary/SubPageSummary';
import classNames from 'classnames';
import { getProfileValue } from '../../../selectors/appSelectors';

class WizardPageWithAdd extends Component {

    render() {
        const { mainPage, subForms, currentSubFormPages, selectedSubPage, selectSubForm, goToSubPage, addedObjects, selectSubPageObject,
            removeSubPageObject, subPageElementGUID, subPageAddButtonTitle, nameDetectionActive, namesDetected, moveToPage, clearNERWarning } = this.props;

        const breadcrumbs = [
            mainPage.MenuLabel,
            ...(currentSubFormPages ? currentSubFormPages.filter((subPage, index) => {
                return index <= selectedSubPage;
            }).map(subPage => {
                return subPage.MenuLabel;
            }) : [])
        ]

        return (
            <>
                <div className={style.WizardPageBreadcrumbs}>
                    {
                        breadcrumbs.map((item, index) => {
                            const classes = [style.WizardPageBreadcrumbItem];
                            if (selectedSubPage === index - 1) {
                                classes.push(style.WizardPageBreadcrumbItemSelected);
                            }
                            return <div key={index} className={classNames(classes)} onClick={() => goToSubPage(index - 1)}>{item}</div>
                        }).reduce((result, item) => {
                            return [
                                result,
                                <div key={item} className={style.WizardPageBreadcrumbIcon}><img src={ArrowRight} alt="arrow" /></div>,
                                item
                            ]
                        })
                    }
                </div>
                {
                    selectedSubPage > -1 ?
                        <WizardPageElementList page={currentSubFormPages[selectedSubPage]} clearNERWarning={clearNERWarning}  moveToPage={moveToPage} namesDetected={namesDetected} nameDetectionActive={nameDetectionActive} />
                        :
                        <>
                            <div className="WizardPageTitle">{mainPage.ScreenTitle}</div>
                            <div className="WizardPageHelpText">{mainPage.HelpText}</div>
                            <div className="WizardPageElements">
                                <div className="WizardPageAddedObjects">
                                    {
                                        addedObjects.map((object, index) => {
                                            return <SubPageSummary
                                                key={index}
                                                subPageObject={object}
                                                onReviewClick={() => { selectSubPageObject(subPageElementGUID, index) }}
                                                onRemoveClick={() => { removeSubPageObject(subPageElementGUID, index) }}
                                            />
                                        })
                                    }
                                </div>
                                <div className={style.WizardPageObjectAddButtons}>
                                    {
                                        subForms.map(subForm => {
                                            return <ActionButton
                                                key={subForm.Id}
                                                className={style.WizardPageObjectAddButton}
                                                backgroundColor="#e9edf0"
                                                color="#2B2D42"
                                                onClick={() => { selectSubForm(subForm.Id) }}>
                                                    <img src={AddCircle} alt="Add" className={style.WizardPageObjectAddButtonIcon} />
                                                    {
                                                        `${subPageAddButtonTitle}${subForms.length > 1 ? ` - ${subForm.ShortDescription}` : ``}`
                                                    }
                                            </ActionButton>
                                        })
                                    }
                                </div>
                            </div>
                        </>
                }


            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        mainPage: getCurrentParentPage(state),
        subForms: getCurrentParentPage(state).WizardSubForms,
        currentSubFormPages: getCurrentSubFormPages(state),
        selectedPage: state.wizardReducer.selectedPage,
        selectedSubForm: state.wizardReducer.selectedSubForm,
        selectedSubPage: state.wizardReducer.selectedSubPage,
        addedObjects: getCurrentPageSubPageObjects(state),
        subPageElementGUID: getSubPageElementGUID(state),
        subPageAddButtonTitle: getSubPageAddButtonTitle(state),
        nameDetectionActive: getProfileValue("Company.FirstReport.AzureNameDetection", state) === "Yes",
        isPageLoading: state.wizardReducer.isPageLoading,
        namesDetected: state.wizardReducer.namesDetected,


    };
};

const mapDispatchToProps = (dispatch) => ({
    selectSubForm: formId => {
        dispatch(selectSubForm(formId));
    },
    goToSubPage: subPage => {
        dispatch(goToSubPage(subPage));
    },
    selectSubPageObject: (elementId, index) => {
        dispatch(selectSubPageObject(elementId, index));
    },
    removeSubPageObject: (elementId, index) => {
        dispatch(removeSubPageObject(elementId, index));
    },
    moveToPage: () => {
        dispatch(moveToPage(undefined));
    },
    clearNERWarning: () => {
        dispatch(clearNERWarning());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardPageWithAdd);
