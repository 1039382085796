import _ from 'lodash';
import {
    GO_TO_PAGE,
    ADD_SUB_PAGE_OBJECT,
    UPDATE_SUB_PAGE_OBJECT,
    SELECT_SUB_FORM,
    GO_TO_NEXT_SUB_PAGE,
    GO_TO_PREVIOUS_SUB_PAGE,
    GO_TO_SUB_PAGE,
    CLEAR_SUB_PAGE,
    SELECT_SUB_PAGE_OBJECT,
    REMOVE_SUB_PAGE_OBJECT,
    UPDATE_ELEMENT_ANSWER,
    UPDATE_FIELD_ANSWER,
    UPDATE_FIELD_SETTING,
    CLEAR_WIZARD,
    SET_USER_TYPE,
    TOGGLE_SIDE_MENU,
    SET_INVALID,
    SET_PAGE_REACHED,
    UPDATE_ADDITIONAL_RECIPIENTS,
    GET_SITEMAP_IMAGE_LIST_BEGIN,
    GET_SITEMAP_IMAGE_LIST_SUCCESS,
    GET_SITEMAP_IMAGE_LIST_FAILURE,
    GET_SITEMAP_IMAGE_BEGIN,
    GET_SITEMAP_IMAGE_SUCCESS,
    GET_SITEMAP_IMAGE_FAILURE,
    RELOAD_OBJECT_PICKLIST_VALUES_BEGIN,
    RELOAD_OBJECT_PICKLIST_VALUES_FAILURE,
    RELOAD_OBJECT_PICKLIST_VALUES_SUCCESS,
    LOAD_RELATED_DOCUMENT_SUCCESS,
    UPLOAD_RELATED_DOCUMENT_BEGIN,
    UPLOAD_RELATED_DOCUMENT_SUCCESS,
    UPLOAD_RELATED_DOCUMENT_FAILURE,
    LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_BEGIN,
    LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_SUCCESS,
    LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_FAILURE,
    CLEAR_SCROLL_TO_FIRST,
    CLEAR_SCROLL_TO_TOP,
    SPEECH_TO_TEXT_SUCCESS,
    SET_FIELD_MIC_IN_USE,
    TRANSLATION_REQUEST_START,
    TRANSLATION_SUCCESS,
    TRANSLATION_FAILURE,
    SET_NAMES_FOUND,
    SET_PAGE_LOADING,
    CLEAR_NER_WARNING,
    LIVE_SEARCH_LIST_SUCCESS,
    LIVE_SEARCH_LIST_REQUEST_START,
    LIVE_SEARCH_LIST_REQUEST_CANCEL,
    LIVE_SEARCH_LIST_FAILURE, 
    RESOLVE_LABEL_SUCCESS,
} from '../actions/wizardActions';
import { CONTINUE_DRAFT_SUCCESS, SET_MANUAL_USER } from '../actions/appActions';
import { USER_PAGE_VALIDATION_TYPES } from '../constants/ValidationTypes';
import { isValidAnswer } from '../utils/convertAnswer';
import { EN_GB } from "../constants/LanguageFlags";
import produce, { enableES5 } from "immer";
enableES5();

const initialState = {
    selectedPage: 0,
    pageReached: 0,
    selectedSubForm: -1,
    selectedSubPage: -1,
    selectedSubPageObject: -1,
    currentSubPageObject: {},
    siteMapLoading: [],
    siteMaps: {},
    speechToText: {},
    siteMapImageListLoading: [],
    siteMapImageLists: {},
    hierarchySiteMapAttributesLoading: [],
    translationIsBeingProcessed: false,
    answers: {
        elements: {},
        fields: {},
        subPageObjects: {},
        resolvedLabels: {},
    },
    invalid: {
        elements: [],
        fields: [],
        pages: {
            USER_PAGE: [],
        },
        scrollToFirst: '',
        currentSubPageObject: {
            elements: [],
            fields: [],
            scrollToFirst: '',
        },
    },
    userTypeSelected: 0,
    sideMenuOpen: false,
    additionalRecipients: [],
    objectPicklistLoading: [],
    objectPicklistValues: {},
    updated: {
        elements: [],
        fields: [],
    },
    scrollToTop: false,
    fieldMicInUse: {},
    settings: {
        fields: {}
    },
    namesDetected: [],
    isPageLoading: false,

};

function goToPage(state, page) {
    return {
        ...state,
        selectedPage: page,
        selectedSubPage: -1,
        pageReached: Math.max(page, state.pageReached),
        sideMenuOpen: false,
        scrollToTop: true,
    };
}

function addSubPageObject(state, elementId) {
    const pageSubPageObjects = state.answers.subPageObjects[elementId] || []
    const formId = state.selectedSubForm;

    return {
        ...state,
        selectedSubForm: -1,
        selectedSubPage: -1,
        selectedSubPageObject: -1,
        currentSubPageObject: {},
        answers: {
            ...state.answers,
            subPageObjects: {
                ...state.answers.subPageObjects,
                [elementId]: [
                    ...pageSubPageObjects,
                    {
                        ...state.currentSubPageObject,
                        formId
                    }
                ]
            }
        }
    }
}

function updateSubPageObject(state, elementId, index) {
    let pageSubPageObjects = state.answers.subPageObjects[elementId] || []
    pageSubPageObjects[index] = state.currentSubPageObject;

    return {
        ...state,
        selectedSubPage: -1,
        selectedSubPageObject: -1,
        currentSubPageObject: {},
        answers: {
            ...state.answers,
            subPageObjects: {
                ...state.answers.subPageObjects,
                [elementId]: pageSubPageObjects
            }
        }
    }
}

function selectSubForm(state, formId) {
    return {
        ...state,
        selectedSubForm: formId,
        selectedSubPage: 0
    }
}

function goToNextSubPage(state) {
    return {
        ...state,
        selectedSubPage: state.selectedSubPage + 1
    };
}

function goToPreviousSubPage(state) {
    return {
        ...state,
        selectedSubPage: state.selectedSubPage - 1
    };
}

function goToSubPage(state, subPage) {
    return {
        ...state,
        selectedSubPage: subPage
    };
}

function clearSubPage(state) {
    return {
        ...state,
        selectedSubForm: -1,
        selectedSubPage: -1,
        selectedSubPageObject: -1,
        currentSubPageObject: {},
        invalid: {
            ...state.invalid,
            currentSubPageObject: {
                fields: [],
                elements: [],
            }
        }
    }
}

function selectSubPageObject(state, elementId, index) {
    const currentSubPageObject = state.answers.subPageObjects[elementId][index];
    return {
        ...state,
        selectedSubPageObject: index,
        selectedSubForm: currentSubPageObject.formId,
        selectedSubPage: 0,
        currentSubPageObject
    }
}

function removeSubPageObject(state, elementId, indexToRemove) {
    let pageSubPageObjects = [...state.answers.subPageObjects[elementId]] || []
    pageSubPageObjects[indexToRemove].deleted = true;

    return {
        ...state,
        answers: {
            ...state.answers,
            subPageObjects: {
                ...state.answers.subPageObjects,
                [elementId]: pageSubPageObjects
            }
        }
    }
}

function updateElement(state, elementId, value) {
    const { selectedSubPage } = state;
    if (selectedSubPage === -1) {
        return {
            ...state,
            answers: {
                ...state.answers,
                elements: {
                    ...state.answers.elements,
                    [elementId]: value
                }
            },
            invalid: {
                ...state.invalid,
                elements: state.invalid.elements.filter(e => e.element !== elementId)
            },
            updated: {
                ...state.updated,
                elements: _.uniq([
                    ...state.updated.elements,
                    elementId
                ])
            }
        };
    } else {
        return {
            ...state,
            currentSubPageObject: {
                ...state.currentSubPageObject,
                elements: {
                    ...state.currentSubPageObject.elements,
                    [elementId]: value
                }
            },
            invalid: {
                ...state.invalid,
                currentSubPageObject: {
                    ...state.invalid.currentSubPageObject,
                    elements: state.invalid.currentSubPageObject.elements.filter(e => e.element !== elementId)
                }
            }
        }
    }
}

function updateField(state, fieldId, value) {
    const { selectedSubPage } = state;
    let addNonLangAttribute = false;
    if (fieldId.endsWith(EN_GB)) {
        addNonLangAttribute = true;
    }
    if (selectedSubPage === -1) {
        return {
            ...state,
            answers: {
                ...state.answers,
                fields: {
                    ...state.answers.fields,
                    ...(addNonLangAttribute ? {[fieldId.replace(/\.en-gb/, '')]: value} : {[fieldId]: value})
                }
            },
            invalid: {
                ...state.invalid,
                fields: state.invalid.fields.filter(f => f.field !== fieldId && !fieldId.match(`^${f.field}.[a-zA-Z]{2}-[a-zA-Z-]*$`))
            },
            updated: {
                ...state.updated,
                fields: _.uniq([
                    ...state.updated.fields,
                    fieldId
                ])
            }
        };
    } else {
        return {
            ...state,
            currentSubPageObject: {
                ...state.currentSubPageObject,
                fields: {
                    ...state.currentSubPageObject.fields,
                    ...(addNonLangAttribute ? {[fieldId.replace(/\.en-gb/, '')]: value} : {[fieldId]: value})
                }
            },
            invalid: {
                ...state.invalid,
                currentSubPageObject: {
                    ...state.invalid.currentSubPageObject,
                    fields: state.invalid.currentSubPageObject.fields.filter(f => f.field !== fieldId && !fieldId.match(`^${f.field}.[a-zA-Z]{2}-[a-zA-Z-]*$`))
                }
            }
        }
    }
}

function updateSetting(state, fieldId, settingName, settingValue) {
    return {
        ...state,
        settings: {
            ...state.settings,
            fields: {
                ...state.settings.fields,
                [fieldId]: {
                    ...state.settings.fields[fieldId],
                    [settingName]: settingValue
                }
            }
        }
    };
}

function updateForm(state, answers) {
    if (!answers) {
        return { ...state };
    }

    return {
        ...state,
        answers: {
            ...state.answers,
            fields: {
                ...state.answers.fields,
                ...answers.fields,
            },
            elements: {
                ...state.answers.elements,
                ...answers.elements,
            },
            subPageObjects: {
                ...answers.subPageObjects
            }
        }
    }
}

function clearWizard(state) {
    return {
        ...initialState,
        userTypeSelected: state.userTypeSelected,
    };
}

function setUserType(state, userTypeSelected) {
    return {
        ...state,
        userTypeSelected,
        invalid: {
            ...state.invalid,
            pages: {
                ...state.invalid.pages,
                USER_PAGE: []
            }
        }
    }
}

function toggleSideMenu(state) {
    return {
        ...state,
        sideMenuOpen: !state.sideMenuOpen
    }
}

function getSiteMapImageBegin(state, elementGuid) {

    return {
        ...state,
        siteMapLoading: [
            ...state.siteMapLoading,
            elementGuid
        ],
        siteMaps: {
            ...state.siteMaps,
            [elementGuid]: undefined
        }
    }
}

function getSiteMapImageSuccess(state, elementGuid, image) {

    return {
        ...state,
        siteMapLoading: state.siteMapLoading.filter(i => {
            return i !== elementGuid;
        }),
        siteMaps: {
            ...state.siteMaps,
            [elementGuid]: image
        }
    }
}

function getSiteMapImageFailure(state, elementGuid, error) {

    return {
        ...state,
        siteMapLoading: state.siteMapLoading.filter(i => {
            return i !== elementGuid;
        }),
        siteMaps: {
            ...state.siteMaps,
            [elementGuid]: undefined
        }
    }
}

function getSiteMapImageListBegin(state, elementGuid) {

    return {
        ...state,
        siteMapImageListLoading: [
            ...state.siteMapImageListLoading,
            elementGuid
        ],
        siteMapImageLists: {
            ...state.siteMapsImageLists,
            [elementGuid]: undefined
        }
    }
}

function getSiteMapImageListSuccess(state, elementGuid, imageList) {

    return {
        ...state,
        siteMapImageListLoading: state.siteMapImageListLoading.filter(i => {
            return i !== elementGuid;
        }),
        siteMapImageLists: {
            ...state.siteMapsImageLists,
            [elementGuid]: imageList.sort((a, b) => a.name.localeCompare(b.name))
        }
    }
}

function getSiteMapImageListFailure(state, elementGuid, error) {

    return {
        ...state,
        siteMapImageListLoading: state.siteMapImageListLoading.filter(i => {
            return i !== elementGuid;
        }),
        siteMapImageLists: {
            ...state.siteMapsImageLists,
            [elementGuid]: undefined
        }
    }
}

function setInvalid(state, invalid, isSubPage) {
    return isSubPage ?
        {
            ...state,
            invalid: {
                ...state.invalid,
                currentSubPageObject: {
                    ...state.invalid.currentSubPageObject,
                    elements: _.uniq([
                        ...state.invalid.currentSubPageObject.elements,
                        ...invalid.elements,
                    ]),
                    fields: _.uniq([
                        ...state.invalid.currentSubPageObject.fields,
                        ...invalid.fields,
                    ]),
                    scrollToFirst: invalid.scrollToFirst
                }
            }
        }
        :
        {
            ...state,
            invalid: {
                ...state.invalid,
                fields: _.uniq([
                    ...state.invalid.fields,
                    ...invalid.fields,
                ]),
                elements: _.uniq([
                    ...state.invalid.elements,
                    ...invalid.elements,
                ]),
                pages: {
                    ...state.invalid.pages,
                    ...invalid.pages,
                },
                scrollToFirst: invalid.scrollToFirst
            }
        }
}

function clearScrollToFirst(state) {
    return {
        ...state,
        invalid: {
            ...state.invalid,
            currentSubPageObject: {
                ...state.invalid.currentSubPageObject,
                scrollToFirst: ''
            },
            scrollToFirst: ''
        }
    }
}

function setPageReached(state, pageReached) {
    return {
        ...state,
        pageReached
    };
}

function updateAdditionalRecipients(state, additionalRecipients) {
    return {
        ...state,
        additionalRecipients
    };
}

function reloadObjectPicklistValuesBegin(state, attributes) {
    return {
        ...state,
        objectPicklistLoading: _.uniq([...state.objectPicklistLoading, ...attributes])
    };
}

function reloadObjectPicklistValuesFailure(state, error, attributes) {
    return {
        ...state,
        objectPicklistLoading: state.objectPicklistLoading.filter(o => attributes.indexOf(o) === -1)
    };
}

function reloadObjectPicklistValuesSuccess(state, values) {

    let updatedAnswers = {};
    for (let [attribute, fieldValues] of Object.entries(values)) {
        const fieldAnswer = state.answers.fields[attribute];
        if (isValidAnswer(fieldAnswer)) {
            if (_.isArray(fieldAnswer)) {
                updatedAnswers[attribute] = fieldAnswer.filter((answer) => {
                    return fieldValues.find(v => typeof v.Identifier === typeof answer
                        ? v.Identifier === answer
                        : v.Label && v.Label.includes(answer));
                });
            } else {
                updatedAnswers[attribute] = fieldValues.findIndex(v => v.Identifier === fieldAnswer) > -1 ? fieldAnswer : undefined;
            }
        }
    }

    return {
        ...state,
        objectPicklistLoading: state.objectPicklistLoading.filter(attribute => !values[attribute]),
        objectPicklistValues: {
            ...state.objectPicklistValues,
            ...values
        },
        answers: {
            ...state.answers,
            fields: {
                ...state.answers.fields,
                ...updatedAnswers,
            }
        }
    };
}

const loadRelatedDocumentSuccess = (state, elementGuid, document) => {

    const { selectedSubPage } = state;

    let documents;

    if (selectedSubPage === -1) {
        documents = [...state.answers.elements[elementGuid]];
    } else {
        documents = [...state.currentSubPageObject.elements[elementGuid]]
    }

    const index = documents.findIndex(item => item.id === document.id);
    if (index > -1) {
        documents[index] = document;
    }

    return {
        ...state,
        answers: {
            ...state.answers,
            elements: {
                ...state.answers.elements,
                [elementGuid]: documents
            }
        }
    }
}

const uploadRelatedDocumentBegin = (state, elementGuid, index) => {
    return getRelatedDocumentUpdatedState(state, elementGuid, index, true, false);
}

const uploadRelatedDocumentSuccess = (state, elementGuid, index) => {
    return getRelatedDocumentUpdatedState(state, elementGuid, index, false, false);
}

const uploadRelatedDocumentFailure = (state, elementGuid, error, index) => {
    return getRelatedDocumentUpdatedState(state, elementGuid, index, false, true);
}

const getRelatedDocumentUpdatedState = (state, elementGuid, index, isLoading, hasError) => {

    const { selectedSubPage } = state;

    let documents;

    if (selectedSubPage === -1) {
        documents = [...state.answers.elements[elementGuid]];
    } else {
        documents = [...state.currentSubPageObject.elements[elementGuid]]
    }

    documents[index].isLoading = isLoading;
    documents[index].hasError = hasError;

    if (selectedSubPage === -1) {
        return {
            ...state,
            answers: {
                ...state.answers,
                elements: {
                    ...state.answers.elements,
                    [elementGuid]: documents
                }
            }
        }
    } else {
        return {
            ...state,
            currentSubPageObject: {
                ...state.currentSubPageObject,
                elements: {
                    ...state.currentSubPageObject.elements,
                    [elementGuid]: documents
                }
            }
        }
    }
}

const loadHierarchySiteMapAttributesBegin = (state, elementGuid) => {
    return {
        ...state,
        hierarchySiteMapAttributesLoading: [
            ...state.hierarchySiteMapAttributesLoading,
            elementGuid
        ]
    }
}

const loadHierarchySiteMapAttributesSuccess = (state, elementGuid, data) => {
    return {
        ...state,
        answers: {
            ...state.answers,
            elements: {
                ...state.answers.elements,
                [elementGuid]: {
                    ...state.answers.elements[elementGuid],
                    latitude: data.latitude,
                    longitude: data.longitude,
                    documentID: data.documentID,
                    hierarchyLocationUpdated: true,
                }
            }
        },
        hierarchySiteMapAttributesLoading: state.hierarchySiteMapAttributesLoading.filter(i => {
            return i !== elementGuid;
        }),
    }
}

const loadHierarchySiteMapAttributesFailure = (state, elementGuid) => {
    return {
        ...state,
        answers: {
            ...state.answers,
            elements: {
                ...state.answers.elements,
                [elementGuid]: {
                    ...state.answers.elements[elementGuid],
                    documentID: undefined,
                    hierarchyLocationUpdated: true,
                }
            }
        },
        hierarchySiteMapAttributesLoading: state.hierarchySiteMapAttributesLoading.filter(i => {
            return i !== elementGuid;
        }),
    }
}

const clearScrollToTop = (state) => {
    return {
        ...state,
        scrollToTop: false,
    }
}

const clearManualUserInvalid = (state, userInfo) => {
    return {
        ...state,
        invalid: {
            ...state.invalid,
            pages: {
                ...state.invalid.pages,
                USER_PAGE: state.invalid.pages.USER_PAGE.filter(p => {

                    if (userInfo.name) {
                        return p !== USER_PAGE_VALIDATION_TYPES.MANUAL_NAME;
                    }
                    if (userInfo.email) {
                        return p !== USER_PAGE_VALIDATION_TYPES.MANUAL_EMAIL;
                    }
                    if (userInfo.phone) {
                        return p !== USER_PAGE_VALIDATION_TYPES.MANUAL_PHONE;
                    }
                    return true;
                })
            }
        }
    }
}

const updateTextFieldValue = (state, fieldId, value) => {
    return {
        ...state,
        speechToText: {
            ...state.speechToText,
            [fieldId]: value
        },
    }
}

const setFieldMicInUse = (state, fieldId, inUse) => {
    return {
        ...state,
        fieldMicInUse: {
            ...state.fieldMicInUse,
            [fieldId]: inUse,
        }
    }
}

const setNERDetectedNames = (state, names) => {
    return {
        ...state,
        namesDetected: names
    }
}

const setPageLoading = (state, bool) => {
    return {
        ...state,
        isPageLoading: bool
    }
}

const clearNERWarning = (state) => {
    return {
        ...state,
        namesDetected: []
    }
}

function reducer(state = initialState, action) {
    let newState = null;

    switch (action.type) {
        case GO_TO_PAGE:
            newState = goToPage(state, action.page);
            break;
        case ADD_SUB_PAGE_OBJECT:
            newState = addSubPageObject(state, action.elementId);
            break;
        case UPDATE_SUB_PAGE_OBJECT:
            newState = updateSubPageObject(state, action.elementId, action.index);
            break;
        case SELECT_SUB_FORM:
            newState = selectSubForm(state, action.formId);
            break;
        case GO_TO_NEXT_SUB_PAGE:
            newState = goToNextSubPage(state);
            break;
        case GO_TO_PREVIOUS_SUB_PAGE:
            newState = goToPreviousSubPage(state);
            break;
        case GO_TO_SUB_PAGE:
            newState = goToSubPage(state, action.subPage);
            break;
        case CLEAR_SUB_PAGE:
            newState = clearSubPage(state);
            break;
        case SELECT_SUB_PAGE_OBJECT:
            newState = selectSubPageObject(state, action.elementId, action.index);
            break;
        case REMOVE_SUB_PAGE_OBJECT:
            newState = removeSubPageObject(state, action.elementId, action.index);
            break;
        case UPDATE_ELEMENT_ANSWER:
            newState = updateElement(state, action.elementId, action.value);
            break;
        case UPDATE_FIELD_ANSWER:
            newState = updateField(state, action.fieldId, action.value);
            break;
        case UPDATE_FIELD_SETTING:
            newState = updateSetting(state, action.fieldId, action.settingName, action.settingValue);
            break;
        case CONTINUE_DRAFT_SUCCESS:
            newState = updateForm(state, action.answers);
            break;
        case CLEAR_WIZARD:
            newState = clearWizard(state);
            break;
        case SET_USER_TYPE:
            newState = setUserType(state, action.userTypeSelected);
            break;
        case TOGGLE_SIDE_MENU:
            newState = toggleSideMenu(state);
            break;
        case SET_INVALID:
            newState = setInvalid(state, action.invalid, action.isSubPage);
            break;
        case CLEAR_SCROLL_TO_FIRST:
            newState = clearScrollToFirst(state);
            break;
        case SET_PAGE_REACHED:
            newState = setPageReached(state, action.pageReached);
            break;
        case UPDATE_ADDITIONAL_RECIPIENTS:
            newState = updateAdditionalRecipients(state, action.additionalRecipients);
            break;
        case GET_SITEMAP_IMAGE_LIST_BEGIN:
            newState = getSiteMapImageListBegin(state, action.elementGuid)
            break;
        case GET_SITEMAP_IMAGE_LIST_SUCCESS:
            newState = getSiteMapImageListSuccess(state, action.elementGuid, action.imageList)
            break;
        case GET_SITEMAP_IMAGE_LIST_FAILURE:
            newState = getSiteMapImageListFailure(state, action.elementGuid, action.error)
            break;
        case GET_SITEMAP_IMAGE_BEGIN:
            newState = getSiteMapImageBegin(state, action.elementGuid)
            break;
        case GET_SITEMAP_IMAGE_SUCCESS:
            newState = getSiteMapImageSuccess(state, action.elementGuid, action.image)
            break;
        case GET_SITEMAP_IMAGE_FAILURE:
            newState = getSiteMapImageFailure(state, action.elementGuid, action.error)
            break;
        case RELOAD_OBJECT_PICKLIST_VALUES_BEGIN:
            newState = reloadObjectPicklistValuesBegin(state, action.attributes)
            break;
        case RELOAD_OBJECT_PICKLIST_VALUES_FAILURE:
            newState = reloadObjectPicklistValuesFailure(state, action.error, action.attributes)
            break;
        case RELOAD_OBJECT_PICKLIST_VALUES_SUCCESS:
            newState = reloadObjectPicklistValuesSuccess(state, action.values)
            break;
        case LOAD_RELATED_DOCUMENT_SUCCESS:
            newState = loadRelatedDocumentSuccess(state, action.elementGuid, action.document);
            break;
        case UPLOAD_RELATED_DOCUMENT_BEGIN:
            newState = uploadRelatedDocumentBegin(state, action.elementGuid, action.index);
            break;
        case UPLOAD_RELATED_DOCUMENT_SUCCESS:
            newState = uploadRelatedDocumentSuccess(state, action.elementGuid, action.index);
            break;
        case UPLOAD_RELATED_DOCUMENT_FAILURE:
            newState = uploadRelatedDocumentFailure(state, action.elementGuid, action.error, action.index);
            break;
        case LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_BEGIN:
            newState = loadHierarchySiteMapAttributesBegin(state, action.elementGuid);
            break;
        case LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_SUCCESS:
            newState = loadHierarchySiteMapAttributesSuccess(state, action.elementGuid, action.data);
            break;
        case LOAD_HIERARCHY_SITEMAP_ATTRIBUTES_FAILURE:
            newState = loadHierarchySiteMapAttributesFailure(state, action.elementGuid);
            break;
        case CLEAR_SCROLL_TO_TOP:
            newState = clearScrollToTop(state);
            break;
        case SET_MANUAL_USER:
            newState = clearManualUserInvalid(state, action.userInfo);
            break;
        case SPEECH_TO_TEXT_SUCCESS:
            newState = updateTextFieldValue(state, action.fieldId, action.value);
            break;
        case SET_FIELD_MIC_IN_USE:
            newState = setFieldMicInUse(state, action.fieldId, action.inUse);
            break;
        case TRANSLATION_REQUEST_START:
            newState = {...state, translationIsBeingProcessed: {...action.payload}};
            break;
        case TRANSLATION_SUCCESS:
        case TRANSLATION_FAILURE:
            newState = {...state, translationIsBeingProcessed: false};
            break;
        case SET_NAMES_FOUND:
            newState = setNERDetectedNames(state, action.names)
            break;
        case SET_PAGE_LOADING:
            newState = setPageLoading(state, action.bool)
            break;
        case CLEAR_NER_WARNING:
            newState = clearNERWarning(state)
            break;
        case LIVE_SEARCH_LIST_REQUEST_START:
            newState = {...state, liveSearchIsBeingProcessed: {...action.payload}};
            break;
        case LIVE_SEARCH_LIST_REQUEST_CANCEL:
            newState = {...state, liveSearchIsBeingProcessed: false};
            break;
        case RESOLVE_LABEL_SUCCESS:
            newState = produce(state, draftState => {
                // action.payload,
                let {resolvedLabels} = draftState.answers;
                const getRequestedLabelsObj = (key) => action.payload[key]
                    .reduce((acc, idAndLabel) => {
                        acc[idAndLabel.Identifier] = idAndLabel.Label;
                        return acc;
                    }, {});
                Object.keys(action.payload).forEach((key) => {
                    if (!resolvedLabels[key]) {
                        resolvedLabels[key] = getRequestedLabelsObj(key);
                    } else {
                        resolvedLabels[key] =
                            {...resolvedLabels[key], ...getRequestedLabelsObj(key)};
                    }
                });
            });
            break;
        case LIVE_SEARCH_LIST_SUCCESS:
            newState = produce(state, draftState => {
                draftState.liveSearchOptions = (draftState.liveSearchOptions || {});
                let fieldObj;
                draftState.liveSearchIsBeingProcessed = false;
                if (!draftState.liveSearchOptions[action.payload.fieldId] || _.isEmpty(action.payload.data)) {
                    draftState.liveSearchOptions[action.payload.fieldId] = {
                        CurrentPage: 1,
                        TotalPages: 1,
                        PageSize: 20,
                        Items: []
                    };
                }
                fieldObj = draftState.liveSearchOptions[action.payload.fieldId];
                if (!_.isEmpty(action.payload.data)) {
                    fieldObj.CurrentPage = action.payload.data.CurrentPage;
                    fieldObj.TotalPages = action.payload.data.TotalPages;
                    fieldObj.PageSize = action.payload.data.PageSize;
                    const mapped =
                        action.payload.data.Items.map((initialObj) => ({
                            value: initialObj.Identifier,
                            text: initialObj.Label || '',
                            selected: false,
                            additionalFields: initialObj.AdditionalValues
                        }));

                    if (!action.payload.data.CurrentPage || action.payload.data.CurrentPage === 1) {
                        fieldObj.Items = mapped;
                    } else {
                        fieldObj.Items = [...fieldObj.Items, ...mapped];
                    }
                }
            });
            break;
        case LIVE_SEARCH_LIST_FAILURE:
            newState = {...state, liveSearchIsBeingProcessed: false};
            break;
        default:
            newState = state;
            break;
    }

    return newState;
}

function getPersistedState(state) {
    return {
        selectedPage: state.selectedPage,
        pageReached: state.pageReached,
        selectedSubForm: state.selectedSubForm,
        selectedSubPage: state.selectedSubPage,
        selectedSubPageObject: state.selectedSubPageObject,
        currentSubPageObject: state.currentSubPageObject,
        answers: state.answers,
        additionalRecipients: state.additionalRecipients,
        objectPicklistValues: state.objectPicklistValues,
    };
};

function getLocalPersistedState(state) {
    return {
        userTypeSelected: state.userTypeSelected,
    };
}

export default {
    reducer,
    initialState,
    getPersistedState,
    getLocalPersistedState,
};
