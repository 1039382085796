import React from 'react';
import PropTypes from 'prop-types';
import { CloseDark } from '../../../../icons';
import './ChosenItem.css';

const ChosenItem = ({ label, onRemove, className, disabled }) => (
  <div className={`${className} ${disabled ? 'ChosenItemDisabled' : ''}`}>
    <span>{label}</span>
    {
      !disabled && <button
        onClick={onRemove}
        type="button"
      >
        <img
          src={CloseDark}
          alt="Remove"
        />
      </button>
    }
  </div>
);

ChosenItem.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ChosenItem.defaultProps = {
  onRemove: () => {},
  className: 'ChosenItem',
  disabled: false,
};

export default ChosenItem;
