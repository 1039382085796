import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import Flag from 'react-flagkit';
import { ArrowDropdownDark, ArrowDropdownWhite } from '../../icons';
import { getFlagCode } from '../../constants/LanguageFlags';
import { updateLanguage } from '../../actions/appActions';
import style from './LanguagePicker.module.scss';
import Translation from '../Translation/Translation';
const classNames = require('classnames');

class LanguagePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.closeIfNeeded = this.closeIfNeeded.bind(this);
        this.selectLanguage = this.selectLanguage.bind(this);
        this.container = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.closeIfNeeded);
        document.addEventListener('focusin', this.closeIfNeeded);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeIfNeeded);
        document.removeEventListener('focusin', this.closeIfNeeded);
    }

    closeIfNeeded(e) {
        const { isOpen } = this.state;
        if (isOpen && this.container && this.container.current && !this.container.current.contains(e.target)) {
            this.setState({ isOpen: false});
        }
    }

    selectLanguage(selected) {
        const { updateLanguage } = this.props;
        updateLanguage(selected);
        this.setState({
            isOpen: false
        });
    }

    toggleOpen() {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });
    }

    render() {
        const { availableLanguages, selectedLanguage, lightBackground } = this.props;
        const { isOpen } = this.state;
        const selectedLanguageItem = availableLanguages.find(lang => {
            return lang.ISOCode === selectedLanguage;
        });
        const selectedLanguageName = selectedLanguageItem ? selectedLanguageItem.LanguageName : 'English';
        const pickerClasses = [style.LanguagePicker];
        if(lightBackground) {
            pickerClasses.push(style.LanguagePickerLightBackground);
        }

        return (
            <div className={classNames([pickerClasses])}>
                <div className={style.LanguagePickerButton} onClick={this.toggleOpen}>
                    <div className={style.LanguagePickerFlag}>
                        <Flag country={getFlagCode(selectedLanguage.toLowerCase())} size="21" />
                    </div>
                    <div className={style.LanguagePickerLanguage}>
                        <Translation>{selectedLanguageName}</Translation>
                    </div>
                    <div className={style.LanguagePickerCaret}>
                        <img src={lightBackground ? ArrowDropdownDark : ArrowDropdownWhite} alt="Language" />
                    </div>
                </div>
                {
                    isOpen && (
                        <div className={style.LanguagePickerDropdown} ref={this.container}>
                            {
                                availableLanguages.map(language => {
                                    return (
                                        <div className={style.LanguagePickerDropdownRow} key={v4()} onClick={() => this.selectLanguage(language.ISOCode)}>
                                            <div className={style.LanguagePickerDropdownFlag}>
                                                <Flag country={getFlagCode(language.ISOCode.toLowerCase())} size="21" />
                                            </div>
                                            <div className={style.LanguagePickerDropdownLanguage}>
                                                <span><Translation>{language.LanguageName}</Translation></span>
                                                <span> (</span>
                                                <span><Translation>{language.CountryName}</Translation></span>
                                                <span>)</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { appReducer } = state;

    return {
        availableLanguages: [...appReducer.availableLanguages].sort((a, b) => {
            const nameA = a.LanguageName.toUpperCase();
            const nameB = b.LanguageName.toUpperCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        }),
        selectedLanguage: appReducer.userSettings.language
    };
};

const mapDispatchToProps = dispatch => ({
    updateLanguage: language => {
        dispatch(updateLanguage(language));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
