import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { ArrowDropdownBlue } from '../../../../icons';
import './SiteMapPicker.scss';

class SiteMapPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            selected: props.selected
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.closeIfNeeded = this.closeIfNeeded.bind(this);
        this.selectSiteMap = this.selectSiteMap.bind(this);
        this.container = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.closeIfNeeded);
        document.addEventListener('focusin', this.closeIfNeeded);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.closeIfNeeded);
        document.removeEventListener('focusin', this.closeIfNeeded);
    }

    componentDidUpdate(prevProps) {
        const { selected } = this.props;
        if (prevProps.selected !== selected) {
            this.setState({
                selected
            });
        }
    }

    closeIfNeeded(e) {
        const { isOpen } = this.state;
        if (isOpen && this.container && this.container.current && !this.container.current.contains(e.target)) {
            this.setState({ isOpen: false });
        }
    }

    selectSiteMap(selected) {
        const { onChange } = this.props;
        this.setState({
            isOpen: false,
            selected,
        }, onChange && onChange(selected));
    }

    toggleOpen() {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });
    }

    render() {
        const { options } = this.props;
        const { isOpen, selected } = this.state;
        const selectedItem = options && options.find(option => {
            return option.id === selected;
        });
        const selectedItemName = selectedItem && selectedItem.name;

        return (
            <>
                {
                    selectedItem &&
                    <div className="SiteMapPicker">
                        <div className="SiteMapPickerButton" onClick={this.toggleOpen}>
                            <div className="SiteMapPickerTitle">{selectedItemName}</div>
                            <div className="SiteMapPickerCaret">
                                <img src={ArrowDropdownBlue} alt="Language" />
                            </div>
                        </div>
                        {
                            isOpen && (
                                <div className="SiteMapPickerDropdown" ref={this.container}>
                                    {
                                        options.map(option => {
                                            return (
                                                <div className="SiteMapPickerDropdownRow" key={v4()} onClick={() => this.selectSiteMap(option.id)}>
                                                    <div className="SiteMapPickerDropdownTitle">{option.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SiteMapPicker);